import { useState } from "react";
import { Modal } from "react-bootstrap";

const UpdateDetailsPopup = (props) => {
  const { profileDetails, setProfileDetails } = props;
  const [email, setEmail] = useState(profileDetails?.email);
  const [contact] = useState(profileDetails?.contact);
  const [name, setName] = useState(profileDetails?.name);
  const [selectGender, setSelectGender] = useState<any>(
    props?.profileDetails?.gender
  );
  const [selectedAge, setSelectedAge] = useState<any>(
    props?.profileDetails?.age
  );

  const ageOptions = [];
  for (let i = 1; i <= 100; i++) {
    ageOptions.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  const handleProfile = () => {
    props.setReqGender(selectGender);
    props.setReqAge(selectedAge);
    setProfileDetails(() => ({
      name: name,
      contact: contact,
      email: email,
      age: selectedAge,
      gender: selectGender,
    }));
    props.onHide();
  };
  return (
    <Modal {...props} centered dialogClassName="width-734">
      <Modal.Body style={{ height: "fit-content" }}>
        <div className="emodal_wrapper">
          <div className="eheading position-relative d-flex justify-content-center align-items-center">
            <div className="">
              <p className="e_head_txt">Update details</p>
            </div>
            <span
              className="position-absolute eclose_btn"
              onClick={props.onHide}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.2097 4.3871L4.29289 4.29289C4.65338 3.93241 5.22061 3.90468 5.6129 4.2097L5.70711 4.29289L12 10.585L18.2929 4.29289C18.6834 3.90237 19.3166 3.90237 19.7071 4.29289C20.0976 4.68342 20.0976 5.31658 19.7071 5.70711L13.415 12L19.7071 18.2929C20.0676 18.6534 20.0953 19.2206 19.7903 19.6129L19.7071 19.7071C19.3466 20.0676 18.7794 20.0953 18.3871 19.7903L18.2929 19.7071L12 13.415L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071C3.90237 19.3166 3.90237 18.6834 4.29289 18.2929L10.585 12L4.29289 5.70711C3.93241 5.34662 3.90468 4.77939 4.2097 4.3871L4.29289 4.29289L4.2097 4.3871Z"
                  fill="#25272D"
                />
              </svg>
            </span>
          </div>
          <div className="ep_user_content">
            <div className="ep_input">
              <label className="ep_input_label">Name</label>
              <input
                type="text"
                placeholder="Name"
                className="ep_input"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="d-flex eu_age_gender">
              <div className="ep_input up_age">
                <label className="ep_input_label">Age</label>
                <select
                  value={selectedAge}
                  onChange={(e) => setSelectedAge(e.target.value)}
                  style={{ backgroundColor: "white", border: "none" }}
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ color: "#8F939A" }}
                  >
                    Select Age
                  </option>
                  {ageOptions}
                </select>
              </div>
              <div className="ep_input up_gender ">
                <label className="ep_input_label">Gender</label>
                <div className="d-flex ">
                  <select
                    value={selectGender}
                    onChange={(e) => setSelectGender(e.target.value)}
                    style={{ backgroundColor: "white", border: "none" }}
                  >
                    <option
                      value=""
                      disabled
                      selected
                      style={{ color: "#8F939A" }}
                    >
                      Select a Gender
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Non-Binary">Prefer not to say</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="ep_input">
              <span className="ep_input_label mt-0">Email</span>
              <input
                type="text"
                placeholder="Email Address"
                className="ep_input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {/* <div className="ep_input">
                            <label>Contact Number</label>
                            <input
                                type="tel"
                                placeholder="Contact Number"
                                className="ep_input"
                                value={contact}
                                onChange={(e) => setContact(e.target.value)}
                            />
                        </div> */}
            {/* 
                        <div className="form_group">
                            <div
                                className={"input_box"}
                            >
                                <span className="input_label">
                                    {"Contact Number"}
                                </span>
                                <ReactInputMask
                                    mask={"+6\\1 999 999 999 9"}
                                    maskChar={null}
                                    value={`${contact}`}
                                    onChange={(e) => setContact(e.target.value)}
                                    placeholder={"Contact Number"}
                                />
                              
                            </div>
                        </div> */}
          </div>
          <div className="d-flex justify-content-end">
            <div
              style={{ width: "100%" }}
              className="ud_cont mt-4"
              onClick={handleProfile}
            >
              Save Changes
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default UpdateDetailsPopup;
