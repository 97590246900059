import React from "react";
import { compressLargeNumber } from "../../../constants/helperConnectUser";

type Props = {
  [key: string]: any;
};

const Avatar = (props: Props) => {
  return (
    <img
      className="avatar_img"
      src={props?.profile || "/static/connect/avatar.svg"}
      alt=""
    />
  );
};

export default Avatar;
