import {
  DIFFICULTY,
  GENDER_PREFERENCE,
  RECURRING_TYPE,
  SCHEDULE_TYPE,
} from "../types";
import { apiConstants } from "./Constants";
export const loginForm = {
  [apiConstants.email]: "",
  [apiConstants.password]: "",
};
export const registrationForm = {
  [apiConstants.user_type]: "",
  [apiConstants.email]: "",
};

export const profileForm: any = {
  [apiConstants.profileImage]: "",
  [apiConstants.gender]: "",
  [apiConstants.username]: "",
  [apiConstants.bio]: "",
  [apiConstants.address]: "",
  [apiConstants.abn]: "",
  [apiConstants.businessContactNumber]: "",
  [apiConstants.businessContactEmail]: "",
  [apiConstants.day]: "",
  [apiConstants.month]: "",
  [apiConstants.year]: "",
  [apiConstants.isTrainer]: false,
};
export const businessInterestForm = {
  [apiConstants.business_interest]: "",
};
export const trainerExpertiseForm = {
  [apiConstants.trainer_expertise]: "",
};
export const uploadDocumentsForm = {
  [apiConstants.trainer_expertise]: "",
};

export const ListingFormDefaultValue: any = {
  [apiConstants.listing_type]: null,
  [apiConstants.isDraft]: false,
  [apiConstants.listing_name]: "",
  [apiConstants.listing_description]: "",
  [apiConstants.media]: [],

  [apiConstants.eventInstruction]: "",
  [apiConstants.selectedInterest]: [],
  [apiConstants.selectedInstruction]: [],
  [apiConstants.listing_location]: null,
  [apiConstants.max_participants]: null,
  [apiConstants.lat]: null,
  [apiConstants.lng]: null,
  [apiConstants.city]: null,
  [apiConstants.state]: null,
  [apiConstants.country]: null,
  [apiConstants.postCode]: null,
  [apiConstants.gender_preference]: [],
  [apiConstants.selectedGender]: ["M", "F", "N"],
  [apiConstants.difficulty_level]: [],
  [apiConstants.listing_cost]: null,
  [apiConstants.upload_image]: "",
  [apiConstants.interests]: [],
  [apiConstants.address]: null,
  [apiConstants.image]: null,
  [apiConstants.min_age]: null,
  [apiConstants.max_age]: null,
  [apiConstants.repeat_every]: 1,
  [apiConstants.event_end_time]: undefined,
  [apiConstants.selected_days]: [],
  [apiConstants.custom_dates]: [],
  [apiConstants.selected_month_dates]: "",
  [apiConstants.selected_month_dates_options]: {},
  [apiConstants.schedule_type]: SCHEDULE_TYPE.O,
  [apiConstants.recurring_type]: RECURRING_TYPE.C,
  [apiConstants.recur_frequency]: "",
  [apiConstants.on_the_basis]: "",
  [apiConstants.has_schedule_date]: false,
  [apiConstants.event_start_time]: undefined,
  [apiConstants.event_start_date]: undefined,
  [apiConstants.event_end_date]: undefined,
  [apiConstants.event_date]: undefined,
  [apiConstants.listingType]: undefined,
  [apiConstants.singleListingType]: undefined,
  [apiConstants.singleListingType]: undefined,
  [apiConstants.event_title]: "",
  [apiConstants.multipleEvents]: [],
  [apiConstants.payment_mode]: null,
  [apiConstants.isCancellationAllowed]: false,
  [apiConstants.cancellation_allowed_till_hours]: undefined,
  [apiConstants.bank_name]: null,
  [apiConstants.bsb_number]: null,
  [apiConstants.account_number]: null,
  [apiConstants.listing_event_type]: "P",
  [apiConstants.existing_or_new]: null,
  [apiConstants.stripe_account_id]: null,
};
export const ListingFilterDefaultValue: any = {
  [apiConstants.lat]: null,
  [apiConstants.lng]: null,
  [apiConstants.gender_preference]: null,
  [apiConstants.interests]: [],
  [apiConstants.address]: null,
  [apiConstants.min_age]: null,
  [apiConstants.max_age]: null,
  [apiConstants.is_favourited]: false,
  [apiConstants.address]: null,
};

export const ExpertiseDefaultValue: any = {
  [apiConstants.notes]: null,
};

export const enquiryForm = {
  [apiConstants.name]: "",
  [apiConstants.email]: "",
  [apiConstants.phone_number]: "",
  [apiConstants.message]: "",
};
