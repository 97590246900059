import React from "react";
import { compressLargeNumber } from "../../../constants/helperConnectUser";

type Props = {
  [key: string]: any;
};

const ListingTypeTag = ({ virtual = false }) => {
  return (
    <div className={`${virtual ? "virtual_type" : ""} listing_type_wrap`}>
      {virtual ? (
        <>
          <img src="/static/listing/virtual.svg" alt="" />
          <div>Virtual</div>
        </>
      ) : (
        <>
          <img src="/static/listing/physical.svg" alt="" />
          <div>Physical</div>
        </>
      )}
    </div>
  );
};

export default ListingTypeTag;
