import IconPeoples from "../../../assets/img/connectIcons/people-group.png";

const NoRequests = () => {
  return (
    <div className="no-data-wrapper">
      <div className="content-wrapper">
        <img src={IconPeoples} alt="connection-img" />
        <div className="shadow-wrap">
          <div className="shadow"></div>
        </div>

        <div className="title-text">No fitness mates requests</div>
        <div className="desc-text">
          <div>There are no requests to display here. Please</div>
          <span>come again later</span>{" "}
        </div>
      </div>
    </div>
  );
};

export default NoRequests;
