import { useEffect, useState } from "react";

const useCheckDeviceScreen = (customWidth = 768) => {
  const [width, setWidth] = useState(customWidth);
  const handleWindowSizeChange = () => {
    if (window) {
      setWidth(window.innerWidth);
    }
  };

  useEffect(() => {
    if (window) {
      setWidth(window.innerWidth);
      window.addEventListener("resize", handleWindowSizeChange);
      return () => {
        window.removeEventListener("resize", handleWindowSizeChange);
      };
    }
  }, []);

  return {
    isMobile: width <= 768,
    isTablet: width > 768 && width <= 1024,
    isDesktop: width > 1024,
  };
};

export default useCheckDeviceScreen;
