import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import "./style.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    className: "customMultiSelect-paper",
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 170,
      boxShadow: "none",
      border: "1px solid #DCDCDC",
      borderRadius: "10px",
    },
  },
};

const names = [
  {
    label: "Monday",
    value: "Mon",
  },
  {
    label: "Tuesday",
    value: "Tue",
  },
  {
    label: "Wednesday",
    value: "Wed",
  },
  {
    label: "Thursday",
    value: "Thu",
  },
  {
    label: "Friday",
    value: "Fri",
  },
  {
    label: "Saturday",
    value: "Sat",
  },
  {
    label: "Sunday",
    value: "Sun",
  },
];

export default function CustomMultiSelect({
  label = "",
  customClass = "",
  name = "",
  watch = (props: any) => null,
  getValues = (props: any) => null,
  handleSetValue = (key: any, val: any) => null,
  isDisabled = false,
}) {
  const [personName, setPersonName] = React.useState<string[]>(
    watch(name) || []
  );

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    let selectedValues = typeof value === "string" ? value.split(",") : value;

    // Sort the selected values according to the order in `names`
    selectedValues = selectedValues
      .filter((val) => names.some((name) => name.value === val)) // Filter valid values
      .sort((a, b) => {
        const indexA = names.findIndex((name) => name.value === a);
        const indexB = names.findIndex((name) => name.value === b);
        return indexA - indexB;
      });

    // Set state with the sorted values
    setPersonName(selectedValues);
    handleSetValue(name, selectedValues);
  };

  return (
    <div className={"customMultiSelect-wrap " + customClass}>
      <FormControl>
        <InputLabel id="demo-multiple-checkbox-label" disabled={isDisabled}>
          {label}
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) => {
            if (selected?.length === 1) {
              const val: any = names.filter(
                (item) => item.value === selected[0]
              );
              return val[0]?.label;
            } else {
              return selected.join(", ");
            }
          }}
          MenuProps={MenuProps}
          disabled={isDisabled}
        >
          {names.map((name) => (
            <MenuItem key={name?.label} value={name?.value}>
              <Checkbox checked={personName.includes(name?.value)} />
              <ListItemText primary={name.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
