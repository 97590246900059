import { useNavigate } from "react-router-dom";
const StartedToday = () => {
  const navigate = useNavigate();

  return (
    <section>
      <div className="started-today-section">
        <div className="content-box">
          <div className="content-left">
            <div className="image-box">
              <div className="image-wrap">
                <img alt="" src="/static/startedtoday1.png" />
                <div className="image-text-box bg-1">
                  Find a workout partner
                </div>
              </div>
              <div className="image-wrap">
                <img alt="" src="/static/startedtoday2.png" />
                <div className="image-text-box bg-2">Here to train</div>
              </div>
              <div className="image-wrap">
                <img alt="" src="/static/startedtoday3.png" />
                <div className="image-text-box bg-3">Ready to earn money</div>
              </div>
              <div className="image-wrap">
                <img alt="" src="/static/startedtoday4.png" />
                <div className="image-text-box bg-4">
                  Looking for a fitness event
                </div>
              </div>
            </div>
          </div>
          <div className="content-right">
            <div className="title">
              What makes us {""}
              <span className="orange-span">different?</span>
            </div>
            <div className="description">
              <span className="orange-span">Fitness mates </span> is more than a
              platform, it’s a community. Our goal is to unite the fitness
              industry, bringing fitness and health more accessible and
              user-friendly.
            </div>
            <div className="benefits-list">
              <ul>
                <li>
                  <img alt="" src="/static/kettlebell.svg" />
                  <span>Free to browse and list services.</span>
                </li>
                <li>
                  <img alt="" src="/static/kettlebell.svg" />
                  <span>
                    Live mapping – see what’s happening LIVE near you.
                  </span>
                </li>
                <li>
                  <img alt="" src="/static/kettlebell.svg" />
                  <span>Find a workout buddy in your area.</span>
                </li>
                <li>
                  <img alt="" src="/static/kettlebell.svg" />
                  <span>
                    Expand your community with our connect and chat feature.
                  </span>
                </li>
                <li>
                  <img alt="" src="/static/kettlebell.svg" />
                  <span>
                    Access to professional athletes, coaches, organisations.
                  </span>
                </li>
                <li>
                  <img alt="" src="/static/kettlebell.svg" />
                  <span>
                    Engage with your favourite team, club and association.
                  </span>
                </li>
                <li>
                  <img alt="" src="/static/kettlebell.svg" />
                  <span>
                    User friendly, convenient and accessible anywhere anytime!
                  </span>
                </li>
                <li>
                  <img alt="" src="/static/kettlebell.svg" />
                  <span>Fitness mates has something for everyone.</span>
                </li>
                <li>
                  <img alt="" src="/static/kettlebell.svg" />
                  <span>Online or face-to-face, we can do both.</span>
                </li>
              </ul>
            </div>
            <div className="button-box">
              <div className="orange-btn" onClick={() => navigate("/register")}>
                Get started today
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StartedToday;
