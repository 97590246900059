import React from "react";
import { BUTTON_TYPE } from "../types";
import { emptyFunction } from "../utils";

import Loader from "./Loader";
interface ButtonProps {
  onClick?: () => void;
  className?: string;
  btnTxt: string;
  type?: any;
  restProps?: any;
  isLoading?: boolean;
  endIcon?: any;
  startIcon?: any;
  disabled?: boolean;
  loaderColor?: string;
  btnImg?: string;
  style?: React.CSSProperties;
}

const BasicButton = (props: ButtonProps) => {
  const {
    onClick = emptyFunction,
    className,
    btnTxt = "",
    disabled,
    type = BUTTON_TYPE.TYPE_BUTTON,
    loaderColor,
    endIcon,
    startIcon,
    ...restProps
  } = props;

  return (
    <button
      style={props?.style}
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={`basic_btn ${className ? className : ""} ${
        disabled ? "disabled" : ""
      }`}
      {...restProps}
    >
      {startIcon || null}
      {btnTxt}
      {endIcon || null}
    </button>
  );
};

export default BasicButton;
