import NoFlag from "../../../assets/img/connectIcons/no-flag.png";

const NoFlagged = () => {
  return (
    <div className="no-data-wrapper">
      <div className="content-wrapper">
        <img src={NoFlag} alt="connection-img" />
        <div className="shadow-wrap">
          <div className="shadow"></div>
        </div>

        <div className="title-text">No Flagged users</div>
        <div className="desc-text">
          <div>There are no flagged users to display here.</div>
        </div>
      </div>
    </div>
  );
};

export default NoFlagged;
