const Chat: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.999 0C18.6258 0 23.9979 5.37212 23.9979 11.999C23.9979 18.6258 18.6258 23.9979 11.999 23.9979C10.0572 23.9979 8.18069 23.5355 6.49487 22.6637L1.90429 23.9444C1.10645 24.1671 0.279073 23.701 0.0562821 22.9031C-0.0174414 22.6391 -0.0174057 22.36 0.0563327 22.0962L1.33742 17.5092C0.4636 15.8218 0 13.9431 0 11.999C0 5.37212 5.37212 0 11.999 0ZM11.999 1.79985C6.36615 1.79985 1.79985 6.36615 1.79985 11.999C1.79985 13.7624 2.24714 15.4587 3.08737 16.9635L3.26813 17.2872L1.93299 22.0678L6.71649 20.7333L7.0399 20.9136C8.54334 21.7519 10.2377 22.1981 11.999 22.1981C17.6318 22.1981 22.1981 17.6318 22.1981 11.999C22.1981 6.36615 17.6318 1.79985 11.999 1.79985ZM8.09931 13.1989H13.4968C13.9938 13.1989 14.3967 13.6018 14.3967 14.0988C14.3967 14.5544 14.0581 14.9309 13.6189 14.9905L13.4968 14.9987H8.09931C7.60229 14.9987 7.19938 14.5958 7.19938 14.0988C7.19938 13.6432 7.53794 13.2667 7.97719 13.2071L8.09931 13.1989H13.4968H8.09931ZM8.09931 8.99923H15.904C16.401 8.99923 16.8039 9.40214 16.8039 9.89915C16.8039 10.3547 16.4654 10.7313 16.0261 10.7909L15.904 10.7991H8.09931C7.60229 10.7991 7.19938 10.3962 7.19938 9.89915C7.19938 9.44356 7.53794 9.06703 7.97719 9.00744L8.09931 8.99923H15.904H8.09931Z"
        fill="#25272D"
      />
    </svg>
  );
};

export default Chat;
