import React from "react";

type Props = {
  [key: string]: any;
};

const ShareTag = (props: Props) => {
  return (
    <img
      {...props}
      style={{ cursor: "pointer" }}
      src="/static/listing/share.svg"
      alt=""
    />
  );
};

export default ShareTag;
