import React from "react";

const ActivateIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4.20039 13.2001C4.20039 8.89228 7.69257 5.4001 12.0004 5.4001C16.3082 5.4001 19.8004 8.89228 19.8004 13.2001C19.8004 15.3513 18.9308 17.2976 17.5217 18.7096C17.1706 19.0615 17.1712 19.6313 17.5231 19.9824C17.8749 20.3335 18.4448 20.3329 18.7959 19.9811C20.5279 18.2454 21.6004 15.8471 21.6004 13.2001C21.6004 7.89816 17.3023 3.6001 12.0004 3.6001C6.69846 3.6001 2.40039 7.89816 2.40039 13.2001C2.40039 15.8471 3.4729 18.2454 5.20492 19.9811C5.55602 20.3329 6.12586 20.3335 6.47771 19.9824C6.82955 19.6313 6.83016 19.0615 6.47906 18.7096C5.07002 17.2976 4.20039 15.3513 4.20039 13.2001ZM7.80039 13.2001C7.80039 10.8805 9.68079 9.0001 12.0004 9.0001C14.32 9.0001 16.2004 10.8805 16.2004 13.2001C16.2004 14.3575 15.7334 15.4042 14.9754 16.1648C14.6245 16.5169 14.6255 17.0867 14.9776 17.4376C15.3296 17.7885 15.8995 17.7875 16.2504 17.4354C17.3309 16.3512 18.0004 14.853 18.0004 13.2001C18.0004 9.88639 15.3141 7.2001 12.0004 7.2001C8.68668 7.2001 6.00039 9.88639 6.00039 13.2001C6.00039 14.853 6.66992 16.3512 7.75042 17.4354C8.10129 17.7875 8.67114 17.7885 9.02321 17.4376C9.37529 17.0867 9.37627 16.5169 9.0254 16.1648C8.2674 15.4042 7.80039 14.3575 7.80039 13.2001ZM12.0004 10.8001C10.6749 10.8001 9.60039 11.8746 9.60039 13.2001C9.60039 14.5256 10.6749 15.6001 12.0004 15.6001C13.3259 15.6001 14.4004 14.5256 14.4004 13.2001C14.4004 11.8746 13.3259 10.8001 12.0004 10.8001Z"
        fill="#8F939A"
      />
    </svg>
  );
};

export default ActivateIcon;
