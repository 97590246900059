import React from "react";
import "./infoStyle.scss";

type Props = {
  [key: string]: any;
};

const InfoCard = (props: Props) => {
  const { title = "" } = props;
  return (
    <div className="info_container">
      <img src="/static/info.svg" alt="" />
      <div className="info_text">{title}</div>
    </div>
  );
};

export default InfoCard;
