const TotalEarnings: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.5 4C2.67157 4 2 4.67157 2 5.5V12.5C2 13.3284 2.67157 14 3.5 14H14.5C15.3284 14 16 13.3284 16 12.5V5.5C16 4.67157 15.3284 4 14.5 4H3.5ZM6 5V6C6 7.10457 5.10457 8 4 8L3 8V7H4C4.55228 7 5 6.55228 5 6V5H6ZM9 10.75C8.0335 10.75 7.25 9.9665 7.25 9C7.25 8.0335 8.0335 7.25 9 7.25C9.9665 7.25 10.75 8.0335 10.75 9C10.75 9.9665 9.9665 10.75 9 10.75ZM3 11V10H4C5.10457 10 6 10.8954 6 12V13H5V12C5 11.4477 4.55228 11 4 11H3ZM14 11C13.4477 11 13 11.4477 13 12V13H12V12C12 10.8954 12.8954 10 14 10H15V11H14ZM14 7H15V8L14 8C12.8954 8 12 7.10457 12 6V5H13V6C13 6.55228 13.4477 7 14 7ZM17.0001 12.5C17.0001 13.8807 15.8808 15 14.5001 15H4.08545C4.29137 15.5826 4.84699 16 5.5001 16H14.5001C16.4331 16 18.0001 14.433 18.0001 12.5V7.49997C18.0001 6.84686 17.5827 6.29125 17.0001 6.08533V12.5Z"
        fill="#25272D"
      />
    </svg>
  );
};

export default TotalEarnings;
