import { STRING_CONSTANTS } from "../utils/StringConstant";
import Button from "./Button";
import CommonOutlineButton from "./CommonOutlineButton";

interface DeleteChat {
  onOk: any;
  onCancel: any;
  userProfile: any;
  setShowRemoveConnection: any;
}
const DeleteChatModal = (props: DeleteChat) => {
  const { onCancel } = props;

  return (
    <div className={"modal show"}>
      <div className="modal-section log_out_modal">
        <div className="modal-top-part" style={{ position: "relative" }}>
          <h5>{STRING_CONSTANTS.delete_chat}</h5>
        </div>
        <div className="button_option">
          <Button
            btnTxt="Confirm"
            className={"backtologin"}
            onClick={() => {}}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "18px",
            }}
          >
            <CommonOutlineButton
              onClick={onCancel}
              image=""
              text="Cancel"
              className={"common_btn_grey"}
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteChatModal;
