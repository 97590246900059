import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import ApiLoader from "../../global/ApiLoader";
import { getItemFromStore, setLocalStorage, showToast } from "../../utils";
import endpoints from "../../utils/endpoints";
import {
  makeGetCall,
  makeNextPageCall,
  makePutCall,
} from "../../utils/Requests";
import { NOTIFICATION_TYPE } from "../../types";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import GlobalHeader2 from "../Dashboard/GlobalHeader2";

const Notification = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notificationCount, setNotificationCount] = useState<any>(null);
  const [listing, setListing] = useState<any>([]);
  const [nextUrl, setNextUrl] = useState<any>(null);

  const getListingData = () => {
    setIsLoading(true);
    makeGetCall({ url: endpoints.notifications })
      .then((res) => {
        if (res?.results) {
          setListing(res?.results);
          setNextUrl(res?.next || null);
        } else {
          showToast(res.status.message, "error");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(err, "error");
      });
  };

  const handleClick = (itemId, notificationType, dataId) => {
    switch (notificationType) {
      case NOTIFICATION_TYPE.TNC:
        navigate(ROUTE_CONSTANTS.policies + "?activeTab=privacy");
        break;
      case NOTIFICATION_TYPE.PTU:
        navigate(ROUTE_CONSTANTS.policies + "?activeTab=privacy");
        break;
      case NOTIFICATION_TYPE.PPU:
        navigate(ROUTE_CONSTANTS.policies);
        break;
      case NOTIFICATION_TYPE.PFS:
        navigate(ROUTE_CONSTANTS.personal_details);
        break;
      case NOTIFICATION_TYPE.TM:
        navigate(ROUTE_CONSTANTS.team_membership);
        break;
      case NOTIFICATION_TYPE.CB:
        navigate(ROUTE_CONSTANTS.my_booking + "?activeTab=Cancelled");
        break;
      case NOTIFICATION_TYPE.MCL:
        navigate(ROUTE_CONSTANTS.events + `/${dataId}`);
        break;
      case NOTIFICATION_TYPE.WL:
        navigate(ROUTE_CONSTANTS.events + `/${dataId}`);
        break;
      case NOTIFICATION_TYPE.CDN:
        navigate(ROUTE_CONSTANTS.chat + `?chatId=${dataId}`);
        break;
      case NOTIFICATION_TYPE.CGN:
        navigate(ROUTE_CONSTANTS.chat + `?chatId=${dataId}`);
        break;
      default:
        break;
    }
    makeGetCall({ url: endpoints.readNotification + `${itemId}/` }).then(
      (res) => {
        if (res.status.code === 200) {
          const updatedListing = listing.map((item) => {
            if (item.id === itemId) {
              return { ...item, isRead: true };
            }
            return item;
          });

          setListing(updatedListing);
          updateNotificationCount();
        }
      }
    );
  };

  const fetchMoreNotification = () => {
    if (!nextUrl) return;
    makeNextPageCall({ url: nextUrl })
      .then((res) => {
        const tempData = [...listing, ...res.results];
        setNextUrl(res?.next);
        setListing(tempData as never);
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  const updateNotificationCount = () => {
    makeGetCall({ url: endpoints.profile })
      .then((res) => {
        if (res.status.code === 200) {
          let data = {
            ...res.data?.userDetail,
            ...res.data?.businessDetail?.[0],
          };
          setLocalStorage("userProfile", data);
          setNotificationCount(data?.unreadNotificationCount);
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  const markAllAsRead = () => {
    setIsLoading(true);

    // Making the PUT request to mark all notifications as read
    makePutCall({ url: endpoints.mark_all_read })
      .then((res) => {
        if (res.status.code === 200) {
          // Update the local state to mark all notifications as read
          const updatedListing = listing.map((item: any) => ({
            ...item,
            isRead: true,
          }));
          setListing(updatedListing);
          updateNotificationCount();
          showToast(res.status.message, "success");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(err, "error");
      });
  };

  useEffect(() => {
    getListingData();
    setNotificationCount(
      getItemFromStore("userProfile")?.unreadNotificationCount
    );
  }, []);

  return (
    <>
      <GlobalHeader2 />
      <div className="notification-page-wrap">
        <div className="content-wrap">
          <div className="top-section">
            <div className="heading-wrapper">
              <div className="heading">Notifications ({notificationCount})</div>
              <div
                className="heading-right"
                onClick={markAllAsRead}
                style={{ cursor: "pointer" }}
              >
                Mark as Read
              </div>
            </div>
          </div>
          {isLoading ? (
            <ApiLoader />
          ) : listing?.length > 0 ? (
            <div className="notifaction-box-wrap">
              <InfiniteScroll
                dataLength={listing?.length}
                next={fetchMoreNotification}
                hasMore={!!nextUrl}
                loader={<ApiLoader />}
              >
                {listing?.map((item: any, index: number) => (
                  <div
                    className="notifaction-box"
                    key={item.id}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleClick(item.id, item.notificationType, item.dataId);
                    }}
                  >
                    {!item.isRead ? (
                      <img
                        alt=""
                        src="/static/unreaddot.svg"
                        className="active-dot"
                      />
                    ) : null}
                    <div className="title">{item?.title}</div>
                    <div className="notification-time">
                      {moment(item?.createdAt).format(
                        "MMMM D, YYYY [at] h:mma"
                      )}
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Notification;
