import { useEffect, useState } from "react";
import Button from "../../../global/Button";
import InputField from "../../../global/InputField";
import { apiConstants } from "../../../utils/Constants";
import { makeGetCall } from "../../../utils/Requests";
import apiConfig from "../../../utils/endpoints";
import moment from "moment";
interface DraftPopUpProps {
  onClose?: any;
  handleNewStripeAccount?: any;
}
const WaitingStripeNewAccountPopup = (props: DraftPopUpProps) => {
  const { onClose, handleNewStripeAccount } = props;
  const [initialLength,setInitialLength] = useState(0);
  const stripeNewTimeStamp = new Date();
  const getSavedPaymentExisting = () => {
    makeGetCall({ url: apiConfig.saved_payments })
      .then((res) => {
        if (res.status.code === 200) {
          if (
            res?.data?.stripeDetails &&
            res?.data?.stripeDetails?.length >= 0
          ) {
              setInitialLength(res?.data?.stripeDetails?.length)
          }
        }
      })
      .catch((err) => {
        console.error(err, "error");
      });
  };
  const getSavedPayment = () => {
    makeGetCall({ url: apiConfig.saved_payments })
      .then((res) => {
        if (res.status.code === 200) {
          if (
            res?.data?.stripeDetails &&
            res?.data?.stripeDetails?.length > 0
          ) {
            if(res?.data?.stripeDetails?.length > initialLength){
              onClose();
            }
            if (
              moment(new Date(res?.data?.stripeDetails[0].createdAt)).isAfter(
                stripeNewTimeStamp
              )
            ) {
              handleNewStripeAccount(res?.data?.stripeDetails[0]?.id);
            }
          }
        }
      })
      .catch((err) => {
        console.error(err, "error");
      });
  };

  useEffect(() => {
    let intervalId;

    getSavedPaymentExisting();
    intervalId = setInterval(getSavedPayment, 5000);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  return (
    <div className={"modal show"}>
      <div className="stripe_acc_pop">
        <div className="modal-top-part">
          <div className="header_container">
            <img src="/static/closeBorder.svg" alt="" onClick={onClose} />
          </div>

          {/* <div className="modal_stripe_heading">Please wait...</div> */}
          <div className="d-flex flex-column align-items-center">
            <div className="stripe_waiting_text">
              Please wait for stripe account to link
            </div>
            <div className="loader_stripe mb-3" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaitingStripeNewAccountPopup;
