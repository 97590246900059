import { useState } from "react";
import BookEventPopup from "./BookEventPopup";

const Promote = () => {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <section>
      <div className="promote-section">
        <div className="content-box">
          <div className="content-left">
            <div className="small-heading">For Businesses</div>
            <div className="title">
              Promote & Elevate your business with
              <span className="orange-span"> Fitness mates</span>
            </div>
            <div className="button-box">
              <div className="orange-btn" onClick={() => setShowModal(true)}>
                Enquire with us
              </div>
            </div>
          </div>
          <div className="content-right">
            <div>
              <div className="title">
                <span className="orange-span">Create & Manage Listings</span>
              </div>
              <div className="description">
                Seamlessly create, manage, and track all your listings and
                performance history within app.
              </div>
            </div>
            <div className="mt-32">
              <div className="title ">
                <span className="orange-span"> Manage your Team</span>
              </div>
              <div className="description">
                Create and boost your team’s roster by listing your group and
                connecting with eager players looking to join.
              </div>
            </div>
            <div className="mt-32">
              <div className="title">
                <span className="orange-span">Promote your Business</span>
              </div>
              <div className="description">
                Enjoy free advertising for your business! Fitness mates is here
                to help fitness businesses, professionals and corporations tap
                into a wider audience, showcase their unique services and
                generate unlimited revenue.
              </div>
            </div>
            <div className="mt-32">
              <div className="title">
                <span className="orange-span">
                  For Fitness Professionals and Enthusiasts
                </span>
              </div>
              <div className="description">
                There is no limit to what service you can list. If you have a
                unique skill or experience to share with Australia, Fitness
                mates is the platform for you!
                <br />
                <br />
                Whether you teach ice skating or horse riding, there will be an
                interest for all unique sports and experiences in the Fitness
                mates community.
              </div>
            </div>
            <div className="mt-32">
              <div className="title">
                <span className="orange-span">Earn Unlimited Income </span>
              </div>
              <div className="description">
                Offer your expertise, services and specialty today by creating a
                session, group or team where users can join anywhere, anytime!
                Create your listing, set your own fee and unlock your earning
                potential today.
              </div>
            </div>
            <div className="button-box">
              <div className="orange-btn" onClick={() => setShowModal(true)}>
                Enquire with us
              </div>
            </div>
          </div>
        </div>
      </div>
      <BookEventPopup show={showModal} onHide={handleCloseModal} />
    </section>
  );
};

export default Promote;
