import { Modal } from "react-bootstrap";
import Button from "../../../global/Button";
import { useState } from "react";
import { CrossIcon } from "../../../utils/Icons/svgs";
import CancelEventCard from "./CancelEventCard";
import { showToast } from "../../../utils";

const CancelBookingPopup = (props) => {
  const [selectAll, setSelectAll] = useState(false);
  const [unSelectAll, setUnSelectAll] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [eventCounter, setEventCounter] = useState(0);
  const { detailsData, cancelEvents, cancelBookingEvents } = props;
  return (
    <Modal {...props} centered dialogClassName="width-734">
      <Modal.Body style={{ height: "fit-content" }}>
        <div className="emodal_wrapper">
          <div className="eheading position-relative d-flex justify-content-center align-items-center m-5 mb-0">
            <div className="">
              <p className="e_head_txt mb-1">Cancel Booking</p>
              <p className="e_subhead_txt">
                Select the events that you want to cancel
              </p>
            </div>
            <span
              className="position-absolute eclose_btn"
              onClick={props.onHide}
            >
              <CrossIcon />
            </span>
          </div>
          <div className="edates_wrapper">
            {eventCounter ? (
              <p
                className="c_b_title cursor-pointer"
                onClick={() => {
                  setUnSelectAll((prev) => !prev);
                  setSelectAll(false);
                  setEventCounter(0);
                }}
              >
                {" "}
                Unselect all
              </p>
            ) : (
              <p
                className="c_b_title cursor-pointer"
                onClick={() => {
                  setSelectAll(true);
                }}
              >
                Select all
              </p>
            )}
            <div className="e_all_dates_container row gx-0 gap-3 mb-5">
              {cancelEvents?.map((item, i) => {
                return (
                  <CancelEventCard
                    selectAll={selectAll}
                    unSelectAll={unSelectAll}
                    showSelect={showSelect}
                    setUnSelectAll={setUnSelectAll}
                    setShowSelect={setShowSelect}
                    item={item}
                    i={i}
                    key={i}
                    cancelBookingEvents={props.cancelBookingEvents}
                    eventCounter={eventCounter}
                    setEventCounter={setEventCounter}
                    detailsData={detailsData}
                  />
                );
              })}
            </div>

            <Button
              btnTxt="Cancel events"
              className={"backtologin"}
              onClick={() => {
                if (!detailsData?.isCancellationAllowed) {
                  showToast(
                    "cancellation is not allowed in this booking",
                    "error"
                  );
                  return;
                }

                if (cancelBookingEvents?.length === 0) {
                  showToast("Please select at least one event.", "error");
                  return;
                }
                props.onHide();
                props.setShowCancelReason(true);
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default CancelBookingPopup;
