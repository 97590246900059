// OutlineButton.tsx
import React from "react";

interface ButtonProps {
  text: string;
  icon?: React.ReactNode; // This can be your icon component
  onClick?: () => void;
  className?: any;
  [key: string]: any;
}

const OutlineButton: React.FC<ButtonProps> = ({
  text,
  icon,
  onClick,
  ...props
}) => {
  return (
    <button {...props} className="outline-button" onClick={onClick}>
      {icon}
      <span>{text}</span>
    </button>
  );
};

export const OutlineYellowButton: React.FC<ButtonProps> = ({
  text,
  icon,
  onClick,
  className = "",
  ...props
}) => {
  return (
    <button
      {...props}
      className={`outline-button-yellow ${className || ""}`}
      onClick={onClick}
    >
      <span>{text}</span>
      {icon}
    </button>
  );
};

export default OutlineButton;
