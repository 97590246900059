import React from "react";
import { compressLargeNumber } from "../../../constants/helperConnectUser";

type Props = {
  [key: string]: any;
};

const DistanceTag = (props: Props) => {
  return (
    <div className="distance_wrap">{` ${compressLargeNumber(
      props?.distance || 0
    )} km away...`}</div>
  );
};

export default DistanceTag;
