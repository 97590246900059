import { useLocation, useNavigate } from "react-router-dom";
import DashboardWrapper from "../DashboardWrapper";
import SubsPlanCard from "./SubsPlanCard";
import Button from "../../../global/Button";
import { useEffect, useState } from "react";

import CancelSubsPopup from "../Popups/CancelSubsPopup";
import {
  HappyEmoji,
  LeftArrowStickIcon,
  MemberIcon,
} from "../../../utils/Icons/svgs";
import { makeGetCall, makePostCall } from "../../../utils/Requests";
import endpoints from "../../../utils/endpoints";
import { showToast } from "../../../utils";
import AddCardPopup from "../Popups/AddCardPopup";
import ApiLoader from "../../../global/ApiLoader";
import Loader from "../../../global/Loader";
import moment from "moment";
import CompareTable from "../../SignupUserMembership/CompareTable";

import bgLeft from "../../../assets/img/backgroundLeft.svg";
import bgRight from "../../../assets/img/backgroundRight.svg";
import checked from "../../../assets/img/signupWelcome/checked.svg";
import dismiss from "../../../assets/img/signupWelcome/dismiss.svg";
import backBtn from "../../../assets/img/signupWelcome/backBtn.svg";
import SubscriptionPlanSelector from "../../../components/SignupUserMembership/SubscriptionPlanSelector";
import useCheckDeviceScreen from "../../../hooks/useCheckScreen";

const Subscription = () => {
  const [showSubCancelPopup, setShowSubCancelPopup] = useState(false);
  const [selectedCard, setSelectedCard] = useState(0);
  const [cardDetail, setCardDetail] = useState([]);
  const [subscriptionDetails, setSubscriptionDetail] = useState<any>(null);
  const [subsPlanLoading, setSubsPlanLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [switchToggle, setSwitchToogle] = useState(false);
  const [showAddCardPopup, setShowAddCardPopup] = useState(false);
  const [myCards, setMyCards] = useState<any>(null);
  const [paymentPlanDetails, setPaymentPlanDetails] = useState({});
  const [purchaseSubsLoading, setPurchaseSubsLoading] = useState(false);
  const [refreshToggle, setRefreshToggle] = useState(false);
  const {isMobile } = useCheckDeviceScreen();
  const [userProfile, setUserProfile] = useState<any>({
    userDetail: { userType: "Member" },
  });
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const subscriptionType = queryParams.get("SubscriptionType");
  const purchasePlan = queryParams.get("purchasePlan");
  const redirectStatus = queryParams.get("redirect_status");

  const [memberData, setMemberData] = useState(null);
  const [guestData, setGuestData] = useState(null);
  const [userTypeData, setUserTypeData] = useState([]);

  useEffect(() => {
    setSubsPlanLoading(true);
    makeGetCall({ url: endpoints.subscription_details })
      .then((res) => {
        if (res.status.code === 200) {
          setSubscriptionDetail(res.data);
        }
        setSubsPlanLoading(false);
      })
      .catch((err) => {
        showToast(err, "error");
        setSubsPlanLoading(false);
      });
  }, [refreshToggle]);

  useEffect(() => {
    // to fetch user profile details
    makeGetCall({ url: endpoints.profile })
      .then((res) => {
        if (res?.data) {
          setUserProfile(res.data);
          setIsPageLoading(false);
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });

    makeGetCall({ url: endpoints.user_types })
      .then((res) => {
        if (res.status.code === 200) {
          setCardDetail(res?.results);
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  }, []);

  useEffect(() => {
    makeGetCall({ url: endpoints.manage_card })
      .then((res) => {
        if (res.status.code === 200) {
          setMyCards(res.data);
        }
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    makeGetCall({ url: endpoints.subscription })
      .then((res) => {
        if (res.status.code === 200) {
          for (let i = 0; i < res?.data?.data.length; i++) {
            if (switchToggle === false) {
              if (res?.data?.data?.[i]?.recurring?.interval === "month") {
                setPaymentPlanDetails(res?.data?.data?.[i]);
              }
            } else {
              if (res?.data?.data?.[i]?.recurring?.interval === "year") {
                setPaymentPlanDetails(res?.data?.data?.[i]);
              }
            }
          }
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  }, [switchToggle]);

  const purchaseSubscription = (price_id, payment_id) => {
    const apiPayload = {
      price_id: price_id,
      payment_id: payment_id,
    };
    setPurchaseSubsLoading(true);
    makePostCall({ url: endpoints.subscription, apiPayload })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res?.status?.message, "success");
          setRefreshToggle(!refreshToggle);
          setTimeout(() => {
            navigate("/subscription");
          }, 1000);
        }
        setPurchaseSubsLoading(false);
      })
      .catch((err) => {
        showToast(err, "error");
        setPurchaseSubsLoading(false);
        setRefreshToggle(!refreshToggle);
      });
  };

  const purchaseSubscriptionTwo = (price_id, payment_id) => {
    const apiPayload = {
      price_id: price_id,
      payment_id: payment_id,
    };
    setPurchaseSubsLoading(true);
    makePostCall({ url: endpoints.subscription, apiPayload })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res?.status?.message, "success");
          setRefreshToggle(!refreshToggle);
          setTimeout(() => {
            navigate("/subscription");
            window.location.reload();
          }, 1000);
        }
        setPurchaseSubsLoading(false);
      })
      .catch((err) => {
        showToast(err, "error");
        setPurchaseSubsLoading(false);
        setRefreshToggle(!refreshToggle);
      });
  };

  useEffect(() => {
    if (
      redirectStatus === "succeeded" &&
      paymentPlanDetails?.id &&
      myCards?.[0]?.id
    ) {
      purchaseSubscriptionTwo(paymentPlanDetails?.id, myCards?.[0]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectStatus, paymentPlanDetails?.id, myCards?.[0]?.id]);

  const compareData = [
    {
      name: "Browse for free",
      guest: "Free",
      member: (
        <>
          <sup>AUD</sup>5/month
        </>
      ),
      isGuestFree: true,
      key: "browseForFree",
    },
    {
      name: "Book Sessions",
      guest: <img src={checked} alt="Yes" />,
      member: <img src={checked} alt="Yes" />,
      key: "bookSessions",
    },
    {
      name: "Connect & Chat",
      guest: "1 per week",
      member: "Unlimited",
      key: "connectAndChat",
    },
    {
      name: "Create listings",
      guest: "Maximum 4",
      member: "Unlimited",
      key: "createListings",
    },
    {
      name: "Promote Business",
      guest: <img src={dismiss} alt="No" />,
      member: <img src={checked} alt="Yes" />,
      key: "promoteBusiness",
    },
    {
      name: "Sell Paid Listings",
      guest: <img src={dismiss} alt="No" />,
      member: <img src={checked} alt="Yes" />,
      key: "sellPaidListings",
    },
    {
      name: "Access to VIP Events",
      guest: <img src={dismiss} alt="No" />,
      member: <img src={checked} alt="Yes" />,
      key: "accessToVipEvents",
    },
    {
      name: "Personalized Dashboard",
      guest: <img src={dismiss} alt="No" />,
      member: <img src={checked} alt="Yes" />,
      key: "personalizedDashboard",
    },
    {
      name: "Event Creation Limit",
      guest: "1 per week",
      member: "Unlimited",
      key: "eventCreationLimit",
    },
    {
      name: "Max Listing Attendees",
      guest: "4",
      member: "Unlimited",
      key: "maxListingAttendees",
    },
    {
      name: "Revenue Generation",
      guest: <img src={dismiss} alt="No" />,
      member: "Unlimited",
      key: "revenueGeneration",
    },
    {
      name: "Lead Generation",
      guest: <img src={dismiss} alt="No" />,
      member: "Unlimited",
      key: "leadGeneration",
    },
  ];

  useEffect(() => {
    const fetchUserType = () => {
      makeGetCall({
        url: endpoints.get_user_type,
      })
        .then((res) => {
          if (res.results[0]) {
            setMemberData(res.results[0]);
          }
          if (res.results[1]) {
            setGuestData(res.results[1]);
          }
          setUserTypeData(res.results);
        })
        .catch((err) => {
          console.error(err);
        });
    };

    fetchUserType();
  }, []);

  const features = [
    `${switchToggle === false ? 30 : 365} days plan`,
    "Create Unlimited Groups Activities",
    "Generate Fitness Activity Revenue",
    "Flexible Scheduling and Booking",
    "Access to Fitness Mates Events and Promotions",
    "Exclusive Membership Discounts",
  ];

  const FeatureList = () => (
    <div className="d-flex flex-column gap-1">
      {features.map((feature, index) => (
        <div
          key={index}
          className="feature-item d-flex align-items-center gap-1"
        >
          <img src={checked} alt="Yes" /> {feature}
        </div>
      ))}
    </div>
  );

  return (
    <DashboardWrapper showTab={true}>
      {isPageLoading ? (
        <div
          style={{
            maxWidth: "875px",
            background: "#ffffff",
            width: "100%",
          }}
        >
          <div className="subscription-plan-right" style={!isMobile ? {display:"flex", justifyContent:"center", maxWidth:"100%", marginTop:"150px"} : {}}>
            <div className="subscription-box">
              <div className="s-title position-relative">
                <div
                  className="d-md-none position-absolute cursor-pointer"
                  onClick={() => navigate(-1)}
                >
                  <LeftArrowStickIcon />
                </div>
              </div>
              <ApiLoader />
            </div>
          </div>
        </div>
      ) : (
        <>
          {!subscriptionType ? (
            //---------------this block show if subs purchase or not------------------>
            <>
              {
                /*  (subscriptionDetails?.subscription?.status !== "active" &&
               subscriptionDetails?.subscription?.status !== "trialing" ) */
                userProfile?.userDetail?.userType !== "Member" ? (
                  <div
                    style={{
                      maxWidth: "910px",
                      background: "transparent",
                      width: "100%",
                    }}
                  >
                    <div className="subscription-w-plan-right">
                      <div className="s-welcome-container">
                        <div className="sw-title">
                          <div
                            className="d-md-none position-absolute cursor-pointer"
                            onClick={() => navigate(-1)}
                          >
                            <LeftArrowStickIcon />
                          </div>
                          <p className="mb-0 mx-auto">
                            Welcome to Fitness Mates
                          </p>
                        </div>
                        <p className="d-none d-md-block sw-desc">
                          Whether you're looking to find workout partners or
                          create fitness events, we got you covered!
                        </p>
                        <p className="sw-desc d-md-none">
                          Whether you're looking to find workout partners or
                          create fitness events, we got you covered!
                        </p>
                        <div className="compare_container">
                          <CompareTable
                            compareData={compareData}
                            guestData={guestData}
                            memberData={memberData}
                          />
                        </div>
                        {/* {cardDetail?.toReversed()?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={`s-card-wrapper ${
                              selectedCard === index ? "Active" : ""
                            } `}
                            onClick={() => setSelectedCard(index)}
                          >
                            <div className="subs-option">
                              {item?.userType === "Member" ? (
                                <MemberIcon
                                  fill={`${
                                    selectedCard === index
                                      ? "#EE7830"
                                      : "#8F939A"
                                  }`}
                                />
                              ) : (
                                <HappyEmoji
                                  fill={`${
                                    selectedCard === index
                                      ? "#EE7830"
                                      : "#8F939A"
                                  }`}
                                />
                              )}
                              <p>{item?.userType}</p>
                            </div>
                            <div className="member-info">
                              {item?.description}
                            </div>

                            <p className="benefit-title">
                              {item?.userType} Benefits{" "}
                            </p>
                            {item?.benefit?.map((itemBenefit, index) => {
                              return (
                                <div className="benefit-container">
                                  <img
                                    src="/static/checkmark-green.png"
                                    alt="kk"
                                  />
                                  <p className="benefit">{itemBenefit}</p>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })} */}
                        {/* <div className="info-box-welcome-wrap">
                        <div className="info-box-welcome">
                          <img src="/static/greyinfo.svg" alt="" />
                          <span>
                            Due to the recurring nature of the payment, the
                            specified amount will be automatically deducted from
                            your account according to the chosen payment term.
                            To cease this recurring payment, please navigate to
                            the "Membership Plan" section within your profile.
                          </span>
                        </div>
                      </div> */}

                        <Button
                          btnTxt="Try membership for free"
                          className={"btn-member"}
                          onClick={() => {
                            navigate(
                              "?SubscriptionType=true&purchasePlan=true"
                            );
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          }}
                        />
                        <Button
                          btnTxt="Continue as a Guest"
                          className={"btn-guest"}
                          onClick={() => {
                            navigate("../personal-details");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="personal-details-wrapper">
                    <div className="subscription-plan-right">
                      <div className="subscription-box">
                        <div className="s-title position-relative">
                          <div
                            className="d-md-none position-absolute cursor-pointer"
                            onClick={() => navigate(-1)}
                          >
                            <LeftArrowStickIcon />
                          </div>
                          Membership plan
                        </div>
                        {subsPlanLoading ? (
                          <ApiLoader />
                        ) : (
                          /* subscriptionDetails?.subscription?.status ===
                          "active" &&  */ //---------------------------if subs purchased it show transaction-------------------
                          <>
                            <SubsPlanCard
                              userProfile={userProfile}
                              onCancelPayCut={() => setShowSubCancelPopup(true)}
                              subscriptionDetails={subscriptionDetails}
                            />

                            {subscriptionDetails?.subscription?.canceledAt !==
                              null &&
                            moment(
                              moment.unix(
                                subscriptionDetails?.subscription
                                  ?.cancelPeriodEnd
                              )
                            ).isBefore(moment()) ? (
                              <Button
                                style={{ maxWidth: "484px" }}
                                btnTxt="Re-subscribe "
                                className={"btn-member"}
                                onClick={() => {
                                  navigate(
                                    "?SubscriptionType=true&purchasePlan=true"
                                  );
                                  window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                  });
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </>
                         )}
                      </div>
                    </div>
                  </div>
                )
              }
            </>
          ) : !purchasePlan ? (
            //------------------------- this block show benefits of member and guess --------------------->

            <div
              style={{
                maxWidth: "909px",
                background: "transparent",
                width: "100%",
              }}
            >
              <div className="subscription-w-plan-right">
                <div className="s-welcome-container ">
                  <div className="sw-title">
                    <div
                      className="d-md-none position-absolute cursor-pointer"
                      onClick={() => navigate(-1)}
                    >
                      <LeftArrowStickIcon />
                    </div>
                    <p className="mb-0 mx-auto">Welcome to Fitness Mates</p>
                  </div>
                  <p className="d-none d-md-block sw-desc">
                    Whether you're looking to find workout partners or create
                    fitness events, we got you covered!
                  </p>
                  <p className="sw-desc d-md-none">
                    Whether you're looking to find workout partners or create
                    fitness events, we got you covered!
                  </p>

                  {cardDetail?.toReversed()?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`s-card-wrapper ${
                          selectedCard === index ? "Active" : ""
                        } `}
                        onClick={() => setSelectedCard(index)}
                      >
                        <div className="subs-option">
                          {item?.userType === "Member" ? (
                            <MemberIcon
                              fill={`${
                                selectedCard === index ? "#EE7830" : "#8F939A"
                              }`}
                            />
                          ) : (
                            <HappyEmoji
                              fill={`${
                                selectedCard === index ? "#EE7830" : "#8F939A"
                              }`}
                            />
                          )}
                          <p>{item?.userType}</p>
                        </div>
                        <div className="member-info">{item?.description}</div>

                        <p className="benefit-title">
                          {item?.userType} Benefits{" "}
                        </p>
                        {item?.benefit?.map((itemBenefit, index) => {
                          return (
                            <div className="benefit-container">
                              <img src="/static/checkmark-green.png" alt="kk" />
                              <p className="benefit">{itemBenefit}</p>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                  {/* <div className="info-box-welcome-wrap">
                    <div className="info-box-welcome">
                      <img src="/static/greyinfo.svg" alt="" />
                      <span>
                        Due to the recurring nature of the payment, the
                        specified amount will be automatically deducted from
                        your account according to the chosen payment term. To
                        cease this recurring payment, please navigate to the
                        "Membership Plan" section within your profile.
                      </span>
                    </div>
                  </div> */}

                  <Button
                    btnTxt="Try membership for free"
                    className={"btn-member"}
                    onClick={() => {
                      navigate("?SubscriptionType=true&purchasePlan=true");
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  />
                  <Button
                    btnTxt="Continue as a Guest"
                    className={"btn-guest"}
                    onClick={() => {
                      navigate("../personal-details");
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            //---------------- this block is use to purchase  purchase subscription ------------------
            // Below commented code is the code before ui revamped of 1 month free trial in case of guest user to upgrade.
            // <div
            //   style={{
            //     maxWidth: "909px",
            //     background: "transparent",
            //     width: "100%",
            //   }}
            // >
            //   <div className="payment-subs-plan-right ">
            //     <h1 className="ps-title">
            //       <p className="d-md-none mb-0" onClick={() => navigate(-1)}>
            //         <LeftArrowStickIcon />
            //       </p>
            //       Welcome to Fitness Mates
            //     </h1>
            //     <p className="ps-subtitle">
            //       Whether you're looking to find workout partners or create
            //       fitness events, we got you covered!
            //     </p>
            //     <div style={{ maxWidth: "555px", margin: "0 auto" }}>
            //       <div className="p-plan-container">
            //         <div className="inner-p-plan-cont">
            //           <p className="">Monthly</p>
            //           <div
            //             className={`switch-button-container cursor-pointer rounded-pill ${
            //               switchToggle ? "active" : ""
            //             }`}
            //             onClick={() => {
            //               setSwitchToogle(!switchToggle);
            //               setPaymentPlanDetails("");
            //             }}
            //           >
            //             <div className="switch-circle"></div>
            //           </div>
            //           <p>Yearly</p>
            //         </div>
            //       </div>

            //       {!paymentPlanDetails ? (
            //         <div className="mb-3">
            //           <ApiLoader />
            //         </div>
            //       ) : (
            //         <>
            //           <div
            //             className={`subs-plan-info ${
            //               switchToggle ? "py-4" : "py-5"
            //             }`}
            //           >
            //             {switchToggle && (
            //               <>
            //                 {paymentPlanDetails?.metadata?.discount ? (
            //                   <div className="sp-offer">
            //                     {paymentPlanDetails?.metadata?.discount}% Off
            //                   </div>
            //                 ) : null}
            //               </>
            //             )}
            //             <div className="s-plan-info">
            //               <p className="s-plan-data">
            //                 Pay {switchToggle ? "Yearly" : "Monthly"}{" "}
            //               </p>
            //               <p
            //                 className={`sp-amount ${
            //                   !switchToggle ? "mb-1" : ""
            //                 }`}
            //               >
            //                 $
            //                 {paymentPlanDetails?.unitAmount ? (
            //                   paymentPlanDetails?.unitAmount / 100
            //                 ) : (
            //                   <Loader />
            //                 )}
            //               </p>
            //               {switchToggle && (
            //                 <p className="sp-small-amount">
            //                   (AUD{" "}
            //                   {parseFloat(
            //                     String(
            //                       paymentPlanDetails?.unitAmount / 100 / 12
            //                     )
            //                   ).toFixed(2)}
            //                   /mo)
            //                 </p>
            //               )}
            //             </div>
            //           </div>
            //           <div style={{ marginTop: "32px" }}>
            //             <FeatureList />
            //           </div>
            //         </>
            //       )}
            //       {myCards?.length !== 0 ? (
            //         <Button
            //           btnTxt="Start free trial"
            //           className={"btn-member mx-auto"}
            //           isLoading={purchaseSubsLoading}
            //           disabled={myCards?.length === 0}
            //           onClick={() => {
            //             purchaseSubscription(
            //               paymentPlanDetails?.id,
            //               myCards?.[0]?.id
            //             );
            //           }}
            //         />
            //       ) : (
            //         <Button
            //           btnTxt="Start free trial"
            //           className={"btn-member mx-auto mt-4"}
            //           onClick={() => {
            //             setShowAddCardPopup(true);
            //           }}
            //         />
            //       )}

            //       <p className="sp-note">
            //         <b>Note:</b> Select the plan according to your preferance,
            //         as it will be changed once selected.
            //       </p>
            //     </div>
            //   </div>
            // </div>

            // below component is added in place of above.
            <SubscriptionPlanSelector isExistingGuest={true} />
          )}

          {showAddCardPopup && (
            <AddCardPopup
              show={showAddCardPopup}
              onHide={() => setShowAddCardPopup(false)}
            />
          )}

          {showSubCancelPopup ? (
            <CancelSubsPopup
              show={showSubCancelPopup}
              onHide={() => setShowSubCancelPopup(false)}
              setRefreshToggle={setRefreshToggle}
            />
          ) : null}
        </>
      )}
    </DashboardWrapper>
  );
};

export default Subscription;
