import React, { Dispatch, SetStateAction } from "react";
import { Box } from "@mui/material";

interface ReviewProps {
  reviewState: [number, Dispatch<SetStateAction<number>>];
}

const ReviewComponent = ({ reviewState }: ReviewProps) => {
  const [rating, setRating] = reviewState;
  const totalStars = 5;

  const handleRating = (rate: number): void => {
    setRating(rate);
  };

  return (
    <Box display={"flex"} gap={1.5}>
      {[...Array(totalStars)].map((_, index) => {
        const currentRating = index + 1;
        return (
          <span
            key={index}
            onClick={() => handleRating(currentRating)}
            style={{ cursor: "pointer" }}
          >
            {currentRating <= rating ? (
              <img
                src="/static/booking/reviewFilled.svg"
                style={{ cursor: "pointer" }}
                alt="filled star"
              />
            ) : (
              <img
                src="/static/booking/reviewEmpty.svg"
                style={{ cursor: "pointer" }}
                alt="empty star"
              />
            )}
          </span>
        );
      })}
    </Box>
  );
};

export default ReviewComponent;
