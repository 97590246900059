import { useEffect } from 'react';
import { getToken } from 'firebase/messaging';
import { messaging } from './firebase-config';
import { makePostCall } from './utils/Requests';
import endpoints from './utils/endpoints';
import { getItemFromStore, setLocalStorage } from './utils';
import { isMobile } from 'react-device-detect';
const useRequestNotificationPermission = () => {
  useEffect(() => {
    if (!isMobile) {

      if ('serviceWorker' in navigator) {
        const requestPermission = async () => {

          if (messaging) {
            try {
              // Request permission using the Notification API
              const permission = await Notification.requestPermission();
              if (permission === 'granted') {
                // Get the token if permission is granted
                if (!getItemFromStore("fToken")) {
                  const token = await getToken(messaging, { vapidKey: 'BAIRB4YAjF1FJvngbWv7bEqCqiJf9e6ZCf29aoIVkxkwGsVqFwPJF3iLBO3zLl7VTZ1-HgmaBCHz4o9g1rTGqB8' });
                  setLocalStorage("fToken", token)
                  // You can now use this token to send push notifications
                  const formData = new FormData();
                  formData.append("deviceType", "WEB");
                  formData.append("token", token);
                  makePostCall({
                    url: endpoints.send_fcm,
                    apiPayload: formData,
                    content_type: "multipart/form-data",
                  })
                    .then((res) => res
                    )
                    .catch((err) => {
                      console.error(err, "error");
                    });
                }
              } else {
                console.error('Unable to get permission to notify.');
              }

            } catch (error) {
              console.error('Error getting permission for Notifications', error);
            }
          }
        };
        requestPermission();
      }
    }

  }, []);
};

export default useRequestNotificationPermission;
