import React from "react";
const ActiveListIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Communication">
        <path
          id="Shape"
          d="M3.5 11C3.5 7.41015 6.41015 4.5 10 4.5C13.5899 4.5 16.5 7.41015 16.5 11C16.5 12.7926 15.7753 14.4146 14.6011 15.5913C14.3085 15.8845 14.309 16.3594 14.6022 16.6519C14.8954 16.9445 15.3703 16.944 15.6629 16.6508C17.1062 15.2044 18 13.2059 18 11C18 6.58172 14.4183 3 10 3C5.58172 3 2 6.58172 2 11C2 13.2059 2.89376 15.2044 4.3371 16.6508C4.62969 16.944 5.10456 16.9445 5.39776 16.6519C5.69097 16.3594 5.69147 15.8845 5.39889 15.5913C4.2247 14.4146 3.5 12.7926 3.5 11ZM6.5 11C6.5 9.067 8.067 7.5 10 7.5C11.933 7.5 13.5 9.067 13.5 11C13.5 11.9645 13.1108 12.8367 12.4792 13.4706C12.1868 13.764 12.1876 14.2389 12.481 14.5312C12.7744 14.8236 13.2493 14.8228 13.5416 14.5294C14.4421 13.6259 15 12.3774 15 11C15 8.23858 12.7614 6 10 6C7.23858 6 5 8.23858 5 11C5 12.3774 5.55794 13.6259 6.45836 14.5294C6.75075 14.8228 7.22562 14.8236 7.51902 14.5312C7.81242 14.2389 7.81323 13.764 7.52084 13.4706C6.88917 12.8367 6.5 11.9645 6.5 11ZM10 9C8.89543 9 8 9.89543 8 11C8 12.1046 8.89543 13 10 13C11.1046 13 12 12.1046 12 11C12 9.89543 11.1046 9 10 9Z"
          fill="#40B67D"
        />
      </g>
    </svg>
  );
};

export default ActiveListIcon;
