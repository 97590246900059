import { Modal } from "react-bootstrap";

const PaymentSuccessfullPopup = (props) => {
  return (
    <Modal {...props} centered dialogClassName="">
      <Modal.Body style={{ height: "fit-content" }}>
        <div
          className="emodal_wrapper mx-auto"
          style={{ minHeight: "fit-content", width: "100%" }}
        >
          <div className="eheading  justify-content-center align-items-center">
            <div>
              <p className="e_head_txt">Payment Successful</p>
            </div>
            <div>
              <p className="e_subhead_txt">
                Your payment was successful! You can now continue using Fitness
                mates
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-end" style={{ width: "100%" }}>
            <div
              className="ud_cont mt-4"
              onClick={props.clickCheckBooking}
              style={{ width: "100%" }}
            >
              Check My Bookings
            </div>
          </div>
          <div className="d-flex justify-content-end" style={{ width: "100%" }}>
            <div
              className="ud_cont_gray mt-4"
              onClick={props.onHide}
              style={{ width: "100%" }}
            >
              Go back
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default PaymentSuccessfullPopup;
