import { Fragment } from "react";
import moment from "moment";
import { FREQUENCY_TYPE, RECURRING_TYPE, SCHEDULE_TYPE } from "../types";
import { dateFormatter } from "../utils";
import { STRING_CONSTANTS } from "../utils/StringConstant";
import { apiConstants } from "../utils/Constants";
import dayjs from "dayjs";
import { DATE_FORMAT } from "./helperScheduleDates";

export const initialScheduleData = {
  scheduleType: SCHEDULE_TYPE.O, // O/R
  recurringDetails: {
    recurringType: "customday", // customday/recurring
    customDaysDetails: {
      customRecurDates: "",
      startTime: "",
      endTime: "",
    },
    repeatDetails: {
      repeatEvery: 1,
      frequencyType: "", // "Day", "Week", "Month"
      weekFrequency: "", // for week type
      monthFrequency: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
    },
  },
  oneTimeDetails: {
    eventDate: "",
    startTime: "",
    endTime: "",
  },
  virtualLinksMultiple: [],
};

export const handleShowScheduleDate = (sessionScheduleData) => {

  if (
    sessionScheduleData?.eventType === "O" &&
    sessionScheduleData?.eventDate?.length > 0
  ) {
    return (
      <>
        <div>
          {`One-time Event: ${sessionScheduleData?.eventDate?.length} date(s) selected`}
        </div>
        <small>
          {`Total Events : ${sessionScheduleData?.payloadSchedule?.length}`}
        </small>
        
      </>
    );
  } else if (
    sessionScheduleData?.eventType === "R" &&
    sessionScheduleData?.eventDate?.length > 0
  ) {

    if (
      sessionScheduleData?.eventType === "R" &&
      sessionScheduleData?.eventDate?.length > 1
    ){
      return (
        <>
          <div>
            {`Recurring Every Month Event: ${sessionScheduleData?.eventDate?.length} date(s) selected`}
          </div>

          {sessionScheduleData?.endEventType === "end-after" ? (
            <small>
              {`End After : ${sessionScheduleData?.endAfterVal} occurrence(s)`}
            </small>
          ) : (
            <small>{`End By : ${dayjs(sessionScheduleData?.endByVal).format(DATE_FORMAT.DATE_MONTH_FORMAT)}`}</small>
          )}
          <br />
          <small>
            {`Total Events : ${sessionScheduleData?.payloadSchedule?.length}`}
          </small>
        </>
      );
    }else if (
      sessionScheduleData?.eventType === "R" &&
      sessionScheduleData?.eventDate?.length < 2
    ) {
      return (
        <>
          <div>
            {`Recurring Event: ${sessionScheduleData?.repeatType} type selected`}
          </div>
          {sessionScheduleData?.endEventType === "end-after" ? (
            <small>
              {`End After : ${sessionScheduleData?.endAfterVal} occurrence(s)`}
            </small>
          ) : (
            <small>{`End By : ${dayjs(sessionScheduleData?.endByVal).format(
              DATE_FORMAT.DATE_MONTH_FORMAT
            )}`}</small>
          )}
          <br />
          <small>
            {`Total Events : ${sessionScheduleData?.payloadSchedule?.length}`}
          </small>
        </>
      );
    }
      
  }else {
     return (
       <div style={{ color: "#8F939A" }}>
         {STRING_CONSTANTS.schedule_date_placeholder}
       </div>
     );
  }


   
};

const getDateFormat = (date) => {
  return date;
};
const getTimeFormat = (time) => {
  const combinedDateTime = moment(time, "DD-MM-YYYY HH:mm:ss.SSS");

  const formattedDate = combinedDateTime.toDate().toString();
  return formattedDate;
};

const getCustomDateFormat = (dates) => {
  return dates?.map((i) => new Date(i));
};

// reset schedule data

const customTimeDisplayData = (time) => {
  const datetime = time;
  const isoDatetime = datetime.replace(" ", "T"); // "2024-10-26T09:00 AM"

  // Now parse with dayjs using custom format
  const formattedTime = dayjs(isoDatetime, "YYYY-MM-DDThh:mm A");

  return formattedTime;
};

export const resetScheduleData = (scheduleData, handleSetValue) => {
  console.log("scheduleData: ", scheduleData);
  handleSetValue(
    apiConstants.schedule_type,
    scheduleData?.type || SCHEDULE_TYPE.O
  );
  if (
    scheduleData?.type === SCHEDULE_TYPE.O &&
    scheduleData?.oneTimeDate &&
    scheduleData?.startTime &&
    scheduleData?.endTime
  ) {
    handleSetValue(apiConstants.event_date, scheduleData?.oneTimeDate);
    handleSetValue(
      apiConstants.event_start_time,
      customTimeDisplayData(scheduleData?.startTime)
    );
    handleSetValue(
      apiConstants.event_end_time,
      customTimeDisplayData(scheduleData?.endTime)
    );
  } else {
    handleSetValue(
      apiConstants.recurring_type,
      scheduleData?.recurringType || "customday"
    );

    if (
      scheduleData?.recurringType === "customday" &&
      scheduleData?.startTime &&
      scheduleData?.endTime &&
      scheduleData?.customDates?.length
    ) {
      handleSetValue(apiConstants.event_custom_date, scheduleData?.customDates);
      handleSetValue(
        apiConstants.event_start_time,
        customTimeDisplayData(scheduleData?.startTime)
      );
      handleSetValue(
        apiConstants.event_end_time,
        customTimeDisplayData(scheduleData?.endTime)
      );
    } else if (scheduleData?.recurringType === "recurring") {
      handleSetValue(
        apiConstants.repeat_every,
        scheduleData?.repeatNumber || 1
      );

      handleSetValue(
        apiConstants.recur_frequency,
        scheduleData?.recurringRepeatType
      );

      if (scheduleData?.recurringRepeatType === "Week") {
        handleSetValue(
          apiConstants.selected_days,
          scheduleData?.weekDays || []
        );
      }
      if (scheduleData?.recurringRepeatType === "Month") {
        handleSetValue(
          apiConstants.monthly_frequency,
          scheduleData?.monthlyFrequencyData
        );
      }

      handleSetValue(
        apiConstants.event_start_date,
        getDateFormat(scheduleData?.startDate)
      );
      handleSetValue(
        apiConstants.event_end_date,
        getDateFormat(scheduleData?.endDate)
      );

      handleSetValue(
        apiConstants.event_start_time,
        customTimeDisplayData(scheduleData?.startTime)
      );
      handleSetValue(
        apiConstants.event_end_time,
        customTimeDisplayData(scheduleData?.endTime)
      );
    }
  }
};

export const timeDayjsFormatInner = (time) => dayjs(time, "YYYY-MM-DD hh:mm A");
export const timeDayjsFormatApi = (time) =>
  moment(time, "YYYY-MM-DD hh:mm A").format("HH:mm:ss.SSSSSS");

export const timeDayjsFormatApiReverse = (date, time) => {
  if (!date || !time) {
    return null;
  } else {
    const combinedDateTime = dayjs(
      `${date} ${time}`,
      "YYYY-MM-DD HH:mm:ss.SSSSSS"
    );
    return combinedDateTime.format("YYYY-MM-DD hh:mm A");
  }
};

export const dateDayjsFormat = (time) => dayjs(time).format("YYYY-MM-DD");
export const timeDayjsFormat = (time) =>
  dayjs(time).format("YYYY-MM-DD hh:mm A");

export const compareDayjsTime = (start, end) => {
  const startTime = timeDayjsFormatInner(start);
  const endTime = timeDayjsFormatInner(end);
  return endTime.isAfter(startTime);
};

export const compareDayjsDate = (start, end) => {
  const startTime = dayjs(start, "YYYY-MM-DD").format("YYYY-MM-DD hh:mm A");
  const endTime = dayjs(end, "YYYY-MM-DD").format("YYYY-MM-DD hh:mm A");
  const startTime2 = timeDayjsFormatInner(startTime);
  const endTime2 = timeDayjsFormatInner(endTime);
  return endTime2.isAfter(startTime2);
};
