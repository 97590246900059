/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Button from "../../global/Button";
import SessionPopup from "../Dashboard/Popups/SessionPopup";
import UpdateDetailsPopup from "../Dashboard/Popups/UpdateDetailsPopup";
import AddCardPopupBookingEvent from "../Dashboard/Popups/AddCardPopupBookingEvent";
import { makeGetCall, makePostCall, makePatchCall } from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import { getItemFromStore, showToast } from "../../utils";
import ApiLoader from "../../global/ApiLoader";
import {
  BlackPlusIcon,
  DateMinus,
  DatePlus,
  GroupPeopleIcon,
  LocationGreyIcon,
  PayCardIcon,
  PeopleCommunityIconH,
  RightArrowStickIcon,
  SessionPackageIcon,
  SpClockIcon,
  SpDateIcon,
  VisaCardImg,
} from "../../utils/Icons/svgs";
import BookingErrorPopup from "../Dashboard/Popups/BookingErrorPopup";
import PaymentSuccessfullPopup from "../Dashboard/Popups/PaymentSuccessfullPopup";
import { LISTING_TYPE } from "../../types";
import { Form } from "react-bootstrap";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";

const PaymentStep = (props) => {
  const { attendees } = props;
  const [selectedPay, setSelecetedPay] = useState<any>("credit_card");
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [showUpdateDetailsModal, setShowUpdateDetailsModal] = useState(false);
  const [showAddCardPopup, setShowAddCardPopup] = useState(false);
  const [detailsData, setDetailsData] = useState<any>();
  const [myCards, setMyCards] = useState([]);
  const [reqGender, setReqGender] = useState("");
  const [reqAge, setReqAge] = useState<any>();
  const [counter, setCounter] = useState(1);
  const [paymentData, setPaymentData] = useState<any>();
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState<any>();
  const [isSendRequestButtonClicked, setIsSendRequestButtonClicked] = useState(false);
  const [ageError, setAgeError] = useState(null);
  const [showBookingError, setShowBookingError] = useState(false);
  const [payLoading, setPayLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const booking_id = searchParams.get("booking_id");
  const { id } = useParams();
  const [profileDetails, setProfileDetails] = useState({
    name: null,
    email: null,
    contact: "",
    age: null,
    gender: null,
  });
  const [packageCounter, setPackageCounter] = useState(1);
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const [showLeavePopup, setShowLeavePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let AllEventsData = [];
  let sessionEventsData = [];
  const [isCheckedTerms, setIsCheckedTerms] = useState(false);
  const [isCheckedPolicy, setIsCheckedPolicy] = useState(false);

  if (selectedEvents && detailsData?.events) {
    for (let k = 0; k < detailsData?.events?.length; k++) {
      for (let l = 0; l < selectedEvents?.length; l++) {
        if (detailsData?.events[k]?.id === selectedEvents[l]?.event) {
          AllEventsData.push({
            ...detailsData?.events[k],
            countEvent: selectedEvents[l].slots,
          });
        }
      }
    }

    sessionEventsData = detailsData?.events?.slice();
  }

  let totalSlots = 0;
  if (selectedEvents) {
    for (let m = 0; m < selectedEvents?.length; m++) {
      totalSlots += Number(selectedEvents?.[m]?.slots);
    }
  }

  const handlePaymentSuccessfullRedirection = () => {
    if (detailsData && detailsData.listingType === LISTING_TYPE.C) {
      navigate(ROUTE_CONSTANTS.team_membership)
    } else {
      navigate(`/my-booking`)
    }
  }

  const handleSuccess = () => {
    setShowAddCardPopup(false);
    makeGetCall({ url: endpoints.manage_card })
      .then((res) => {
        if (res.status.code === 200) {
          setMyCards(res.data);
        }
      })
      .catch(() => { });
    showToast("Card has been added successfully.", "success");
  };
  const handleCards = (e) => {
    setSelecetedPay(e.target.value);
  };

  const renderCardImage = (card) => {
    if (card === "visa") {
      return <VisaCardImg />;
    } else {
      return (
        <div
          style={{
            width: "auto",
            height: "24px",
            border: "1px solid #f8f9fa",
            color: "rgb(30, 49, 136)",
            padding: "2px",
            fontWeight: "bold",
          }}
        >
          {card}
        </div>
      );
    }
  };

  const handlePayment = () => {
    let apiPayload = {};

    ///pay load for post api//
    if (
      detailsData?.listingType === LISTING_TYPE.S ||
      detailsData?.listingType === LISTING_TYPE.SPECIAL
    ) {
      let sel = selectedEvents?.map((obj) => {
        return { event_id: obj.event, slots: obj.slots };
      });

      apiPayload = {
        name: profileDetails?.name,
        email: profileDetails?.email,
        // contact_number: profileDetails?.contact,
        events: sel,
        attendees: attendees,
      };
    } else if (detailsData?.listingType === LISTING_TYPE.M) {
      apiPayload = {
        name: profileDetails?.name,
        email: profileDetails?.email,
        listing_slots: props.parentPackageCounter,
        attendees: attendees,
      };
    }

    if (detailsData?.listingType === LISTING_TYPE.C) {
      let apiPayloadTeam = {
        listing: id,
        status: "R",
        slots_requested: props.parentPackageCounter,
        team_request_attendees: attendees,
      };

      makePostCall({
        url: endpoints.mylisting_new + `team-interest-requests/`,
        apiPayload: apiPayloadTeam,
      })
        .then((res) => {
          if (res.status.code === 200) {
            setShowPaymentPopup(true);
          }
        })
        .catch((err) => {
          showToast(err, "error");
        });
    } else {
      setPayLoading(true);
      makePostCall({
        url: endpoints.mylisting_new + `${id}/bookings/`,
        apiPayload,
      })
        .then((res) => {
          if (res.status.code === 200) {
            setShowPaymentPopup(true);
            setPayLoading(false);
          }
        })
        .catch((err) => {
          showToast(err, "error");
          setPayLoading(false);
        });
    }
  };

  //payment-preview api //

  const handlePay = () => {
    //pay load for api//
    let slotsTotal;
    if (
      detailsData?.listingType === LISTING_TYPE.S ||
      detailsData?.listingType === LISTING_TYPE.SPECIAL
    ) {
      slotsTotal = totalSlots;
    } else if (
      detailsData?.listingType === LISTING_TYPE.M ||
      detailsData?.listingType === LISTING_TYPE.C
    ) {
      slotsTotal = props.parentPackageCounter;
    }

    setPaymentLoading(true);

    makeGetCall({
      url:
        endpoints.mylisting_new + `${id}/payments-preview/?slots=${slotsTotal}`,
    })
      .then((res) => {
        if (res.status.code === 200) {
          setPaymentData(res.data);
        }
        setPaymentLoading(false);
      })
      .catch((err) => {
        showToast(err, "error");
        setPaymentLoading(false);
      });
  };

  /// managecard api ...

  useEffect(() => {
    makeGetCall({ url: endpoints.manage_card })
      .then((res) => {
        if (res.status.code === 200) {
          setMyCards(res.data);
        }
      })
      .catch(() => { });
  }, []);

  //intermediate booking api
  useEffect(() => {
    makeGetCall({ url: endpoints.listings_intermediate + `${booking_id}` })
      .then((res) => {
        if (res.status.code === 200) {
          setSelectedEvents(res.data.bookingData);
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  }, [booking_id]);

  const changeCardDefault = (id) => {
    setIsLoading(true);
    makePatchCall({
      url: endpoints.manage_card,
      apiPayload: { source_id: id },
    })
      .then((res) => {
        if (res.status.code === 200) {
          setIsLoading(false);
          makeGetCall({ url: endpoints.manage_card })
            .then((res) => {
              if (res.status.code === 200) {
                setMyCards(res.data);
              }
            })
            .catch(() => { });
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  // mylistingnew details api  //

  useEffect(() => {
    makeGetCall({
      url: endpoints.mylisting_new + `${id}`,
    })
      .then((res) => {
        setDetailsData(res.data);
        const userProfileData = getItemFromStore("userProfile");
        setProfileDetails((prevData) => ({
          ...prevData,
          name: userProfileData.name,
          email: userProfileData.email,
          gender:
            userProfileData.gender === "M"
              ? "Male"
              : userProfileData.gender === "F"
                ? "Female"
                : "Prefer not to say",

          age: moment().diff(
            moment(userProfileData.dob, "YYYY-MM-DD"),
            "years"
          ),
          contact: userProfileData.mobileNumber,
        }));
        setReqAge(
          moment().diff(moment(userProfileData.dob, "YYYY-MM-DD"), "years")
        );
        setReqGender(userProfileData.gender);
      })
      .catch(() => { });
  }, [id]);

  ///payement api
  useEffect(() => {
    if (!detailsData?.price) {
      return;
    }

    if (
      detailsData?.listingType === LISTING_TYPE.S ||
      detailsData?.listingType === LISTING_TYPE.SPECIAL
    ) {
      if (selectedEvents) {
        handlePay();
      }
    } else if (
      detailsData?.listingType === LISTING_TYPE.M ||
      detailsData?.listingType === LISTING_TYPE.C
    ) {
      handlePay();
    }
  }, [selectedEvents, packageCounter, detailsData]);

  const handleMinusCounter = () => {
    if (packageCounter > 1) {
      setPackageCounter((prev) => prev - 1);
    }
  };

  const handlePlusCounter = () => {
    if (packageCounter < detailsData?.teamSlotsAvailable) {
      setPackageCounter((prev) => prev + 1);
    }
  };

  const radioStyle = {
    height: "20px", // Increase the radio button size
    accentColor: "black",
  };

  function isDisabled() {
    // alert(detailsData?.listingType + " - " + isCheckedPolicy + " - " + isCheckedTerms);
    if (detailsData?.listingType == "C" && isCheckedPolicy && isCheckedTerms) {
      return false;
    }
    if (payLoading) {
      return true;
    } else if (!isCheckedPolicy || !isCheckedTerms) {
      return true;
    }
    if (!detailsData?.price) {
      return false;
    }

    if (myCards.length < 1) {
      if (Number(detailsData?.price) === 0.0 || detailsData?.price === null) {
        return false;
      }
      return true;
    }

    return false;
  }

  return (
    <>
      {!selectedEvents && !detailsData ? (
        <div className="mt-5">
          <ApiLoader />
          {detailsData && detailsData.listingType !== LISTING_TYPE.C ? (
            //when listing type team we are not proccessing payment
            <div
              className="h2 text-center mt-5"
              style={{ fontFamily: "Poppins" }}
            >
              Please wait system is processing your request
            </div>
          ) : null}
        </div>
      ) : (
        <div className="container-fluid booking_event booking_event_res p-md-0">
          <div
            className="booking_container booking_container_res row"
            style={{ paddingTop: "0px" }}
          >
            <div
              className="right_container col-12 col-md-8 mx-auto"
              style={{ borderLeft: "none", maxWidth: "692px" }}
            >
              <div className=" info_wrapper ">
                {/* {detailsData?.listingType !== LISTING_TYPE.M && (
                  <h2 className="p_title d-none d-md-block">Confirm & Pay</h2>
                )} */}
                <hr className="mb-5 d-md-none"></hr>
                <div className="ep_session d-md-flex">
                  {(detailsData?.listingType === LISTING_TYPE.S ||
                    detailsData?.listingType === LISTING_TYPE.SPECIAL) && (
                      <>
                        <p>{selectedEvents?.length} Sessions Selected</p>
                        <p
                          className="dates-btn-txt  d-flex align-items-center gap-3"
                          style={{ cursor: "pointer" }}
                          // onClick={() => setShowSessionModal(true)}
                          onClick={() => props.setCurrentStep(1)}
                        >
                          <p className="mb-0" style={{ color: "#EE762F" }}>
                            View Details
                          </p>
                          <span>
                            <RightArrowStickIcon fill="#EE762F" />
                          </span>
                        </p>
                      </>
                    )}
                  {/* {detailsData?.listingType === LISTING_TYPE.M && (
                    <p>
                      Sessions Included ({detailsData?.events?.length} Sessions)
                    </p>
                  )} */}

                  {/* {detailsData?.listingType !== LISTING_TYPE.C ? (
                    <p
                      className="dates-btn-txt  d-flex align-items-center gap-3"
                      style={{ cursor: "pointer" }}
                      // onClick={() => setShowSessionModal(true)}
                      onClick={() => props.setCurrentStep(1)}
                    >
                      <p className="mb-0" style={{ color: "#EE762F" }}>
                        View Details
                      </p>
                      <span>
                        <RightArrowStickIcon fill="#EE762F" />
                      </span>
                    </p>
                  ) : (
                    ""
                  )} */}
                </div>

                {(detailsData?.listingType === LISTING_TYPE.S ||
                  detailsData?.listingType === LISTING_TYPE.SPECIAL) && (
                    <>
                      <div className="">
                        {AllEventsData?.map((item, i) => {
                          return (
                            <div className="booking-session-card" key={i}>
                              <div className="ep_date_first">
                                <p className="ep_dt mb-0 ep_dt_min_width"
                                  style={{ textDecoration: 'none' }}
                                >
                                  <p style={{ marginBottom: '0px', textDecoration: 'underline' }}>
                                    {moment(item?.date).format("ddd")},{" "}
                                    {moment(item?.date).date()}{" "}
                                    {moment(item?.date).format("MMMM")}
                                  </p>
                                  <p
                                    className="ep_tm mb-0 d-sm-none d-block"
                                    style={{
                                      display: "inline-flex",
                                      alignItems: "center",
                                      border: '0px',
                                      textDecoration: 'none'
                                    }}
                                  >
                                    <img alt="" src="/static/clock.svg" />
                                    {moment(item?.startTime?.substring(0, 5), [
                                      "HH:mm",
                                    ]).format("hh:mmA")}
                                    -
                                    {moment(item.endTime?.substring(0, 5), [
                                      "HH:mm",
                                    ]).format("hh:mmA")}
                                    <img style={{ marginLeft: '6px' }} src="/static/EmptyDot.svg" />
                                    <span style={{ marginLeft: '6px' }}>
                                      <GroupPeopleIcon />
                                    </span>
                                    <span className="mb-0" style={{ marginLeft: '6px' }}>{item.countEvent}</span>
                                  </p>

                                </p>
                                <p
                                  className="ep_tm mb-0 d-sm-block d-none"
                                  style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img alt="" src="/static/clock.svg" />
                                  {moment(item?.startTime?.substring(0, 5), [
                                    "HH:mm",
                                  ]).format("hh:mmA")}
                                  -
                                  {moment(item.endTime?.substring(0, 5), [
                                    "HH:mm",
                                  ]).format("hh:mmA")}
                                </p>
                                <div className="ep_first d-sm-flex d-none">
                                  <span>
                                    <GroupPeopleIcon />
                                  </span>
                                  <p className="mb-0">{item.countEvent}</p>
                                </div>
                                <div className="ep_date_second">
                                  <p className="mb-0">
                                    {!(
                                      Number(detailsData?.price) === 0.0 ||
                                      detailsData?.price === null
                                    )
                                      ? `$${detailsData?.price * item.countEvent}`
                                      : ""}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                {detailsData?.listingType === LISTING_TYPE.M && (
                  <>
                    <div className="d-flex gap-2 overflow-auto">
                      <>
                        <h2 className="p_title d-none d-md-block">
                          {detailsData?.title}
                        </h2>
                      </>
                    </div>
                    <div className="session_p_selected_info mt-3 ">
                      <div className="d-flex gap-3 sp_first">
                        <SessionPackageIcon />
                        Package Selected
                      </div>
                      <div className="sp_second">
                        <div>{props.parentPackageCounter}</div>
                        <div className="s_p_amount">
                          $
                          {paymentData?.total
                            ? parseFloat(paymentData?.total).toFixed(2)
                            : 0}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {detailsData?.listingType === LISTING_TYPE.C && (
                  // according to new design
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: "20px",
                      marginBottom: "60px",
                      marginTop: "20px",
                      display: "flex",
                      lineHeight: "24px",
                      gap: "16px",
                      justifyContent: "center",
                    }}
                  >
                    <img src="/static/boxactive.svg" alt="" />
                    Team Selected: <span>{props.parentPackageCounter}</span>
                  </div>

                  // Comment this code for new design
                  // <div className="session_p_selected_info mt-3 ">
                  //   <div className="d-flex gap-3 sp_first">
                  //     <PeopleCommunityIconH
                  //       fill="#EE7830"
                  //       width="16"
                  //       height="16"
                  //     />
                  //     Team Member
                  //   </div>
                  //   <div className="sp_second">
                  //     <div className="s_p_counter">
                  //       <span
                  //         className="d-flex align-items-center cursor-pointer"
                  //         style={{
                  //           borderRadius: "100%",
                  //           backgroundColor: "rgba(238, 120, 48, 0.4)",
                  //         }}
                  //         onClick={() => handleMinusCounter()}
                  //       >
                  //         <DateMinus />
                  //       </span>
                  //       <span>{props.parentPackageCounter}</span>
                  //       <div
                  //         className="d-flex align-items-center cursor-pointer"
                  //         style={{
                  //           borderRadius: "100%",
                  //           backgroundColor: "rgba(238, 120, 48, 0.4)",
                  //         }}
                  //         onClick={() => handlePlusCounter()}
                  //       >
                  //         <DatePlus />
                  //       </div>
                  //     </div>
                  //     <div>
                  //       $
                  //       {paymentData?.total
                  //         ? parseFloat(paymentData?.total).toFixed(2)
                  //         : 0}
                  //     </div>
                  //   </div>
                  // </div>
                )}

                {paymentLoading ? (
                  <ApiLoader />
                ) : !(Number(detailsData?.price) === 0.0) ? (
                  <>
                    {detailsData?.listingType !== LISTING_TYPE.C && (
                      <>
                        <div className="ep_amount">
                          <hr style={{ color: "#dddddd", marginTop: "27px" }} />
                          <div className="ep_am_total">
                            <p className="first">Total Amount</p>
                            <p className="second">
                              ${parseFloat(paymentData?.total).toFixed(2)}
                            </p>
                          </div>
                        </div>
                        {/* <p className="ep_note" style={{ textAlign: "left" }}>
                          Note:{" "}
                          <span>
                            The Booking fee{" "}
                            {parseFloat(paymentData?.merchantFee).toFixed(2)}{" "}
                            has been charged inclusively
                          </span>
                        </p> */}
                      </>
                    )}
                  </>
                ) : null}

                <div className="ep_pay_info" style={{marginTop:"0px"}}>
                  {/* removing this according new design for team listing */}
                  {!(
                    Number(detailsData?.price) === 0.0 ||
                    detailsData?.price === null
                  ) ? (
                    <>
                      {detailsData?.listingType !== LISTING_TYPE.C && (
                        <>
                          <p className="pay_title">Payment Information</p>
                          <div className="p-3 ep_card_container">
                            <div className="ep_card">
                              <div
                                className=""
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    display: "inline-flex",
                                    gap: "24px",
                                  }}
                                >
                                  <span className="col-2 col-md-1">
                                    <PayCardIcon />
                                  </span>
                                  <span className="pay-text ">
                                    Credit or debit card
                                  </span>
                                </div>

                                <input
                                  className="col-1"
                                  type="radio"
                                  style={radioStyle}
                                  value="credit_card"
                                  checked={selectedPay === "credit_card"}
                                  onChange={handleCards}
                                />
                              </div>
                              {selectedPay === "credit_card" && (
                                <>
                                  {myCards.map((mc, index) => (
                                    <div
                                      className=" ep_pay_card d-flex justify-content-between"
                                      key={index}
                                    >
                                      <div className="d-flex first gap-3">
                                        <span>{renderCardImage(mc.brand)}</span>
                                        <p className="mb-0 card_num">
                                          <span>
                                            &#183; &#183; &#183; &#183;
                                          </span>{" "}
                                          {mc.last4}
                                        </p>
                                        <p className="mb-0 card_val">
                                          {mc.expMonth}/{mc.expYear}
                                        </p>
                                      </div>
                                      {mc.default ? (
                                        <div
                                          className="second"
                                          style={{ color: "#EE7830" }}
                                        >
                                          Selected
                                        </div>
                                      ) : (
                                        <div
                                          onClick={() =>
                                            changeCardDefault(mc.id)
                                          }
                                          className="second"
                                          style={{ cursor: "pointer" }}
                                        >
                                          {isLoading ? (
                                            <div
                                              className="lds-ring"
                                              style={{
                                                margin: "0 auto",
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <img
                                                style={{ width: "24px" }}
                                                src="/static/spinner.svg"
                                                alt="loader"
                                              />
                                            </div>
                                          ) : (
                                            "Select"
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  ))}

                                  <div
                                    className="row ep_added_card gx-0  align-items-center"
                                    onClick={() => setShowAddCardPopup(true)}
                                  >
                                    <span className="col-1">
                                      <BlackPlusIcon />
                                    </span>
                                    <div className="col-10">Add a new card</div>
                                  </div>
                                </>
                              )}
                              {/* <hr style={{ border: "1px solid #DDD" }}></hr>
                          <div className="row gx-0  align-items-center">
                            <span className="col-2 col-md-1">
                              <img src="/static/apple-pay.svg" alt="" />
                            </span>
                            <div className="col-9 col-md-10 pay-text ">
                              Apple Pay
                            </div>

                            <input
                              className="col-1 cursor-not-allowed"
                              type="radio"
                              style={radioStyle}
                              value="apple_pay"
                              disabled={true}
                              // checked={selectedPay === "apple_pay"}
                              // onChange={handleCards}
                            />
                          </div> */}

                              {/* <hr style={{ border: "1px solid #DDD" }}></hr>
                          <div className="row gx-0  align-items-center">
                            <span className="col-2 col-md-1">
                              <img src="/static/google-pay.svg" alt="" />
                            </span>
                            <div className="col-9 col-md-10 pay-text ">
                              Google Pay
                            </div>

                            <input
                              className="col-1 cursor-not-allowed"
                              type="radio"
                              style={radioStyle}
                              value="google_pay"
                              disabled={true}
                              // checked={selectedPay === "google_pay"}
                              // onChange={handleCards}
                            />
                          </div> */}
                            </div>
                          </div>{" "}
                        </>
                      )}
                    </>
                  ) : null}
                </div>
              </div>
              <div className="all-button all-button-d-res d-md-none my-4">
                {detailsData?.isCancellationAllowed ? (
                  <div className="c-cancellation-policy text-start">
                    <p className="c-first">Cancellation Policy</p>
                    <p className="c-second">
                      You can cancel your booking up until{" "}
                      {"{" + detailsData?.cancellationAllowedTillHours + "}"}{" "}
                      hours of your event
                    </p>
                  </div>
                ) : (
                  <div className="c-cancellation-policy text-start d-none">
                    <p className="c-first">Cancellation Policy</p>
                    <p className="c-second">
                      No cancellation allowed after booking
                    </p>
                  </div>
                )}

                <div className="all-button-top d-flex justify-content-center">
                  {/* {(detailsData?.listingType === LISTING_TYPE.S ||
                    detailsData?.listingType === LISTING_TYPE.SPECIAL) && (
                    <div className="event_btn_price">
                      <span className="event_price">{`$${
                        !(
                          Number(detailsData?.price) === 0.0 ||
                          detailsData?.price === null
                        )
                          ? detailsData?.price
                          : 0
                      }`}</span>

                      <span
                        className="event_class"
                        style={{ display: "flex", flexShrink: "0" }}
                      >
                        {!(
                          Number(detailsData?.price) === 0.0 ||
                          detailsData?.price === null
                        )
                          ? "/class"
                          : "(No Charges)"}
                      </span>
                    </div>
                  )} */}

                  {/* {detailsData?.listingType === LISTING_TYPE.M && (
                    <div style={{ flexShrink: 0 }}>
                      <div className="event_btn_price">
                        <span className="event_price">{`$${
                          !(
                            Number(detailsData?.price) === 0.0 ||
                            detailsData?.price === null
                          )
                            ? detailsData?.price
                            : 0
                        }`}</span>

                        <span className="event_class">
                          {!(
                            Number(detailsData?.price) === 0.0 ||
                            detailsData?.price === null
                          )
                            ? "/package"
                            : "(No Charges)"}
                        </span>
                      </div>
                      {!(
                        Number(detailsData?.price) === 0.0 ||
                        detailsData?.price === null
                      ) ? (
                        <span className="p_txt_below" style={{ flexShrink: 0 }}>
                          {packageCounter} package included
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  )} */}

                  {/* {detailsData?.listingType === LISTING_TYPE.C && (
                    <div>
                      <div
                        className="event_btn_price "
                        style={{ display: "block", textAlign: "start" }}
                      >
                        <span
                          style={{ textAlign: "start" }}
                          className="event_price "
                        >
                          ${detailsData?.price}
                        </span>

                        
                      </div>
                      <span className="" style={{ fontSize: "12px" }}>
                        per/month
                      </span>
                    </div>
                  )} */}

                  {/* <Button
                    btnTxt={
                      !(
                        Number(detailsData?.price) === 0.0 ||
                        detailsData?.price === null
                      )
                        ? `Pay $${parseFloat(paymentData?.total).toFixed(2)}`
                        : "Confirm"
                    }
                    className={"backtologin"}
                    onClick={() => {
                      handlePayment();
                    }}
                    isLoading={payLoading || paymentLoading}
                    disabled={isDisabled()}
                  /> */}

                  {/* <div>
                    <Button
                      style={{ width: "350px" }}
                      btnTxt={
                        !(
                          Number(detailsData?.price) === 0.0 ||
                          detailsData?.price === null
                        )
                          ? `Pay $${parseFloat(paymentData?.total).toFixed(2)}`
                          : "Confirm"
                      }
                      className={"backtologin mb-0 mx-auto"}
                      onClick={() => {
                        handlePayment();
                      }}
                      isLoading={
                        payLoading || paymentLoading || !paymentData?.total
                      }
                      disabled={isDisabled()}
                    />
                  </div> */}
                </div>
              </div>
              <div className="checkbox-ticket mt-0 mt-md-3 pl-1">
                <Form.Check
                  className={`checked_cont ${isCheckedTerms ? "active" : ""}`}
                  type="checkbox"
                  bsPrefix="checked_cont"
                  checked={isCheckedTerms}
                  onChange={() => setIsCheckedTerms(!isCheckedTerms)}
                ></Form.Check>
                <div
                  style={{
                    textAlign: "start",
                    fontSize: "13px",
                    lineHeight: "16px",
                    fontFamily: "Poppins",
                  }}
                >
                  <span onClick={() => setIsCheckedTerms(!isCheckedTerms)}> I have read the{" "}</span>
                 
                  <a
                    href="/policies"
                    target="_blank"
                    style={{
                      color: "#ee762f",
                      lineHeight: "16px",
                      fontWeight: 500,
                      fontFamily: "Poppins",
                    }}

                  >
                    terms & conditions
                  </a>{" "}
                  <span onClick={() => setIsCheckedTerms(!isCheckedTerms)}>and acknowledge to proceed for cancellation terms.</span>
                </div>
              </div>
              <div
                className="checkbox-ticket mt-0 mt-md-3 pl-1"
                style={{
                  marginBottom: "20px",
                }}
              >
                <Form.Check
                  className={`checked_cont ${isCheckedPolicy ? "active" : ""}`}
                  type="checkbox"
                  bsPrefix="checked_cont"
                  checked={isCheckedPolicy}
                  onChange={() => setIsCheckedPolicy(!isCheckedPolicy)}
                ></Form.Check>
                <div
                  style={{
                    textAlign: "start",
                    fontSize: "13px",
                    lineHeight: "16px",
                    fontFamily: "Poppins",
                  }}
                >
                  <span onClick={() => setIsCheckedPolicy(!isCheckedPolicy)}> I agree to the{" "}</span>
                  <a
                    href="/policies"
                    target="_blank"
                    style={{
                      color: "#ee762f",
                      lineHeight: "16px",
                      fontWeight: 500,
                      fontFamily: "Poppins",
                    }}
                  >
                    Fee Waiver policy
                  </a>{" "}
                  <span onClick={() => setIsCheckedPolicy(!isCheckedPolicy)}>of fitnessmates.</span>
                </div>
              </div>
              <div
                className="btn_footer_listing_booking "
                style={{ paddingTop: "0px" }}
              >
                <Button
                  style={{ maxWidth: "546px", marginTop: "0px" }}
                  btnTxt={
                    detailsData?.listingType === LISTING_TYPE.C
                      ? "Send request"
                      : !(
                        Number(detailsData?.price) === 0.0 ||
                        detailsData?.price === null
                      )
                        ? `Pay $${parseFloat(paymentData?.total).toFixed(2) || 0}`
                        : "Confirm"
                  }
                  className={"backtologin mb-0"}
                  onClick={() => {
                    if(detailsData?.listingType === LISTING_TYPE.C){
                      setIsSendRequestButtonClicked(true);
                    }
                    handlePayment();
                  }}
                  isLoading={payLoading || paymentLoading}
                  disabled={isSendRequestButtonClicked || isDisabled()}
                />
              </div>
            </div>
          </div>

          {showSessionModal && (
            <SessionPopup
              show={showSessionModal}
              onHide={() => setShowSessionModal(false)}
              counter={counter}
              setCounter={setCounter}
              allEvents={sessionEventsData}
              realAllEventsData={AllEventsData}
              selectedEvents={selectedEvents}
              detailsData={detailsData}
              setSelectedEvents={setSelectedEvents}
            />
          )}
          {showUpdateDetailsModal && (
            <UpdateDetailsPopup
              show={showUpdateDetailsModal}
              onHide={() => setShowUpdateDetailsModal(false)}
              profileDetails={profileDetails}
              setProfileDetails={setProfileDetails}
              setReqGender={setReqGender}
              setReqAge={setReqAge}
            />
          )}
          {showAddCardPopup && (
            <AddCardPopupBookingEvent
              handleSuccess={handleSuccess}
              show={showAddCardPopup}
              onHide={() => setShowAddCardPopup(false)}
            />
          )}
          <BookingErrorPopup
            show={showBookingError}
            onHide={() => {
              setShowBookingError(false);
              setAgeError(null);
            }}
            gender={detailsData?.gender}
            ageError={ageError}
            setAgeError={setAgeError}
          />
          <PaymentSuccessfullPopup
            show={showPaymentPopup}
            onHide={() => {
              setShowPaymentPopup(false);
              navigate(`/listing-two/${id}`);
            }}
            clickCheckBooking={() => handlePaymentSuccessfullRedirection()}
          />
        </div>
      )}
      {showLeavePopup ? (
        <div className={"modal show"}>
          <div
            className="modal-section log_out_modal csv-modal-wrap"
            style={{ maxWidth: "398px" }}
          >
            <div className="close-btn-csv">
              <img
                src="/static/cross-black.svg"
                onClick={() => setShowLeavePopup(false)}
                className="close-btn"
                alt=""
              />
            </div>
            <div className="modal-top-part" style={{ paddingTop: "24px" }}>
              <h5>Are you sure you want to leave this page?</h5>
              <p>
                Please note that if you leave this page, all the data you have
                filled in during the booking process will be lost and cannot be
                retrieved.
              </p>
            </div>
            <div className="button_option">
              <Button
                btnTxt="Yes, leave"
                className={"backtologin"}
              // onClick={downloadCsv}
              />
              <Button
                btnTxt="Cancel"
                className={"backtologin notnow"}
              // onClick={() => setShowCsvPopup(false)}
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default PaymentStep;
