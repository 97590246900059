import Modal from "react-bootstrap/Modal";
import { showToast } from "../../../utils";
import React from "react";

const ShareEvents = ({ showShare, detailsData, handleNotShowShare }) => {
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(detailsData?.dynamicLink);
      showToast("Text copied!", "success");
    } catch (err) {
      showToast("Failed to copy the text. Please try again.", "error");
    }
  };

  const encodedMessage = encodeURIComponent("Check out this link!");
  const encodedLink = encodeURIComponent(detailsData?.dynamicLink);

  const handleWhatsAppShare = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodedMessage}%20${encodedLink}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleSmsShare = () => {
    const smsUrl = `sms:?body=${encodedMessage}%20${encodedLink}`;
    window.open(smsUrl, "_blank");
  };

  const handleMessengerShare = () => {
    const messengerUrl = `fb-messenger://share?link=${encodedLink}`;
    window.open(messengerUrl, "_blank");
  };

  const handleTwitterShare = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedMessage}%20${encodedLink}`;
    window.open(twitterUrl, "_blank");
  };

  const handleFacebookShare = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`;
    window.open(facebookUrl, "_blank");
  };

  const handleEmailShare = () => {
    const emailUrl = `mailto:?subject=${encodedMessage}&body=${encodedMessage}%20${encodedLink}`;
    window.open(emailUrl, "_blank");
  };

  return (
    <Modal
      show={showShare}
      onHide={handleNotShowShare}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="share_modal_layout">
          <img
            src="/static/close.svg"
            alt="Close"
            style={{ cursor: "pointer" }}
            onClick={handleNotShowShare}
            className="share_modal_close_btn"
          />
          <h4>Share Listing</h4>
          <div className="share_modal_details">
            {detailsData?.thumbnail ? (
              <img
                src={detailsData?.thumbnail || "/static/expertise2.png"}
                className="event_img"
                alt=""
                style={{
                  width: "110px",
                  height: "86px",
                  borderRadius: "12px",
                }}
                loading="lazy"
                onClick={(e) => {
                  e.preventDefault();
                  // scrollToTop();
                  // navigate(`listing-two/${detailsData.id}`);
                }}
              />
            ) : detailsData?.photos?.length > 0 ? (
              /\.(mp4|mov|avi)$/.test(
                detailsData?.photos[0].documentName?.toLowerCase()
              ) ? (
                <>
                  <video
                    style={{
                      width: "110px",
                      height: "86px",
                      borderRadius: "12px",
                    }}
                    src={detailsData?.photos[0]?.uploadDocument}
                    className="event_img"
                    //preload="none"
                    // autoPlay
                    controls
                    controlsList="nodownload"
                    // playsInline
                    // loop
                    muted
                    onClick={(e) => {
                      e.preventDefault();
                      // scrollToTop();
                      // navigate(`listing-two/${detailsData.id}`);
                    }}
                  >
                    <source src={detailsData?.photos[0]?.uploadDocument} />
                  </video>
                </>
              ) : (
                <img
                  src={
                    detailsData?.photos[0]?.uploadDocument ||
                    "/static/expertise2.png"
                  }
                  className="event_img"
                  alt=""
                  style={{
                    width: "110px",
                    height: "86px",
                    borderRadius: "12px",
                  }}
                  loading="lazy"
                  onClick={(e) => {
                    e.preventDefault();
                    // scrollToTop();
                    // navigate(`listing-two/${detailsData.id}`);
                  }}
                />
              )
            ) : (
              <img
                style={{
                  width: "110px",
                  height: "86px",
                  borderRadius: "12px",
                }}
                src="/static/expertise2.png"
                alt=""
              />
            )}

            <div className="title">
              {detailsData?.title && <h5>{detailsData?.title}</h5>}
              <p className="sub-title">
                Address: {detailsData?.address ? detailsData?.address : ""}
              </p>
              <div className="d-sm-none">
                {detailsData?.listingType === "S" ||
                detailsData?.listingType === "SPECIAL" ? (
                  <button
                    className="session_btn_popup"
                    // style={{ padding: "10px" }}
                  >
                    <img src="/static/Sport-white.svg" alt="Sport Balls" />
                    Session
                  </button>
                ) : detailsData?.listingType === "P" ? (
                  <button
                    className="package_btn_popup"
                    style={{ padding: "10px" }}
                  >
                    <img src="/static/box-white.svg" alt="Sport Balls" />
                    Package
                  </button>
                ) : (
                  <button
                    className="team_btn_popup"
                    style={{ padding: "10px" }}
                  >
                    <img src="/static/people-white.svg" alt="Sport Balls" />
                    Team
                  </button>
                )}
              </div>
            </div>
            <div className="d-none d-sm-block">
              {detailsData?.listingType === "S" ||
              detailsData?.listingType === "SPECIAL" ? (
                <button
                  className="session_btn_popup"
                  // style={{ padding: "10px" }}
                >
                  <img src="/static/Sport-white.svg" alt="Sport Balls" />
                  Session
                </button>
              ) : detailsData?.listingType === "P" ? (
                <button
                  className="package_btn_popup"
                  style={{ padding: "10px" }}
                >
                  <img src="/static/box-white.svg" alt="Sport Balls" />
                  Package
                </button>
              ) : (
                <button className="team_btn_popup" style={{ padding: "10px" }}>
                  <img src="/static/people-white.svg" alt="Sport Balls" />
                  Team
                </button>
              )}
            </div>
          </div>
          <div className="share_options">
            <span className="flex-fill" onClick={() => handleCopy()}>
              <img src="/static/copy.svg" alt="Copy icon" />
              Copy Link
            </span>
            <span className="flex-fill" onClick={handleEmailShare}>
              <img src="/static/mail.svg" alt="Mail icon" />
              Email
            </span>
            <span className="flex-fill" onClick={handleSmsShare}>
              <img src="/static/message.svg" alt="Message icon" />
              Message
            </span>
            <span className="flex-fill" onClick={handleWhatsAppShare}>
              <img src="/static/whatsapp.svg" alt="WhatsApp icon" />
              WhatsApp
            </span>
            <span className="flex-fill" onClick={handleMessengerShare}>
              <img src="/static/messenger.svg" alt="Messenger icon" />
              Messenger
            </span>
            <span className="flex-fill" onClick={handleFacebookShare}>
              <img src="/static/facebook.svg" alt="Facebook icon" />
              Facebook
            </span>
            <span className="flex-fill" onClick={handleTwitterShare}>
              <img src="/static/twitter.svg" alt="Twitter icon" />
              Twitter
            </span>
          </div>
          <button className="mt-5 d-none back-btn" onClick={handleNotShowShare}>
            Back
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(ShareEvents);
