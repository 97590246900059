import React from "react";

const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={"prev-arrow-btn-container"}
        style={{ ...style }}
        onClick={onClick}
      >
        <div className="prev-arrow-btn">
          <img src="/static/chevron-black-right.svg" alt="" />
        </div>
      </div>
    );
  }

  export default React.memo(SamplePrevArrow);