import React from "react";
import { STRING_CONSTANTS } from "../utils/StringConstant";
import Button from "./Button";
interface LogoutProps {
  onOk: any;
  onCancel: any;
}
const LogoutModal = (props: LogoutProps) => {
  const { onOk, onCancel } = props;
  return (
    <div className={"modal show"}>
      <div className="modal-section log_out_modal">
        <div className="modal-top-part">
          <h5>{STRING_CONSTANTS.logout_title}</h5>
          <p>{STRING_CONSTANTS.logout_desc}</p>
        </div>
        <div className="button_option">
          <Button
            btnTxt="Log out"
            className={"backtologin"}
            onClick={() => onOk()}
          />
          <Button
            btnTxt="Cancel"
            className={"backtologin cancel"}
            onClick={() => onCancel()}
          />
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
