import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { DATE_FORMAT } from "./helperScheduleDates";

dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(advancedFormat);

// for test purpose example
// const eachWeekDay = ["Tue", "Wed", "Fri"];
// const eachWeekOf = [2, 3, 5];
// const startDate = dayjs("2024-10-1");
// const dates = ["2024-10-1", "2024-10-19"];
// const endDate = dayjs("2026-1-30");
// const endsBy = "end-after";
// const occurrence = 20;
// const skip = 2;

function getWeekOfMonth(date) {
  const startOfMonth = dayjs(date).startOf("month");
  return Math.ceil(date.date() / 7);
}

// single-date: recurring-month
export function getRecurringMonthDates(
  start,
  end,
  weekDays,
  weekNumbers,
  endsBy,
  occurrence,
  skip
) {
  let currentDate: any = dayjs(start);
  let result: any = [];

  let selectedMonth = dayjs(start).month();

  if (endsBy === "end-after") {
    let occurrenceCount = 0;

    while (
      occurrenceCount < occurrence &&
      (currentDate.isBefore(dayjs().add(1, "year")) ||
        currentDate.isSame(dayjs().add(1, "year")))
    ) {
      const currentMonth = currentDate.month();

      if (currentMonth === selectedMonth) {
        const weekDay = currentDate.format("ddd");
        const weekOfMonth = getWeekOfMonth(currentDate);

        if (weekDays.includes(weekDay) && weekNumbers.includes(weekOfMonth)) {
          result.push({
            date: currentDate.format(DATE_FORMAT.DATE_MONTH_FORMAT),
          });
        }
      }
      // add day logic
      currentDate = currentDate.add(1, "day");

      //skip logic
      if (currentDate.month() !== currentMonth) {
        if (skip > 0) {
          currentDate = currentDate
            .startOf("month")
            .add(Number(skip || 0), "month");
        }
        selectedMonth = currentDate.month();
        occurrenceCount++;
      }
    }
  } else {
    while (
      (currentDate.isBefore(end) || currentDate.isSame(end)) &&
      (currentDate.isBefore(dayjs().add(1, "year")) ||
        currentDate.isSame(dayjs().add(1, "year")))
    ) {
      const currentMonth = currentDate.month();

      if (currentMonth === selectedMonth) {
        const weekDay = currentDate.format("ddd");
        const weekOfMonth = getWeekOfMonth(currentDate);

        if (weekDays.includes(weekDay) && weekNumbers.includes(weekOfMonth)) {
          result.push({
            date: currentDate.format(DATE_FORMAT.DATE_MONTH_FORMAT),
          });
        }
      }
      // add day logic
      currentDate = currentDate.add(1, "day");

      //skip logic
      if (currentDate.month() !== currentMonth) {
        if (skip > 0) {
          currentDate = currentDate
            .startOf("month")
            .add(Number(skip || 0), "month");
        }
        selectedMonth = currentDate.month();
      }
    }
  }

  return result;
}

// single-date: recurring-week
export function getRecurringWeekDates(
  start,
  end,
  weekDays,
  endsBy,
  occurrence,
  skip
) {
  let currentDate = dayjs(start).clone();
  const result: any = [];

  if (endsBy === "end-after") {
    for (let week = 0; week < occurrence; week++) {
      if (currentDate.isAfter(dayjs().add(1, "year"))) break;

      for (let i = 0; i < 7; i++) {
        if (currentDate.isAfter(dayjs().add(1, "year"))) break;
        if (weekDays.includes(currentDate.format("ddd"))) {
          result.push({
            date: currentDate.format(DATE_FORMAT.DATE_MONTH_FORMAT),
          });
        }
        if (currentDate.format("ddd") === "Sat") {
          currentDate = currentDate.add(1, "days");
          break;
        } else {
          currentDate = currentDate.add(1, "days");
        }
      }
      currentDate = currentDate.add(skip, "weeks");
    }
  } else {
    while (
      (currentDate.isBefore(end) || currentDate.isSame(end)) &&
      (currentDate.isBefore(dayjs().add(1, "year")) ||
        currentDate.isSame(dayjs().add(1, "year")))
    ) {
      for (let i = 0; i < 7; i++) {
        if (
          currentDate.isAfter(dayjs().add(1, "year")) ||
          currentDate.isAfter(end)
        ) {
          break;
        }
        if (weekDays.includes(currentDate.format("ddd"))) {
          result.push({
            date: currentDate.format(DATE_FORMAT.DATE_MONTH_FORMAT),
          });
        }
        if (currentDate.format("ddd") === "Sat") {
          currentDate = currentDate.add(1, "days");
          break;
        } else {
          currentDate = currentDate.add(1, "days");
        }
      }
      currentDate = currentDate.add(skip, "weeks");
    }
  }

  return result;
}

// recurring - day ( single-date)
export function getRecurringDayDates(start, end, endsBy, occurrence, skip) {
  let currentDate = dayjs(start);
  let result: any = [];

  if (endsBy === "end-after") {
    let occurrenceCount = 0;

    while (
      occurrenceCount < occurrence &&
      (currentDate.isBefore(dayjs().add(1, "year")) ||
        currentDate.isSame(dayjs().add(1, "year")))
    ) {
      result.push({
        date: currentDate.format(DATE_FORMAT.DATE_MONTH_FORMAT),
      });
      occurrenceCount++;
      //skip logic with add day logic
      currentDate = currentDate.add(Number(skip || 0) + 1, "day");
    }
  } else {
    while (
      (currentDate.isBefore(end) || currentDate.isSame(end)) &&
      (currentDate.isBefore(dayjs().add(1, "year")) ||
        currentDate.isSame(dayjs().add(1, "year")))
    ) {
      result.push({
        date: currentDate.format(DATE_FORMAT.DATE_MONTH_FORMAT),
      });
      //skip logic with add day logic
      currentDate = currentDate.add(Number(skip || 0) + 1, "day");
    }
  }

  return result;
}

// single-date/multi-dates: one-time
export function getOnetimeDates(dates) {
  let result: any = [];

  dates?.forEach((val) => {
    const formattedDate = dayjs(val);
    result.push({
      date: formattedDate.format(DATE_FORMAT.DATE_MONTH_FORMAT),
    });
  });

  return result;
}

// multi-dates: recurring-month
export function getMultiDatesRecurringMonth(
  dates,
  endDate,
  endsBy,
  occurrence,
  skip
) {
  let currentDate = dayjs(dates[0]);
  const datesOnly = dates?.map((val) => dayjs(val).format("DD"));
  let result: any = [];

  let selectedMonth = dayjs(dates[0]).month();

  if (endsBy === "end-after") {
    let occurrenceCount = 0;

    while (
      occurrenceCount < occurrence &&
      (currentDate.isBefore(dayjs().add(1, "year")) ||
        currentDate.isSame(dayjs().add(1, "year")))
    ) {
      const currentMonth = currentDate.month();

      if (currentMonth === selectedMonth) {
        if (datesOnly?.includes(currentDate.format("DD"))) {
          result.push({
            date: currentDate.format(DATE_FORMAT.DATE_MONTH_FORMAT),
          });
        }
      }
      // add day logic
      currentDate = currentDate.add(1, "day");

      //skip logic
      if (currentDate.month() !== currentMonth) {
        if (skip > 0) {
          currentDate = currentDate
            .startOf("month")
            .add(Number(skip || 0), "month");
        }
        selectedMonth = currentDate.month();
        occurrenceCount++;
      }
    }
  } else {
    while (
      (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) &&
      (currentDate.isBefore(dayjs().add(1, "year")) ||
        currentDate.isSame(dayjs().add(1, "year")))
    ) {
      const currentMonth = currentDate.month();

      if (currentMonth === selectedMonth) {
        if (datesOnly?.includes(currentDate.format("DD"))) {
          result.push({
            date: currentDate.format(DATE_FORMAT.DATE_MONTH_FORMAT),
          });
        }
      }
      // add day logic
      currentDate = currentDate.add(1, "day");

      //skip logic
      if (currentDate.month() !== currentMonth) {
        if (skip > 0) {
          currentDate = currentDate
            .startOf("month")
            .add(Number(skip || 0), "month");
        }
        selectedMonth = currentDate.month();
      }
    }
  }

  return result;
}
