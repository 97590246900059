import { useState, useRef, useEffect } from "react";
import dropdownOpenIcon from "../../assets/img/createListingIcon/arrow-down-gray.svg";

const Dropdown = ({
  title = "Select",
  options = [],
  selectedDropdownValue,
  setSelectedDropdownValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleOptionChange = (option: any) => {
    setSelectedDropdownValue(option.label);
    toggleDropdown();
  };
  
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdown-container" ref={dropdownRef}>
      <div className="dropdown-content" onClick={toggleDropdown}>
        <span className="dropdown-text">{selectedDropdownValue || title}</span>
        <div className={`dropdown-icon ${isOpen ? "open" : ""}`}>
          <img src={dropdownOpenIcon} />
        </div>
      </div>
      {isOpen && (
        <div
          style={{
            position: "absolute",
            left: "0",
            width: "100%",
            top: "50px",
            border: "1px solid #ddd",
            borderRadius: "4px",
            background: "white",
            color: "#25272d",
            zIndex: "1000",
          }}
        >
          {options?.map((data: any) => (
            <div
              style={{
                padding: "4px 21px",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "24px",
                cursor: "pointer",
              }}
              key={data?.id}
              onClick={() => handleOptionChange(data)}
            >
              {data?.label ? data.label : ""}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
