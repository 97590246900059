import * as Yup from "yup";
import { apiConstants, errorMessage } from "../../utils/Constants";
import { STRING_CONSTANTS } from "../../utils/StringConstant";

export const BasicDetailsValidation = Yup.object().shape({
  [apiConstants.name]: Yup.string()
    .required(errorMessage.fieldRequired(STRING_CONSTANTS.username))
    .max(250, STRING_CONSTANTS.user_name_250_max),
});

export const  PersonalDetailsValidation = Yup.object().shape({
  [apiConstants.user_type]: Yup.string().required(
    errorMessage.fieldRequired(STRING_CONSTANTS.user_type)
  ),
  [apiConstants.name]: Yup.string()
    .required(errorMessage.fieldRequired(STRING_CONSTANTS.username))
    .max(250, STRING_CONSTANTS.user_name_250_max),
  [apiConstants.businessContactEmail]: Yup.string()
    .email()
    .required(errorMessage.fieldRequired("business contact email"))
    .max(250, STRING_CONSTANTS.user_name_250_max),
  [apiConstants.email]: Yup.string()
    .email()
    .required(errorMessage.fieldRequired("email"))
    .max(250, STRING_CONSTANTS.user_name_250_max),
  [apiConstants.gender]: Yup.string()
    .required("Please select gender.")
    .max(250, STRING_CONSTANTS.user_name_250_max),
  [apiConstants.bio]: Yup.string()
    .required(errorMessage.fieldRequired(STRING_CONSTANTS.bio))
    .test("word-count", "Bio must be at most 300 words", (value) => {
      if (!value) return true;
      const wordCount = value.trim().split(/\s+/).length;
      return wordCount <= 300;
    }),
  // .max(500, STRING_CONSTANTS.bio_500_max),
});
export const  PersonalDetailsValidationGuest = Yup.object().shape({
  [apiConstants.user_type]: Yup.string().required(
    errorMessage.fieldRequired(STRING_CONSTANTS.user_type)
  ),
  [apiConstants.name]: Yup.string()
    .required(errorMessage.fieldRequired(STRING_CONSTANTS.username))
    .max(250, STRING_CONSTANTS.user_name_250_max),
  [apiConstants.email]: Yup.string()
    .email()
    .required(errorMessage.fieldRequired("email"))
    .max(250, STRING_CONSTANTS.user_name_250_max),
  [apiConstants.gender]: Yup.string()
    .required("Please select gender.")
    .max(250, STRING_CONSTANTS.user_name_250_max),
  [apiConstants.bio]: Yup.string().test("word-count", "Bio must be at most 300 words", (value) => {
      if (!value) return true;
      const wordCount = value.trim().split(/\s+/).length;
      return wordCount <= 300;
    }),
  // .max(500, STRING_CONSTANTS.bio_500_max),
});

export const BusinessInterestValidation = Yup.object().shape({});
export const BusinessInterestValidationTwo = Yup.object().shape({
  attendees: Yup.array().of(
    Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Name is required"),
        email: Yup.string().required("Email is required"),
        // phone_number: Yup.number().required("Contact is required"),
      })
    )
  ),
});
export const PasswordValidation = Yup.object().shape({
  [apiConstants.current_password]: Yup.string().required(
    errorMessage.fieldRequired(STRING_CONSTANTS.old_password)
  ),
  [apiConstants.new_password]: Yup.string()
    .required(errorMessage.fieldRequired(STRING_CONSTANTS.new_password))
    .min(8, STRING_CONSTANTS.password_length_8),
});

export const UnlinkSocialValidation = Yup.object().shape({
  [apiConstants.email]: Yup.string()
    .required(errorMessage.fieldRequired(apiConstants.email))
    .email(STRING_CONSTANTS.invalid_email),
  [apiConstants.password]: Yup.string()
    .required(errorMessage.fieldRequired(apiConstants.password))
    .min(8, STRING_CONSTANTS.password_length_8),
});

export const BlockListingValidation = Yup.object().shape({
  [apiConstants.block_id]: Yup.string().required(
    errorMessage.fieldRequired(apiConstants.block_id)
  ),
});

export const tradingNameValidation = Yup.object().shape({
  [apiConstants.tradingName]: Yup.string()
    .required(errorMessage.fieldRequired(STRING_CONSTANTS.trading_name))
    .max(250, STRING_CONSTANTS.user_name_250_max),

});