/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  getItemFromStore,
  isUserAuthenticated,
  notAValidUser,
  removeAllItemFromStore,
  setLocalStorage,
  showToast,
} from "../../utils";
import endpoints from "../../utils/endpoints";
import {
  makeGetCall,
  makeNextPageCall,
  makePatchCall,
  makePostCall,
} from "../../utils/Requests";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import useDebounce from "../../utils/useDebounce";

import InfiniteScroll from "react-infinite-scroll-component";

import ApiLoader from "../../global/ApiLoader";
import moment from "moment";
import { STRING_CONSTANTS } from "../../utils/StringConstant";
import { USER_TYPE } from "../../types";
import LogoutModal from "../../global/LogoutModal";

const GlobalHeader = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [users, setUsers] = useState<any>([]);
  const [sideList, setSideList] = useState(false);
  const [, setIsLoading] = useState(false);
  const [hasSerachData, setHasSearchData] = useState(true);
  const [logout, setLogout] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [profileImageExtension, setProfileImageExtension] = useState("");

  const [nextUrl, setNextUrl] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [userNameRes, setUserNameRes] = useState();
  const [unReadNotificationCount, setUnreadNotificationCount] =
    useState<any>(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  let tempUserType = getItemFromStore("userProfile")?.userType;
  const [, setUserType] = useState(tempUserType);
  const queryParams = new URLSearchParams(location.search);
  const toastShowOrNot = queryParams.get("toast");
  const onboardingStep = queryParams.get("step");
  const [, setUserDetails] = useState<any>(null);

  if (
    process.env.REACT_APP_IS_PROD === "true" &&
    !window.location.pathname.includes([
      ROUTE_CONSTANTS.register,
      ROUTE_CONSTANTS.manage_profile,
      ROUTE_CONSTANTS.joining,
    ])
  )
    window.location.replace(ROUTE_CONSTANTS.register);

  useEffect(() => {
    if (!tempUserType) {
      makeGetCall({ url: endpoints.profile })
        .then((res) => {
          setUserType(res.data.userDetail.userType);
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
  }, []);

  const fetchMoreData = () => {
    if (!nextUrl) return;
    makeNextPageCall({ url: nextUrl }).then((res) => {
      const tempData = [...users, ...res.results];
      setNextUrl(res?.next);
      setUsers(tempData as never);
      if (tempData.length > 0) {
        setHasSearchData(true);
      } else {
        setHasSearchData(false);
      }
    });
  };
  const getData = () => {
    let params = { search: searchTerm || "" };
    setIsLoading(true);
    makeGetCall({ url: endpoints.users, params }).then((res) => {
      setUsers(res?.results);
      if (res?.results.length > 0) {
        setHasSearchData(true);
      } else {
        setHasSearchData(false);
      }
      setNextUrl(res?.next || null);
      setIsLoading(false);
    });
  };
  const handleLogout = () => {
    let apiPayload = { email: getItemFromStore("userProfile").email };
    makePostCall({ url: endpoints.logout, apiPayload })
      .then((res) => {
        if (res.status.code === 200) {
          removeAllItemFromStore();
          showToast(STRING_CONSTANTS.logout_success, "success");
          navigate(ROUTE_CONSTANTS.dashboard);
        }
      })
      .catch((err) => showToast(err, "error"));
  };
  useEffect(() => {
    window.onpopstate = () => {
      let currentPath = window.location.pathname;
      if (
        currentPath === ROUTE_CONSTANTS.manage_profile ||
        currentPath === ROUTE_CONSTANTS.upload_document ||
        currentPath === ROUTE_CONSTANTS.business_intrest ||
        currentPath === ROUTE_CONSTANTS.manage_expertise
      ) {
        navigate(ROUTE_CONSTANTS.dashboard);
      }
    };
  }, [navigate]);

  // useEffect(() => {
  //   if (!getItemFromStore("userProfile")?.isProfileCompleted) {
  //     makePatchCall({
  //       url: endpoints.profile,
  //       apiPayload: { isProfileCompleted: true },
  //     });
  //   }
  // }, []);

  useEffect(() => {
    let decoUserData: any;
    makeGetCall({ url: endpoints.profile })
      .then((res) => {
        setUserNameRes(res.data?.userDetail?.name);
        setUnreadNotificationCount(
          res?.data?.userDetail?.unreadNotificationCount
        );
        if (res.status.code === 200) {
          let data = {
            ...res.data?.userDetail,
            ...res.data?.businessDetail?.[0],
          };

          setUserDetails(data);

          const pathname = window.location.pathname;
          if (data) {
            //Just added fot avoiding redirection

            if (pathname === "/user-profile") {
              navigate(ROUTE_CONSTANTS.user_profile);
            } else if (pathname === "/events") {
              // if (isUserAuthenticated()) {
              // if (!data.bio || !data.name) {
              //   navigate(ROUTE_CONSTANTS.manage_profile);
              // }
              // } else {
              navigate(ROUTE_CONSTANTS.events);
              // }
            } else if (pathname.startsWith("/events/")) {
              // if (isUserAuthenticated()) {
              //   if (!data.bio || !data.name) {
              //     navigate(ROUTE_CONSTANTS.manage_profile);
              //   }
              // } else {
              navigate(pathname, { replace: true });
              // }
            } else if (!data.name) {
              if (window.location.pathname !== ROUTE_CONSTANTS.welcome) {
                navigate(ROUTE_CONSTANTS.manage_profile);
              }
            }
            //for pre registration form
            //  else if (data?.interest?.length === 0)
            // else if (
            //   data?.interest?.length === 0 &&
            //   process.env.REACT_APP_IS_PROD !== "true"
            // ) {
            //   if (!toastShowOrNot) {
            //     showToast(
            //       "complete the onboarding process",
            //       "error",
            //       "complete-process-error"
            //     );
            //   }
            //   if (onboardingStep !== "profile") {
            //     navigate(ROUTE_CONSTANTS.business_intrest);
            //   }
            // }
            else {
              decoUserData = {
                ...data,
                businessContactNumber: data.businessContactNumber?.slice(3),
                dob: moment(data.dob, "YYYY-MM-DD")?.toDate(),
              };

              setProfileImage(decoUserData?.profilePicture);
              setProfileImageExtension(decoUserData?.profilePictureExtension);
              setLocalStorage("userProfile", decoUserData);
            }
          }
        } else {
          showToast(STRING_CONSTANTS.wrong_data_message, "error");
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  }, [props.isUserProfileChange]);

  useEffect(() => {
    if (searchTerm) getData();
    else {
      setUsers([]);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setUnreadNotificationCount(
      getItemFromStore("userProfile")?.unreadNotificationCount
    );
  }, [getItemFromStore("userProfile")]);

  return (
    <div
      className="container-fluid p-0 m-0 "
      style={{
        position: "fixed",
        top: 0,
        zIndex: 999,
        backgroundColor: "#ffffff",
      }}
    >
      <div className="top-bar ">
        <div className="profile-managment-top">
          <div className="'left-logo d-none d-md-block">
            <Link to={ROUTE_CONSTANTS.landing}>
              <img src="/static/orange-logo.svg" alt="" />
            </Link>
          </div>
          <div className=" d-md-none">
            <Link to={ROUTE_CONSTANTS.landing}>
              <img src="/static/logo_small.svg" alt="" />
            </Link>
          </div>
          <div className="right-menu">
            <div className={`menus d-none d-md-block`}>
              <ul>
                <li>
                  <Link
                    to={ROUTE_CONSTANTS.landing}
                    className={
                      location.pathname === ROUTE_CONSTANTS.landing ||
                        location.pathname === ROUTE_CONSTANTS.events
                        ? "active"
                        : ""
                    }
                  >
                    {STRING_CONSTANTS.event}
                  </Link>
                </li>

                {isUserAuthenticated() ? (
                  <li>
                    <Link
                      to={ROUTE_CONSTANTS.connect}
                      className={
                        location.pathname === ROUTE_CONSTANTS.connect
                          ? "active"
                          : ""
                      }
                    >
                      {STRING_CONSTANTS.connect}
                    </Link>
                  </li>
                ) : (
                  <li onClick={() => notAValidUser(navigate)}>
                    <Link to={ROUTE_CONSTANTS.login}>
                      {STRING_CONSTANTS.connect}
                    </Link>
                  </li>
                )}
                {isUserAuthenticated() ? (
                  <li>
                    <Link
                      to={ROUTE_CONSTANTS.view_listing}
                      className={
                        location.pathname === ROUTE_CONSTANTS.view_listing
                          ? "active"
                          : ""
                      }
                    >
                      {STRING_CONSTANTS.listing}
                    </Link>
                  </li>
                ) : (
                  <li onClick={() => notAValidUser(navigate)}>
                    <Link
                      to={ROUTE_CONSTANTS.login}
                      className={
                        location.pathname === ROUTE_CONSTANTS.view_listing
                          ? "active"
                          : ""
                      }
                    >
                      {STRING_CONSTANTS.listing}
                    </Link>
                  </li>
                )}

                {isUserAuthenticated() ? (
                  <li>
                    <Link
                      to={ROUTE_CONSTANTS.promotion}
                      className={
                        location.pathname === ROUTE_CONSTANTS.promotion
                          ? "active"
                          : ""
                      }
                    >
                      {STRING_CONSTANTS.promotion}
                    </Link>
                  </li>
                ) : (
                  <li onClick={() => notAValidUser(navigate)}>
                    <Link
                      to={ROUTE_CONSTANTS.login}
                      className={
                        location.pathname === ROUTE_CONSTANTS.promotion
                          ? "active"
                          : ""
                      }
                    >
                      {STRING_CONSTANTS.promotion}
                    </Link>
                  </li>
                )}
              </ul>
            </div>
            <div className="icon-menu">
              <ul>
                {isUserAuthenticated() ? (
                  <li className="d-none d-md-block">
                    <Link to={ROUTE_CONSTANTS.chat}>
                      {location.pathname === ROUTE_CONSTANTS.chat ? (
                        <img src="/static/chat-theme.svg" alt="" />
                      ) : (
                        <img src="/static/Chat.svg" alt="" />
                      )}
                    </Link>
                  </li>
                ) : (
                  <li
                    className="d-none d-md-block"
                    onClick={() => notAValidUser(navigate)}
                  >
                    <Link to={ROUTE_CONSTANTS.login}>
                      {location.pathname === ROUTE_CONSTANTS.chat ? (
                        <img src="/static/chat-theme.svg" alt="" />
                      ) : (
                        <img src="/static/Chat.svg" alt="" />
                      )}
                    </Link>
                  </li>
                )}
                <li
                  onClick={() => {
                    isUserAuthenticated()
                      ? setSideList(true)
                      : notAValidUser(navigate);
                  }}
                >
                  <img src="/static/Search.svg" alt="" />
                </li>
                <li
                  className="header-notify-icon"
                  onClick={() => {
                    isUserAuthenticated()
                      ? setSideList(true)
                      : notAValidUser(navigate);
                  }}
                >
                  <Link to={ROUTE_CONSTANTS.notifications}>
                    <img src="/static/notification-icon.svg" alt="" />
                    {unReadNotificationCount ? (
                      <div className="notify-badge rounded-circle">
                        {unReadNotificationCount > 9 ? (
                          <span className="nine-plus">9+</span>
                        ) : (
                          <span className="no-plus">
                            {unReadNotificationCount}
                          </span>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </Link>
                </li>
                <li className={`menu-des d-none d-md-block`}>
                  <div
                    className="inner"
                    onClick={() =>
                      isUserAuthenticated()
                        ? navigate(ROUTE_CONSTANTS.personal_details)
                        : notAValidUser(navigate)
                    }
                  >
                    <img
                      src="/static/navigation.svg"
                      className="menu-icon"
                      alt=""
                    />
                    {profileImageExtension?.includes("video") ? (
                      <video
                        className="profile"
                        autoPlay
                        playsInline
                        loop
                        muted
                        src={profileImage}
                      >
                        <source src={""} type="video/mp4" />
                      </video>
                    ) : (
                      <img
                        src={profileImage || "/static/dummyimg.svg"}
                        className="profile"
                        alt=""
                      />
                    )}
                  </div>
                </li>

                <li className="menu-des  d-md-none">
                  <div
                    className="inner"
                    onClick={() =>
                      isUserAuthenticated() ? "" : setShowMenu(true)
                    }
                  >
                    <img
                      onClick={() =>
                        isUserAuthenticated() ? setShowMenu(true) : ""
                      }
                      src="/static/navigation.svg"
                      className="menu-icon"
                      alt=""
                    />
                    {profileImageExtension?.includes("video") ? (
                      <video
                        className="profile"
                        autoPlay
                        playsInline
                        loop
                        muted
                        src={profileImage}
                        onClick={() =>
                          isUserAuthenticated()
                            ? navigate(ROUTE_CONSTANTS.profile_menu)
                            : setShowMenu(true)
                        }
                      >
                        <source src={""} type="video/mp4" />
                      </video>
                    ) : (
                      <img
                        src={profileImage || "/static/dummyimg.svg"}
                        className="profile"
                        alt=""
                        onClick={() =>
                          isUserAuthenticated()
                            ? navigate(ROUTE_CONSTANTS.profile_menu)
                            : setShowMenu(true)
                        }
                      />
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {sideList ? (
        <div className="fixed-sidebar">
          <div className="sidebar-box" style={{ maxWidth: "577px" }}>
            <img
              src="/static/cross-black.svg"
              onClick={() => setSideList(false)}
              className="close-btn"
              alt=""
            />
            <div className="inner-contet">
              <div className="form-set">
                <div className="form-group">
                  <input
                    type="text"
                    id="search_input"
                    placeholder="Find local listers and events..."
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                  />
                  <label htmlFor="search_input">
                    <img
                      src="/static/search-gray.svg"
                      className="gray-search"
                      alt=""
                    />
                    <img
                      src="/static/Search.svg"
                      className="black-search"
                      alt=""
                    />
                  </label>
                  <img
                    src="/static/cross-circle-gray-dark.svg"
                    className="corss-icon"
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSearchTerm("");
                      setUsers([]);
                      setHasSearchData(true);
                    }}
                  />
                </div>
                <button onClick={() => navigate(`/events?f`)}>
                  <img src="/static/filter-btn-de.svg" alt="" />
                </button>
              </div>
              <div className="search-list">
                {users.length > 0 ? (
                  <InfiniteScroll
                    dataLength={users?.length}
                    next={fetchMoreData}
                    hasMore={nextUrl ? true : false}
                    loader={<ApiLoader />}
                  >
                    {users.map((item: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className="on-list"
                          onClick={() => {
                            navigate(`/our-mates/${item.id}`);
                            setSideList(false);
                          }}
                        >
                          <div className="top-part">
                            <h6>{item.name}</h6>
                            <p>
                              <img
                                src={
                                  item.userType === USER_TYPE.GUEST
                                    ? "/static/guesticon.svg"
                                    : item.userType === USER_TYPE.MEMBER &&
                                      item?.isTrainer
                                      ? "/static/membericon.svg"
                                      : "/static/briefcase.svg"
                                }
                                alt=""
                              />{" "}
                              {item.userType === USER_TYPE.MEMBER &&
                                item.isTrainer
                                ? "Trainer"
                                : item.userType}
                            </p>
                          </div>

                          <img src="/static/arrow-right.svg" alt="" />
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                ) : !hasSerachData && searchTerm ? (
                  <div className="no-data">
                    <img src="/static/no_search_found.svg" alt="" />
                    <p>
                      {STRING_CONSTANTS.we_apologise_no_search_result}
                      <b style={{ color: "#25272D" }}>"{searchTerm}"</b>.
                    </p>
                  </div>
                ) : (
                  <div className="no-data">
                    <img src="/static/search_list_icon.svg" alt="" />
                    <p>{STRING_CONSTANTS.no_listing_data}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {/* {showMenu && */}
      <div className={` fixed-sidebar-menu ${showMenu ? "open " : ""} `}>
        <div className={`sidebar_menu p-3 ${showMenu ? "open" : ""} `}>
          <div className="py-1 close_menu text-end">
            <img
              style={{ cursor: "pointer" }}
              onClick={() => setShowMenu(false)}
              src="/static/close-sm.png"
              alt=""
            />
          </div>
          <div className="profile_container p-1">
            {profileImageExtension?.includes("video") ? (
              <video
                className="profile profile-video"
                autoPlay
                playsInline
                loop
                muted
                src={profileImage}
                onClick={() => {
                  isUserAuthenticated()
                    ? navigate(ROUTE_CONSTANTS.profile_menu)
                    : navigate(ROUTE_CONSTANTS.login);
                  setShowMenu(false);
                }}
              >
                <source src={""} type="video/mp4" />
              </video>
            ) : (
              <img
                src={profileImage || "/static/dummyimg.svg"}
                className="profile"
                alt=""
                onClick={() => {
                  isUserAuthenticated()
                    ? navigate(ROUTE_CONSTANTS.profile_menu)
                    : navigate(ROUTE_CONSTANTS.login);
                  setShowMenu(false);
                }}
              />
            )}
            <div className="profile_info">
              <div className="profile_content">
                <span>welcome</span>
                <h4 style={{ wordBreak: "break-all" }} className="">
                  {userNameRes}
                </h4>
              </div>
              <img src="/static/ar-sm-left.png" alt="arrow" />
            </div>
          </div>

          <div className="profile_line my-4"></div>
          <div className="all_menus">
            <Link to="/" style={{ textDecoration: "none" }}>
              <li className="menu_item">
                <p>Home</p>
                <img src="/static/ar-sm-left.png" alt="" />
              </li>
            </Link>
            <Link
              to={ROUTE_CONSTANTS.events}
              style={{ textDecoration: "none" }}
            >
              <li className="menu_item">
                <p>Events</p>
                <img src="/static/ar-sm-left.png" alt="" />
              </li>
            </Link>
            <Link
              to={ROUTE_CONSTANTS.connect}
              style={{ textDecoration: "none" }}
            >
              <li className="menu_item">
                <p>Connect</p>
                <img src="/static/ar-sm-left.png" alt="" />
              </li>
            </Link>
            <Link
              to={ROUTE_CONSTANTS.view_listing}
              style={{ textDecoration: "none" }}
            >
              <li className="menu_item">
                <p>{STRING_CONSTANTS.listing}</p>
                <img src="/static/ar-sm-left.png" alt="" />
              </li>
            </Link>

            <Link
              to={ROUTE_CONSTANTS.promotion}
              style={{ textDecoration: "none" }}
            >
              <li className="menu_item">
                <p> {STRING_CONSTANTS.promotion}</p>
                <img src="/static/ar-sm-left.png" alt="" />
              </li>
            </Link>
            {isUserAuthenticated() ? (
              <Link
                to={ROUTE_CONSTANTS.chat}
                style={{ textDecoration: "none" }}
              >
                <li className="menu_item">
                  <p>Message</p>
                  <img src="/static/ar-sm-left.png" alt="" />
                </li>
              </Link>
            ) : (
              <Link
                to={ROUTE_CONSTANTS.chat}
                style={{ textDecoration: "none" }}
              >
                <li className="menu_item">
                  <p>Message</p>
                  <img src="/static/ar-sm-left.png" alt="" />
                </li>
              </Link>
            )}

            {isUserAuthenticated() ? (
              <li
                className="menu_item"
                style={{ cursor: "pointer" }}
                onClick={() => setLogout(true)}
              >
                <p>Logout</p>
                <img src="/static/ar-sm-left.png" alt="" />
              </li>
            ) : (
              <Link
                to={ROUTE_CONSTANTS.login}
                style={{ textDecoration: "none" }}
              >
                <li className="menu_item">
                  <p>Login</p>
                  <img src="/static/ar-sm-left.png" alt="" />
                </li>
              </Link>
            )}
          </div>
        </div>
      </div>
      {/* } */}
      {logout && (
        <LogoutModal
          onOk={() => handleLogout()}
          onCancel={() => setLogout(false)}
        />
      )}
    </div>
  );
};

export default GlobalHeader;
