import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Button from "../../global/Button";
import InputField from "../../global/InputField";
import { showToast } from "../../utils";
import { apiConstants } from "../../utils/Constants";
import endpoints from "../../utils/endpoints";
import Slider from "react-slick";
import { makePostCall } from "../../utils/Requests";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import { STRING_CONSTANTS } from "../../utils/StringConstant";
import { ForgotPasswordValidations } from "./AuthValidation";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Logo from "../../assets/img/icons/Logo";
const signupBg1 = require("../../assets/img/signup-bg-1.jpg");
const signupBg2 = require("../../assets/img/signup-bg-2.jpg");
const signupBg3 = require("../../assets/img/signup-bg-3.jpg");

const ForgotPassword = () => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ForgotPasswordValidations),
    mode: "onSubmit",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  useEffect(() => {
    sliderRef.current.slickGoTo(currentSlide);
  }, [currentSlide]);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
  };
  const handleForgotPassowrd = () => {
    handleSubmit((data) => {
      setIsLoading(true);
      makePostCall({ url: endpoints.forgot_password, apiPayload: data })
        .then((res) => {
          if (res.status.code === 200) {
            showToast(res.status.message, "success");
          } else {
            showToast(res.status.message, "error");
          }
          setIsLoading(false);
        })
        .catch((err) => {
          showToast(err, "error");
          setIsLoading(false);
        });
    })();
  };
  const navigate = useNavigate();
  const imgchange = [0, 1, 2];
  return (
    <>
      <div className=" d-md-none logo_nav ">
        <Link to="/">
          <img src="/static/logo_small.svg" alt="" />
        </Link>
      </div>
      <div className="login constiner-fluid p-0 m-0 ">
        <div style={{ minHeight: "100vh" }} className="row p-0 m-0 ">
          <div className="col1  p-0 d-none d-md-block col-5">
            <div className="img-slider">
              <Slider {...settings} ref={sliderRef}>
                <div className="slider-item">
                  <img src={signupBg1} alt="" style={{ objectFit: "cover" }} />
                  <div className="overlay"></div>
                </div>
                <div className="slider-item">
                  <img src={signupBg3} alt="" style={{ objectFit: "cover" }} />
                  <div className="overlay"></div>
                </div>
                <div className="slider-item">
                  <img src={signupBg2} alt="" style={{ objectFit: "cover" }} />
                  <div className="overlay"></div>
                </div>
              </Slider>
            </div>
            <div className="txtb" style={{ width: "100%" }}>
              <div className="logo">
                <Logo fill="#FFFFFF" />
                <p style={{ color: "#FFFFFF" }}>
                  {currentSlide === 0
                    ? "Find your ideal fitness mates and activities, anywhere anytime."
                    : currentSlide === 1
                      ? "Connecting fitness and health enthusiasts to join classes and activities together"
                      : "Create or join a health or fitness activity that suits your needs today."}
                </p>
              </div>
              <div className="guest-button">
                <div className="slider-box">
                  <div className="slider-bg"></div>
                  {imgchange.map((index) => (
                    <div
                      key={index}
                      className={`slider ${currentSlide === index ? "active" : ""
                        }`}
                      style={{ transform: `translateX(${index * 43}px)` }}
                    ></div>
                  ))}
                </div>
                {/* <NavLink
                  to={ROUTE_CONSTANTS.events}
                  style={{ color: "#FFFFFF" }}
                >
                  Continue as Guest
                </NavLink> */}
              </div>
            </div>
          </div>
          <div
            className="col2 col-12 col-md-7"
            style={{ justifyContent: "center" }}
          >
            <h1>{STRING_CONSTANTS.forgot_pass_title}</h1>
            <p>{STRING_CONSTANTS.forgot_pass_desc}</p>
            <InputField
              type={"email"}
              name={apiConstants.email}
              placeholder={"Enter Email"}
              inputLabel={"Email Address"}
              control={control}
              errors={errors}
              setValue={setValue}
            />
            <br />
            <br />
            <br />

            <Button
              btnTxt="Send reset link"
              isLoading={isLoading}
              onClick={handleForgotPassowrd}
            />
            <Button
              btnTxt="Back to login"
              className={"backtologin"}
              onClick={() => navigate(ROUTE_CONSTANTS.login)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
