import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
export default function CustomSingleSelect({
  label = "",
  placeholder = "",
  name = "",
  watch = (props: any) => null,
  getValues = (props: any) => null,
  handleSetValue = (key: any, val: any) => null,
  dropdownData = [],
  isDisabled = false,
}) {
  const dropdownRef = useRef(null);
  const [showSelectDropdown, setShowSelectDropdown] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowSelectDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="custom-single-select" ref={dropdownRef}>
      <div className="form_group">
        <div className="input_box" style={{ zIndex: 4 }}>
          {label && <span className="input_label">{label}</span>}
          <input
            className={" cursor-pointer " + `${isDisabled ? 'disable' : ''}`}
            type="text"
            readOnly
            placeholder={placeholder}
            value={getValues(name) || ""}
            onClick={() => {
              if (!isDisabled) setShowSelectDropdown(!showSelectDropdown);
            }}
            name={name}
          />
          {showSelectDropdown ? (
            <ul className="frequency_option selection">
              {dropdownData.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={watch(name) === item ? "active" : ""}
                    onClick={() => {
                      if (!isDisabled) {
                        handleSetValue(name, item);
                        if (item === "Week") {
                          // handleReset(4);
                        } else if (item === "Month") {
                          // handleReset(5);
                        }
                        setShowSelectDropdown(!showSelectDropdown);
                      }
                    }}
                  >
                    {item}
                  </li>
                );
              })}
            </ul>
          ) : null}
        </div>
      </div>
    </div>
  );
}
