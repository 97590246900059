import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Button from "../../global/Button";
import InputField from "../../global/InputField";
import { showToast } from "../../utils";
import { apiConstants } from "../../utils/Constants";
import endpoints from "../../utils/endpoints";
import { makePutCall } from "../../utils/Requests";
import { PasswordValidation } from "../Profile/ProfileValidations";
import DashboardWrapper from "./DashboardWrapper";
import { useNavigate } from "react-router-dom";

export default function PasswordManagement() {
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { isValid },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(PasswordValidation),
  });
  const [oldPasswordType, setOldPasswordType] = useState("password");
  const [newPasswordType, setNewPasswordType] = useState("password");
  const navigate = useNavigate();
  const handlePassword = () => {
    handleSubmit((data) => {
      let payload = {
        oldPassword: data.current_password,
        password: data.new_password,
      };
      makePutCall({ url: endpoints.change_password, apiPayload: payload })
        .then((res) => {
          if (res.status.code === 200) {
            showToast(res.status.message, "success");
            reset();
          } else {
            showToast(res.status.message, "error");
          }
        })
        .catch((err) => showToast(err, "error"));
    })();
  };
  return (
    <>
      <div className="">
        <DashboardWrapper showTab={true}>
          <div className="main-section-right profile-managment-right ">
            <div className="profile_box">
              <div
                className="title"
                style={{ marginBottom: "12px", textAlign: "left" }}
              >
                Password management
                <img
                  src="/static/backarrow.svg"
                  alt=""
                  className="back_arrow d-md-none"
                  onClick={() => navigate(-1)}
                />
              </div>
              <div
                className="sub_title pass-sub-title mb-36"
                style={{
                  margin: "0 12px 36px 0",
                  textAlign: "left",
                }}
              >
                To update your password please enter your current password & new
                password.
              </div>
              <div className="all-resetpassword" style={{ maxWidth: "555px" }}>
                <div className="password_box">
                  <InputField
                    type={oldPasswordType}
                    name={apiConstants.current_password}
                    control={control}
                    inputLabel={"Current Password"}
                    placeholder={"Enter old password"}
                    setValue={setValue}
                    addIconPaddingTop={true}
                  />
                  {oldPasswordType === "text" ? (
                    <img
                      src="/static/openeye.svg"
                      alt=""
                      onClick={() => setOldPasswordType("password")}
                      className={"password_management_icon"}
                    />
                  ) : (
                    <img
                      src="/static/closeeye.svg"
                      alt=""
                      onClick={() => setOldPasswordType("text")}
                      className={"password_management_icon"}
                    />
                  )}
                </div>
                <div className="password_box">
                  <InputField
                    type={newPasswordType}
                    name={apiConstants.new_password}
                    control={control}
                    inputLabel={"New Password"}
                    placeholder={"Enter new password"}
                    setValue={setValue}
                    addIconPaddingTop={true}
                  />

                  {newPasswordType === "text" ? (
                    <img
                      src="/static/openeye.svg"
                      alt=""
                      onClick={() => setNewPasswordType("password")}
                      className={"password_management_icon"}
                    />
                  ) : (
                    <img
                      src="/static/closeeye.svg"
                      alt=""
                      onClick={() => setNewPasswordType("text")}
                      className={"password_management_icon"}
                    />
                  )}
                </div>
              </div>
              <div
                className="main-section-form-button"
                style={{ maxWidth: "555px" }}
              >
                <Button
                  btnTxt="Update password"
                  className={isValid ? "backtologin" : "backtologin disabled"}
                  onClick={() => handlePassword()}
                />
              </div>
            </div>
          </div>
        </DashboardWrapper>
      </div>
    </>
  );
}
