import React from "react";
import ReactPaginate from "react-paginate";
import "./style.scss";

type Props = {
  [key: string]: any;
};

const Pagination = ({ handlePageClick, currentPage, pageCount = 1 }: Props) => {
  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={
        <>
          <div className="next_page_container">
            <img src="/static/listing/next.svg" alt="" /> <span>Next</span>
          </div>
        </>
      }
      onPageChange={handlePageClick}
      pageRangeDisplayed={2}
      forcePage={currentPage}
      pageCount={pageCount}
      previousLabel={
        <>
          <div className="prev_page_container">
            <span>Previous</span> <img src="/static/listing/back.svg" alt="" />
          </div>
        </>
      }
      renderOnZeroPageCount={null}
      marginPagesDisplayed={1}
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      breakClassName="page-item"
      breakLinkClassName="page-link"
      containerClassName="pagination"
      activeClassName="active"
    />
  );
};

export default Pagination;
