import { useNavigate, useParams } from "react-router-dom";
import { LeftArrowStickIcon } from "../../utils/Icons/svgs";
import GlobalHeader from "./GlobalHeader";
import { useEffect, useState } from "react";
import { makeGetCall } from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import moment from "moment";
import ApiLoader from "../../global/ApiLoader";

const SeeMoreParagraph = ({ item }) => {
  const [seeMore, setSeeMore] = useState(false);
  return (
    <p>
      {!seeMore ? (
        item?.review?.length > 150 ? (
          <>
            {item?.review?.slice(0, 150)}...
            <span
              className="cursor-pointer"
              onClick={() => setSeeMore(!seeMore)}
            >
              See more
            </span>
          </>
        ) : (
          item?.review
        )
      ) : (
        <>
          {item?.review}{" "}
          <span className="cursor-pointer" onClick={() => setSeeMore(!seeMore)}>
            Hide
          </span>
        </>
      )}
    </p>
  );
};

const ReviewScreen = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const [reviewData, setReviewData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    makeGetCall({
      url: endpoints.mylisting_new + `${id}/reviews/`,
    })
      .then((res) => {
        setReviewData(res?.results);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <GlobalHeader />
        {isLoading ? (
          <div className="page_loader">
            <ApiLoader />
          </div>
        ) : (
          <div
            className="review-screen-container "
            style={{ minHeight: "100vh" }}
          >
            <div className="review-main-container ">
              <div className=" rv-title position-relative">
                <span onClick={() => navigate(-1)}>
                  <LeftArrowStickIcon />
                </span>
                <p>Review ({reviewData?.length})</p>
              </div>
              <hr className="d-none d-md-block rhr"></hr>
              <div className="all-user-r-container container-fluid ">
                {reviewData?.length === 0 && (
                  <div className="text-center">No Review Found Yet</div>
                )}

                {reviewData?.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className="user-r-wrapper">
                        <div className="user-r-info">
                          <div className="user-info">
                            <img
                              src={
                                item?.user?.profilePicture ||
                                "/static/dummyimg.svg"
                              }
                              alt=""
                            />
                            <div className="user-p-info">
                              <p className="mb-0 user-p-name">
                                {item?.user?.name}
                              </p>
                              <p className="mb-0 user-p-date">
                                {moment(item?.createdAt).format("Do")}{" "}
                                {moment(item?.createdAt).format("MMMM")},{" "}
                                {moment(
                                  item?.createdAt
                                    ?.split("T")?.[1]
                                    ?.substring(0, 5),
                                  ["HH:mm"]
                                ).format("hh:mma")}
                              </p>
                            </div>
                          </div>
                          <div className="user-r-star">
                            <p className="mb-0">⭐ {item.star} stars </p>
                          </div>
                        </div>
                        <div className="user-r-desc">
                          <SeeMoreParagraph item={item} />
                        </div>
                      </div>

                      <hr
                        className={`${
                          reviewData?.length === index + 1 ? "d-none" : ""
                        }`}
                        style={{ height: "2px", backgroundColor: "#F3F3F3" }}
                      ></hr>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ReviewScreen;
