import React, { useRef } from "react";
import "./style.scss";

type PrimaryTextFieldProps = {
  label?: any;
  value: any;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: any;
  wordLimit?: number;
  disabled?: boolean;
  rows?: number;
  name?: string;
};

const PrimaryTextField: React.FC<PrimaryTextFieldProps> = ({
  value,
  onChange,
  placeholder = "",
  wordLimit,
  disabled = false,
  rows = 1,
  name = "",
  label = "",
}) => {
  const wordCount = value?.trim()?.split(" ")?.filter(Boolean).length;
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleFocus = () => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  };

  const handleClear = () => {
    const event = {
      target: { value: "" },
    } as React.ChangeEvent<HTMLTextAreaElement>;
    onChange(event);
  };

  return (
    <div className="textfield_wrapper" onClick={handleFocus}>
      <div className="text_field_label">{label}</div>
      <textarea
        onChange={(e) => {
          if (!wordLimit || e.target.value.split(" ").length <= wordLimit) {
            onChange(e);
          }
        }}
        ref={textAreaRef}
        className="text_input"
        rows={rows}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        name={name}
      />
      {rows && wordLimit && (
        <span className="word_count">
          {wordCount || 0} / {wordLimit}
        </span>
      )}
      {value && (
        <img
          className="clear_field"
          src="/static/cross-circle-gray-dark.svg"
          alt=""
          onClick={handleClear}
        />
      )}
    </div>
  );
};

export default PrimaryTextField;
