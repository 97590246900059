import { ROUTE_CONSTANTS } from "../utils/RouteConstants";

export const stepsNavigation = (nav, authData) => {
  const auth = authData?.userDetail;
  if (auth?.isProfileCompleted) {
    return nav(ROUTE_CONSTANTS?.landing);
  } else {
    if (!auth?.userType) {
      return nav(ROUTE_CONSTANTS?.welcome);
    } else {
      if (auth?.userType === "Member") {
        if (auth?.isTrainer || auth?.isBusiness || auth?.isIndividual) {
          return nav(ROUTE_CONSTANTS?.manage_profile);
        }else{
          return nav(ROUTE_CONSTANTS?.select_member_type);
        }
      } else {
        return nav(ROUTE_CONSTANTS?.manage_profile);
      }
    }
  }
};
