import Events from "../components/Dashboard/Event/Events";
import EventsSearch from "../components/Dashboard/Event/EventsSearch";

const EventsSearchPage = () => {
  return (
    <>
      <EventsSearch />
    </>
  );
};

export default EventsSearchPage;
