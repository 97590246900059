import { useState } from "react";
import { apiConstants } from "../utils/Constants";
import Button from "./Button";
interface CommonModalProps {
  onSubmit?: any;
  title?: string;
  desc?: string;
  onClose?: () => void;
  formData?: any;
}

const CommonModal = (props: CommonModalProps) => {
  const { onSubmit, title, desc, onClose, formData } = props;
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");

  const handleNext = () => {
    let passObj = { ...formData, password };

    onSubmit(passObj);
  };
  return (
    <div className={"modal show"}>
      <div className="modal-section">
        <div className="modal-top-part">
          <button className="close">
            <img
              src="/static/dismiss-cross-round-black.svg"
              alt=""
              onClick={onClose}
            />
          </button>
          {title && <h5>{title}</h5>}
          {desc && <p>{desc}</p>}
          <div className="form_group ">
            <div className="input_box">
              <span className="input_label">Password</span>
              <input
                type={passwordType}
                placeholder="*******"
                name={apiConstants.confirm_password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <img
                src={
                  passwordType === "password"
                    ? "/static/closeeye.svg"
                    : "/static/openeye.svg"
                }
                onClick={() => {
                  setPasswordType(
                    passwordType === "password" ? "text" : "password"
                  );
                }}
                className="eye-show-hide"
                alt=""
              />
            </div>
          </div>
        </div>
        <Button
          btnTxt="Confirm"
          className={"backtologin"}
          onClick={handleNext}
        />
      </div>
    </div>
  );
};

export default CommonModal;
