import { useRef } from "react";
import Button from "../../../global/Button";
import InputField from "../../../global/InputField";
import { showToast } from "../../../utils";
import { apiConstants } from "../../../utils/Constants";
interface DraftPopUpProps {
  onClose?: any;
  handleBankDetails?: any;
  control?: any;
  errors?: any;
  setValue?: any;
  isEditMode?: boolean;
  watch?: any;
}
const AddBankAccountPopup = (props: DraftPopUpProps) => {
  const {
    onClose,
    handleBankDetails,
    control,
    errors,
    setValue,
    isEditMode = false,
    watch,
  } = props;

  const bsbNumberRef = useRef(null);
  const accNumberRef = useRef(null);
  const handleSave = () => {
    if (!watch("bNumber") || !watch("aNumber") || !watch("bName")) {
      showToast("Please fill all the details", "error");
    } else if (watch("bNumber")?.length !== 6) {
      showToast("BSB number should be 6 digits", "error");
    } else if (String(watch("aNumber"))?.length > 10 || String(watch("aNumber"))?.length < 6) {
      showToast(
        "Account number must be between 6 to 10 characters long",
        "error"
      );
    } else {
      setValue(apiConstants.bsb_number, watch("bNumber"));
      setValue(apiConstants.account_number, watch("aNumber"));
      setValue(apiConstants.bank_name, watch("bName"));
      handleBankDetails();
      setValue("bNumber", "");
      setValue("aNumber", "");
      setValue("bName", "");
    }
  };
  const handleCancel = () => {
    setValue("bNumber", "");
    setValue("aNumber", "");
    setValue("bName", "");
    onClose();
  };

  return (
    <div className={"modal show"}>
      <div className="stripe_acc_pop">
        <div className="modal-top-part">
          <div className="header_container">
            <img src="/static/closeBorder.svg" alt="" onClick={onClose} />
          </div>

          <div className="modal_stripe_heading">{ isEditMode ? "Edit bank details" : "Add bank details" }</div>
          <div className="bank_details_form">
            <InputField
              key="bName"
              type={"text"}
              name={"bName"}
              inputLabel={"Bank Name"}
              placeholder={"Enter the Bank name"}
              control={control}
              errors={errors}
              setValue={setValue}
            />
            <InputField
              key="bNumber"
              type={"number"}
              name={"bNumber"}
              inputLabel={"BSB Number"}
              placeholder={"123-456"}
              control={control}
              errors={errors}
              setValue={setValue}
              maxlength={6}
              reference={bsbNumberRef}
            />
            <InputField
              key="aNumber"
              type={"number"}
              name={"aNumber"}
              inputLabel={"Bank Account Number"}
              placeholder={"0111-334-344"}
              control={control}
              maxlength={10}
              errors={errors}
              setValue={setValue}
              reference={accNumberRef}
            />
          </div>
        </div>
        <Button
          btnTxt={isEditMode ? "Edit bank details" : "Add bank details"}
          className={"stripe_existing"}
          onClick={() => handleSave()}
        />
        <Button
          btnTxt="Cancel"
          className={"stripe_new"}
          onClick={handleCancel}
        />
      </div>
    </div>
  );
};

export default AddBankAccountPopup;
