import React, { useEffect, useState } from "react";
import Policies from "../Legal/Policies";
import DashboardWrapper from "./DashboardWrapper";
import {
  getItemFromStore,
  isUserAuthenticated,
  notAValidUser,
  removeAllItemFromStore,
  setLocalStorage,
  showToast,
} from "../../utils";
import GlobalFooter from "./GlobalFooter";

const PolicyPage = () => {
  // alert('bye');

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // useEffect(()=>{
  //   alert('hi');
  // },[])
  return isUserAuthenticated() ? (
    <DashboardWrapper showTab={true} showFooter>
      <div className="main-section-right profile-managment-right inner-policy-pages-setup rounded-5 inner-polcy-bg">
        <Policies showTopBar={false} />
      </div>
    </DashboardWrapper>
  ) : (
    <>
      <Policies />

      <GlobalFooter />
    </>
  );
};

export default PolicyPage;
