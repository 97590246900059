import React, { useEffect, useState } from "react";
import ApiLoader from "../../global/ApiLoader";
import { showToast } from "../../utils";
import endpoints from "../../utils/endpoints";
import { makeGetCall } from "../../utils/Requests";
import DashboardWrapper from "./DashboardWrapper";
import { useNavigate } from "react-router-dom";

const Faq = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const onItemClick = (index: any) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  useEffect(() => {
    setLoading(true);
    makeGetCall({ url: endpoints.faq })
      .then((res) => {
        if (res.status.code === 200) {
          setResults(res.results);
        } else {
          showToast(res.status.message, "error");
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        showToast(err, "error");
      });
  }, []);
  return (
    <DashboardWrapper showTab={true}>
      <div className="main-section-right profile-managment-right">
        <div className="profile_box">
          <div className="title">
            Frequently asked questions
            <img
              src="/static/backarrow.svg"
              alt=""
              className="back_arrow d-md-none"
              onClick={() => navigate(-1)}
            />
          </div>
          <div className="all-collapse-setup-with-button">
            <div className="collapse-policy">
              {loading ? (
                <ApiLoader />
              ) : (
                results.map((item: any, index) => {
                  return (
                    <div
                      className={`collapse-par collapse-first ${
                        activeIndex === index ? "active" : ""
                      } `}
                      key={index}
                    >
                      <div
                        className="collapse-header"
                        onClick={() => onItemClick(index)}
                      >
                        <div className="collapse-header-left">
                          {item.question}
                        </div>
                        <img src="/static/plus.svg" className="plus" alt="" />
                        <img
                          src="/static/minus.svg"
                          className="nagive"
                          alt=""
                        />
                      </div>
                      <div className="collapse-bottom">
                        <p>{item.answer}</p>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default Faq;
