import { useState } from "react";
import { Modal } from "react-bootstrap";
import useCheckDeviceScreen from "../../hooks/useCheckScreen";

const HowItWorks = () => {
  const [showVideo, setShowVideo] = useState(false);
  const { isMobile } = useCheckDeviceScreen();
  const videoUrl = "https://www.youtube.com/embed/UBpFgn79K0M?si=r8aSIJXJaNFhvPPH";
  const handleKnowHowItworksClick = () => setShowVideo(true);
  const handleHide = () => setShowVideo(false);

  return (
    <div className={"how-it-works-wrapper"}>
      <div className="section-content">
        <div className="heading">Connect with Health & Fitness Enthusiasts</div>
        <div className="sub-heading">
          Your go to app for all things health, fitness and experiences
        </div>
        <div className="btn-div" onClick={handleKnowHowItworksClick}>
          Know how it works?
        </div>
      </div>
      {showVideo && (
        <Modal
          centered
          dialogClassName={!isMobile ? "width-734" : ""}
          show={showVideo}
          onHide={handleHide}
        >
          <Modal.Body style={{ position: "relative", width: "100%" }}>
            <iframe
              width="100%"
              height={!isMobile ? "415" : "315px"}
              src={videoUrl}
              title="YouTube video player"
              // frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              // allowFullScreen
            ></iframe>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default HowItWorks;
