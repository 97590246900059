// import PromotionList from "../components/Promotion/PromotionList";
import PromotionsResult from "../components/Promotion/PromotionsResult";

const PromotionPage = () => {
  return (
    <PromotionsResult />
    // <PromotionList />;
  );
};

export default PromotionPage;
