import moment from "moment";

export function generateMonthlyEventsWithSkip(
  startDate,
  endDate,
  startTime,
  endTime,
  type = "date",
  skipMonths
) {
  let start = moment(startDate, "YYYY-MM-DD");
  let weekdays = start.format("dddd");
  let dateDD = start.format("DD");
  let weekOfMonth = start.week() - moment(start).startOf("month").week() + 1;
  const end = moment(endDate, "YYYY-MM-DD");
  const events = [];

  while (start <= end) {
    const monthStart = start.clone().startOf("month");
    const monthEnd = start.clone().endOf("month");

    let weekCounter = 1;

    for (let day = 0; day <= monthEnd.date(); day++) {
      const currentDay = monthStart.clone().add(day, "days");
      if (currentDay > end) break;
      if (type === "date" && currentDay.format("DD") === dateDD) {
        const eventDate = currentDay.format("YYYY-MM-DD");
        events.push({
          date: eventDate,
          event_start_time: startTime,
          event_end_time: endTime,
          virtual_link: "",
        });

        break;
      } else if (
        type === "day" &&
        currentDay.format("dddd") === weekdays &&
        weekCounter === weekOfMonth
      ) {
        const eventDate = currentDay.format("YYYY-MM-DD");

        events.push({
          date: eventDate,
          event_start_time: startTime,
          event_end_time: endTime,
          virtual_link: "",
        });

        break;
      }

      if (currentDay.format("dddd") === weekdays) {
        weekCounter++;
      }
    }

    start = start.add(skipMonths + 1, "months").startOf("month");
  }

  return events;
}

export function generateWeekdayEventsWithSkip(
  startDate,
  endDate,
  startTime,
  endTime,
  weekdays,
  skipWeeks
) {
  let start = moment(startDate, "YYYY-MM-DD");
  const end = moment(endDate, "YYYY-MM-DD");
  const events = [];

  while (start <= end) {
    const weekStart = start.clone();
    let weekIncluded = false;

    for (let day = 0; day < 7; day++) {
      const currentDay = weekStart.clone().add(day, "days");
      if (currentDay > end) break;

      if (weekdays.includes(currentDay.format("dddd"))) {
        const eventDate = currentDay.format("YYYY-MM-DD");

        events.push({
          date: eventDate,
          event_start_time: startTime,
          event_end_time: endTime,
          virtual_link: "",
        });

        weekIncluded = true;
      }
    }

    if (weekIncluded) {
      start = start.add((skipWeeks + 1) * 7, "days");
    } else {
      start = start.add(1, "days");
    }
  }

  return events;
}

export function generateDateRangeEventsWithSkip(
  startDate,
  endDate,
  startTime,
  endTime,
  skipDays = 0
) {
  let start = moment(startDate, "YYYY-MM-DD");
  const end = moment(endDate, "YYYY-MM-DD");
  const events = [];

  while (start <= end) {
    const eventDate = start.format("YYYY-MM-DD");

    events.push({
      date: eventDate,
      event_start_time: startTime,
      event_end_time: endTime,
      virtual_link: "",
    });

    // Add the number of skipDays to the current date
    start = start.add(skipDays + 1, "days");
  }

  return events;
}
