import React from "react";

interface ButtonProps {
  text: string;
  icon?: React.ReactNode; // This can be your icon component
  onClick?: () => void;
  onBlur?: () => void;
  className?: string;
  image?: string;
  width?: string;
  height?: string;
}

const CommonOutlineButton: React.FC<ButtonProps> = ({
  text,
  icon,
  onClick,
  onBlur,
  className,
  image,
  width = "162px",
  height = "40px",
  ...props
}) => {
  return (
    <button
      style={{ height: height, width: width }}
      {...props}
      className={className}
      onClick={onClick}
    >
      {icon}
      <img src={image} alt="" />
      <span>{text}</span>
    </button>
  );
};

export default CommonOutlineButton;
