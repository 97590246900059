import { AUTH_SUCCESS, AUTH_LOGOUT, LOADING } from "./actionType";

interface AuthUpdates {
  token?: string;
  [key: string]: any;
}

export const authSuccess = (updates: AuthUpdates) => {
  if (updates?.token) {
    localStorage.setItem("fm_token", updates?.token);
  }
  return {
    type: AUTH_SUCCESS,
    updates,
  };
};

export const logoutSuccess = () => {
  localStorage.removeItem("fm_token");
  return {
    type: AUTH_LOGOUT,
  };
};

export const updateLoading = (updates: Partial<AuthUpdates>) => {
  return {
    type: LOADING,
    updates,
  };
};
