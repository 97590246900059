import { Modal } from "react-bootstrap";
import Button from "../../../global/Button";
import { useEffect, useState } from "react";
import { Calendar } from "react-multi-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import { showToast } from "../../../utils";

const ScheduleDatesPopup = (props) => {
  const { detailsData } = props;
  const [selectedDates, setSelectedDates] = useState(props.selDates);
  const [alleventData, setAllEventData] = useState([]);

  const handleDateChange = (date) => {
    let dateArray = [];
    for (let i = 0; i < date?.length; i++) {
      dateArray.push(date?.[i]?.format());
    }
    setSelectedDates(dateArray);
  };

  useEffect(() => {
    const allevent = [];
    for (let i = 0; i < detailsData?.events?.length; i++) {
      allevent.push(detailsData?.events?.[i]?.date);
    }
    setAllEventData(allevent);
  }, [detailsData]);

  const handlePopup = () => {
    if (selectedDates?.length === 0) {
      showToast("Please select at least one event.", "error");
      return;
    }
    let newarray = [];
    for (let i = 0; i < detailsData?.events?.length; i++) {
      for (let k = 0; k < selectedDates?.length; k++) {
        if (detailsData?.events?.[i]?.date === selectedDates?.[k]) {
          newarray.push(detailsData?.events?.[i]);
        }
      }
    }
    console.log(newarray)
    props.onHide();
    props.setSelectedEvents(newarray);
    props.setSecond(true);
    props.setShowDateModal(true);
    props.setRefresh((prev) => !prev);
  };

  return (
    <Modal {...props} centered dialogClassName="width-734">
      <Modal.Body style={{ height: "fit-content" }}>
        <div className="emodal_wrapper">
          <div className="eheading position-relative d-flex justify-content-center align-items-center">
            <div className="">
              <p className="e_head_txt">Available Dates</p>
              <p className="e_subhead_txt">
                Please select the event dates that suits you.
              </p>
            </div>
            <span
              className="position-absolute eclose_btn"
              onClick={() => {
                props.onHide();
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.2097 4.3871L4.29289 4.29289C4.65338 3.93241 5.22061 3.90468 5.6129 4.2097L5.70711 4.29289L12 10.585L18.2929 4.29289C18.6834 3.90237 19.3166 3.90237 19.7071 4.29289C20.0976 4.68342 20.0976 5.31658 19.7071 5.70711L13.415 12L19.7071 18.2929C20.0676 18.6534 20.0953 19.2206 19.7903 19.6129L19.7071 19.7071C19.3466 20.0676 18.7794 20.0953 18.3871 19.7903L18.2929 19.7071L12 13.415L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071C3.90237 19.3166 3.90237 18.6834 4.29289 18.2929L10.585 12L4.29289 5.70711C3.93241 5.34662 3.90468 4.77939 4.2097 4.3871L4.29289 4.29289L4.2097 4.3871Z"
                  fill="#25272D"
                />
              </svg>
            </span>
          </div>

          <div className="edates_wrapper">
            <p className="em_title">Select a new date</p>
            <div className="e_date_calendar">
              <Calendar
                mapDays={({ date, today, isSameDate }) => {
                  let props = {};
                  props.style = {
                    backgroundColor: "",
                    color: "",
                  };
                  props.disabled = false;
                  if (alleventData.includes(date.format())) {
                    props.style.backgroundColor = "#FCE4D6";
                  }
                  if (!alleventData.includes(date.format())) {
                    props.disabled = true;
                  }
                  if (isSameDate(date, today)) {
                    props.disabled = true;
                  }
                  return props;
                }}
                value={selectedDates}
                onChange={handleDateChange}
                minDate={new Date()}
                multiple={true}
                showOtherDays={true}
                format={"YYYY-MM-DD"}
              />
              <div className="calendar-info-cont">
                <div className="c-info-cont">
                  <div className="c-color-1"></div>
                  <p className="c-a-info">Event Occurrence Dates</p>
                </div>
                <div className="c-info-cont">
                  <div className="c-color-2"></div>
                  <p className="c-a-info">Event date selected</p>
                </div>
                <div className="c-info-cont">
                  <div className="c-color-3"></div>
                  <p className="c-a-info">Not Available</p>
                </div>
              </div>
            </div>
            <Button
              btnTxt="Confirm Date"
              className={"backtologin mt-4"}
              onClick={() => {
                handlePopup();
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ScheduleDatesPopup;
