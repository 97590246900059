import { initializeApp } from 'firebase/app';
import { getMessaging, isSupported } from 'firebase/messaging';
import {  isMobile } from 'react-device-detect';

let messaging = null;
const firebaseConfig = {
  apiKey: "AIzaSyA4fcjCLctKMq48E6krC7MGLZptt6QhaW8",
  authDomain: "fitness-mates.firebaseapp.com",
  projectId: "fitness-mates",
  storageBucket: "fitness-mates.appspot.com",
  messagingSenderId: "1043237618876",
  appId: "1:1043237618876:web:7c08122924a8f949d8bcb9",
  measurementId: "G-ZHN2RJVM8P"
};
if (!isMobile) {
  if ('serviceWorker' in navigator) {
    // Initialize Firebase
    if (isSupported()) {
      const app = initializeApp(firebaseConfig);
      // Initialize Firebase Messaging and check support
      messaging = isSupported() ? getMessaging(app) : null;
    } else {
      // ValidationErrorToast("Registration Failed", "Firebase not supported",)
    }
  }
}


export { messaging };
