import Button from "../../../global/Button";
import { STRING_CONSTANTS } from "../../../utils/StringConstant";
interface DraftPopUpProps {
  onClose: any;
  discard?: any;
  draft: any;
  showDiscard?: any;
  [key: string]: any;
}
const DraftPopup = (props: DraftPopUpProps) => {
  const {
    onClose,
    discard,
    draft,
    showDiscard,
    title = STRING_CONSTANTS.save_listing_as_draft,
    btnTxt = "Save draft",
  } = props;

  return (
    <div className={"modal show"}>
      <div className="modal-section draft_popup">
        <div className="modal-top-part">
          <button className="close">
            <img
              src="/static/dismiss-cross-round-black.svg"
              alt=""
              onClick={onClose}
            />
          </button>
          <h5>{title}</h5>

          <div className="action_btns">
            <Button
              btnTxt={btnTxt}
              btnImg="/static/savedraft.svg"
              onClick={() => draft()}
            />
            {showDiscard ? (
              <Button
                btnTxt="Discard"
                btnImg="/static/discard.svg"
                onClick={() => discard()}
              />
            ) : null}
          </div>
        </div>
        <Button
          btnTxt="Continue editing  "
          className={"backtologin"}
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default DraftPopup;
