import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useCallback, useState } from "react";
import { googleKey } from "../../utils/Constants";
import ApiLoader from "../../global/ApiLoader";

const OpenMapContainer = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleKey,
  });

  const { eventCenter } = props;
  const [map, setMap] = useState(null);
  const [markerPoint, setMarkerPoint] = useState<any>(null);

  const parseWKTToPoint = (wktString) => {
    const cleanedString = wktString
      .replace("SRID=4326;POINT (", "")
      .replace(")", "");
    const [lng, lat] = cleanedString.split(" ").map(Number);
    return { lat, lng };
  };

  const onLoad = useCallback(function callback(map) {
    const point = parseWKTToPoint(eventCenter);
    setMarkerPoint(point);

    // const bounds = new window.google.maps.LatLngBounds(point);
    // map.fitBounds(bounds);

    // setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ width: "100%", height: "100vh" }}
      zoom={15}
      onLoad={onLoad}
      onUnmount={onUnmount}
      center={markerPoint}
      options={{
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: true,
        panControl: false,
        rotateControl: false,
        streetViewControl: false,
      }}
    >
      <>{markerPoint && <Marker position={markerPoint} />}</>
    </GoogleMap>
  ) : (
    <>
      <ApiLoader />
    </>
  );
};
export default OpenMapContainer;
