const AboutUs = () => {
  return (
    <section className="landing-about-us">
      <div className="about-us-top">
        <div className="phone-image-box-mob">
          <img alt="" src="/static/aboutusphone.png" />
        </div>
        <div className="title">About Us</div>
        <div className="description">
          At <span className="orange-span">Fitness mates</span>, our passion and
          dedication to our community are the driving forces behind everything
          we do. We believe that fitness should be all about personal growth,
          reaching your full potential, and building a community of support
          around you. That’s why we’ve poured our hearts and souls into creating
          a platform that not only connects people but also inspires them to
          reach their full potential. Whether you’re a seasoned athlete, a
          fitness enthusiast, or someone just starting their wellness journey,{" "}
          <span className="orange-span">Fitness mates</span> is here to be your
          trusted companion. We’ve curated a space where you can discover new
          activities, connect with like-minded individuals, and tap into a
          wealth of knowledge from industry experts.
        </div>
        <div className="welcome-text orange-span">
          Welcome to <span className="orange-span">Fitness mates</span> – where
          your journey to a happier, healthier you begins!
        </div>
        <div className="phone-image-box">
          <img alt="" src="/static/aboutusphone.png" />
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
