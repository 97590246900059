import React, { Fragment } from "react";
import ListingLayout from "../../components/AddListingComponent/ListingLayout";
import EditListingForm from "../../components/EditListingForm";

// import "./style.scss";

type Props = {
  [key: string]: any;
};

const ModifyListingPage = ({}: Props) => {

  return (
    <Fragment>
      <ListingLayout>
        <EditListingForm edit />
      </ListingLayout>
    </Fragment>
  );
};

export default ModifyListingPage;
