import React, { useState } from "react";
import "./style.scss";

export default function CustomWeekDay({
  label = "",
  data = [],
  name = "",
  getValues = (props: any) => null,
  handleSetValue = (key: any, val: any) => null,
  isDisabled = false,
}) {
  const [selected, setSelected] = useState(getValues(name) || [""]);
  const handleChangeWeekday = (val: any) => {
    if (selected.includes(val)) {
      setSelected(selected.filter((item: any) => item !== val));
      handleSetValue(
        name,
        selected.filter((item: any) => item !== val)
      );
    } else {
      setSelected([...selected, val]);
      handleSetValue(name, [...selected, val]);
    }
  };
  return (
    <div className="Custom-week-wrap">
      {label && <div className="label">{label}</div>}
      <ul className="custom-weeklayset">
        {data.map((item: any, index: number) => {
          return (
            <li
              className={
                selected.includes(item?.value) && !isDisabled
                  ? "active"
                  : isDisabled
                  ? " disabled"
                  : ""
              }
              key={index}
              onClick={() => handleChangeWeekday(item?.value)}
            >
              {item.label}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
