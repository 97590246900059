const ComingSoon = () => {
  return (
    <div className="coming_soon">
      <img src="/static/orange-logo.svg" alt="" />

      <div>Good things coming soon!</div>
    </div>
  );
};

export default ComingSoon;
