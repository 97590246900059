import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import IconHome from "../../assets/img/connectIcons/home-gray.png";
import IconHomeActive from "../../assets/img/connectIcons/home-orange.png";
import IconMyConnections from "../../assets/img/connectIcons/my-connections-gray.png";
import IconMyConnectionsActive from "../../assets/img/connectIcons/my-connections-orange.png";
import IconRequest from "../../assets/img/connectIcons/connection-request-gray.png";
import IconSuggestion from "../../assets/img/connectIcons/suggestion-gray.png";
import IconSuggestionActive from "../../assets/img/connectIcons/suggestion-orange.png";
import IconFavourite from "../../assets/img/connectIcons/like-gray.png";
import IconFavouriteActive from "../../assets/img/connectIcons/like-orange.png";
import IconFlagged from "../../assets/img/connectIcons/flag-gray.png";
import IconFlaggedActive from "../../assets/img/connectIcons/flag-orange.png";

export const FITNESSMATES_REQUEST = "Fitness mates requests";

export const CONNECTIONS_STATUS = {
  CONNECTED: "Connected",
  CONNECT: "Connect",
  PENDING: "Pending",
  REMOVE: "Remove"
};

export const CONNCECTION_STATUS_CHOICES = {
  ACCEPTED: "A",
  REJECTED: "R",
  PENDING: "P",
};

export const CONNECTION_TYPE = {
  SUGGESTIONS: "suggestion",
  REQUESTS: "request",
  FAVOURITES: "favourites",
  CONNECTION: "connection",
  Flagged:"flagged",
};

export const SUGGESTIONS_TYPES = {
   INTEREST_BASED:"interest",
   NEAR_BY: "near_by",
   TRAINER: "trainer",
   OTHER: "other"
}

export const initialFilterData = {
  gender: "P",
  minAge: "",
  maxAge: "",
  allAges: false,
  distance: 0,
  interest: [],
};

export const connectSidebarData = [
  {
    title: "Connect zone",
    icon: IconHome,
    activeIcon: IconHomeActive,
    alt: "menu icon",
    path: ROUTE_CONSTANTS.connect,
  },
  {
    title: "My connections",
    icon: IconMyConnections,
    activeIcon: IconMyConnectionsActive,
    alt: "menu icon",
    path: "/connect/connections",
  },
  {
    title: "Fitness mates requests",
    icon: IconRequest,
    activeIcon: IconSuggestionActive,
    alt: "menu icon",
    path: "/connect/requests",
  },
  {
    title: "Suggestions",
    icon: IconSuggestion,
    activeIcon: IconSuggestionActive,
    alt: "menu icon",
    path: "/connect/suggestions",
  },
  {
    title: "Favourites",
    icon: IconFavourite,
    activeIcon: IconFavouriteActive,
    alt: "menu icon",
    path: "/connect/favourite",
  },
  {
    title: "Flagged",
    icon: IconFlagged,
    activeIcon: IconFlaggedActive,
    alt: "menu icon",
    path: "/connect/flagged",
  },
];

export const USER_ROLES = {
  GUEST: "Guest",
  MEMBER: "Member",
  TRAINER: "Trainer",
};

export const shortenName = (fullName: string , amount=15) => {
  if (fullName?.length > amount) {
    return fullName.substring(0, amount) + "...";
  } else {
    return fullName;
  }
};

export const getFilterParams = (filterdata: any) => {
  
  let filterParms: any = {};
  if (filterdata.interest?.length > 0) {
    const intrestList = filterdata.interest.map(item => item.id);
    filterParms.interest = intrestList.join(",")
  }
  if (!filterdata.allAges && filterdata.minAge && filterdata.maxAge) {
    filterParms.min_age = filterdata.minAge;
    filterParms.max_age = filterdata.maxAge;
  }
  if (filterdata.distance > 0) {
    filterParms.sort = "distance_nearby";
    filterParms.dist = filterdata.distance * 1000;
  }
  if (filterdata.gender) {
    filterParms.gender = filterdata.gender;
  }
  return filterParms;
};