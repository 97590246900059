import { useState } from "react";
import { useRef } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Button from "../global/Button";
import { showToast } from "../utils";

const ImageCropper = ({
  getImage,
  setCropedImage,
  setModal,
  cropperAspect = null,
  isProfile = false,
}) => {
  const imgRef = useRef(null);
  const prevRef = useRef(null);

  const [crop, setCrop] = useState<any>();
  const [resultImage, setResultImage] = useState(null);

  async function canvasPreviewData() {
    let image = imgRef.current;
    let canvas = document.createElement("canvas");
    if (!crop?.width || !canvas?.height) {
      showToast("please select the cropped area", "error");
      return;
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    ctx.save();

    ctx.restore();

    const base64Image = canvas.toDataURL("image/jpeg");
    setResultImage(base64Image);
  }
  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {
      type: mime,
    });
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;

    const tempCrop = makeAspectCrop(
      {
        unit: "px",
        width: width,
      },
      cropperAspect || 1,
      width,
      height
    );
    setCrop(tempCrop);
  };

  const handleUpload = async () => {
    setCropedImage(dataURLtoFile(resultImage, "profile.jpeg"));
    setModal(false);
    setResultImage(null);
  };

  const handleDone = async () => {
    setResultImage(null);
  };

  const handleClose = () => {
    setModal(false);
  };

  return (
    <div className="croper-wrap">
      <div className="crop-box">
        <div>
          {resultImage ? (
            <div className="result-image">
              <img
                alt=""
                className="preview-image"
                ref={prevRef}
                src={resultImage}
              />
            </div>
          ) : (
            <div className="image-cropper">
              <ReactCrop
                src={getImage}
                aspect={cropperAspect}
                ruleOfThirds
                crop={crop}
                onChange={(c: any, p: any) => {
                  setCrop(c);
                }}
              >
                <img ref={imgRef} src={getImage} alt="" onLoad={onImageLoad} />
              </ReactCrop>
            </div>
          )}
        </div>
      </div>
      <div className="action-buttons">
        <Button
          btnTxt="Cancel"
          className={"goback backtologin"}
          onClick={resultImage ? handleDone : handleClose}
        />
        <Button
          btnTxt={resultImage ? "Upload" : "Crop"}
          onClick={() => (resultImage ? handleUpload() : canvasPreviewData())}
          className={"backtologin"}
        />
      </div>
    </div>
  );
};

export default ImageCropper;
