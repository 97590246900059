import { useRef, useState, useEffect } from "react";
import BookEventPopup from "./BookEventPopup";
import { useNavigate } from "react-router-dom";
import useCheckDeviceScreen from "../../hooks/useCheckScreen";

const Hero = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showInfoBox, setShowInfoBox] = useState(true);
  const { isMobile } = useCheckDeviceScreen();

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.play().catch((error) => {
        console.error("Autoplay prevented:", error);
      });
    }
  }, []);

  return (
    <section>
      <div className="hero-section">
        {showInfoBox ? (
          <div className="register-info-box">
            <span>
              <img alt="dumbellwhite" src="/static/dumbellwhite.svg" />
            </span>
            <span>Limited time only</span>
            <span>
              <img alt="dumbellwhite" src="/static/dumbellwhite.svg" />
            </span>
            <span>List your services for FREE</span>
            <span>
              <img alt="dumbellwhite" src="/static/dumbellwhite.svg" />
            </span>
            <span>Register your business now</span>
            <span>
              <img alt="dumbellwhite" src="/static/dumbellwhite.svg" />
            </span>
            <span
              className="close-infobox"
              onClick={() => setShowInfoBox(false)}
            >
              <img alt="" src="/static/close-white.png" />
            </span>
          </div>
        ) : null}
        <div
          className="video-background"
          style={{
            height:
              showInfoBox && !isMobile
                ? `calc(100vh - 118px)`
                : "calc(100vh - 64px)",
          }}
        >
          <video
            id="backgroundVideo"
            ref={videoRef}
            autoPlay
            muted
            loop
            playsInline
            className="video"
          >
            <source
              src="https://fitness-mates.s3.ap-southeast-2.amazonaws.com/websitevideo/Video+For+Web+Site.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <div className="overlay"></div>
          <div className="content">
            <div className="logo-box">
              <img
                alt="dumbellwhite"
                src="/static/Fitness-Mates_App-Icon_v02.gif"
                className="animated-gif"
              />
            </div>
            <div className="title">
              <span>Connect with</span> like-minded Health & Fitness Enthusiasts
            </div>
            <div className="sub-title">
              Your go to app for all things health, fitness and experiences
            </div>
            <div className="button-box">
              <button
                className="hero-btn get-started"
                onClick={() => navigate("/register")}
              >
                Get started
              </button>
              <button
                className="hero-btn book-demo"
                onClick={() =>
                  window.open(
                    "https://meetings.hubspot.com/tim1497/website-cta-app-demo",
                    "_blank"
                  )
                }
              >
                Book demo
              </button>
            </div>
          </div>
        </div>
      </div>
      <BookEventPopup show={showModal} onHide={handleCloseModal} />
    </section>
  );
};

export default Hero;
