import React from "react";
import "./lineStyle.scss";

type Props = {
  [key: string]: any;
};

const HorizontalLine = (props: Props) => {
  const {} = props;
  return <div className="hr_line" />;
};

export default HorizontalLine;
