import React, { Fragment } from "react";
import ListingLayout from "../../components/AddListingComponent/ListingLayout";
import { USER_TYPE } from "../../types";
import { useAppSelector } from "../../store/store";
import SelectListingType from "../../components/AddListingComponent/SelectListingType";

type Props = {
  [key: string]: any;
};

const SelectListingTypePage = ({}: Props) => {
  const auth = useAppSelector((state) => state.auth);

  return (
    <Fragment>
      <ListingLayout>
        <SelectListingType
          guest={auth?.moreUserDetails?.userType === USER_TYPE.GUEST}
          limit={auth?.moreUserDetails?.canCreateListing}
        />
      </ListingLayout>
    </Fragment>
  );
};

export default SelectListingTypePage;
