import React from "react";

type Props = {
  [key: string]: any;
};

const MediaContainer = ({ file, type = "", className = "" }: Props) => {
  // alert('hi');
  const handleBlobUrl = (file: File) => {
    return URL.createObjectURL(file);
  };
  if (
    file?.type?.startsWith("video") ||
    type?.startsWith("video") ||
    /\.(mp4|mov|avi)$/.test(file?.name?.toLowerCase())
  ) {
    return (
      <video
        src={file?.type ? handleBlobUrl(file) : file}
        className={className}
        autoPlay
        muted
        loop
      />
    );
  } else {
    return (
      <img
        src={file?.type ? handleBlobUrl(file) : file}
        alt=""
        className={className}
      />
    );
  }
};

export default MediaContainer;
