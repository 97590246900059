import React, { useEffect, useRef, useState } from "react";
import { getRequiredMediaUrl } from "../../../utils";
import IconPlay from "../../../assets/img/icons/play-video.png";

const RenderMedia = ({
  media,
  isVideo,
  id,
  indexItem = 0,
  activeSlideIndex = 0,
  loadAll = false,
  thumbnail = "",
}) => {
  const videoRef = useRef(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    if (!videoSrc) {
      setVideoSrc(getRequiredMediaUrl(media));
    }
  };

  const handlePlayButtonClick = (media: any) => {
    const mediaUrl = getRequiredMediaUrl(media);
    setVideoSrc(mediaUrl);
    setIsPlaying(true); // Mark the video as playing to hide the custom button

    if (videoRef.current) {
      videoRef.current.src = mediaUrl; // Dynamically set the video src
      videoRef.current.load();
      // Programmatically play the video
      videoRef.current.addEventListener("canplay", () => {
        videoRef.current.play();
      });
    }
  };

  useEffect(() => {
    if (isPlaying && videoRef.current) {
      // Ensure controls are shown only when video starts playing
      videoRef.current.controls = true;
    }
  }, [isPlaying]);

  if (indexItem !== 0 && activeSlideIndex !== 0 && !loadAll) {
    return <></>;
  }
  if (isVideo) {
    return (
      <>
        <video
          ref={videoRef}
          src={videoSrc}
          poster={thumbnail ? thumbnail : getRequiredMediaUrl(media)}
          className="event_img"
          controls
          disablePictureInPicture
          controlsList="nodownload noplaybackrate nofullscreen"
          muted
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.preventDefault();
            window.open(`/listing-two/${id}`);
          }}
          onPlay={handlePlay}
        >
          {videoSrc && <source src={videoSrc} />}
        </video>
        {/* Custom Play Button Overlay */}
        {!isPlaying && (
          <div
            style={{
              position: "absolute",
              bottom: "35px",
              left: "15px",
              cursor: "pointer",
              borderRadius: "50%",
              opacity: 0,
            }}
            onClick={() => handlePlayButtonClick(media)}
          >
            <img
              src={IconPlay}
              alt="Play"
              loading="lazy"
              style={{ width: "24px", height: "24px", opacity: 0 }}
            />
          </div>
        )}
      </>
    );
  }
  return (
    <img
      src={getRequiredMediaUrl(media) || "/static/expertise2.png"}
      className="event_img"
      alt="event-img"
      loading="lazy"
      onClick={(e) => {
        e?.preventDefault();
        window.open(`/listing-two/${id}`);
      }}
      style={{ cursor: "pointer" }}
    />
  );
};

export default React.memo(RenderMedia);
