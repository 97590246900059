import React, { useLayoutEffect, useState } from "react";
import { STRING_CONSTANTS } from "../../../utils/StringConstant";
import { useNavigate } from "react-router-dom";
import { CrossIcon, ErrorInfo } from "../../../utils/Icons/svgs";
import { showToast } from "../../../utils";
import { makeGetCall } from "../../../utils/Requests";
import apiConfig from "../../../utils/endpoints";
import { LISTING_TYPE } from "../../../types";
import {
  ListingTypesForGuest,
  ListingTypesForMember,
} from "../../../utils/Constants";
import "./style.scss";
import { Skeleton } from "@mui/material";
import { ROUTE_CONSTANTS } from "../../../utils/RouteConstants";

type Props = {
  guest: boolean;
  limit: boolean;
};

const SelectListingType = (props: Props) => {
  const { guest = false, limit = true } = props;
  const navigate = useNavigate();
  const [draftCountData, setDraftCountData] = useState<any>(null);
  const [guestInfoVisibility, setGuestInfoVisibility] = useState<boolean>(true);
  const listingTypes = guest ? ListingTypesForGuest : ListingTypesForMember;
  const [loading, setLoading] = useState(true);

  const handleDisableLoading = () => {
    setTimeout(() => {
      setLoading(false);
    }, 0);
  };

  // fetch draft data
  const fetchDraftCount = () => {
    makeGetCall({ url: apiConfig.draft_count })
      .then((res) => {
        setDraftCountData(res?.data);
        handleDisableLoading();
      })
      .catch((err) => {
        showToast(err, "error");
        handleDisableLoading();
      });
  };

  // draft count before ui loads
  useLayoutEffect(() => {
    fetchDraftCount();
  }, []);

  const handleListingType = (typeData: any) => {
    switch (typeData?.type) {
      case LISTING_TYPE.S:
        navigate(`${ROUTE_CONSTANTS.new_listing_path}session`, {
          state: {
            listingType: typeData?.type,
            typeData,
          },
        });
        break;
      case LISTING_TYPE.M:
        navigate(`${ROUTE_CONSTANTS.new_listing_path}package`, {
          state: {
            listingType: typeData?.type,
            typeData,
          },
        });
        break;

      case LISTING_TYPE.C:
        navigate(`${ROUTE_CONSTANTS.new_listing_path}team`, {
          state: {
            listingType: typeData?.type,
            typeData,
          },
        });
        break;
      default:
        break;
    }
  };

  // draft case inner card UI
  const handleDraftCase = (type, countData) => {
    if (
      (type === LISTING_TYPE.S && countData?.singleListingCount > 0) ||
      (type === LISTING_TYPE.M && countData?.packageListingCount > 0) ||
      (type === LISTING_TYPE.C && countData?.teamListingCount > 0)
    ) {
      return (
        <div className="draft_container">
          <img
            className="draft_saved_icon"
            src="/static/listing/saveGrey.svg"
            alt=""
          />
          <div className="draft_title">Draft Available</div>
        </div>
      );
    }
  };

  //listing cards UI
  const listingCards = () => {
    return listingTypes.map((item, index) => {
      if (loading) {
        return (
          <Skeleton
            key={index + item?.title}
            className="skeleton_class"
            variant="rounded"
          />
        );
      } else {
        return (
          <div
            className="card_inner"
            onClick={() => {
              if (!limit) {
                showToast(
                  "Your weekly limit to  create a listing has been reached!",
                  "error"
                );
              } else {
                handleListingType(item);
              }
            }}
            key={index + item?.title}
          >
            <div className="card-body">
              <img src={item.icon} alt="" />
              <div className="card_title">{item.title}</div>
              <div className="card_desc">{item.desc}</div>

              {handleDraftCase(item?.type, draftCountData)}
            </div>
          </div>
        );
      }
    });
  };

  return (
    <>
      <div className="select_listing_container">
        <div className="wrap_select_listing">
          {guest && guestInfoVisibility && (
            <div className="infoguestlisting">
              <div className="dl-email-verified_listing">
                <div>
                  <ErrorInfo />
                </div>
                <div>
                  <p className=" dl-title mb-0">
                    Being a guest user, you can only create 1 listing per week.
                  </p>
                  <p
                    className="dl-subtitle mb-0 cursor-pointer"
                    onClick={() => navigate("/subscription")}
                  >
                    Click here to upgrade as a member.
                  </p>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setGuestInfoVisibility(false);
                  }}
                >
                  <CrossIcon width="16" height="16" fill="white" />
                </div>
              </div>
            </div>
          )}

          <div className="heading_header">
            {STRING_CONSTANTS.select_listing_type}
          </div>

          <div
            className="card_listing_type_wrap"
            style={{
              justifyContent:
                guest && guestInfoVisibility ? "center" : "stretch",
            }}
          >
            {listingCards()}
          </div>
          <div className="or-divider-wrap">
            <div className="divide-line" />
            <div> OR</div>
            <div className="divide-line" />
          </div>
          {loading ? (
            <Skeleton className="draft_btn_skeleton" variant="rounded" />
          ) : (
            <button
              className={"draft_btn_add"}
              disabled={
                draftCountData?.singleListingCount === 0 &&
                draftCountData?.packageListingCount === 0 &&
                draftCountData?.teamListingCount === 0
              }
              onClick={() => navigate("/drafts")}
            >
              Continue from draft
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default SelectListingType;
