import React from "react";
import { Modal } from "react-bootstrap";
import { showToast } from "../../../utils";
import "./style.interest.scss";

type Props = {
  [key: string]: any;
};

const AllInterestModal = (props: Props) => {
  const { show, handleClose, list = [], handleRemoveInterest } = props;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      fullscreen
      centered
      className="list_interest_container"
    >
      <Modal.Body>
        <div className="all_interest_header">
          <img
            src="/static/close/closePrimary.svg"
            alt="Close"
            style={{ cursor: "pointer" }}
            onClick={handleClose}
            className="close_btn_all"
          />
        </div>
        <div className="heading_title">Interest Category</div>
        <div className="body_all_list">
          {list?.map((item, idx) => (
            <div className="list_chip" key={item?.title + idx}>
              <img src={item?.icon} alt="" />
              <div>{item?.title}</div>
              <img
                src="/static/createListingIcon/clearGrey.svg"
                className="cursor-pointer"
                alt=""
                onClick={() => handleRemoveInterest(item.id)}
              />
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AllInterestModal;
