import React, { Fragment } from "react";
import { Box } from "@mui/material";
import GlobalHeader2 from "../../Dashboard/GlobalHeader2";
import GlobalFooter from "../../Dashboard/GlobalFooter";
import "./style.scss";

type Props = {
  header?: boolean;
  footer?: boolean;
  children: any;
};

const ListingLayout = ({ header = true, footer = false, children }: Props) => {
  return (
    <Fragment>
      {header && <GlobalHeader2 />}
      <Box className="landing_layout_wrapper">{children}</Box>
      {footer && <GlobalFooter />}
    </Fragment>
  );
};

export default ListingLayout;
