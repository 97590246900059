import * as Yup from "yup";
import { apiConstants, errorMessage } from "../../utils/Constants";
import { STRING_CONSTANTS } from "../../utils/StringConstant";

export const SignInValidations = Yup.object().shape({
  [apiConstants.email]: Yup.string()
    .email(STRING_CONSTANTS.invalid_email)
    .required(errorMessage.fieldRequired(apiConstants.email)),
  [apiConstants.password]: Yup.string().required(
    errorMessage.fieldRequired(apiConstants.password)
  ),
});
export const RegistrationValidations = Yup.object().shape({
  [apiConstants.email]: Yup.string()
    .email(STRING_CONSTANTS.invalid_email)
    .required(errorMessage.fieldRequired(apiConstants.email)),
  [apiConstants.password]: Yup.string()
    .min(8, STRING_CONSTANTS.password_length_8)
    .required(errorMessage.fieldRequired(apiConstants.password)).test(
      'no-whitespace', 
      'Password must not contain whitespace', 
      value => !/\s/.test(value)
    ),
  [apiConstants.confirmPassword]: Yup.string()
    .min(8, STRING_CONSTANTS.confirm_password_length_8)
    .required(errorMessage.fieldRequired("confirm password")).test(
      'no-whitespace', 
      'Confirm password must not contain whitespace', 
      value => !/\s/.test(value)
    ),
});

export const ForgotPasswordValidations = Yup.object().shape({
  [apiConstants.email]: Yup.string()
    .email(STRING_CONSTANTS.invalid_email)
    .required(errorMessage.fieldRequired(apiConstants.email)),
});

export const EnquiryValidations = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  phone_number: Yup.string().required("Phone number is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  message: Yup.string().required("Message is required"),
});
export const EnquiryValidationsNew = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  phone_number: Yup.string(),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  message: Yup.string(),
});
