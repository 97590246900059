import { useState } from "react";
import { makeDeleteCall, makePostCall } from "../../../utils/Requests";
import { showToast } from "../../../utils";
import { CONNECTION_TYPE } from "../ConnectConstants";

interface FavouritePropsI {
  statusFav: any;
  userId:any,
  setIsFavouriteClicked?: React.Dispatch<React.SetStateAction<boolean>>;
  isAcceptRejct?:any,
  handleNotFav?:any
  mode?: any;
}

const Favourites: React.FC<FavouritePropsI> = ({
  statusFav,
  userId,
  setIsFavouriteClicked,
  isAcceptRejct = false,
  handleNotFav = (id:any) => {},
  mode=CONNECTION_TYPE.SUGGESTIONS
}) => {
  const [favStatus, setFavStatus] = useState(statusFav);

  const handleFavouriteClick = () => {
    const payload = { user: userId };
    makePostCall({
      url: "auth/favourite/",
      apiPayload: payload,
    })
      .then((res) => {
        if (res.status.code === 200) {
          setFavStatus((prev: any) => !prev);
          showToast(res.status.message, "success");
          setIsFavouriteClicked(true);
        } else {
          showToast(res.status.message || res.error.message, "error");
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  const handleUnfavouriteClick = () => {
    const payload = { user: userId };
    makeDeleteCall({
      url: "auth/favourite/",
      apiPayload: payload,
    })
      .then((res) => {
        if(mode === CONNECTION_TYPE.FAVOURITES){
            handleNotFav(userId);
          }
        if (res.status.code === 200) {
          setFavStatus((prev: any) => !prev);
          showToast(res.status.message, "success");
          setIsFavouriteClicked(true);
        } else {
          showToast(res.status.message || res.error.message, "error");
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  const handleFavClick = () => {
    if (favStatus) {
      handleUnfavouriteClick();
    } else {
      handleFavouriteClick();
    }
  };

  return (
    <span
      onClick={(e) => {
        e.stopPropagation();
        
        handleFavClick();
      }}
      style={{
        border: "1px solid #6C6D6F",
        // width: isAcceptRejct ? "49px" : "40px",
        // height: isAcceptRejct ? "40px" : "33px",
        height:"40px",
        width:"40px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // backgroundColor: favStatus ? "#FEEFE7" : "white",
      }}
    >
      <img
        src={
          favStatus ? "/static/listing/heartA.svg" : "/static/listing/heart.svg"
        }
        alt=""
        height="40px"
        width="40px"
      />
    </span>
  );
};

export default Favourites;
