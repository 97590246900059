import { useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../global/Button";
import moment from "moment";
import { makePostCall } from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import { useNavigate, useParams } from "react-router-dom";
import { getItemFromStore, showToast } from "../../utils";
import {
  CalendarIcon,
  LeftArrowStickIcon,
  LocationGreyIcon,
  RightArrowIcon,
  SpClockIcon,
} from "../../utils/Icons/svgs";
import EventDateCard from "./Popups/EventDateCard";
import WaitlistPopup from "./Popups/WaitlistPopup";
import { LISTING_TYPE } from "../../types";
import EventArchivedCard from "./Popups/EventArchivedCard";
import Dismiss from "../../assets/img/icons/Dismiss";
import "../../assets/scss/allEventsDates.scss";

const EventDateListing = (props) => {
  return (
    <Modal
      show={props?.show}
      onHide={() => props?.onHide()}
      centered
      dialogClassName="all_dates_dialog_modal"
    >
      <Modal.Body
        style={{ height: "fit-content" }}
        className="all_event_dates_body"
      >
        <div className="d-flex align-items-center">
          <div className="all_event_dates_text">Available Dates</div>
          <div className="dismiss_container">
            <Dismiss onClick={props?.onHide} className="cursor-pointer" />
          </div>
        </div>
        <div className="sub_heading_all_events">
          Check the available dates of the selected event.
        </div>
        <div>
          <div className="dates_cards_container">
            {props?.detailsData?.events?.map((item, idx) => (
              <div className="dates_cards" key={"events" + idx}>
                <div className="dates_cards_date">
                  {moment(item?.date).format("ddd")},{" "}
                  {moment(item?.date).date()}{" "}
                  {moment(item?.date).format("MMMM")}
                </div>
                <div className="dates_cards_timing">
                  <span className="date-hl ">
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.49961 1.6001C12.0342 1.6001 14.8996 4.46548 14.8996 8.0001C14.8996 11.5347 12.0342 14.4001 8.49961 14.4001C4.96499 14.4001 2.09961 11.5347 2.09961 8.0001C2.09961 4.46548 4.96499 1.6001 8.49961 1.6001ZM8.09961 4.0001C7.8787 4.0001 7.69961 4.17918 7.69961 4.4001V8.4001L7.70605 8.472C7.73992 8.6586 7.90324 8.8001 8.09961 8.8001L10.4996 8.8001L10.5715 8.79365C10.7581 8.75978 10.8996 8.59647 10.8996 8.4001C10.8996 8.17918 10.7205 8.0001 10.4996 8.0001L8.49961 8.0001V4.4001L8.49317 4.3282C8.4593 4.1416 8.29598 4.0001 8.09961 4.0001Z"
                        fill="#8F939A"
                      />
                    </svg>
                  </span>
                  <p className="date-txt mb-0">
                    {moment(item?.startTime?.substring(0, 5), ["HH:mm"]).format(
                      "hh:mmA"
                    )}{" "}
                    -{" "}
                    {moment(item?.endTime?.substring(0, 5), ["HH:mm"]).format(
                      "hh:mmA"
                    )}
                  </p>
                </div>
                <div className="dates_cards_spots">
                  {item?.slotsAvailable || 0} Left out of{" "}
                  <span>{props?.maxParticipants || 0} spots</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <Button
            btnTxt="Back to Screen"
            style={{ maxWidth: "350px" }}
            onClick={props?.onHide}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default EventDateListing;
