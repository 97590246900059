import { useState } from "react";
import { STRING_CONSTANTS } from "../utils/StringConstant";
import Button from "./Button";
import { apiConstants, flaggingReasons } from "../utils/Constants";
import { useForm } from "react-hook-form";
import RadioSelect from "./RadioSelect";
import { makePostCall } from "../utils/Requests";
import { Form } from "react-bootstrap";
import { showToast } from "../utils";

interface UserFlagged {
  onOk?: any;
  onCancel?: any;
  setShowFlagUser?: any;
  userProfile?: any;
  setShowUserFlaggedModal?: any;
  fetchUserProfile?: any;
}
const FlagUserModal = (props: UserFlagged) => {
  const { control, setValue, getValues, watch } = useForm({});
  const { onOk, setShowFlagUser, userProfile, fetchUserProfile } = props;
  const [, setLoading] = useState(false);
  const [reasonValue, setReasonValue] = useState("");

  const flagUser = () => {
    setLoading(true);
    let reason = "";
    if (
      getValues(apiConstants?.flagReason) === "Other (Please provide details)"
    ) {
      reason = reasonValue;
    } else {
      reason = getValues("flag_reason");
    }

    makePostCall({
      url: `auth/${userProfile?.id}/flag-user/`,
      apiPayload: { reason: reason },
    })
      .then((res) => {
        setLoading(false);
        onOk();
        setShowFlagUser(false);
        showToast(res?.status?.message, "success");
        fetchUserProfile();
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  return (
    <div className={"modal show"}>
      <div className="flag_user_modal">
        <div className="" style={{ display: "flex", justifyContent: "end" }}>
          <div className="round-button" onClick={() => setShowFlagUser(false)}>
            <img src="/static/CrossBlack.svg" alt="" />
          </div>
        </div>
        <div className="modal-top-part" style={{ position: "relative" }}>
          <div className="title">{STRING_CONSTANTS.user_flag_user}</div>
          <div
            className="description"
            style={{
              textAlign: "left",
              alignItems: "flex-start",
              marginTop: "32px",
              marginBottom: "8px",
            }}
          >
            {STRING_CONSTANTS.whats_wrong}
          </div>
          <div className="sub-text">
            Your feedback matters! Use this feature to report any users that
            violate our community guidelines. Help us keep our platform safe and
            enjoyable for everyone. Thank you!
          </div>
        </div>
        <div className="radio-container">
          {flaggingReasons.map((r) => {
            return (
              <RadioSelect
                key={r.id}
                name={"flag_reason"}
                id={r.id}
                type="radio"
                style={{ cursor: "pointer" }}
                control={control}
                setValue={setValue}
                getValues={getValues}
              />
            );
          })}
        </div>

        {watch(apiConstants?.flagReason) ===
          "Other (Please provide details)" && (
          <div className="fl-textarea p-3 mb-4">
            <div className="d-flex justify-content-between">
              <label>Other</label>
              <div className="fl-words">
                {reasonValue?.length ? reasonValue?.split(" ")?.length : "0"}
                /300
              </div>
            </div>
            <Form.Control
              as="textarea"
              rows={2}
              style={{ border: "0px", padding: "0px" }}
              placeholder="Your feedback helps us improve."
              value={reasonValue}
              onChange={(e) => {
                if (e.target.value?.split(" ")?.length <= 300) {
                  setReasonValue(e.target.value);
                }
              }}
            />
          </div>
        )}
        <div className="button_option">
          <Button btnTxt="Submit" onClick={flagUser} />
        </div>
      </div>
    </div>
  );
};

export default FlagUserModal;
