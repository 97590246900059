import React from "react";
import { compressLargeNumber } from "../../../constants/helperConnectUser";

type Props = {
  [key: string]: any;
};

const FavoriteTag = ({ active = false, ...others }: Props) => {
  return (
    <>
      {active ? (
        <img
          src="/static/listing/heartA.svg"
          style={{ cursor: "pointer" }}
          {...others}
          alt=""
        />
      ) : (
        <img
          src="/static/listing/heart.svg"
          style={{ cursor: "pointer" }}
          {...others}
          alt=""
        />
      )}
    </>
  );
};

export default FavoriteTag;
