import { useState } from "react";
import { Modal } from "react-bootstrap";
import Loader from "../../global/Loader";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import { isUserAuthenticated, showToast } from "../../utils";
import { makePostCall } from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import { enquiryForm } from "../../utils/FormConfig";
import { EnquiryValidations } from "../Auth/AuthValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { apiConstants } from "../../utils/Constants";

const BookEventPopup = ({ show, onHide = () => null }) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: enquiryForm,
    resolver: yupResolver(EnquiryValidations),
    mode: "onSubmit",
  });

  const onSubmit = (data) => {
    let formData = new FormData();
    formData.append("name", data.name);
    formData.append("phone_number", data.phone_number);
    formData.append("email", data.email.toLowerCase());
    formData.append("message", data.message);
    formData.append("profile_link", data.profile_link);
    setIsLoading(true);
    makePostCall({
      url: endpoints.enquiry_form,
      apiPayload: formData,
      content_type: "multipart/form-data",
    })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res.status.message, "success");
          setIsLoading(false);
          reset(); // Reset the form after successful submission
        }
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const handleHide = () => {
    reset(); // Reset the form when the modal is closed
    onHide(); // Call the provided onHide function
  };
  const handleInput = (event) => {
    const value = event.target.value.replace(/[^0-9+e]/g, "");
    event.target.value = value;
  };

  return (
    <Modal
      centered
      dialogClassName="book-event-popup-wrap"
      show={show}
      onHide={handleHide} // Use handleHide here
    >
      <Modal.Body style={{ height: "fit-content", position: "relative" }}>
        <div className="content-box">
          <div className="content-left">
            <div>
              <div className="title">Want to be apart of it all?</div>
              <div className="sub-title">
                Start your <span className="orange-span">Fitness mates </span>{" "}
                journey today!
              </div>
            </div>
          </div>
          <div className="content-right">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="book-event-top">
                <div className="small-title">Tell us a little about you</div>
                <div className="title orange-span">
                  Are you a fitness business, PT, Athlete, Ambassador, or simply
                  want to connect with us?
                </div>
              </div>

              <div className="form-input-box">
                <div className="name-phone-box">
                  <div className="text-field">
                    <Controller
                      name={apiConstants.name}
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="text"
                          className={`text-base ${
                            errors.name ? "is-invalid" : ""
                          }`}
                          placeholder=" "
                        />
                      )}
                    />
                    <span>Full Name*</span>
                    {errors.name && (
                      <p className="text-danger" style={{ marginBottom: "0" }}>
                        {errors.name.message}
                      </p>
                    )}
                  </div>
                  <div className="text-field">
                    <Controller
                      name={apiConstants.phone_number}
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="text"
                          autoFocus={false}
                          maxLength={12}
                          placeholder=""
                          className={`text-base  ${
                            errors.phone_number ? "is-invalid" : ""
                          }`}
                          onInput={handleInput}
                        />
                      )}
                    />
                    <span>Mobile Number*</span>
                    {errors.phone_number && (
                      <p
                        className="text-danger ml"
                        style={{ marginBottom: "0" }}
                      >
                        {errors.phone_number.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="text-field ">
                  <Controller
                    name={apiConstants.email}
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="email"
                        placeholder=" "
                        className={`text-base wid ${
                          errors.email ? "is-invalid" : ""
                        }`}
                      />
                    )}
                  />
                  <span>Email Address*</span>
                  {errors.email && (
                    <p className="text-danger" style={{ marginBottom: "0" }}>
                      {errors.email.message}
                    </p>
                  )}
                </div>
                {/* Profile Link input */}
                <div className="text-field ">
                  <Controller
                    name={apiConstants.profile_link}
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        placeholder=" "
                        className={`text-base wid ${
                          errors.profile_link ? "is-invalid" : ""
                        }`}
                      />
                    )}
                  />
                  <span>Profile URL or website link</span>
                  {errors.profile_link && (
                    <p className="text-danger" style={{ marginBottom: "0" }}>
                      {errors.profile_link.message}
                    </p>
                  )}
                </div>
                <div className="text-field ">
                  <Controller
                    name={apiConstants.message}
                    control={control}
                    render={({ field }) => (
                      <textarea
                        {...field}
                        placeholder=" "
                        className={`text-base wid ${
                          errors.name ? "is-invalid" : ""
                        }`}
                      />
                    )}
                  />
                  <span>Add a message*</span>
                  {errors.message && (
                    <p className="text-danger" style={{ marginBottom: "0" }}>
                      {errors.message.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="mt-32">
                <div className="button-box">
                  <div className="agree-text">
                    By continuing I agree to the storage & processing of my
                    data. For more info visit our{" "}
                    <Link
                      to={
                        isUserAuthenticated()
                          ? ROUTE_CONSTANTS.policies
                          : ROUTE_CONSTANTS.policy
                      }
                      style={{ textDecoration: "none" }}
                      className="PrivacyTerms-text"
                    >
                      privacy policy
                    </Link>
                    .
                  </div>
                  <button className="orange-btn" type="submit">
                    {isLoading ? (
                      <div style={{ minWidth: "128px" }}>
                        <Loader />
                      </div>
                    ) : (
                      <>Send</>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="close_container" onClick={handleHide}>
          <img
            src="/static/dismisswhite.svg"
            alt="close icon"
            className="close_icon"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BookEventPopup;
