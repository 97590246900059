import { useState } from "react";
import { showToast } from "../../utils";
import { makePatchCall } from "../../utils/Requests";
import AcceptRejectButton from "./AcceptRejectButton";
import BorderlessTable from "./BorderlessTable";
import endpoints from "../../utils/endpoints";
import { TEAM_REQUEST_TYPE_PAYLOAD } from "../../utils/Constants";
import useCheckDeviceScreen from "../../hooks/useCheckScreen";
import IconDismiss from "../../assets/img/createListingIcon/dismiss.svg";
import IconCorrect from "../../assets/img/createListingIcon/charm_tick.svg";
import Accordion from "./Accordion";
import IconPhone from "../../assets/img/createListingIcon/phone.svg";
import IconDotGray from "../../assets/img/createListingIcon/dot-gray.svg";
import IconEmail from "../../assets/img/createListingIcon/mail.svg";
import IconDesc from "../../assets/img/createListingIcon/description.svg";

const MyTeamListingRequestCard = ({
  requestData,
  activeTab,
  fetchRequestDetails,
}) => {
  const { isMobile } = useCheckDeviceScreen();
  const tabs = ["Total", "Accepted", "Rejected"];
  const [isAcceptButtonClicked, setIsAcceptButtonClicked] = useState(false);

  const handleAcceptRejectClick = (status: any, userId: any) => {
    makePatchCall({
      url: `${endpoints.team_listing}/${userId}`,
      apiPayload: { status: status },
    })
      .then((res) => {
        if (res.status.code === 200) {
          fetchRequestDetails();
        } else {
          showToast(res.status.message || res.error.message, "error");
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  return (
    <div className="request-card-Wrapper">
      <div>
        <div className="card-heading-wrap">
          <div className="card-heading-start">
            <div>
              {requestData?.userDetails?.profilePictureExtension?.includes(
                "video"
              ) ? (
                <video
                  autoPlay
                  playsInline
                  loop
                  muted
                  src={requestData?.userDetails?.profilePicture}
                  className="booki-img-style vid"
                >
                  <source src={"" || ""} type="video/mp4" />
                </video>
              ) : (
                <img
                  src={
                    requestData?.userDetails?.profilePicture
                      ? requestData?.userDetails?.profilePicture
                      : "/static/dummyimg.svg"
                  }
                  className="booki-img-style"
                  alt="profile-img"
                />
              )}
            </div>
            <div>
              <div className="name-text">{requestData?.userDetails?.name}</div>
              <div className="email-text">
                {requestData?.userDetails?.email}
              </div>
            </div>
          </div>
          {!isMobile && (
            <div className="card-heading-end">
              {(activeTab === tabs[0] || activeTab === tabs[2]) && (
                <AcceptRejectButton
                  classname="reject-btn-class"
                  handleClick={() =>
                    handleAcceptRejectClick(
                      TEAM_REQUEST_TYPE_PAYLOAD.REJECTED,
                      requestData?.id
                    )
                  }
                  title={activeTab === tabs[0] ? "Reject" : "Rejected"}
                  disabled={activeTab === tabs[0] ? false : true}
                />
              )}
              {(activeTab === tabs[0] || activeTab === tabs[1]) && (
                <AcceptRejectButton
                  classname="accpet-btn-class"
                  handleClick={() => {
                    setIsAcceptButtonClicked(true);
                    handleAcceptRejectClick(
                      TEAM_REQUEST_TYPE_PAYLOAD.ACCEPTED,
                      requestData?.id
                    )
                  }}
                  title={activeTab === tabs[0] ? "Accept" : "Accepted"}
                  disabled={isAcceptButtonClicked ? isAcceptButtonClicked : (activeTab === tabs[0]) ? false : true}
                />
              )}
            </div>
          )}
          {isMobile && (
            <div className="card-heading-end">
              {activeTab === tabs[0] && (
                <img
                  style={{
                    height: "40px",
                    width: "40px",
                    border: "0px",
                    backgroundColor: "#FC5555",
                    borderRadius: "12px",
                    padding: "8px",
                  }}
                  src={IconDismiss}
                  onClick={() =>
                    activeTab === tabs[0]
                      ? handleAcceptRejectClick(
                          TEAM_REQUEST_TYPE_PAYLOAD.REJECTED,
                          requestData?.id
                        )
                      : {}
                  }
                />
              )}
              {activeTab === tabs[0] && (
                <img
                  src={IconCorrect}
                  style={{
                    height: "40px",
                    width: "40px",
                    border: "0px",
                    backgroundColor: "#29CC6A",
                    borderRadius: "12px",
                    padding: "8px",
                  }}
                  onClick={() => {
                    if(activeTab === tabs[0] && !isAcceptButtonClicked){
                      handleAcceptRejectClick(
                        TEAM_REQUEST_TYPE_PAYLOAD.ACCEPTED,
                        requestData?.id
                      )
                      setIsAcceptButtonClicked(true);  
                    }
                  }}
                />
              )}
              {activeTab === tabs[1] && (
                <span className="accepted-txt">Accepted</span>
              )}
              {activeTab === tabs[2] && (
                <span className="rejected-txt">Rejected</span>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="horizontal-break"></div>
      {!isMobile && (
        <div className="table-wrapper">
          <div className="attende-text">{ requestData?.slotsRequested !== null ? `${requestData?.slotsRequested} Attendee Details` : "Attendee Details"}</div> 
          <BorderlessTable data={requestData?.attendees} />
        </div>
      )}
      {isMobile && (
        <div className="table-wrapper">
          {
            <Accordion
              title={requestData?.slotsRequested !== null ? `${requestData?.slotsRequested} Attendee Details` : "Attendee Details" }
              titleClassName="attende-text"
            >
              {requestData?.attendees?.map((attende: any, index: any) => {
                return (
                  <div className="attende-card-wrapper" key={index+11}>
                    <div className="name-age-wrapper">
                      <div className="name-container">{`${index + 1}. ${
                        attende?.name
                      }`}</div>
                      <div className="age-gender-container">
                        <span>
                          {attende?.gender === "F"
                            ? "Female"
                            : attende?.gender === "M"
                            ? "Male"
                            : "Prefer not to say"}
                        </span>
                        {attende?.age && (
                          <img
                            style={{
                              height: "5px",
                              width: "5px",
                              border: "0px",
                            }}
                            src={IconDotGray}
                            alt="icon-dot"
                          />
                        )}
                        {attende?.age && <span>{attende?.age}</span>}
                      </div>
                    </div>
                    <div className="contact-wrapper">
                      {attende?.email && (
                        <div className="email-wrapper">
                          {" "}
                          <img
                            style={{
                              height: "12px",
                              width: "12px",
                              border: "0px",
                            }}
                            src={IconEmail}
                            alt="icon-email"
                          />
                          <span>{attende?.email}</span>
                        </div>
                      )}
                      {attende?.contactNumber && (
                        <div className="phone-wrapper">
                          <img
                            style={{
                              height: "12px",
                              width: "12px",
                              border: "0px",
                            }}
                            src={IconPhone}
                            alt="icon-phone"
                          />
                          <span>{`${attende?.contactNumber}`}</span>
                        </div>
                      )}
                    </div>

                    {attende?.description && (
                      <div className="desc-wrapper">
                        <img
                          style={{
                            height: "12px",
                            width: "12px",
                            border: "0px",
                            marginTop: "5px",
                          }}
                          src={IconDesc}
                          alt="icon-desc"
                        />
                        <span>{attende?.description}</span>
                      </div>
                    )}
                  </div>
                );
              })}
            </Accordion>
          }
        </div>
      )}
    </div>
  );
};

export default MyTeamListingRequestCard;
