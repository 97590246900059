import React, { useState } from "react";
import "./style.scss";

type Props = {
  [key: string]: any;
};

const ReadMore = ({ data = "", count = 85 }: Props) => {
  const [isReadMore, setIsReadMore] = useState(true);
  if (data?.length > count) {
    return (
      <div className="read_more_container">
        {isReadMore ? `${data?.slice(0, count)}... ` : `${data} `}
        <span
          className="read_more_btn"
          onClick={() => setIsReadMore((prev) => !prev)}
        >
          {isReadMore ? "Read more" : "Read less"}
        </span>
      </div>
    );
  } else {
    return data;
  }
};

export default ReadMore;
