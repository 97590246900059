import React, { forwardRef } from "react";
import { Controller, useController } from "react-hook-form";
import { emptyFunction } from "../utils";

interface RadioSelectProps {
  name: string;
  type: React.HTMLInputTypeAttribute;
  inputLabel?: string;
  as?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
  hideErrorMessage?: boolean;
  errors?: object | any;
  value?: string | boolean | any;
  maxlength?: number | any;
  id?: string;
  control?: any;
  extramsg?: string;
  isChecked?: boolean;
  customOnChange?: any;
  reference?: any;
  onlyPastDates?: boolean;
  onlyFuturedates?: boolean;
  onKeyDown?: any;
  fileOnChange?: any;
  getValues?: any;
  setValue?: any;
  pattern?: any;
  handleAge?: any;
}

const RadioSelect = forwardRef((props: RadioSelectProps, ref: any) => {
  const {
    name,
    type,
    placeholder,
    style,
    control,
    id,
    disabled,
    onKeyDown = emptyFunction,
  } = props;

  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });

  return (
    <div style={style} className={`radio-select`}>
      <Controller
        control={control}
        name={name}
        render={() => {
          return (
            <input
              className="radio_orange"
              type={type}
              onChange={onChange}
              id={value}
              checked={value === id}
              placeholder={placeholder}
              value={id}
              disabled={disabled}
              onKeyDown={onKeyDown || {}}
            />
          );
        }}
      />
      <div className="radio-value">{id}</div>
    </div>
  );
});

export default RadioSelect;
