const AcceptRejectButton = ({
  classname = "",
  handleClick,
  title,
  disabled = false,
}) => {
  return (
    <button className={classname} onClick={handleClick} disabled={disabled}>
      {title}
    </button>
  );
};

export default AcceptRejectButton;
