import React from "react";
import { Modal } from "react-bootstrap";
import MediaContainer from "../MediaContainer";
import { showToast } from "../../../utils";
import "./style.scss";

type Props = {
  [key: string]: any;
};

const FilePreview = (props: Props) => {
  const { show, handleClose, data } = props;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      fullscreen
      centered
      className="file_preview_container"
    >
      <Modal.Body>
        <div className="file_preview_wrap">
          <img
            src="/static/createListingIcon/clearGrey.svg"
            alt="Close"
            style={{ cursor: "pointer" }}
            onClick={handleClose}
            className="file_preview_close"
          />
          <MediaContainer
            className={"custom_file_preview"}
            documentName={data?.documentName}
            documentFileType={data?.documentFileType}
            uploadDocumentUrl={data?.uploadDocumentUrl}
            controls
            muted={false}
            autoPlay={true}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FilePreview;
