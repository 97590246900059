import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import GlobalHeader2 from "../../components/Dashboard/GlobalHeader2";
import Footer from "../../components/LandingNew/Footer";
import "./style.scss";
import EventsContainer from "../../components/ConnectUserProfile/EventContainer";
import { useNavigate, useParams } from "react-router-dom";
import { eventData } from "./event";
import "../../assets/scss/eventsPage.scss";
import MediaContainer from "../../components/ConnectUserProfile/MediaContainer";
import {
  TAB_DATA,
  getGeolocation,
  gettingAge,
  gettingDistance,
  gettingDuration,
  gettingGender,
  gettingGenderIcon,
  gettingUserMemberType,
} from "../../constants/helperConnectUser";
import Pagination from "../../components/ConnectUserProfile/Pagination";
import ReadMore from "../../components/ConnectUserProfile/ReadMore";
import ExpandIcon from "../../components/ConnectUserProfile/ExpandIcon";
import {
  makeDeleteCall,
  makeGetCall,
  makePatchCall,
  makePostCall,
} from "../../utils/Requests";
import apiConfig from "../../utils/endpoints";
import LoadingLayer from "../../components/ConnectUserProfile/LoaderLayer";
import { getItemFromStore, showToast } from "../../utils";
import FlagUserModal from "../../global/FlagUserModal";
import UserFlaggedModal from "../../global/UserFlaggedModal";
import RemoveConnectionModal from "../../global/RemoveConnectionModal";
import QualificationModal from "../../components/ConnectUserProfile/QualificationModal";
import GlobalFooter from "../../components/Dashboard/GlobalFooter";

type Props = {
  [key: string]: any;
};

const event = eventData;

const ConnectUserProfile = (props: Props) => {
  const selfDetails = getItemFromStore("userProfile");
  const navigate = useNavigate();
  const params = useParams();
  const [activeTab, setActiveTab] = useState("S");
  const divRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [expandedTab, setExpandedTab] = useState(null);
  const [activeLoading, setLoading] = useState(true);
  const [resultProfile, setResultProfile] = useState(null);
  const [resultListing, setResultListing] = useState(null);

  const [seeAllQualification, setSeeAllQualification] = useState(false);
  const [seeAllInterest, setSeeAllInterest] = useState(false);
  const [isActionRunning, setActionRunning] = useState(false);

  const [toggleFlag, setToggleFlag] = useState(false);
  const [flaggedDisclaimer, setFlaggedDisclaimer] = useState(false);

  const [connectedOption, setConnectedOption] = useState(false);
  const [confirmRemove, setConfirmRemove] = useState(false);
  const dropdownRef = useRef(null);

  //pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const limit = 12;

  //
  const [toggleQualification, setQualificationModal] = useState(false);
  const [qualificationDetails, setQualificationDetails] = useState(null);

  //click outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setConnectedOption(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //loading
  const disableLoading = useCallback(() => {
    setTimeout(() => {
      setLoading(false);
    }, 0);
  }, [setLoading]);

  //scroll point
  const applyInitialScrollPoint = () => {
    if (divRef.current) {
      const elementPosition =
        divRef.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 90;

      window.scrollTo({
        top: offsetPosition,
        behavior: "auto",
      });
    }
  };

  //listing
  const fetchListings = useMemo(() => {
    // type: S,P, C
    return async (userId, type = "S", page = 0, scroll = false) => {
      const locationData: any = await getGeolocation();

      await makeGetCall({
        url: `${
          apiConfig.listings
        }/${userId}?listing_type=${type}&limit=${limit}&offset=${limit * page}${
          locationData?.isLocationAccessible
            ? `&point=${locationData?.currentLng},${locationData?.currentLat}`
            : ""
        }`,
      })
        .then((res) => {
          setResultListing(res?.results);
          setActiveTab(type);
          if (scroll) {
            applyInitialScrollPoint();
          }
          setTotalPage(Math.ceil(res?.count / limit));
          setCurrentPage(page);
          disableLoading();
        })
        .catch(() => {
          setLoading(false);
        });
    };
  }, [setResultListing, disableLoading]);

  //user details
  const fetchUserProfile = useMemo(() => {
    return async (id, isReload = true) => {
      const locationData: any = await getGeolocation();

      await makeGetCall({
        url: `${apiConfig.user_by_id}/${id}${
          locationData?.isLocationAccessible
            ? `?point_location=${locationData?.currentLng},${locationData?.currentLat}`
            : ""
        }`,
      })
        .then((res) => {
          setResultProfile(res.data[0]);
          window.scrollTo(0, 0);

          setFlaggedDisclaimer(res.data[0]?.isFlagged);
          if (isReload) {
            fetchListings(params?.id, activeTab, currentPage, false);
          } else {
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    };
  }, [setResultProfile]);

  useEffect(() => {
    if (selfDetails?.id === params?.id) {
      setLoading(false);
      showToast("User not allow to see details of self.", "error");
      navigate("/");
      window.scrollTo(0, 0);
    } else if (params?.id) {
      fetchUserProfile(params?.id);
    }
  }, [params?.id, fetchUserProfile]);

  //on tab change
  const handleTabChange = (tab) => {
    setLoading(true);
    fetchListings(params?.id, tab, 0, true);
  };

  const handlePaginationChange = (res) => {
    setLoading(true);
    fetchListings(params?.id, activeTab, res?.selected, true);
  };

  //responsive expand
  const handleExpand = (tab) => {
    if (expandedTab === tab) {
      setExpandedTab(null);
    } else {
      setExpandedTab(tab);
    }
  };

  const handleScroll = () => {
    if (divRef.current) {
      let fromTop = divRef.current.getBoundingClientRect().top;
      if (fromTop < 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleEventActionCallback = (id: any, type: any) => {
    switch (type) {
      case "fav":
        setResultListing((prev: any) =>
          prev.map((i: any) =>
            i.id === id ? { ...i, isFavourite: !i?.isFavourite } : i
          )
        );
        break;

      case "user_fav":
        // isFlagged
        setResultProfile((prev: any) => ({
          ...prev,
          isMarkedFavourite: !prev?.isMarkedFavourite,
        }));
        break;

      default:
        break;
    }
  };

  const handleUserFavorite = async () => {
    if (!isActionRunning) {
      setActionRunning(true);

      if (!resultProfile?.isMarkedFavourite) {
        const apiPayload = {
          user: params?.id,
          markedBy: selfDetails?.id,
        };
        makePostCall({ url: apiConfig.favourite, apiPayload })
          .then((res) => {
            setActionRunning(false);
            handleEventActionCallback("", "user_fav");
            showToast(res.status.message, "success");
          })
          .catch(() => {
            setActionRunning(false);
          });
      } else {
        const apiPayload = {
          user: params?.id,
        };
        makeDeleteCall({ url: apiConfig.favourite, apiPayload })
          .then((res) => {
            setActionRunning(false);
            handleEventActionCallback("", "user_fav");
            showToast(res.status.message, "success");
          })
          .catch(() => {
            setActionRunning(false);
          });
      }
    }
  };

  const sendRequest = () => {
    setLoading(true);
    makePostCall({ url: `auth/${params?.id}/connections/` })
      .then((res) => {
        fetchUserProfile(params?.id, false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const acceptRequest = () => {
    setLoading(true);
    makePatchCall({
      url: `${apiConfig.accept_connection_request}/${resultProfile?.connectionStatus?.connectionId}`,
      apiPayload: { status: "A" },
    })
      .then((res) => {
        fetchUserProfile(params?.id, false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const rejectRequest = () => {
    setLoading(true);
    makePatchCall({
      url: `${apiConfig.accept_connection_request}/${resultProfile?.connectionStatus?.connectionId}`,
      apiPayload: { status: "R" },
    })
      .then((res) => {
        fetchUserProfile(params?.id, false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const deleteRequest = () => {
    setLoading(true);
    makeDeleteCall({
      url: `${apiConfig.accept_connection_request}/${resultProfile?.connectionStatus?.connectionId}`,
      apiPayload: { status: "R" },
    })
      .then((res) => {
        fetchUserProfile(params?.id, false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleRemove = () => {
    setConfirmRemove(true);
    setConnectedOption(false);
  };

  const connectActions = (status) => {
    switch (status) {
      case "Connect":
        return (
          <div className="actions">
            <button className="primary_btn" onClick={sendRequest}>
              Connect
              {/* <img src="/static/connect/connect.svg" alt="" /> */}
            </button>
          </div>
        );
      case "Accept request":
        return (
          <div className="actions">
            <button className="trinary_btn" onClick={rejectRequest}>
              Decline
            </button>
            <button className="primary_btn" onClick={acceptRequest}>
              Accept
            </button>
          </div>
        );
      case "Connected":
        return (
          <div className="actions">
            <div className="connected_pop" ref={dropdownRef}>
              <button
                className="primary_btn"
                onClick={() => setConnectedOption(true)}
              >
                Connected
                <img src="/static/connect/expandMore.svg" alt="" />
              </button>
              {connectedOption && (
                <div className="option_drop">
                  <div className="options_data" onClick={handleRemove}>
                    Remove connection
                  </div>
                </div>
              )}
            </div>
            <button
              className="secondary_btn"
              onClick={() =>
                navigate(
                  resultProfile?.chatRoom === 2
                    ? `/chat?chatId=${resultProfile?.chatRoom}`
                    : `/chat?userId=${resultProfile?.id}`
                )
              }
            >
              Message <img src="/static/connect/message.svg" alt="" />
            </button>
          </div>
        );
      case "Pending":
        return (
          <div className="actions">
            {/* <button className="trinary_btn">Requested</button> */}
            <button className="trinary_btn" onClick={deleteRequest}>
              Cancel
            </button>
            <button
              className="primary_btn"
              disabled
              style={{
                cursor: "not-allowed",
              }}
            >
              Pending
            </button>
          </div>
        );
      default:
        return (
          <div className="actions">
            <button className="primary_btn" onClick={sendRequest}>
              Connect
              {/* <img src="/static/connect/connect.svg" alt="" /> */}
            </button>
          </div>
        );
    }
  };

  const handleDocumentsClick = (data) => {
    setQualificationDetails(data);
    setQualificationModal(true);
  };

  return (
    <div>
      <GlobalHeader2 />
      {activeLoading && <LoadingLayer />}
      <div className="user_outer_layer_main">
        <div className="user_left_wrap">
          <div className="user_left_container">
            <div className="profile_cover_wrapper">
              {resultProfile?.coverPhotoVideo ? (
                <MediaContainer
                  className={"custom_media"}
                  documentName={
                    resultProfile?.coverPhotoVideo ||
                    "/static/connect/avatar.svg"
                  }
                  documentFileType={
                    resultProfile?.coverExtension || "image/svg"
                  }
                  uploadDocumentUrl={
                    resultProfile?.coverPhotoVideo ||
                    "/static/connect/avatar.svg"
                  }
                  preview
                  // mouseHover
                />
              ) : (
                <div
                  className="custom_media"
                  style={{
                    borderRadius: "12px 12px 0px 0px",
                    background: "rgb(68, 78, 78)",
                  }}
                />
              )}
              <img
                src={
                  resultProfile?.profilePicture || "/static/connect/avatar.svg"
                }
                alt=""
                className="profile_image_wrap"
              />

              <img
                src={
                  resultProfile?.isMarkedFavourite
                    ? "/static/listing/heartA.svg"
                    : "/static/listing/heart.svg"
                }
                alt=""
                className="liked_btn"
                onClick={handleUserFavorite}
              />
              <img
                src="/static/connect/flag.svg"
                alt=""
                className="flag_btn"
                onClick={() => setToggleFlag(true)}
              />
            </div>
            <div className="profile_details_wrap">
              <div className="tag_wrapper">
                <div className="user_type_tag">
                  {gettingUserMemberType(
                    resultProfile?.isTrainer,
                    resultProfile?.userType
                  )}
                </div>
              </div>
              <div className="mates_name">{resultProfile?.name || ""}</div>
              <div className="gender_section">
                <div
                  className="body_text"
                  style={{ justifyContent: "flex-end" }}
                >
                  <img src="/static/connect/birthday.svg" alt="" />
                  {gettingAge(resultProfile?.dob) || "NA"}
                </div>
                <div className="body_text">
                  <img
                    src={gettingGenderIcon(resultProfile?.gender)}
                    alt=""
                    style={{ justifyContent: "flex-start" }}
                  />
                  {gettingGender(resultProfile?.gender) || "NA"}
                </div>
              </div>
              <div className="distance">
                <img src="/static/connect/locationPrimary.svg" alt="" />
                {resultProfile?.distance === null
                  ? "Location not available"
                  : gettingDistance(resultProfile?.distance || 0)}
              </div>
              {connectActions(resultProfile?.connectionStatus?.status)}
            </div>

            <div className="personal_experience_session">
              {/* //bio section */}
              <div className="description_session">
                <div className="dec_title">Personalised blurb</div>
                <div className="dec_body">
                  <ReadMore data={resultProfile?.bio || ""} />
                </div>
              </div>
              {/* //interest section */}
              {resultProfile?.interest?.length > 0 && (
                <div className="interest_session">
                  <div className="interest_title">
                    <span>Interests selected by user</span>
                    <ExpandIcon
                      onClick={() => handleExpand("I")}
                      expanded={expandedTab === "I"}
                      className={"resp_expand"}
                    />
                  </div>
                  <div
                    className={
                      expandedTab === "I"
                        ? "interest_chip_container"
                        : "responsive_action interest_chip_container"
                    }
                  >
                    {resultProfile?.interest
                      ?.slice(
                        0,
                        seeAllInterest ? resultProfile?.interest?.length : 6
                      )
                      .map((val, idx) => (
                        <div className="chip_interest" key={"interest" + idx}>
                          {val?.title || ""}
                        </div>
                      ))}
                    {resultProfile?.interest?.length > 6 && !seeAllInterest && (
                      <div
                        className="chip_interest special"
                        onClick={() => setSeeAllInterest(true)}
                      >{`${resultProfile?.interest?.length - 6}+more`}</div>
                    )}
                  </div>
                </div>
              )}
              {/* //qualification section  */}
              {resultProfile?.qualifications?.length > 0 && (
                <div className="qualification_session">
                  <div className="equ_title">
                    <span>{`Experience & Qualifications (${resultProfile?.qualifications?.length})`}</span>
                    <ExpandIcon
                      onClick={() => handleExpand("Q")}
                      expanded={expandedTab === "Q"}
                      className={"resp_expand"}
                    />
                  </div>
                  <div
                    className={
                      expandedTab === "Q"
                        ? "qual_card_wrap"
                        : "responsive_action qual_card_wrap"
                    }
                  >
                    {resultProfile?.qualifications
                      ?.slice(
                        0,
                        seeAllQualification
                          ? resultProfile?.qualifications?.length
                          : 4
                      )
                      .map((val, idx) => (
                        <div className="qual_card" key={"qual" + idx}>
                          <div className="card_header">
                            <div className="title_card">
                              {val?.interestTitle || ""}
                            </div>
                            {val?.documentsCount > 0 && (
                              <button
                                className="action_qual_btn"
                                onClick={() =>
                                  handleDocumentsClick(val?.documents)
                                }
                              >
                                <div className="btn_text">
                                  {"Check document"}
                                </div>
                                <img
                                  src="/static/connect/arrowMidTop.svg"
                                  alt=""
                                />
                              </button>
                            )}
                          </div>
                          <div className="card_duration">
                            {gettingDuration(val?.duration)}
                          </div>
                          {val?.interestInformation && (
                            <div className="card_desc">
                              <ReadMore data={val?.interestInformation || ""} />
                            </div>
                          )}
                        </div>
                      ))}
                    {resultProfile?.qualifications?.length > 4 && (
                      <div className="see_btn_wrap">
                        <button
                          className="btn_see"
                          onClick={() =>
                            setSeeAllQualification((prev) => !prev)
                          }
                        >
                          {seeAllQualification ? "See less" : "See all"}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="user_right_wrap">
          <div className="header_user_listings">
            <div className="header_title" ref={divRef}>
              Created Listings
            </div>
            <div
              // className="listing_tabs_wrap"
              className={
                isSticky ? "sticky listing_tabs_wrap" : "listing_tabs_wrap"
              }
            >
              {TAB_DATA?.map(
                ({ value, activeIcon, inactiveIcon, title }, idx) => (
                  <div
                    className={`${
                      activeTab === value ? "active_tab" : ""
                    } tabs_container`}
                    key={title + value}
                    onClick={() => handleTabChange(value)}
                  >
                    {}
                    <img
                      className="tabs_icon"
                      src={activeTab === value ? activeIcon : inactiveIcon}
                      alt=""
                    />
                    <div className="tab_title">{title}</div>
                  </div>
                )
              )}
            </div>
          </div>
          <EventsContainer
            listing={resultListing}
            handleEventActionCallback={handleEventActionCallback}
          />
          <div className="pagination_container">
            <Pagination
              pageCount={totalPage}
              currentPage={currentPage}
              handlePageClick={handlePaginationChange}
            />
          </div>
        </div>
      </div>

      {toggleFlag ? (
        <FlagUserModal
          setShowFlagUser={() => setToggleFlag(false)}
          onCancel={() => setToggleFlag(false)}
          onOk={() =>
            navigate("/", {
              replace: true,
            })
          }
          userProfile={resultProfile}
        />
      ) : null}

      {flaggedDisclaimer ? (
        <UserFlaggedModal
          onOk={() =>
            navigate("/", {
              replace: true,
            })
          }
        />
      ) : null}
      {confirmRemove && (
        <RemoveConnectionModal
          setShowRemoveConnection={setConfirmRemove}
          userProfile={resultProfile}
          onOk={() => fetchUserProfile(params?.id, false)}
          onCancel={() => setConfirmRemove(false)}
        />
      )}

      {toggleQualification && (
        <QualificationModal
          details={qualificationDetails}
          onCancel={() => {
            setQualificationModal(false);
            setQualificationDetails(null);
          }}
        />
      )}

      <GlobalFooter />
    </div>
  );
};

export default ConnectUserProfile;
