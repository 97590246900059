import { useState } from "react";
import GlobalFooter from "../Dashboard/GlobalFooter";
import GlobalHeader2 from "../Dashboard/GlobalHeader2";
import "./styleMembership.scss";
import { useLocation } from "react-router-dom";
import FullLoader from "../../global/FullLoader";
import UserTypeSelector from "./UserTypeSelector";
import SubscriptionPlanSelector from "./SubscriptionPlanSelector";

const UserMembership = ({ setShowPlan }: any) => {
  const [isLoading] = useState(false);
  const [socialLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const showPlanTab = queryParams.get("showPlanTab");

  return (
    <>
      <GlobalHeader2 />
      {socialLoading ? (
        <FullLoader />
      ) : (
        <>
          {showPlanTab ? (
            <SubscriptionPlanSelector />
          ) : (
            <UserTypeSelector isLoading={isLoading} setShowPlan={setShowPlan} />
          )}
        </>
      )}
      {!showPlanTab && <GlobalFooter />}
    </>
  );
};

export default UserMembership;
