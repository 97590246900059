import { Form, Modal } from "react-bootstrap";
import Button from "../../../global/Button";
import DateCounter from "../DateCounter";
import moment from "moment";
import { useEffect, useState } from "react";
import { showToast } from "../../../utils";
import { makePostCall } from "../../../utils/Requests";
import endpoints from "../../../utils/endpoints";
import {
  CalendarIcon,
  CrossIcon,
  GroupPeopleIcon,
  LocationGreyIcon,
  RightArrowIcon,
  SpClockIcon,
} from "../../../utils/Icons/svgs";
import { Calendar } from "react-multi-date-picker";

import { LISTING_TYPE } from "../../../types";

export const AllEventsCard = (props) => {
  const { bookingarr, item, selectedEvents, clickWaitlist } = props;
  const findSelectedEvent = selectedEvents?.find(
    (obj) => obj.event === item.id
  );

  const checkMe = item?.slotsAvailable !== 0 ? true : false;
  const slot = findSelectedEvent ? findSelectedEvent.slots : 1;
  const [isChecked, setIsChecked] = useState(checkMe);
  const [counter, setCounter] = useState(slot);

  const eventId = item.id;
  const findBookinngArr = bookingarr.find((obj) => obj.event === eventId);
  if (isChecked) {
    if (findBookinngArr) {
      findBookinngArr.slots = counter;
    } else {
      if (item?.slotsAvailable !== 0) {
        bookingarr.push({
          event: eventId,
          slots: counter,
        });
      }
    }
  } else {
    if (findBookinngArr) {
      const indexToRemove = bookingarr.findIndex(
        (obj) => obj.event === eventId
      );
      bookingarr.splice(indexToRemove, 1);
    }
  }
  return (
    <div
      className={` col-12 col-md-5 em-date position-relative ${isChecked ? "active" : ""
        }`}
    >
      <div className="em_date_first">
        <div className="em_actual_date">
          <p className="mb-0">
            {moment(item?.date).format("ddd")}, {moment(item?.date).date()}{" "}
            {moment(item?.date).format("MMMM")}
          </p>
          {item?.slotsAvailable === 0 && (
            <div className="em_b_full">Booking full</div>
          )}
        </div>
        <p className="mb-0 d-flex gap-2 align-items-center">
          <span>
            {item?.slotsAvailable !== 0 ? (
              <GroupPeopleIcon />
            ) : (
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 3C3 1.89543 3.89543 1 5 1C6.10457 1 7 1.89543 7 3C7 3.64476 6.6949 4.21826 6.2212 4.584C6.20695 4.59451 6.19279 4.60515 6.17873 4.6159C5.84819 4.85743 5.44074 5 5 5C3.89543 5 3 4.10457 3 3ZM9.7788 4.584C9.79305 4.59451 9.80721 4.60515 9.82127 4.61591C10.1518 4.85744 10.5593 5 11 5C12.1046 5 13 4.10457 13 3C13 1.89543 12.1046 1 11 1C9.89543 1 9 1.89543 9 3C9 3.64476 9.3051 4.21826 9.7788 4.584ZM6.26756 6C6.61337 5.4022 7.25972 5 8 5C8.74028 5 9.38663 5.4022 9.73244 6C9.90261 6.29417 10 6.63571 10 7C10 8.10457 9.10457 9 8 9C6.89543 9 6 8.10457 6 7C6 6.63571 6.09739 6.29417 6.26756 6ZM2.49998 6L5.17071 6C5.06015 6.31278 5 6.64936 5 7C5 7.76835 5.28885 8.46924 5.76389 9H5.49998C4.41484 9 3.4912 9.69139 3.14547 10.6576C2.80551 10.5193 2.49098 10.3386 2.21215 10.1148C1.45897 9.51027 1 8.61608 1 7.5C1 6.67161 1.67156 6 2.49998 6ZM10.5 9C11.5851 9 12.5088 9.69137 12.8545 10.6576C13.1945 10.5193 13.509 10.3386 13.7879 10.1148C14.541 9.51027 15 8.61608 15 7.5C15 6.67157 14.3284 6 13.5 6L10.8293 6C10.9398 6.31278 11 6.64936 11 7C11 7.76835 10.7111 8.46924 10.2361 9H10.5ZM11.8871 10.928C11.9598 11.1043 12 11.2975 12 11.5C12 12.6161 11.541 13.5103 10.7879 14.1148C10.0466 14.7098 9.05308 15 8 15C6.94692 15 5.95342 14.7098 5.21215 14.1148C4.45897 13.5103 4 12.6161 4 11.5C4 11.2975 4.04015 11.1043 4.11292 10.928C4.33778 10.3833 4.87411 10 5.49998 10L10.5 10C11.1259 10 11.6622 10.3833 11.8871 10.928Z"
                  fill="#25272D"
                />
              </svg>
            )}
          </span>
          <p className="mb-0 em_slots">
            {props.detailsData?.maxParticipants - item.slotsAvailable}
            <span style={{ color: "black" }}>
              /{props.detailsData?.maxParticipants}
            </span>
          </p>
        </p>
      </div>

      {item?.slotsAvailable !== 0 ? (
        <div className=" em-date-second d-flex align-items-center gap-3">
          {isChecked ? (
            <DateCounter
              counter={counter}
              setCounter={setCounter}
              maxSlot={item?.slotsAvailable}
            />
          ) : (
            ""
          )}

          <Form.Check
            className={`checked_cont ${isChecked ? "active" : ""}`}
            type="checkbox"
            bsPrefix="checked_cont"
            checked={isChecked}
            onChange={() => {
              setIsChecked(!isChecked);
              setCounter(1);
            }}
          ></Form.Check>
        </div>
      ) : (
        <div
          className="em_whislist cursor-pointer "
          onClick={() => clickWaitlist(item?.id)}
        >
          Add to waitlist
        </div>
      )}
    </div>
  );
};

const SessionPopup = (props) => {
  const { selectedEvents, allEvents, realAllEventsData } = props;
  const [selectedDates, setSelectedDates] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);

  let bookingarr = [];
  useEffect(() => {
    const newarr = [];
    for (let i = 0; i < realAllEventsData?.length; i++) {
      newarr.push(realAllEventsData?.[i]?.date);
    }
    setSelectedDates(newarr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateChange = (date) => {
    let dateArray = [];
    for (let i = 0; i < date?.length; i++) {
      dateArray.push(date?.[i]?.format());
    }
    setSelectedDates(dateArray);
  };

  const handleConfirmDate = () => {
    bookingarr = [];
    const newarray = [];
    realAllEventsData.length = 0;
    if (selectedDates?.length === 0) {
      showToast("Please select at least one event.", "error");
      return;
    }
    for (let i = 0; i < selectedDates?.length; i++) {
      const newdata = allEvents?.find((obj) => obj.date === selectedDates?.[i]);
      newarray.push(newdata);
      realAllEventsData.push(newdata);
    }

    setShowCalendar(false);
  };

  const handleWaitlist = (eventId) => {
    const data = {
      listing_type: LISTING_TYPE.S,
      event: eventId,
    };

    makePostCall({ url: endpoints.listings_waitlist, apiPayload: data })
      .then((res) => {
        showToast(res?.status?.message, "success");
      })
      .catch((err) => showToast(err, "error"));
  };

  const handleBooking = () => {
    if (bookingarr.length < 1) {
      showToast("Please select at least one event.", "error");
      return;
    }

    const data = {
      booking_data: bookingarr,
    };

    makePostCall({ url: endpoints.listings_intermediate, apiPayload: data })
      .then((res) => {
        props.onHide();
      })
      .catch((err) => console.error(err));
    props.setSelectedEvents(bookingarr);
  };

  let alleventData = [];
  if (allEvents) {
    for (let i = 0; i < allEvents?.length; i++) {
      alleventData.push(allEvents?.[i]?.date);
    }
  }

  return (
    <Modal {...props} centered dialogClassName="width-734">
      <Modal.Body style={{ height: "fit-content" }}>
        {(props?.detailsData.listingType === LISTING_TYPE.S ||
          props?.detailsData.listingType === LISTING_TYPE.SPECIAL) && (
            <div className="emodal_wrapper">
              <div className="eheading position-relative d-flex justify-content-center align-items-center">
                <div className="">
                  <p className="e_head_txt">Sessions</p>
                  <p className="e_subhead_txt">
                    You can view or edit sessions from here
                  </p>
                </div>
                <span
                  className="position-absolute eclose_btn"
                  onClick={props.onHide}
                >
                  <CrossIcon />
                </span>
              </div>
              <div className="edates_wrapper">
                <div className="e_all_dates_container row gx-0 gap-3">
                  {!showCalendar ? (
                    realAllEventsData?.map((item, i) => {
                      return (
                        <AllEventsCard
                          bookingarr={bookingarr}
                          item={item}
                          i={i}
                          key={i}
                          selectedEvents={selectedEvents}
                          detailsData={props.detailsData}
                          realAllEventsData={realAllEventsData}
                          clickWaitlist={(eventId) => handleWaitlist(eventId)}
                        />
                      );
                    })
                  ) : (
                    <div className="e_date_calendar">
                      <Calendar
                        mapDays={({ date }) => {
                          let props = {};
                          props.style = {
                            backgroundColor: "",
                            color: "",
                          };
                          props.disabled = false;
                          if (alleventData.includes(date.format())) {
                            props.style.backgroundColor = "#FCE4D6";
                          }
                          if (!alleventData.includes(date.format())) {
                            props.disabled = true;
                          }
                          return props;
                        }}
                        value={selectedDates}
                        onChange={handleDateChange}
                        minDate={new Date()}
                        multiple={true}
                        showOtherDays={true}
                        format={"YYYY-MM-DD"}
                      />
                      <div className="calendar-info-cont">
                        <div className="c-info-cont">
                          <div className="c-color-1"></div>
                          <p className="c-a-info">Event Occurrence Dates</p>
                        </div>
                        <div className="c-info-cont">
                          <div className="c-color-2"></div>
                          <p className="c-a-info">Event date selected</p>
                        </div>
                        <div className="c-info-cont">
                          <div className="c-color-3"></div>
                          <p className="c-a-info">Not Available</p>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* RestEventsData?.map((item, i) => {
                                return <RestEventCard bookingarr={bookingarr} item={item} key={i} i={i} />
                            }) */}
                </div>
                {!showCalendar ? (
                  <div
                    className="schedule_event_dates my-3"
                    onClick={() => setShowCalendar(true)}
                  >
                    <div className="d-flex align-items-center py-4 px-2 gap-1">
                      <span>
                        <CalendarIcon />
                      </span>
                      <p className="mb-0 sch_txt">Add new dates</p>
                    </div>
                    <div>
                      <RightArrowIcon />
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {!showCalendar ? (
                  <Button
                    btnTxt="Save Changes"
                    className={"backtologin"}
                    onClick={() => {
                      handleBooking();
                    }}
                  />
                ) : (
                  <Button
                    btnTxt="confirm"
                    className={"backtologin mt-4"}
                    onClick={() => {
                      handleConfirmDate();
                    }}
                  />
                )}
              </div>
            </div>
          )}

        {props?.detailsData?.listingType === LISTING_TYPE.M && (
          <div className="session_p_modal">
            <div className="sp_c_icon mb-3">
              <span>
                <CalendarIcon width="48" height="48" />
              </span>
            </div>
            <div className="sp_txt">
              <p className="sp_title mb-0">session</p>
              <p className="sp_subtitle mb-0">
                Please check all the available events
              </p>
            </div>
            <div className="mx-auto  ">
              {props?.detailsData?.events?.map((item, i) => {
                return (
                  <div className="sp_date_container mx-auto mb-3">
                    <div className="sp_only_date">
                      <p className="sp_date_month mb-0">
                        {moment(item?.date).format("MMMM")}
                      </p>
                      <p className="sp_date_date mb-0">
                        {moment(item?.date).date()}
                      </p>
                      <p className="sp_date_day mb-0">
                        {moment(item?.date).format("ddd")}
                      </p>
                    </div>
                    {/* <div></div> */}
                    <hr />
                    <div className="sp_info">
                      <div className="sp_title">{item.title} </div>
                      <div className="sp_loc">
                        <div className="d-flex align-items-center">
                          <LocationGreyIcon />
                        </div>
                        <div className="loc">{item?.address?.address}</div>
                        <div className="sp_map">open maps</div>
                      </div>
                      <div className="sp_time">
                        <SpClockIcon />{" "}
                        {moment(item?.startTime?.substring(0, 5), [
                          "HH:mm",
                        ]).format("hh:mmA")}
                        -{" "}
                        {moment(item?.endTime?.substring(0, 5), [
                          "HH:mm",
                        ]).format("hh:mmA")}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="d-flex justify-content-center">
              <Button
                btnTxt="Book"
                className={"backtologin "}
                style={{ width: "350px" }}
                onClick={() => {
                  props.onHide();
                }}
              />
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default SessionPopup;
