const ArrowForward: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2673 0.20953C9.96736 -0.0761565 9.49262 -0.0646132 9.20694 0.235314C8.92125 0.53524 8.93279 1.00997 9.23272 1.29566L15.484 7.25015L0.75 7.25015C0.335787 7.25015 1.00313e-07 7.58594 9.53739e-08 8.00015C9.04345e-08 8.41437 0.335787 8.75015 0.75 8.75015L15.4844 8.75015L9.23272 14.705C8.93279 14.9907 8.92125 15.4654 9.20694 15.7653C9.49262 16.0653 9.96736 16.0768 10.2673 15.7911L17.6862 8.72441C17.8553 8.56339 17.9552 8.35797 17.9861 8.14443C17.9952 8.09774 18 8.0495 18 8.00015C18 7.95046 17.9952 7.90189 17.9859 7.8549C17.9548 7.64183 17.8549 7.43692 17.6862 7.27623L10.2673 0.20953Z"
        fill="url(#paint0_linear_10363_3903)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10363_3903"
          x1="-2.67654"
          y1="18.91"
          x2="31.538"
          y2="10.0695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EE7830" />
          <stop offset="1" stop-color="#EE762F" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ArrowForward;
