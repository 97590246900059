import React from "react";
const TimeIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({color="#8F939A"}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2ZM9.5 5C9.22386 5 9 5.22386 9 5.5L9 10.5L9.00806 10.5899C9.05039 10.8231 9.25454 11 9.5 11H12.5L12.5899 10.9919C12.8231 10.9496 13 10.7455 13 10.5C13 10.2239 12.7761 10 12.5 10H10V5.5L9.99194 5.41012C9.94961 5.17688 9.74546 5 9.5 5Z"
        fill={color}
      />
    </svg>
  );
};

export default TimeIcon;
