import React, { useState } from "react";
import "./style.scss";
import FilePreview from "../../ConnectUserProfile/FilePreview";

type Props = {
  [key: string]: any;
};

const MediaContainer = (props: Props) => {
  const {
    className = "",
    documentName = "",
    documentFileType = "",
    uploadDocumentUrl = "",
    preview = false,
    controls = false,
    autoPlay = false,
    muted = true,
    mouseHover = false,
  } = props;

  const [mediaLoading, setMediaLoading] = useState(true);
  const [enablePreview, setEnablePreview] = useState(false);

  const handleMouseEnter = (e: React.MouseEvent<HTMLVideoElement>) => {
    const videoElement = e.currentTarget;
     
    if (mouseHover && videoElement && typeof videoElement.play === "function") {
      try {
        videoElement.play();
      } catch (error) {
        console.error("Error playing the video:", error);
      }
    }
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLVideoElement>) => {
    const videoElement = e.currentTarget;

    if (
      mouseHover &&
      videoElement &&
      typeof videoElement.pause === "function"
    ) {
      try {
        videoElement.pause();
      } catch (error) {
        console.error("Error pausing the video:", error);
      }
    }
  };

  const handlePreview = () => {
    setEnablePreview(true);
  };

  if (
    /\.(mp4|mov|avi)$/.test(documentName?.toLowerCase()) ||
    documentFileType?.startsWith("video")
  ) {
    return (
      <div className={`${className || ""} media_wrapper event_media`} style={{height:"230px", borderRadius:"12px 12px 0px 0px"}}>
        <video
          key={documentName}
          src={uploadDocumentUrl}
          playsInline
          className={className || "event_media"}
          loop
          autoPlay={autoPlay}
          disablePictureInPicture
          controlsList="nodownload noplaybackrate nofullscreen"
          muted={muted}
          controls={controls}
          style={{ cursor: "pointer" ,borderRadius:"12px 12px 0px 0px", height:"230px"}}
          onLoadedData={() => setMediaLoading(false)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <source src={uploadDocumentUrl}
          onLoadedData={() => setMediaLoading(false)}
           />
        </video>
        {mediaLoading && (
          <div
            className={`${className || ""} media_loading_wrapper event_media` } style={{height:"230px",borderRadius:"12px 12px 0px 0px"}}
          >
            <img
              src="/static/connect/spinner.svg"
              className="media_loading"
              alt=""
            />
          </div>
        )}
        {preview && (
          <img
            src="/static/connect/videoPause.svg"
            className="media_preview"
            alt=""
            onClick={handlePreview}
          />
        )}
        {enablePreview && (
          <FilePreview
            show={enablePreview}
            handleClose={() => setEnablePreview(false)}
            data={{
              documentName,
              documentFileType,
              uploadDocumentUrl,
            }}
          />
        )}
      </div>
    );
  } else if (
    /\.(jpg|jpeg|svg|ico|gif|png|webp|heic|bmp|tif|tiff)$/.test(
      documentName?.toLowerCase()
    ) ||
    documentFileType?.startsWith("image")
  ) {
    return (
      <div className={`${className || ""} media_wrapper event_media`} style={{height:"230px", borderRadius:"12px 12px 0px 0px"}}>
        <img
          key={documentName}
          src={uploadDocumentUrl}
          className={className || "event_media"}
          loading="lazy"
          alt=""
          style={{borderRadius:"12px 12px 0px 0px", height:"230px"}}
          
          onLoad={() => setMediaLoading(false)}
        />
        {mediaLoading && (
          <div
            className={`${className || ""} media_loading_wrapper event_media`} style={{height:"230px", borderRadius:"12px 12px 0px 0px"}}
          >
            <img
              height="230px"
              src="/static/connect/spinner.svg"
              className="media_loading"
              alt=""
            />
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className={`${className || ""} media_wrapper event_media`} style={{height:"230px", borderRadius:"12px 12px 0px 0px"}}>
        {mediaLoading && (
          <div
            className={`${
              className || ""
            } media_loading_wrapper event_media not_supported`}
            style={{height:"230px", borderRadius:"12px 12px 0px 0px"}}
          >
            File not supported
          </div>
        )}
      </div>
    );
  }
};

export default MediaContainer;
