/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component } from "react";
import { googleKey } from "../utils/Constants";
import axios from "axios";
import InputField from "./InputField";
import { apiConstants } from "../utils/Constants";
import {
  LocationGreyFillIcon,
  LocationGreyIcon,
  RightArrowStickIcon,
} from "../utils/Icons/svgs";
//import Button from "./Button";
import Button from "../global/Button";
import { showToast } from "../utils";

class GooglePlacesSearchBarFilterSidebar extends Component<Readonly<any>, any> {
  autocompleteInput: any = React.createRef();
  autocomplete: any = null;
  mapContainer: any = React.createRef();
  marker: any = null;
  map: any = null;

  constructor(props: Readonly<any>) {
    super(props);
    this.state = {
      radius: 5000,
      lat: "",
      lng: "",
    };
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
    this.getCurrentLocation = this.getCurrentLocation.bind(this);
  }

  componentDidMount() {
    if (googleKey) {
      let lng;
      let lat;
      if (this.props.location?.split("POINT ").length > 1) {
        lng = Number(
          this.props.location
            ?.split("POINT ")?.[1]
            ?.split(" ")?.[0]
            ?.substring(1)
        );
        lat = Number(
          this.props.location
            ?.split("POINT ")?.[1]
            ?.split(" ")?.[1]
            .slice(0, -1)
        );
      }
      const options = {};
      this.autocomplete = new google.maps.places.Autocomplete(
        this.autocompleteInput.current,
        options
      );
      this.autocomplete.addListener("place_changed", this.handlePlaceChanged);
      // this.autocomplete.addListener("value_changed", this.handleAutocomplete);
    }
    this.map = new google.maps.Map(this.mapContainer.current, {
      zoom: 15, // Or any default zoom level you prefer
      center: {
        lat: Number(
          this.props.location
            ?.split("POINT ")?.[1]
            ?.split(" ")?.[1]
            .slice(0, -1)
        ),
        lng: Number(
          this.props.location
            ?.split("POINT ")?.[1]
            ?.split(" ")?.[0]
            ?.substring(1)
        ),
      }, // Default center, can be any lat-lng
      draggable: false,
      fullscreenControl: false,
      mapTypeControl: false,
      zoomControl: false,
      streetViewControl: false,
    });
    // this.createRadiusSelectorControl();
    var cordinates = {
      lat: Number(
        this.props.location?.split("POINT ")?.[1]?.split(" ")?.[1].slice(0, -1)
      ),
      lng: Number(
        this.props.location?.split("POINT ")?.[1]?.split(" ")?.[0]?.substring(1)
      ),
    };
    // Otherwise, create a new marker for the current location
    this.marker = new google.maps.Marker({
      map: this.map,
      position: cordinates,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // Compare the previous props/state with current props/state
    // For example, if you want to check if a specific prop 'value' has changed
    console.log(this.autocompleteInput?.current?.value);
    if (
      this.autocompleteInput?.current?.value === "" &&
      this.state.recentLoc === true
    ) {
      // Call your function here
      this.setState({ recentLoc: false });
    }
  }

  componentWillUnmount() {
    if (this.autocomplete) {
      google.maps.event.clearInstanceListeners(this.autocomplete);
    }
  }

  handlePlaceChanged() {
    this.setState({ recentLoc: true });
    const place = this.autocomplete.getPlace();
    if (place && place.geometry) {
      let postalCode = 2000,
        region = "";
      const address_components = place.address_components;
      // eslint-disable-next-line array-callback-return
      address_components.map((component: any) => {
        if (component.types.includes("postal_code")) {
          postalCode = component.short_name || component.long_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
          region = component.short_name || component.long_name;
        }
        if (component.types.includes("country")) {
          region = component.long_name || component.short_name;
        }
      });
      // Update the map's center to the selected place's location
      this.map.setCenter(place.geometry.location);
      this.map.setZoom(15); // Optional: Set a preferred zoom level

      // If a marker already exists, set its position to the new location
      if (this.marker) {
        this.marker.setPosition(place.geometry.location);
      } else {
        // Otherwise, create a new marker
        this.marker = new google.maps.Marker({
          map: this.map,
          position: place.geometry.location,
        });
      }

      this.props.onChangeLocation(
        place.geometry.location.lat(),
        place.geometry.location.lng(),
        place.formatted_address,
        address_components
      );
    }
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          // Center the map to the current location
          this.map.setCenter({ lat, lng });
          // If a marker already exists, set its position to the current location
          if (this.marker) {
            this.marker.setPosition({ lat, lng });
          } else {
            // Otherwise, create a new marker for the current location
            this.marker = new google.maps.Marker({
              map: this.map,
              position: { lat, lng },
            });
          }

          axios
            .get(
              `https://geocode.maps.co/reverse?lat=${lat}&lon=${lng}&api_key=669e10aa0854a480228272pbhcf0d67`
            )
            .then((res) => {
              if (res.data.display_name) {
                this.setState({ address: res.data.display_name });
                let location = this.props.handleLocation(
                  lat,
                  lng,
                  res.data.display_name,
                  [
                    {
                      long_name: res.data?.address?.city,
                      short_name: res.data?.address?.city,
                      types: ["administrative_area_level_1", "political"],
                    },
                    {
                      long_name: res.data?.address?.state,
                      short_name: res.data?.address?.state,
                      types: ["administrative_area_level_1", "political"],
                    },
                    {
                      long_name: res.data?.address?.country,
                      short_name: res.data?.address?.country,
                      types: ["administrative_area_level_1", "political"],
                    },
                  ]
                );
                this.props.setValue(
                  apiConstants.address,
                  res.data.display_name
                );
                this.props.setValue(apiConstants.lat, lat);
                this.props.setValue(apiConstants.lng, lng);
                this.props.setValue(apiConstants.postCode, location.zipcode);
                this.props.setValue(apiConstants.state, location.state);
                this.props.setValue(apiConstants.city, location.locality);
                this.props.setValue(apiConstants.country, location.country);
                this.props.onChangeLocation(lat, lng, res.data.display_name, [
                  {
                    long_name: res.data?.address?.city,
                    short_name: res.data?.address?.city,
                    types: ["administrative_area_level_1", "political"],
                  },
                  {
                    long_name: res.data?.address?.state,
                    short_name: res.data?.address?.state,
                    types: ["administrative_area_level_1", "political"],
                  },
                  {
                    long_name: res.data?.address?.country,
                    short_name: res.data?.address?.country,
                    types: ["administrative_area_level_1", "political"],
                  },
                ]);
              }
            })
            .catch((err) => {});
        },
        (error) => {
          showToast(
            "User's current location is not available.",
            "error",
            "current_location"
          );
          console.error("Error fetching geolocation:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }

  createRadiusSelectorControl = () => {
    // const radiusSelectorDiv = document.createElement("div");
    // radiusSelectorDiv.className = "radius-selector";
    // radiusSelectorDiv.innerHTML = `
    //   <span><img alt="" src ="/static/mapicon.svg"></img> </span>
    //   <select class="radiusSelect">
    //     <option value="5000" selected >5 KM</option>
    //     <option value="10000">10 KM</option>
    //     <option value="15000">15 KM</option>
    //     <option value="20000">20 KM</option>
    //   </select>
    // `;
    // radiusSelectorDiv
    //   .querySelector(".radiusSelect")
    //   .addEventListener("change", (e) => {
    //     const selectedRadius = e.target.value;
    //     this.setState({ radius: selectedRadius });
    //     this.props.onRadiusChange(selectedRadius);
    //   });
    // this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
    //   radiusSelectorDiv
    // );
  };

  handleAutocomplete() {
    const service = new window.google.maps.places.AutocompleteService();
    const input = this.autocompleteInput.current.value;
    if (input) {
      service.getQueryPredictions({ input }, (predictions, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          predictions
        ) {
          // Clear previous results
          const resultsList = document.getElementById("results");
          resultsList.innerHTML = "";

          // Display new predictions
          predictions.forEach((prediction) => {
            const li = document.createElement("div");
            li.appendChild(document.createTextNode(prediction.description));
            resultsList.appendChild(li);
          });
        } else {
          console.error(status);
        }
      });
    } else {
      const resultsList = document.getElementById("results");
      resultsList.innerHTML = "";
    }
  }

  render() {
    return (
      <div
        className="form_group google_search_bar sidebar-map"
        style={{ height: "100%" }}
      >
        <div
          ref={this.mapContainer}
          className="maps-container"
          style={{ height: "auto", width: "100%" }}
        ></div>
      </div>
    );
  }
}

export default GooglePlacesSearchBarFilterSidebar;
