import React from "react";

type Props = {
  [key: string]: any;
};

const ExpandIcon = ({ expanded = false, ...others }: Props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
      {...others}
    >
      <circle
        cx="16"
        cy="16"
        r="16"
        transform="rotate(-180 16 16)"
        fill="#25272D"
        fill-opacity="0.1"
      />
      <path
        d="M11 13.5L16 18.5L21 13.5"
        stroke="black"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
        transform={expanded ? "rotate(180 16 16)" : "rotate(0 16 16)"}
      />
    </svg>
  );
};

export default ExpandIcon;
