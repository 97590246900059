import IconPeoples from "../../assets/img/connectIcons/people-group.png";

const NoPendingTeamRequest = ({mode="Total" ,handleTableChange }) => {
  const tabs = ["Total", "Accepted", "Rejected"];
  
  return (
    <div className="no-data-wrapper">
      <div className="content-wrapper">
        <img src={IconPeoples} alt="connection-img" />
        <div className="shadow-wrap">
          <div className="shadow"></div>
        </div>

        <div className="title-text">
            {mode === tabs[0] ? "No requests to show" : mode === tabs[1] ? "No accepted request to show" : "No rejected request to show"
}
            </div>
        <div className="desc-text">
          <div>There are no requests to display at the</div>
          {mode === tabs[0] && <span>moment. Please check back later.</span> }
          {mode !== tabs[0] && <span>{"moment. Please check" + " "}</span> }
          {mode !== tabs[0] && <span
            className="click-text" 
            onClick={() => handleTableChange(tabs[0]) }
          >
            total requests.
          </span> 
          }
        </div>
      </div>
    </div>
  );
};

export default NoPendingTeamRequest;
