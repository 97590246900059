import React, { useState } from "react";

function CopyLinkButton({ url }) {
  const [label, setLabel] = useState("Copy Link");
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(`Join me on this listing.\n${url}`);
      setLabel("Copied");
    } catch (error) {
      console.error("Failed to copy:", error);
    }
  };

  return (
    <div
      onClick={handleCopy}
      style={{
        display: "flex",
        alignItems: "center",
        padding: "4px 8px",
        fontWeight: "500",
        gap: "12px",
        cursor: "pointer",
        width: "145px",
        border: "1px solid #f3f3f3",
        borderRadius: "4px",
        margin: "0 auto",
        color: label !== "Copy Link" ? "#1b70e0" : "#25272d",
      }}
    >
      <img
        alt=""
        src={
          label !== "Copy Link"
            ? "/static/green-checkmark-line-icon.svg"
            : "/static/text-documents-dual-tone-icon.svg"
        }
        width="20px"
        height="20px"
      />
      <span>{label}</span>
    </div>
  );
}

export default CopyLinkButton;
