import { useState } from "react";
import FilePreview from "../FilePreview";
import "./style.scss";
interface RemoveConnection {
  onCancel: any;
  details: any;
}
const QualificationModal = (props: RemoveConnection) => {
  const { onCancel, details = null } = props;

  const [enablePreview, setEnablePreview] = useState(false);
  const [previewData, setPreviewData] = useState(null);

  const downloadFile = (filename, file) => {
    const link = document.createElement("a");
    link.href = file;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePreview = (data) => {
    setPreviewData({
      documentName: data?.documentName,
      documentFileType: data?.documentFileType,
      uploadDocumentUrl: data?.uploadDocument,
    });
    setEnablePreview(true);
  };

  const QualData = ({ data }) => {
    return (
      <div className="rows_details">
        <div className="rows_qual">
          <div className="rows_file">
            <img className="doc_img_q" src="/static/connect/doc.svg" alt="" />
            <div className="file_name_q">{data?.documentName || ""}</div>
          </div>
        </div>
        <div className="rows_action">
          <div
            className="btn_down_q"
            onClick={() =>
              downloadFile(data?.documentName, data?.uploadDocument)
            }
          >
            Download
            <img
              className="btn_img_q"
              src="/static/connect/download.svg"
              alt=""
            />
          </div>
          {(/\.(jpg|jpeg|svg|ico|gif|png|webp|heic|bmp|tif|tiff)$/.test(
            data?.uploadDocument?.toLowerCase()
          ) ||
            data?.documentFileType?.startsWith("image")) && (
            <div
              className="btn_down_q btn_preview_q"
              onClick={() => handlePreview(data)}
            >
              <span>Preview</span>
              <img
                className="btn_img_q"
                src="/static/connect/preview.svg"
                alt=""
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={"modal show"}>
      <div className="qualification_modal">
        <div className="header_qualification">
          <div className="title_header">Yoga Qualification Documents</div>
          <img
            className="close_q"
            src="/static/close.svg"
            alt=""
            onClick={onCancel}
          />
        </div>
        <div className="sub_header_qualification">
          Please download or preview the documents of selected interest.
        </div>
        <div className="tabular_details">
          {details?.map((item, index) => (
            <QualData data={item} key={"qual" + index} />
          ))}
        </div>
        <div className="button_option">
          <button className="go_btn_back" onClick={onCancel}>
            Go back
          </button>
        </div>
      </div>

      {enablePreview && (
        <FilePreview
          show={enablePreview}
          handleClose={() => setEnablePreview(false)}
          data={previewData}
        />
      )}
    </div>
  );
};

export default QualificationModal;
