import NameAge from "./NameAge";
import IconDot from "../../../assets/img/connectIcons/icon-dot.png";
import IconFlaggedUSer from "../../../assets/img/connectIcons/flagged-orange-icon.svg" ;
import { gettingDistance } from "../../../constants/helperConnectUser";

const FlaggedUserCard = ({ connectionData }) => {
  return (
    <div className="flagged-card-wrapper">
      <div className="user-detail-wraper">
        <div className="user-img-wrapper">
          {connectionData?.profilePictureExtension?.includes("video") ? (
            <video
              autoPlay
              playsInline
              loop
              muted
              src={connectionData?.profilePicture}
              className="profile-img-style vid"
            >
              <source src={"" || ""} type="video/mp4" />
            </video>
          ) : (
            <img
              src={
                connectionData?.profilePicture
                  ? connectionData.profilePicture
                  : "/static/dummyimg.svg"
              }
              className="profile-img-style"
              alt="profile-img"
            />
          )}

          <img src={IconFlaggedUSer} alt="flag" className="flag-style" />
        </div>
        <div className="name-interest-wraper">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="name-wrap">
              <NameAge connectionName={connectionData?.name} connectionAge="" />
            </div>
            <div className="role-wrapper">
              <div className="user-role">
                <div
                  style={{
                    height: "3px",
                    width: "3px",
                    borderRadius: "100%",
                    border: "none",
                    marginTop: "-3px",
                  }}
                >
                  <img src={IconDot} width="3px" height="3px" />
                </div>
                <span style={{ marginLeft: "5px", marginTop: "-3px" }}>
                  {connectionData?.isTrainer
                    ? "Trainer"
                    : connectionData?.userType}
                </span>
              </div>
            </div>
          </div>

          <div className="text-style">
            <span>{`${connectionData?.interestsCount} mutual interests`}</span>
            <span>&#8226;</span>
            <span>
              {connectionData?.distance  === null ? "Location not available" : gettingDistance(connectionData?.distance || 0)}
            </span>
          </div>
        </div>
      </div>
      {/* {mode === "flag" && (
        <div className="btn-wrap">
          <Button
            title="Unflag"
            className="unflag-btn-class"
            handleBtnClick={handleUnflagClick}
          />
        </div>
      )} */}
    </div>
  );
};

export default FlaggedUserCard;
