import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import endpoints from "../../utils/endpoints";
import { makeGetCall } from "../../utils/Requests";
import parse from "html-react-parser";
import { dateFormatter, scrollToTop, showToast } from "../../utils";
import ApiLoader from "../../global/ApiLoader";
import GlobalHeader2 from "../Dashboard/GlobalHeader2";

const Policies = ({ showTopBar = true }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const urlActiveTab = queryParams.get("activeTab");
  const [terms, setTerms] = useState("");
  const [termsDate, setTermsDate] = useState(null);
  const [privacyDate, setPrivacyDate] = useState(null);
  const [privacy, setPrivacy] = useState("");
  const [showPolicy, setShowPolicy] = useState(
    urlActiveTab ? "privacy" : "terms"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadinPol, setIsLoadingPol] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setIsLoadingPol(true);
    makeGetCall({ url: endpoints.terms })
      .then((res) => {
        if (res.status.code === 200) {
          scrollToTop()
          setTerms(res.data.content);
          setTermsDate(res.data.modifiedAt);
          setIsLoading(false);

        }
      })
      .catch((err) => {
        setIsLoading(false);

        showToast(err, "error");
      });

    makeGetCall({ url: endpoints.privacy })
      .then((res) => {
        if (res.status.code === 200) {
          scrollToTop()
          setPrivacy(res.data.content);
          setPrivacyDate(res.data.modifiedAt);
          setIsLoadingPol(false);

        }
      })
      .catch((err) => {
        setIsLoadingPol(false);
        showToast(err, "error");
      });
  }, []);

  useEffect(() => {
    if (urlActiveTab === "privacy") {
      setShowPolicy("privacy");
    } else {
      setShowPolicy("terms");
    }
    scrollToTop()
  }, [urlActiveTab]);

  const termhtmlString = terms;
  const termsElement = parse(termhtmlString);
  const privacyhtmlString = privacy;
  const privacyElement = parse(privacyhtmlString);
  return (
    <>
      <div className="container-fluid p-0">
        {showTopBar ? <GlobalHeader2 /> : ""}
        <div
          className="manage_profile container-fluid p-0 m-0"
          style={{
            overflowY:
              window.location.pathname === "/policy" ? "none" : "scroll",
          }}
        >
          <div className="profile_box policy-wrapper pt-0 pt-md-4 " style={{ minHeight: '100vh' }}>
            <div className=" tandp-options gx-0 row  d-md-none">
              <div
                onClick={() => {
                  setShowPolicy("terms");
                }}
                className=" options-title col mx-0 px-0 border-bottom"
              >
                <div className="d-flex py-4 gap-1 justify-content-center">
                  {showPolicy === "terms" ? (
                    <img src="/static/Question-circle-or-sm.png" alt="" />
                  ) : (
                    <img src="/static/Question-circle-bl-sm.png" alt="" />
                  )}
                  <div
                    className={`${showPolicy === "terms" ? "active-title" : ""
                      }`}
                  >
                    {" "}
                    Terms and Condtitons
                  </div>
                </div>
                {showPolicy === "terms" && (
                  <div className="active-or-line"></div>
                )}
              </div>

              <div
                onClick={() => {
                  setShowPolicy("privacy");
                }}
                className="  options-title col px-0 border-bottom"
              >
                <div className="d-flex py-4 gap-1 justify-content-center">
                  {showPolicy === "privacy" ? (
                    <img src="/static/Shield-or-sm.png" alt="" />
                  ) : (
                    <img src="/static/Shield-bl-sm.png" alt="" />
                  )}
                  <div
                    className={`${showPolicy === "privacy" ? "active-title" : ""
                      }`}
                  >
                    Privacy Policy
                  </div>
                </div>
                {showPolicy === "privacy" && (
                  <div className="active-or-line"></div>
                )}
              </div>
            </div>
            <div className="title d-none d-md-block">Terms and Privacy</div>
            {isLoading || isLoadinPol ? (
              <ApiLoader />
            ) : (
              <>
                <div className="title d-md-none mt-4">
                  {showPolicy === "terms"
                    ? "Terms & Conditon"
                    : "Privacy Policy"}
                </div>
                <div className="sub_title pb-4">
                  Last updated:{" "}
                  {showPolicy === "terms"
                    ? termsDate
                      ? dateFormatter(termsDate, "DD-MMM-YYYY")
                      : ""
                    : privacyDate
                      ? dateFormatter(privacyDate, "DD-MMM-YYYY")
                      : ""}
                </div>
                <div className="all-collapse-setup-with-button">
                  <div className="collapse-policy ">
                    <div
                      className={` p-0 pt-md-4 ${showPolicy === "terms"
                        ? "collapse-par collapse-first active"
                        : "collapse-par collapse-first"
                        }
`}
                    >
                      <div
                        className="collapse-header d-none d-md-block d-md-flex"
                        onClick={() => {
                          showPolicy === "terms"
                            ? setShowPolicy("")
                            : setShowPolicy("terms");
                        }}
                      >
                        <div className="collapse-header-left">
                          <img
                            src="/static/question-circle-black.svg"
                            className="defalt"
                            alt=""
                          />
                          <img
                            src="/static/question-circle-active.svg"
                            className="active"
                            alt=""
                          />
                          Terms and Conditions
                        </div>
                        <img src="/static/plus.svg" className="plus" alt="" />
                        <img
                          src="/static/minus.svg"
                          className="nagive"
                          alt=""
                        />
                      </div>
                      <div className="collapse-bottom">
                        {termhtmlString && termsElement}
                      </div>
                    </div>
                    <div
                      className={
                        showPolicy === "privacy"
                          ? "collapse-par collapse-first active pt-0 pt-md-4"
                          : "collapse-par collapse-first"
                      }
                    >
                      <div
                        className="collapse-header d-none d-md-block d-md-flex "
                        onClick={() => {
                          showPolicy === "privacy"
                            ? setShowPolicy("")
                            : setShowPolicy("privacy");
                        }}
                      >
                        <div className="collapse-header-left">
                          <img
                            src="/static/Shield-black.svg"
                            className="defalt"
                            alt=""
                          />
                          <img
                            src="/static/Shield-theme.svg"
                            className="active"
                            alt=""
                          />
                          Privacy policy
                        </div>
                        <img src="/static/plus.svg" className="plus" alt="" />
                        <img
                          src="/static/minus.svg"
                          className="nagive"
                          alt=""
                        />
                      </div>
                      <div className="collapse-bottom ">
                        {privacyhtmlString && privacyElement}
                      </div>
                    </div>
                  </div>
                  {/* <div className="continue_btn">
							<Button
								btnTxt="Continue"
								className={"backtologin"}
								onClick={() => navigate(ROUTE_CONSTANTS.login)}
							/>
						</div> */}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Policies;
