import ComingBtn from "./ComingBtn";

const Journey = () => {
  return (
    <section>
      <div className="Journey-section">
        <div className="title">
          Start your <span className="orange-span"> Fitness mates </span>
          journey today!
        </div>
        <div className="sub-title">
          Be part of the Fitness revolution – Join the movement and start
          earning today
        </div>
        <div className="download-btn-wrap">
          <ComingBtn
            text="Download on App Store"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M17.7595 12.7667C17.7695 11.9906 17.9757 11.2296 18.3588 10.5546C18.7419 9.87951 19.2896 9.31235 19.9508 8.90584C19.5307 8.30593 18.9766 7.81222 18.3324 7.46394C17.6881 7.11566 16.9715 6.92239 16.2396 6.89949C14.6781 6.73559 13.1643 7.83385 12.3686 7.83385C11.5575 7.83385 10.3324 6.91576 9.0132 6.9429C8.15988 6.97047 7.32826 7.21861 6.59936 7.66314C5.87046 8.10767 5.26914 8.73343 4.85398 9.47945C3.05562 12.593 4.39704 17.169 6.11971 19.6857C6.98161 20.9181 7.98891 22.2948 9.30695 22.2459C10.5967 22.1924 11.0784 21.4235 12.6353 21.4235C14.1777 21.4235 14.6297 22.2459 15.9745 22.2149C17.3585 22.1924 18.2304 20.977 19.0621 19.733C19.6814 18.8548 20.1579 17.8843 20.474 16.8573C19.67 16.5173 18.9838 15.948 18.5011 15.2206C18.0184 14.4932 17.7605 13.6397 17.7595 12.7667Z"
                  fill="white"
                />
                <path
                  d="M15.22 5.24581C15.9746 4.33992 16.3464 3.17557 16.2564 2C15.1035 2.12109 14.0386 2.67209 13.2738 3.54321C12.8998 3.96878 12.6134 4.46388 12.431 5.00019C12.2485 5.53651 12.1735 6.10354 12.2103 6.66885C12.7869 6.67479 13.3574 6.54981 13.8787 6.30331C14.4001 6.05682 14.8587 5.69524 15.22 5.24581Z"
                  fill="white"
                />
              </svg>
            }
            onClick={() => {
              window.open(
                "https://apps.apple.com/in/app/fitness-mates-pty-ltd/id6449068527",
                "_blank"
              );
            }}
          />
          <ComingBtn
            text="Download on Play Store"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M4.8205 6.38136L9.83649 11.4144C10.1766 11.7562 10.1766 12.3094 9.83649 12.6503L4.81399 17.7721L4.8205 6.38136ZM6.55433 3.91771L12.174 7.15593L14.1682 8.30476L12.2961 10.1834C11.956 10.5243 11.4043 10.5243 11.0642 10.1834L5.0353 4.13657C5.39492 3.76556 6.04419 3.61586 6.55433 3.91771ZM5.92459 20.2601C5.58693 20.2601 5.28671 20.1096 5.0711 19.8924L11.0642 13.8813C11.4043 13.5404 11.956 13.5404 12.2961 13.8813L14.1414 15.7331L6.52911 20.0982C6.34767 20.204 6.13857 20.2601 5.92459 20.2601ZM18.7204 13.0523L15.7035 14.8365L13.5238 12.6503C13.1837 12.3094 13.1837 11.7562 13.5238 11.4144L15.7279 9.20382L18.7302 10.9718L18.7497 10.9824C19.1297 11.1891 19.3648 11.582 19.3648 12.0092C19.3648 12.4396 19.1264 12.8342 18.7204 13.0523ZM19.6675 9.4658L7.42898 2.41332C6.97416 2.1432 6.45344 2 5.92459 2C4.56258 2 3.36981 2.92834 3.09399 4.18376C3.06389 4.30418 2.99392 4.61661 3.00042 4.91358L3.00124 19.1186C3.011 19.4066 3.08911 19.7964 3.10131 19.8436C3.44141 21.1144 4.60245 22.0013 5.92459 22.0013C6.44612 22.0013 6.95789 21.863 7.39806 21.6059L19.6415 14.5851C20.5918 14.075 21.1825 13.0913 21.1825 12.0173C21.1825 10.9547 20.6023 9.97757 19.6675 9.4658Z"
                  fill="white"
                />
              </svg>
            }
            onClick={() => {
              window.open(
                "https://play.google.com/store/apps/details?id=com.fitnessmates.mobile.app",
                "_blank"
              );
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default Journey;
