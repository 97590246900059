import React from "react";
import { apiConstants } from "../../../utils/Constants";
import "./style.scss";
import InputField from "../../../global/InputField";
import InfoCard from "../InfoCard/InfoCard";
import HorizontalLine from "../HorizontalLine/HorizontalLine";
import { STRING_CONSTANTS } from "../../../utils/StringConstant";
import { LISTING_TYPE } from "../../../types";
import GooglePlacesSearchBar from "../../../global/GooglePlaceSelector";

type Props = {
  [key: string]: any;
};

const LocationSection = (props: Props) => {
  const {
    watch,
    setIsLocationAccessible,
    setShowLocationPopup,
    isLocationAccessible,
    handleLocation,
    handleSetValue,
    setValue,
    control,
    errors,
    setLocationData,
  } = props;

  return (
    <>
      <div className="main-had">
        <h3 className="headis">
          {watch(apiConstants.listingDetails)?.type === LISTING_TYPE.S
            ? STRING_CONSTANTS.listing_location
            : watch(apiConstants.listingDetails)?.type === LISTING_TYPE.M
            ? null
            : STRING_CONSTANTS.team_location}
        </h3>
      </div>

      <div className="with-button">
        <div className="input_box">
          {watch(apiConstants.listingDetails)?.type ===
          LISTING_TYPE.M ? null : (
            <>
              <GooglePlacesSearchBar
                setIsLocationAccessible={(val) => setIsLocationAccessible(val)}
                setShowLocationPopup={(val) => setShowLocationPopup(val)}
                isLocationAccessible={isLocationAccessible}
                isListing={true}
                onChangeLocation={(
                  lat: string,
                  lng: string,
                  address: string,
                  address_components: any
                ) => {
                  let location = handleLocation(
                    lat,
                    lng,
                    address,
                    address_components
                  );
                  setLocationData(address);
                  handleSetValue(apiConstants.address, address);
                  handleSetValue(apiConstants.lat, lat);
                  handleSetValue(apiConstants.lng, lng);
                  handleSetValue(apiConstants.postCode, location.zipcode);
                  handleSetValue(apiConstants.state, location.state);
                  handleSetValue(apiConstants.city, location.locality);
                  handleSetValue(apiConstants.country, location.country);
                }}
                lat={watch(apiConstants.lat)}
                lng={watch(apiConstants.lng)}
                setValue={setValue}
                handleLocation={handleLocation}
                control={control}
                errors={errors}
                name={apiConstants.address}
                label={"Search location"}
                placeholder="Include a location"
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default LocationSection;
