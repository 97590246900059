import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { UnlinkSocialValidation } from "../components/Profile/ProfileValidations";
import { apiConstants } from "../utils/Constants";
import { STRING_CONSTANTS } from "../utils/StringConstant";
import Button from "./Button";
import InputField from "./InputField";
interface UnlinkAccountModalProps {
  onClose?: any;
  onSuccess?: (data: any) => void;
  socialLinkType: string | null;
}
const UnlinkModal = (props: UnlinkAccountModalProps) => {
  const { onClose, onSuccess, socialLinkType } = props;
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(UnlinkSocialValidation),
    mode: "onSubmit",
  });
  const [inputType, setInputType] = useState("password");
  const handleNext = () => {
    handleSubmit((data) => {
      onSuccess?.(data);
      onClose();
    })();
  };
  return (
    <div className={"modal show unlink_modal"}>
      <div className="modal-section">
        <div className="modal-top-part">
          <button className="close">
            <img
              src="/static/dismiss-cross-black.svg"
              alt=""
              onClick={onClose}
            />
          </button>
          <h5>{STRING_CONSTANTS.unlink_social_login}</h5>
          <p className="desc">
            To unlink your <b> {socialLinkType} account </b>, please provide a
            new email and password to sign back into Fitness Mates
          </p>
          <InputField
            name={apiConstants.email}
            type="email"
            control={control}
            errors={errors}
            inputLabel="Email Address"
            placeholder="Enter email"
            setValue={setValue}
          />
          <div className="pass_box">
            <InputField
              name={apiConstants.password}
              type={inputType}
              control={control}
              errors={errors}
              inputLabel="Password"
              placeholder="Enter password"
              setValue={setValue}
            />
            {inputType === "text" ? (
              <img
                src="/static/openeye.svg"
                alt=""
                className="close_eye"
                onClick={() => setInputType("password")}
              />
            ) : (
              <img
                src="/static/closeeye.svg"
                alt=""
                className="close_eye"
                onClick={() => setInputType("text")}
              />
            )}
          </div>
        </div>
        <Button
          btnTxt="Confirm"
          className={"backtologin"}
          onClick={handleNext}
        />
      </div>
    </div>
  );
};

export default UnlinkModal;
