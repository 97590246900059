const Calendar: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 0C0.671573 0 0 0.671573 0 1.5V10.5C0 11.3284 0.671573 12 1.5 12H14.5C15.3284 12 16 11.3284 16 10.5V1.5C16 0.671573 15.3284 0 14.5 0H1.5ZM4.5 6C3.67157 6 3 5.32843 3 4.5C3 3.67157 3.67157 3 4.5 3C5.32843 3 6 3.67157 6 4.5C6 5.32843 5.32843 6 4.5 6ZM2.94879 6.75H6.05121C6.57521 6.75 7 7.17479 7 7.69879C7 8.54603 6.42339 9.28454 5.60144 9.49003L5.54243 9.50478C4.85801 9.67589 4.14199 9.67589 3.45757 9.50478L3.39856 9.49003C2.57661 9.28454 2 8.54603 2 7.69879C2 7.17479 2.42479 6.75 2.94879 6.75ZM9 4.5C9 4.22386 9.22386 4 9.5 4H12.5C12.7761 4 13 4.22386 13 4.5C13 4.77614 12.7761 5 12.5 5H9.5C9.22386 5 9 4.77614 9 4.5ZM9.5 7H12.5C12.7761 7 13 7.22386 13 7.5C13 7.77614 12.7761 8 12.5 8H9.5C9.22386 8 9 7.77614 9 7.5C9 7.22386 9.22386 7 9.5 7Z"
        fill="url(#paint0_linear_10402_9832)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10402_9832"
          x1="-2.91282"
          y1="-1.78436"
          x2="1.15677"
          y2="21.8474"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EE7830" />
          <stop offset="1" stop-color="#EE762F" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Calendar;
