import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  MarkerClusterer,
  OverlayView,
} from "@react-google-maps/api";
import { googleKey } from "../../../utils/Constants";
import { EventsMapCard } from "./EventsMapCard";
import { makePostCall } from "../../../utils/Requests";
import { scrollToBottom, scrollToTop, showToast } from "../../../utils";
import Button from "../../../global/Button";
import { ROUTE_CONSTANTS } from "../../../utils/RouteConstants";

const EventsMapContainer = (props) => {
  const navigate = useNavigate();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleKey,
  });
  const [, setMap] = React.useState(null);
  const [center, setCenter] = useState<any>({
    lat: -31.397,
    lng: 151.644,
  });

  const [cordNew, setCordsNew] = useState([]);
  const [enableSelfMarker, setEnableSelfMarker] = useState(false);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const fetchCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setEnableSelfMarker(true);
        // setCurrentLat(position.coords.latitude);
        // setCurrentLng(position.coords.longitude);
        // setIslocationAccessible(true);
      },
      (err) => {
        // setIslocationAccessible(false);
        showToast(
          "We kindly ask for your location to enhance your app experience; however, we regret to inform you that without this information, we are unable to proceed further.",
          "error"
        );
      }
    );
  };
  useEffect(() => {
    let temp_array = [];
    let temp_storage = [];
    setCenter({
      lat: props.latitude || -25.397,
      lng: props.longitude || 134.644,
    });
    //console.log(props.eventMap);
    props.eventMap.map((item, index) => {
      if (item.coordinates) {
        if (
          temp_storage.includes(item.coordinates[0] + "-" + item.coordinates[1])
        ) {
          let temp_item = { ...item };
          var lat = (Math.random() * 1) / index + item.coordinates[0];
          var lng = (Math.random() * 1) / index + item.coordinates[1];
          temp_item.coordinates = [lat, lng];
          temp_array.push(temp_item);
        } else {
          temp_storage.push(item.coordinates[0] + "-" + item.coordinates[1]);
          temp_array.push(item);
        }
      }
    });
    // console.log('temp storage')
    // console.log(temp_array)
    if (props.distance != "5000") {
      setTimeout(() => {
        fetchCurrentLocation()
      }, 1000)
    }
    setCordsNew([...temp_array]);
  }, [props.latitude]);

  const [showEvent, setShowEvent] = useState(false);
  const createRadiusSelectorControl = () => {
    const radiusSelectorDiv = document.createElement("div");
    radiusSelectorDiv.className = "radius-selector";
    radiusSelectorDiv.innerHTML = `
      <span><img alt="" src ="/static/mapicon.svg"></img> </span>
      <select class="radiusSelect">
        <option value="5000" selected >5 KM</option>
        <option value="10000">10 KM</option>
        <option value="15000">15 KM</option>
        <option value="20000">20 KM</option>
      </select>
    `;

    radiusSelectorDiv
      .querySelector(".radiusSelect")
      .addEventListener("change", (e) => {
        // const selectedRadius = e.target.value;
        // this.setState({ radius: selectedRadius });
        // this.props.onRadiusChange(selectedRadius);
      });

    // window.google.maps.controls[google.maps.ControlPosition.TOP_RIGHT].push(
    //   radiusSelectorDiv
    // );
  };

  return (
    <>
      {isLoaded ? (
        <>
          <div
            style={{ position: "relative" }}
            className="events-map-container"
          >
            {/*   <div className="map-radius-button radius-selector">
              <span>
                <img alt="" src="/static/mapicon.svg"></img>{" "}
              </span>
              <select className="radiusSelect" onChange={(e) => {
                props.setDistance(e.target.value)

              }}>
                <option value="5000" selected={props.distance == "5000"}>
                  5 KM
                </option>
                <option value="10000" selected={props.distance == "10000"}>10 KM</option>
                <option value="15000" selected={props.distance == "15000"}>15 KM</option>
                <option value="20000" selected={props.distance == "20000"}>20 KM</option>
              </select>
            </div>
 */}
            <div
              className="create-listing-btn-2"
              style={{
                position: "absolute",
                bottom: "30px",
                display: "flex",
                justifyContent: " flex-end",
                right: "0",
                left: "0",
                margin: "0 auto",
                width: "300px",
                zIndex: "10",
                cursor: "pointer",
              }}
              onClick={fetchCurrentLocation}
            >
              <img src="/static/GoCurrentLocation.svg" />
            </div>

            <GoogleMap
              mapContainerStyle={{
                width: "100%",
                height: "calc(100vh - 100px)",
              }}
              zoom={6}
              onLoad={onLoad}
              onUnmount={onUnmount}
              center={center}
              options={{
                mapTypeControl: false,
                fullscreenControl: false,
                zoomControl: true,
                panControl: false,
                rotateControl: false,
                streetViewControl: false,
                minZoom:2,
                maxZoom:18,
                zoomControlOptions: {
                  position: google.maps.ControlPosition.TOP_LEFT,
                },
              }}
            >
              <MarkerClusterer minimumClusterSize={3}>
                {(clusterer) => (
                  <div>
                    {enableSelfMarker && (
                      <Marker
                        key={"selft"}
                        position={{
                          lat: center.lat,
                          lng: center.lng,
                        }}
                        icon={{
                          url: "static/CurentMarker.svg",
                          scaledSize: new window.google.maps.Size(60, 60),
                        }}
                      />
                    )}
                    {cordNew.map(
                      (item, index) =>
                        item.coordinates && (
                          <Marker
                            clusterer={clusterer}
                            key={index}
                            position={{
                              lat: item.coordinates[1],
                              lng: item.coordinates[0],
                            }}
                            onClick={() => setShowEvent(item)}
                            icon={{
                              url: "/static/chat-theme-2.svg",
                              scaledSize: new window.google.maps.Size(60, 60),
                            }}
                            // options={{ customInfo: item }}
                          />
                        )
                    )}
                  </div>
                )}
              </MarkerClusterer>
            </GoogleMap>

            {showEvent && (
              <div
                style={{ position: "absolute", bottom: "20%", left: "40%" }}
                className="custom-card-position"
              >
                <EventsMapCard
                  item={showEvent}
                  showEvent={showEvent}
                  onClickOutside={() => {
                    setShowEvent(false);
                  }}
                />
              </div>
            )}

            {/* <div
              className="create-listing-btn"
              style={{
                position: "fixed",
                bottom: " 30px",
                display: "flex",
                justifyContent: " flex-end",
                right: " 70px",
              }}
            >
              <Button
                btnImg="/static/plusadd.svg"
                btnTxt="Create Listing"
                className="add_listing_btn"
                onClick={() => {
                  navigate(ROUTE_CONSTANTS.create_listing);
                }}
              />
            </div> */}
          </div>
        </>
      ) : (
        <div>Loading....</div>
      )}
    </>
  );
};

export default EventsMapContainer;
