import Button from "../../../global/Button";
interface DraftPopUpProps {
  onClose?: any;
  handleExisting?: any;
  handleNew?: any;
  method?: any;
}
const ConfirmStripePopup = (props: DraftPopUpProps) => {
  const { onClose, handleExisting, handleNew, method } = props;

  return (
    <div className={"modal show"}>
      <div className="stripe_acc_pop">
        <div className="modal-top-part">
          <div className="header_container">
            <img src="/static/closeBorder.svg" alt="" onClick={onClose} />
          </div>

          <div className="modal_stripe_heading">
            {method === "stripe" ? "Stripe Account" : "Direct Bank Transfer"}
          </div>
          <div className="modal_stripe_subheading">
            Please select the preferred payment method for receiving the
            earnings from your organized event.
          </div>
        </div>
        <Button
          btnTxt="Choose from existing"
          className={"stripe_existing"}
          onClick={() => handleExisting(method)}
        />
        <Button
          btnTxt="Add new"
          className={"stripe_new"}
          onClick={() => handleNew(method)}
        />
      </div>
    </div>
  );
};

export default ConfirmStripePopup;
