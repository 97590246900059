import { useEffect } from "react";

export const useOutsideClick = (ref, callback) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
};

export const useOutsideClickMap = (ref, callback) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        let element = event.target;

        // Flag to indicate if the click is inside the autocomplete dropdown
        let insideAutocompleteDropdown = false;

        // Traverse up the DOM tree to check if we're inside the autocomplete dropdown
        while (element) {
          if (
            element.classList &&
            (element.classList.contains("pac-item") ||
              element.classList.contains("pac-container"))
          ) {
            insideAutocompleteDropdown = true;
            break; // Stop the loop if we find the autocomplete dropdown
          }
          element = element.parentElement; // Move up to the parent element
        }

        // Only call the callback if the click is outside the ref element and not inside the autocomplete dropdown
        if (!insideAutocompleteDropdown) {
          callback();
        }
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
};
