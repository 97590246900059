const Interest = ({ interest }) => {
  return (
    <span
      style={{
        border: "1.5px solid #F3F3F3",
        borderRadius: "38px",
        color: "#141414",
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "20px",
        padding: "2px 10px 2px 10px",
      }}
    >
      {interest}
    </span>
  );
};

export default Interest;
