import { Modal } from "react-bootstrap";

const ConnectFilter = ({ show, onHide, children, ref }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      dialogClassName="filter-popup-container"
      ref={ref}
    >
      <Modal.Body>
      {children}
      </Modal.Body>
    </Modal>
  );
};

export default ConnectFilter;
