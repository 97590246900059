/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DeleteAccountModal from "../../global/DeleteAccountModal";
import LogoutModal from "../../global/LogoutModal";
import UnlinkModal from "../../global/UnlinkModal";
import { USER_TYPE } from "../../types";
import {
  getItemFromStore,
  getUserProfile,
  isSocialLogin,
  removeAllItemFromStore,
  scrollToTop,
  showToast,
} from "../../utils";

import endpoints from "../../utils/endpoints";
import { makeGetCall, makePostCall } from "../../utils/Requests";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import { STRING_CONSTANTS } from "../../utils/StringConstant";
import GlobalHeader from "./GlobalHeader";
import { CrossIcon, ErrorInfo } from "../../utils/Icons/svgs";
import TotalEarnings from "../../assets/img/icons/TotalEarnings";
import GlobalHeader2 from "./GlobalHeader2";
import GlobalFooter from "./GlobalFooter";
import { useAppDispatch } from "../../store/store";
import { logoutSuccess } from "../../store/auth/action";

const DashboardWrapper = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [logout, setLogout] = useState(false);
  const [unLinkOption, setUnLinkOption] = useState(false);
  const [socialLinkType, setSocialLinkType] = useState<string | null>("");
  const [isProfileChange, setIsprofileChange] = useState(props.userUpdate);
  const [notShowEmailVerified, setNotShowEmailVerified] = useState(false);
  const [isEmailSend, setIsEmailSend] = useState(false);
  const [sentEmailShow, setSentEmailShow] = useState(false);
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    let apiPayload = { email: getItemFromStore("userProfile").email };
    makePostCall({ url: endpoints.logout, apiPayload })
      .then((res) => {
        if (res.status.code === 200) {
          navigate(ROUTE_CONSTANTS.login);
          removeAllItemFromStore();
          setTimeout(() => {
            dispatch(logoutSuccess());
          }, 0);
          showToast(STRING_CONSTANTS.logout_success, "success");
        }
      })
      .catch((err) => showToast(err, "error"));
  };
  const handleEmailVerified = () => {
    makeGetCall({ url: endpoints.email_verification })
      .then((res) => {
        if (res.status.code === 200) {
          setIsEmailSend(true);
          setSentEmailShow(true);
        }
      })
      .catch((err) => showToast(err, "error"));
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  useEffect(() => {
    if (!getItemFromStore("access_token")) {
      navigate("/");
    }
  }, [getItemFromStore("access_token")]);

  useEffect(() => {
    getUserProfile(navigate, props.reset);
  }, [isProfileChange, props.userUpdate, props.isUserProfileChange]);
  const handleUnlink = (data: any) => {
    let formData: any = new FormData();
    formData.append("email", data?.email);
    formData.append("password", data?.password);
    formData.append("social_link_type", socialLinkType);

    makePostCall({
      url: endpoints.unlink_account,
      apiPayload: formData,
      content_type: "multipart/form-data",
    })
      .then((res) => {
        showToast(res.status.message, "success");
        setIsprofileChange(true);
        getUserProfile(navigate, "");
        setSocialLinkType("");
      })
      .catch((err) => {
        showToast(err, "error");
        setSocialLinkType("");
      });
  };

  const getUserTypeLabel = () => {
    const  { isBusiness, isTrainer, isIndividual } = getItemFromStore("userProfile") || {};
    const userType = isBusiness
      ? USER_TYPE.BUSSINES
      : isTrainer
      ? USER_TYPE.TRAINER
      : isIndividual
      ? USER_TYPE.INDIVIDUAL
      : USER_TYPE.INDIVIDUAL;

    return userType;
  };
  return (
    <div className="profile-managment p-0 m-0 ">
      <GlobalHeader2 isUserProfileChange={props.isUserProfileChange} />
      <div className="main-section mt-3 mt-md-4 d-flex justify-content-start">
        <div
          className={`all-main-section-left ${
            props.showTab ? "d-none d-md-block " : ""
          }`}
        >
          <div className="main-section-left mx-auto">
            <div className="main-section-left-top">
              <div className="main-section-left-top-extra">
                <div className="main-section-left-top-left">
                  {getItemFromStore(
                    "userProfile"
                  )?.profilePictureExtension?.includes("video") ? (
                    <Link to={ROUTE_CONSTANTS.personal_details}>
                      <video
                        className="profile-pic"
                        autoPlay
                        playsInline
                        loop
                        muted
                        width="65px"
                        height="65px"
                        style={{ borderRadius: "100%", objectFit: "cover" }}
                        src={getItemFromStore("userProfile")?.profilePicture}
                      >
                        <source src={"" || ""} type="video/mp4" />
                      </video>
                    </Link>
                  ) : (
                    <Link to={ROUTE_CONSTANTS.personal_details}>
                      <img
                        src={
                          getItemFromStore("userProfile")?.profilePicture ||
                          "/static/dummyimg.svg"
                        }
                        width="65px"
                        height="65px"
                        style={{ borderRadius: "100%", objectFit: "cover" }}
                        alt=""
                      />
                    </Link>
                  )}
                  <div className="content-part">
                    {/* <p className="m-0">Welcome</p> */}
                    <h6 style={{ marginBottom: "0px" }}>
                      {getItemFromStore("userProfile")?.name}
                    </h6>
                    <div className="account-info-box">
                      <span>
                        {getItemFromStore("userProfile")?.userType ===
                        USER_TYPE.MEMBER ? (
                          <div
                            style={{
                              width: "fit-content",
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                              border: "1px solid #EE762F",
                              color: "#EE762F",
                              borderRadius: "8px",
                              padding: "0px 8px",
                            }}
                          >
                            <span
                              style={{
                                width: "3px",
                                height: "3px",
                                background: "#EE762F",
                                borderRadius: "50%",
                              }}
                            />
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "10px",
                                fontWeight: 400,
                                lineHeight: "16px",
                                textAlign: "center",
                              }}
                            >
                              { getUserTypeLabel()}
                            </span>
                          </div>
                        ) : // <img src="/static/member.svg" alt="" />
                        getItemFromStore("userProfile")?.userType ===
                          USER_TYPE.GUEST ? (
                          <img src="/static/guest.svg" alt="" />
                        ) : null}
                      </span>
                      {getItemFromStore("userProfile")?.userType ===
                      USER_TYPE.GUEST ? (
                        <span className="upgrade-member-link">
                          <Link to={"/subscription"}>Upgrade to Member</Link>
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="logout-desing" onClick={() => setLogout(true)}>
                  <img src="/static/sign-out.svg" alt="" />
                  <img src="/static/sign-out-theme.svg" alt="" />
                </div>
              </div>
              {getItemFromStore("userProfile")?.isEmailVerified ||
              notShowEmailVerified ? (
                ""
              ) : !isEmailSend ? (
                <div className="dl-email-verified">
                  <div>
                    <ErrorInfo />
                  </div>
                  <div>
                    <p className=" dl-title mb-0">
                      Please verify your email address.
                    </p>
                    <p
                      className=" dl-subtitle mb-0 cursor-pointer"
                      onClick={() => handleEmailVerified()}
                    >
                      Resend verification email
                    </p>
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setNotShowEmailVerified(true);
                    }}
                  >
                    <CrossIcon width="16" height="16" fill="white" />
                  </div>
                </div>
              ) : sentEmailShow ? (
                <div className="dl-email-verified active">
                  <div>
                    <ErrorInfo />
                  </div>
                  <div>
                    <p className=" dl-title mb-0">
                      We’ve sent you a verification email.
                    </p>
                    <p
                      className=" dl-subtitle mb-0 cursor-pointer"
                      onClick={() => handleEmailVerified()}
                    >
                      We’ve sent you a verification email.
                    </p>
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setSentEmailShow(false);
                    }}
                  >
                    <CrossIcon width="16" height="16" fill="white" />
                  </div>
                </div>
              ) : null}
            </div>

            {getItemFromStore("userProfile")?.userType === USER_TYPE.MEMBER ? (
              <div className="main-section-left-mid">
                <div className="main-section-card">
                  <TotalEarnings />
                  <div className="earning_text">
                    Total Earnings:
                    <span>
                      ${getItemFromStore("userProfile")?.totalEarnings || 0}
                    </span>
                  </div>
                </div>
                {/* <div className="main-section-card">
                  <p>
                    <img alt="" src="/static/gray-multiple.svg" /> Remaining
                    listings
                  </p>
                  <h4>{getItemFromStore("userProfile")?.listingBalance}</h4>
                </div> */}
              </div>
            ) : null}
            <div className="main-section-left-bottom">
              <div className="first-menu">
                <ul>
                  <li>
                    <Link
                      className={
                        location.pathname === ROUTE_CONSTANTS.personal_details
                          ? "active"
                          : ""
                      }
                      to={ROUTE_CONSTANTS.personal_details}
                    >
                      <img src="/static/person.svg" alt="" />
                      <img src="/static/person-theme.svg" alt="" />
                      Profile Details
                    </Link>
                  </li>
                  {getItemFromStore("userProfile")?.userType === "Member" && (
                    <li>
                      <Link
                        className={
                          location.pathname === ROUTE_CONSTANTS.business_details
                            ? "active"
                            : ""
                        }
                        to={ROUTE_CONSTANTS.business_details}
                      >
                        <img src="/static/person-edit-gray.svg" alt="" />
                        <img src="/static/person-edit-theme.svg" alt="" />
                        Business Details
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link
                      to={ROUTE_CONSTANTS.view_listing}
                      className={
                        location.pathname === ROUTE_CONSTANTS.view_listing
                          ? "active"
                          : ""
                      }
                    >
                      <img src="/static/listing-edit-gray.svg" alt="" />
                      <img src="/static/listing-edit-theme.svg" alt="" />
                      {STRING_CONSTANTS.listing}
                    </Link>
                  </li>
                  {getItemFromStore("userProfile")?.userType !==
                  USER_TYPE.GUEST ? (
                    <li>
                      <Link
                        className={
                          location.pathname === ROUTE_CONSTANTS.my_earning
                            ? "active"
                            : ""
                        }
                        to={ROUTE_CONSTANTS.my_earning}
                      >
                        <img src="/static/money-edit-gray.svg" alt="" />
                        <img src="/static/money-edit-theme.svg" alt="" />
                        My Earnings
                      </Link>
                    </li>
                  ) : null}

                  <li>
                    <Link
                      className={
                        location.pathname === ROUTE_CONSTANTS.my_booking
                          ? "active"
                          : ""
                      }
                      to={ROUTE_CONSTANTS.my_booking}
                    >
                      <img src="/static/ribbonstar-gray.svg" alt="" />
                      <img src="/static/ribbonstar-theme.svg" alt="" />
                      My Bookings
                    </Link>
                  </li>

                  <li>
                    <Link
                      className={
                        location.pathname === ROUTE_CONSTANTS.team_membership
                          ? "active"
                          : ""
                      }
                      to={ROUTE_CONSTANTS.team_membership}
                    >
                      <img
                        src="/static/people-community-edit-gray.svg"
                        alt=""
                      />
                      <img
                        src="/static/people-community-edit-theme.svg"
                        alt=""
                      />
                      My Team Listings
                    </Link>
                  </li>

                  <li>
                    <Link
                      className={
                        location.pathname === ROUTE_CONSTANTS.intrest
                          ? "active"
                          : ""
                      }
                      to={ROUTE_CONSTANTS.intrest}
                    >
                      <img src="/static/Sport-gray.svg" alt="" />
                      <img src="/static/Sport-theme.svg" alt="" />
                      Interests
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={
                        location.pathname === ROUTE_CONSTANTS.favourites
                          ? "active"
                          : ""
                      }
                      to={ROUTE_CONSTANTS.favourites}
                    >
                      <img src="/static/heart-gray.svg" alt="" />
                      <img src="/static/heart-theme.svg" alt="" />
                      Favourites
                    </Link>
                  </li>

                  {getItemFromStore("userProfile")?.userType === "Member" &&
                  getItemFromStore("userProfile")?.isTrainer ? (
                    <>
                      <li>
                        <Link
                          className={
                            location.pathname === ROUTE_CONSTANTS.experience
                              ? "active"
                              : ""
                          }
                          to={ROUTE_CONSTANTS.experience}
                        >
                          <img src="/static/calendar-edit-gray.svg" alt="" />
                          <img src="/static/calendar-edit-theme.svg" alt="" />
                          Training experience
                        </Link>
                      </li>

                      {/* <li>
                        <Link
                          className={
                            location.pathname === ROUTE_CONSTANTS.qualification
                              ? "active"
                              : ""
                          }
                          to={ROUTE_CONSTANTS.qualification}
                        >
                          <img src="/static/certificate-gray.svg" alt="" />
                          <img src="/static/certificate-theme.svg" alt="" />
                          Qualifications
                        </Link>
                      </li> */}
                    </>
                  ) : null}
                  {!isSocialLogin() && (
                    <li>
                      <Link
                        className={
                          location.pathname ===
                          ROUTE_CONSTANTS.password_management
                            ? "active"
                            : ""
                        }
                        to={ROUTE_CONSTANTS.password_management}
                      >
                        <img src="/static/key-multiple-gray.svg" alt="" />
                        <img src="/static/key-multiple-theme.svg" alt="" />
                        Password management
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link
                      className={
                        location.pathname === ROUTE_CONSTANTS.payment_methods
                          ? "active"
                          : ""
                      }
                      to={ROUTE_CONSTANTS.payment_methods}
                    >
                      <img src="/static/wallet-gray.svg" alt="" />
                      <img src="/static/wallet-theme.svg" alt="" />
                      Wallet Management
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={
                        location.pathname === ROUTE_CONSTANTS.bank_accounts
                          ? "active"
                          : ""
                      }
                      to={ROUTE_CONSTANTS.bank_accounts}
                    >
                      <img src="/static/payment-gray.svg" alt="" />
                      <img src="/static/payment-theme.svg" alt="" />
                      Bank account & stripe
                    </Link>
                  </li>

                  <li>
                    <Link
                      className={
                        location.pathname === ROUTE_CONSTANTS.subscription
                          ? "active"
                          : ""
                      }
                      to={ROUTE_CONSTANTS.subscription}
                    >
                      <img src="/static/payment-gray.svg" alt="" />
                      <img src="/static/payment-theme.svg" alt="" />
                      Membership plan
                    </Link>
                  </li>

                  {/* {getItemFromStore("userProfile")?.userType !==
                  USER_TYPE.GUEST ? (
                    <li>
                      <Link
                        className={
                          location.pathname === ROUTE_CONSTANTS.purchase_listing
                            ? "active"
                            : ""
                        }
                        to={ROUTE_CONSTANTS.purchase_listing}
                      >
                        <img src="/static/total-gray-tag.svg" alt="" />
                        <img src="/static/total-tag.svg" alt="" />
                        Listing Block Purchase
                      </Link>
                    </li>
                  ) : (
                    ""
                  )} */}
                </ul>
              </div>
            </div>
          </div>

          {isSocialLogin() && (
            <>
              <p className="title">Social sign-in</p>
              <div className="main-section-left">
                <div className="main-section-left-bottom">
                  <div className="first-menu">
                    <ul>
                      {getItemFromStore("userProfile")?.socialLinks?.apple && (
                        <li>
                          <div className="unlink_box">
                            <div>
                              <img src="/static/apple.svg" alt="" />
                              Apple
                            </div>
                            <span
                              onClick={() => {
                                setSocialLinkType("apple");
                                setUnLinkOption(true);
                              }}
                            >
                              Unlink
                              <img src="/static/checked.svg" alt="" />
                            </span>
                          </div>
                        </li>
                      )}
                      {getItemFromStore("userProfile")?.socialLinks
                        ?.facebook && (
                        <li>
                          <div className="unlink_box">
                            <div>
                              <img src="/static/fb.svg" alt="" />
                              Facebook
                            </div>
                            <span
                              onClick={() => {
                                setSocialLinkType("facebook");
                                setUnLinkOption(true);
                              }}
                            >
                              Unlink
                              <img src="/static/checked.svg" alt="" />
                            </span>
                          </div>
                        </li>
                      )}
                      {getItemFromStore("userProfile")?.socialLinks?.google && (
                        <li>
                          <div className="unlink_box">
                            <div>
                              <img src="/static/google.svg" alt="" />
                              Google
                            </div>
                            <span
                              onClick={() => {
                                setSocialLinkType("google");
                                setUnLinkOption(true);
                              }}
                            >
                              Unlink
                              <img src="/static/checked.svg" alt="" />
                            </span>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </>
          )}
          <p className="title">Help and Feedback</p>
          <div className="main-section-left">
            <div className="main-section-left-bottom">
              <div className="first-menu">
                <ul>
                  <li>
                    <Link
                      className={
                        location.pathname === ROUTE_CONSTANTS.policies
                          ? "active"
                          : ""
                      }
                      to={ROUTE_CONSTANTS.policies}
                    >
                      <img src="/static/Shield-gray.svg" alt="" />
                      <img src="/static/Shield-theme.svg" alt="" />
                      Terms and Privacy
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={
                        location.pathname === ROUTE_CONSTANTS.faq
                          ? "active"
                          : ""
                      }
                      to={ROUTE_CONSTANTS.faq}
                    >
                      <img src="/static/question-circle-gray.svg" alt="" />
                      <img src="/static/question-circle-active.svg" alt="" />
                      Frequently asked questions
                    </Link>
                  </li>
                  <li>
                    <a href="mailto:hello@fitnessmates.com">
                      <img src="/static/mail-gray.svg" alt="" />
                      <img src="/static/mail-theme.svg" alt="" />
                      Contact admin
                    </a>
                  </li>
                  {!isSocialLogin() ? (
                    <li>
                      <p
                        className={
                          location.pathname === ROUTE_CONSTANTS.delete_account
                            ? "active"
                            : ""
                        }
                        onClick={() => setDeleteAccount(true)}
                      >
                        <img src="/static/delete-red.svg" alt="" />
                        <img src="/static/delete-red.svg" alt="" />
                        Delete account
                      </p>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {props.children}
      </div>
      {deleteAccount && (
        <DeleteAccountModal
          onClose={() => setDeleteAccount(false)}
          onSuccess={handleLogout}
        />
      )}

      {logout && (
        <LogoutModal
          onOk={() => handleLogout()}
          onCancel={() => setLogout(false)}
        />
      )}
      {unLinkOption && (
        <UnlinkModal
          socialLinkType={socialLinkType}
          onClose={() => setUnLinkOption(false)}
          onSuccess={(data: any) => handleUnlink(data)}
        />
      )}
      {props?.showFooter && <GlobalFooter />}
    </div>
  );
};

export default DashboardWrapper;
