import React from "react";

const RibbonStar: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 10C18 13.3137 15.3137 16 12 16C8.68629 16 6 13.3137 6 10C6 6.68629 8.68629 4 12 4C15.3137 4 18 6.68629 18 10ZM11.8466 7.10184L11.1302 8.65489C11.1047 8.71016 11.0524 8.74821 10.9919 8.75538L9.29353 8.95675C9.14523 8.97433 9.08568 9.15763 9.19531 9.25902L10.451 10.4202C10.4957 10.4616 10.5157 10.5231 10.5038 10.5828L10.1705 12.2603C10.1414 12.4068 10.2973 12.5201 10.4276 12.4471L11.92 11.6118C11.9731 11.582 12.0379 11.582 12.091 11.6118L13.5834 12.4471C13.7137 12.5201 13.8696 12.4068 13.8405 12.2603L13.5072 10.5828C13.4953 10.5231 13.5153 10.4616 13.56 10.4202L14.8157 9.25902C14.9253 9.15763 14.8658 8.97433 14.7175 8.95675L13.0191 8.75538C12.9586 8.74821 12.9063 8.71016 12.8808 8.65489L12.1644 7.10184C12.1019 6.96624 11.9091 6.96624 11.8466 7.10184ZM12 16.9998C13.4872 16.9998 14.8662 16.536 16 15.7451V19.4998C16 19.6871 15.8953 19.8587 15.7288 19.9444C15.5623 20.0301 15.3618 20.0155 15.2094 19.9067L12 17.9998L8.79062 19.9067C8.63821 20.0155 8.43774 20.0301 8.27121 19.9444C8.10467 19.8587 8 19.6871 8 19.4998V15.7451C9.13383 16.536 10.5128 16.9998 12 16.9998Z"
        fill="#25272D"
      />
    </svg>
  );
};

export default RibbonStar;
