import { Modal } from "react-bootstrap";
import Button from "../../../global/Button";

const WaitlistPopup = (props) => {
  return (
    <Modal {...props} centered dialogClassName="width-modal-390">
      <Modal.Body style={{ height: "fit-content" }}>
        <div className="emodal_wrapper">
          <div className=" d-flex justify-content-center mb-4">
            <img src="/static/big-checkmark.svg" alt="jj" />
          </div>

          <h1 className="wl-title mb-3">You’re on the waitlist!</h1>
          <div className="wl-subtitle mb-3">
            We’ve added you onto our waitlist for “High intensity interval
            training classes”. Be sure to keep notifications on for any updates.
          </div>
          <Button
            btnTxt="Sounds Good!"
            className={"backtologin mt-4"}
            onClick={() => {
              props.onHide();
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default WaitlistPopup;
