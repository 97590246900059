import { InputAdornment, TextField, TextFieldProps } from "@mui/material";

import React from "react";

type Props = TextFieldProps & {
  endIcon?: React.ReactNode;
  readOnly?: boolean;
};

const InputFieldFloating = ({
  error = false,
  placeholder = "",
  endIcon = "",
  readOnly,
  ...otherProps
}: Props) => {
  return (
    <TextField
      {...otherProps}
      //   focused={focused}
      sx={{
        width: "100%",
        ".MuiInputLabel-root": {
          fontSize: "0.93rem",
          fontFamily: "Poppins !important",
          "&.Mui-focused": {
            fontSize: "0.93rem",
            color: error ? "#FF3B30 !important" : "#25272D !important",
          },
        },
        ".MuiOutlinedInput-root": {
          borderRadius: "10px",
          "& fieldset": {
            border: error ? "1px solid #FF3B30" : "1px solid #DCDCDC",
          },
          "&:hover fieldset": {
            border: error ? "1px solid #FF3B30" : "1px solid #DCDCDC",
          },
          "&.Mui-focused fieldset": {
            border: error ? "1px solid #FF3B30" : "1px solid #DCDCDC",
          },
        },
        ".MuiInput-input": {
          fontFamily: "Poppins !important",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",
          padding: "13px !important",
          borderRadius: "10px !important",
          color: "#25272D",
        },
        ".MuiInput-input:focus": {
          outline: "none",
          borderRadius: "10px !important",
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" sx={{ cursor: "pointer" }}>
            {endIcon}
          </InputAdornment>
        ),
        placeholder: placeholder || "",
        readOnly: readOnly,
      }}
    />
  );
};

export default InputFieldFloating;
