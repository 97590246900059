import { useEffect, useState } from "react";
import { makeGetCall } from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import { CopyToClipboard } from "react-copy-to-clipboard";
import IconPhysicalEvent from "../../assets/img/createListingIcon/physical-orange.svg";
import IconVirtualEvent from "../../assets/img/createListingIcon/virtual-blue.svg";
import IconTeamBlack from "../../assets/img/createListingIcon/icon-team-black.svg";
import { showToast } from "../../utils";
import ApiLoader from "../../global/ApiLoader";
import MyTeamListingRequestCard from "./MyTeamListingRequestCard";
import { TEAM_REQUEST_TYPE_PAYLOAD } from "../../utils/Constants";
import { LeftArrowStickIcon } from "../../utils/Icons/svgs";
import { useNavigate } from "react-router-dom";
import useCheckDeviceScreen from "../../hooks/useCheckScreen";
import { shortenName } from "../Connect/ConnectConstants";
import NoPendingTeamRequest from "./NoPendingRequest";

const MyTeamBookings = ({ teamListingId, teamData, handleUpdate }) => {
  const tabs = ["Total", "Accepted", "Rejected"];
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [teamListingData, setTeamListingData] = useState([]);
  const [pendingRequestCount, setPendingRequestCount] = useState(0);
  const [requestStatus, setRequestStatus] = useState("R");
  const { isMobile } = useCheckDeviceScreen();

  const getMapUrl = (location: any) => {
    const str = location;
    const coordinates = str?.replace("SRID=4326;POINT (", "")?.replace(")", "");

    if (coordinates) {
      const [longitude, latitude] = coordinates
        ?.split(" ")
        ?.map((coord: any) => parseFloat(coord));
      return `https://www.google.com/maps?q=${latitude},${longitude}`;
    } else {
      return `https://www.google.com/maps`;
    }
  };

  const handleTableChange = (tab: any) => {
    setIsLoading(true);
    setActiveTab(tab);
    if (tab === tabs[0]) {
      setRequestStatus(TEAM_REQUEST_TYPE_PAYLOAD.TOTAL);
    } else if (tab === tabs[1]) {
      setRequestStatus(TEAM_REQUEST_TYPE_PAYLOAD.ACCEPTED);
    } else {
      setRequestStatus(TEAM_REQUEST_TYPE_PAYLOAD.REJECTED);
    }
  };

  const fetchRequestDetails = () => {
    makeGetCall({
      url: endpoints.team_listing,
      params: { status: requestStatus, listing_id: teamListingId },
    })
      .then((res) => {
        if (res?.data) {
          if (requestStatus === TEAM_REQUEST_TYPE_PAYLOAD.TOTAL) {
            setPendingRequestCount(res?.data?.length);
            handleUpdate()
          }
          setTeamListingData(res.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        setIsLoading(false);
        showToast(err.message, "error");
      });
  };

  useEffect(() => {
    fetchRequestDetails();
  }, [teamListingId, requestStatus]);

  return (
    <div className="my-team-bookings-wrapper">
      <div className="d-flex d-md-none">
        <div className="back-icon" onClick={() => navigate(-1)}>
          <LeftArrowStickIcon />
        </div>
        <div
          className="heading-mobile-bookings"
          style={{
            margin: "0px",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          Bookings
        </div>
      </div>
      <div className="head-container">
        <div className="title-container-wrap">
          <div className="title-container">
            <div className="title">{teamData?.title}</div>
            <div className="team-icon-wrapper">
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src="/static/people-white.svg" alt="Sport Balls" />
              </span>
              <span>Team</span>
            </div>
            <div
              className="event-type-wrapper"
              style={{
                background:
                  teamData?.listingEventType === "V"
                    ? "#0099FF1A"
                    : "#EE78301A",
              }}
            >
              <img
                src={
                  teamData?.listingEventType === "V"
                    ? IconVirtualEvent
                    : IconPhysicalEvent
                }
                height="18.5px"
                width="18.5px"
              />
              <span className="icon-listing-text">
                {teamData?.listingEventType === "V" ? "Virtual" : "Physical"}
              </span>
            </div>
          </div>
          {/* <div
            className=""
            // onClick={() => setShowCsvPopup(true)}
          >
            <img alt="event-icon" src="/static/document-right.svg" />
          </div> */}
        </div>
        <div className="team-booking-text">
          <img alt="card-icon" src={IconTeamBlack} />
          <span>{`Total team member: ${
            teamData?.maxParticipants - teamData?.teamSlotsAvailable
          } out of ${teamData?.maxParticipants} spots`}</span>
        </div>
        <div className="location-wrap">
          <div className="location-type-text">{"Meet link"}</div>
          <div className="location-copy-wrap">
            {" "}
            <span>
              {teamData?.listingEventType === "V"
                ? isMobile
                  ? shortenName(teamData?.teamVirtualLink, 17)
                  : shortenName(teamData?.teamVirtualLink, 50)
                : "Google map location"}
            </span>{" "}
            <CopyToClipboard
              onClick={(e) => e.stopPropagation()}
              onCopy={() => showToast("Text copied!", "success")}
              text={
                teamData?.listingEventType === "V"
                  ? `${teamData?.teamVirtualLink}`
                  : getMapUrl(teamData?.teamLocation)
              }
            >
              <img
                alt=""
                src="/static/copyblue.svg"
                className="copy-btn"
                height="24px"
                width="65px"
              />
            </CopyToClipboard>{" "}
          </div>
        </div>
      </div>
      <div className="tabs-wrapper">
        <div className="tabs-container">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`tab-button ${activeTab === tab ? "active" : ""}`}
              onClick={() => handleTableChange(tab)}
            >
              {tab !== tabs[0] ? tab : `Total Requests(${pendingRequestCount})`}
            </button>
          ))}
        </div>
        <div className="tab-content">
          {isLoading ? (
            <div style={{marginTop:"70px"}}> <ApiLoader /></div>
            
          ) : (
            <>
              {
                <>
                  {isLoading ? (
                    <ApiLoader />
                  ) : (
                    <div className="card-wrapper">
                      {teamListingData?.length !== 0 ? (
                        teamListingData?.map((requestData: any) => (
                          <MyTeamListingRequestCard
                            requestData={requestData}
                            activeTab={activeTab}
                            fetchRequestDetails={fetchRequestDetails}
                          />
                        ))
                      ) : (
                        <NoPendingTeamRequest
                          mode={activeTab}
                          handleTableChange={handleTableChange}
                        />
                      )}
                    </div>
                  )}
                </>
              }
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyTeamBookings;
