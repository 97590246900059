


const Time: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
   <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM8.4375 3.375C8.12684 3.375 7.875 3.62684 7.875 3.9375L7.875 9.5625L7.88406 9.66361C7.93169 9.92601 8.16136 10.125 8.4375 10.125H11.8125L11.9136 10.1159C12.176 10.0683 12.375 9.83864 12.375 9.5625C12.375 9.25184 12.1232 9 11.8125 9H9L9 3.9375L8.99094 3.83639C8.94331 3.57398 8.71364 3.375 8.4375 3.375Z" fill="#25272D"/>
</svg>
  );
};

export default Time;
