import { googleKey } from "../utils/Constants";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import React from "react";

const GoogleMapContainer = (props: any) => {
  const containerStyle = {
    width: "100%",
    height: props?.height ? props.height : "19.47288rem",
    borderRadius: "12px",
  };

  const OPTIONS = {
    mapTypeControl: false,

  };

  const center = props.center;

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleKey,
  });

  const [, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={15}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={OPTIONS}
        >
          <>
            <Marker position={center} />
            {
              (props.packageEvents !== undefined && props.packageEvents && props.packageEvents.length > 0)
                ?
                props.packageEvents.map((pe, index) => (
                  pe?.address?.location.split("POINT ").length > 1
                    ?
                    <Marker position={{
                      lng: Number(
                        pe?.address?.location
                          ?.split("POINT ")?.[1]
                          ?.split(" ")?.[0]
                          ?.substring(1)
                      ),
                      lat: Number(
                        pe?.address?.location
                          ?.split("POINT ")?.[1]
                          ?.split(" ")?.[1]
                          .slice(0, -1)
                      )
                    }} />

                    :
                    ""
                ))
                :
                ""
            }
          </>
        </GoogleMap>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};
export default GoogleMapContainer;
