import React from "react";

const ApiLoaderListing = ({
    isLoadingDraft = false
}) => {
    return (
        <div
            className="lds-ring"
            style={{ margin: "auto", display: "flex", justifyContent: "center", position: 'fixed', zIndex: '100', height: '100vh', width: '100%', backdropFilter: 'blur(5px)' }}
        >
            <div style={{ margin: 'auto', textAlign: 'center' }}>
                <img style={{ width: "50px" }} src="/static/spinner.svg" alt="loader" />
                {isLoadingDraft ?<p>Draft creation in process..</p> : <p>Listing creation in process..</p>}
                
            </div>

        </div>
    );
};

export default ApiLoaderListing;
