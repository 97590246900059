import { Modal } from "react-bootstrap";

const BookingErrorPopup = (props) => {
  return (
    <Modal {...props} centered dialogClassName="">
      <Modal.Body style={{ height: "fit-content" }}>
        <div
          className="emodal_wrapper mx-auto"
          style={{ height: "fit-content", width: "390px" }}
        >
          <div className="eheading  justify-content-center align-items-center">
            <div>
              <p className="e_head_txt">Sorry you can’t book this session</p>
            </div>
            <div>
              <p className="e_subhead_txt">
                Opps you can not book this session as this is specifically for{" "}
                {props.ageError
                  ? props.ageError
                  : props.gender?.map((i) =>
                      i === "M" ? (
                        <span>Male </span>
                      ) : i === "F" ? (
                        <span>Female </span>
                      ) : (
                        <span>Prefer not to say </span>
                      )
                    )}{" "}
                only. Please book some other session as per the requirement.
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-end" style={{ width: "100%" }}>
            <div
              className="ud_cont mt-4"
              onClick={props.onHide}
              style={{ width: "100%" }}
            >
              Go back
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default BookingErrorPopup;
