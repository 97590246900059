import React from "react";
import "./styleDatesCard.scss";
import { Box, Grid } from "@mui/material";
import { DATE_FORMAT } from "../../../../constants/helperScheduleDates";
import dayjs from "dayjs";

type Props = {
  data: any;
};

const DatesPreviewCard = ({ data }: Props) => {
  return (
    <Grid item xs={10} sm={6.66} md={5} lg={4}>
      <Box className="card_inner_body">
        <Box className="date_card_data">
          {dayjs(data?.date, DATE_FORMAT.DATE_MONTH_FORMAT).format(
            DATE_FORMAT.SORT_DAY_MONTH_FORMAT
          )}
        </Box>
        <Box className="time_card_data">
          <img
            className="time_icon_preview"
            src="/static/listing/timeClockGrey.svg"
            alt=""
          />
          <Box>
            {`${dayjs(data?.startTime).format(
              DATE_FORMAT.HRS_12_FORMAT
            )} - ${dayjs(data?.endTime).format(DATE_FORMAT.HRS_12_FORMAT)}`}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default DatesPreviewCard;
