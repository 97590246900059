import React from "react";

const ApiLoader = () => {
  return (
    <div
      className="lds-ring"
      style={{ margin: "0 auto", display: "flex", justifyContent: "center" }}
    >
      <img style={{ width: "50px" }} src="/static/spinner.svg" alt="loader" />
    </div>
  );
};

export default ApiLoader;
