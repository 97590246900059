import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CrossIcon } from "../../../utils/Icons/svgs";
import Button from "../../../global/Button";
import { CancelReason } from "../../../utils/Constants";
import { LISTING_TYPE } from "../../../types";
import endpoints from "../../../utils/endpoints";
import { makePatchCall } from "../../../utils/Requests";
import { showToast } from "../../../utils";

const CancelReasonPopup = (props) => {
  const { detailsData } = props;
  const [selectCancelReason, setSelectCancelReason] = useState<any>(0);
  const [isChecked, setIsChecked] = useState<any>();
  const [cancelReason, setCancelReason] = useState<any>();
  const [reasonValue, setReasonValue] = useState<any>();
  const [cancelLoading, setCancelLoading] = useState(false);

  const cancelBooking = () => {
    if (!isChecked) {
      return;
    }
    let apiPayload = {};

    if (
      detailsData?.listingType === LISTING_TYPE.S ||
      detailsData?.listingType === LISTING_TYPE.SPECIAL
    ) {
      apiPayload = {
        events_booking: [],
        cancellation_reason: "",
      };
    } else {
      apiPayload = {
        listing_booking: [],
        cancellation_reason: "",
      };
    }

    if (
      detailsData?.listingType === LISTING_TYPE.S ||
      detailsData?.listingType === LISTING_TYPE.SPECIAL
    ) {
      for (let i = 0; i < props.cancelBookingEvents.length; i++) {
        apiPayload.events_booking.push(props.cancelBookingEvents[i]);
      }
    } else {
      for (let i = 0; i < props.cancelBookingEvents.length; i++) {
        apiPayload.listing_booking.push(props.cancelBookingEvents[i]);
      }
    }

    if (selectCancelReason === "other") {
      apiPayload.cancellation_reason = reasonValue;
    } else {
      apiPayload.cancellation_reason = cancelReason;
    }

    setCancelLoading(true);
    makePatchCall({
      url: endpoints.mylisting_new + `${props.listing_id}/cancel-bookings/`,
      apiPayload,
    })
      .then((res) => {
        setCancelLoading(false);
        showToast("Event has been successfully canceled.", "success");
        props.setRefresh((prev) => !prev);
        props.onHide();

      })
      .catch((err) => {
        setCancelLoading(false);
      });
  };

  return (
    <Modal {...props} centered dialogClassName="width-619">
      <Modal.Body style={{ height: "fit-content" }}>
        <div className="emodal_wrapper p-3">
          <div className="d-flex justify-content-end">
            <span
              className="text-end"
              onClick={props.onHide}
              style={{ cursor: "pointer" }}
            >
              <CrossIcon />
            </span>
          </div>
          <div className="eheading position-relative d-flex justify-content-center align-items-center  ">
            <div className="">
              <p className="e_head_txt mb-1">Cancel Booking</p>
              {detailsData?.listingType === LISTING_TYPE.S ||
                detailsData?.listingType === LISTING_TYPE.SPECIAL ? (
                <p className="e_subhead_txt">
                  To cancel this package, please provide a reason from the
                  predefined list. Once approved, the full refund will be
                  initiated to your account.
                </p>
              ) : (
                <p className="e_subhead_txt">
                  Transfer your listings to an Excel spreadsheet for
                  organisation and analysis.
                </p>
              )}
            </div>
          </div>
          <div className="edates_wrapper p-0">
            <div className="mb-3 cancel_r_wrapper">
              {CancelReason?.map((item, i) => {
                return (
                  <div
                    className="d-flex gap-3 align-items-center cancel_r my-3"
                    key={i + 1}
                  >
                    <input
                      type="radio"
                      value={i + 1}
                      checked={selectCancelReason === i + 1}
                      onChange={() => {
                        setSelectCancelReason(() => i + 1);
                        setCancelReason(item);
                      }}
                    />
                    <p className="mb-0">{item}</p>
                  </div>
                );
              })}
              <div className="d-flex gap-3 align-items-center cancel_r my-3">
                <input
                  type="radio"
                  value="other"
                  checked={selectCancelReason === "other"}
                  onChange={() => {
                    setSelectCancelReason("other");
                  }}
                />
                <p className="mb-0">Other</p>
              </div>
            </div>

            {selectCancelReason === "other" && (
              <div className="ed_textarea px-3 my-3">
                <label>Cancellation reason</label>
                {/* <textarea className=" p-0"
                                style={{ border: "0px", height: "50" }}
                                placeholder="Your feedback helps us improve."
                            /> */}
                <Form.Control
                  as="textarea"
                  row={2}
                  style={{ border: "0px", padding: "0px" }}
                  placeholder="Your feedback helps us improve."
                  value={reasonValue}
                  onChange={(e) => {
                    setReasonValue(e.target.value);
                  }}
                />
              </div>
            )}
            <div className="ed_textbox my-3">
              <Form.Check
                className={`checked_cont ${isChecked ? "active" : ""}`}
                type="checkbox"
                bsPrefix="checked_cont"
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
              ></Form.Check>

              <p className="mb-0">
                I have read the{" "}
                <Link
                  style={{ cursor: "pointer", textDecoration: "none" }}
                  to="/policies"
                >
                  <span>terms & conditions  </span>
                </Link>
                and acknowledge to proceed for cancellation terms.
              </p>
            </div>

            <Button
              btnTxt="Cancel Booking"
              className={"backtologin"}
              disabled={cancelLoading || !isChecked || !selectCancelReason}
              isLoading={cancelLoading}
              onClick={() => {
                cancelBooking();
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default CancelReasonPopup;
