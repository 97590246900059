import { USER_ROLES } from "../ConnectConstants";

const RoleCard = ({ role, isTrainer }) => {
  return (
    <span
      style={
        (role === USER_ROLES.GUEST && !isTrainer )
          ? {
              backgroundColor: "#f3f3f3",
              color: "#6c6d6f",
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "18px",
              borderRadius: "8px",
              padding: "1px 12px 1px 12px",
            }
          : {
              backgroundColor: "#FEEFE7",
              color: "#EE7830",
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "18px",
              borderRadius: "8px",
              padding: "1px 12px 1px 12px",
            }
      }
    >
     {isTrainer ? "Trainer" : role }
    </span>
  );
};

export default RoleCard;
