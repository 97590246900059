import React from "react";
import Loader from "react-js-loader";

const CommonLoader = ({
  type = "spinner-cub",
  bgColor = "#ee7830",
  size = 100,
}: any) => {
  return (
    <div className="loader">
      <Loader type={type} bgColor={bgColor} size={size} />
    </div>
  );
};

export default CommonLoader;
