import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { removeLocalStorage, showToast } from "../utils";
import endpoints from "../utils/endpoints";
import { makeDeleteCall } from "../utils/Requests";
import { ROUTE_CONSTANTS } from "../utils/RouteConstants";

import { STRING_CONSTANTS } from "../utils/StringConstant";
import Button from "./Button";
interface DeleteAccountModalProps {
  onClose?: () => void;
  onSuccess?: any;
}
const DeleteAccountModal = (props: DeleteAccountModalProps) => {
  const navigate = useNavigate();
  const { onClose } = props;
  const [pass, setPass] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const deleteAccount = () => {
    const formData = new FormData();
    formData.append("password", pass);
    makeDeleteCall({ url: endpoints.delete_account, apiPayload: formData })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res.status.message, "success");
          removeLocalStorage("access_token");
          removeLocalStorage("refresh_token");
          removeLocalStorage("userProfile");
          removeLocalStorage("fToken");

          navigate(ROUTE_CONSTANTS.login);
        } else {
          showToast(res.status.message, "error");
        }
      })
      .catch((err) => showToast(err, "error"));
  };
  return (
    <div className={"modal show"}>
      <div className="modal-section">
        <div className="modal-top-part">
          <button className="close">
            <img
              src="/static/dismiss-cross-black.svg"
              alt=""
              onClick={onClose}
            />
          </button>
          <h5>{STRING_CONSTANTS.delete_account_title}</h5>
          <p>{STRING_CONSTANTS.delete_account_desc}</p>
          <div className="form_group ">
            <div className="input_box">
              <span className="input_label">Password</span>
              <input
                type={passwordType}
                placeholder="*******"
                onChange={(e) => setPass(e.target.value)}
              />
              <img
                src={
                  passwordType === "password"
                    ? "/static/closeeye.svg"
                    : "/static/openeye.svg"
                }
                onClick={() => {
                  setPasswordType(
                    passwordType === "password" ? "text" : "password"
                  );
                }}
                className="eye-show-hide"
                alt=""
              />
            </div>
          </div>
        </div>
        <Button
          btnTxt="Confirm"
          className={"backtologin"}
          onClick={deleteAccount}
        />
      </div>
    </div>
  );
};

export default DeleteAccountModal;
