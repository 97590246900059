type Props = {
  [key: string]: any;
};

const ListView = ({ active = false, ...otherProps }: Props) => {
  const fillColor = active ? "rgba(238, 120, 48, 0.1)" : "#F3F3F3";
  const strokeColor = active ? "#EE7830" : "#25272D";
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <circle cx="30" cy="30" r="30" fill={fillColor} />
      <path
        d="M25.9077 21.8203H40.9077M25.9077 30.0021H40.9077M25.9077 38.1839H40.9077M20.4531 21.8203V21.8339M20.4531 30.0021V30.0158M20.4531 38.1839V38.1976"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ListView;
