import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import "./style.scss";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface DialogProps {
  open: boolean;
  onClose?: any;
  children: React.ReactElement<any, any>;
  maxWidth?: any;
  position?: "bottom" | "center";
  disableBackdropClick?: boolean;
}

export default function DialogModal({
  open = false,
  onClose = () => null,
  maxWidth,
  children,
  position = "bottom",
  disableBackdropClick = false,
}: DialogProps) {
  const handleClose = (e, reason) => {
    if (disableBackdropClick && reason === "backdropClick") {
      return;
    } else {
      onClose();
    }
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth
      className={`${
        position === "bottom" ? "bottom_dialog" : "center_dialog"
      } dialog_layout_modal`}
      maxWidth={false}
      PaperProps={{
        sx: { maxWidth: maxWidth || "734px" },
        className: "custom_paper_class",
      }}
    >
      {children}
    </Dialog>
  );
}
