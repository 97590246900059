/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getItemFromStore,
  isUserAuthenticated,
  notAValidUser,
  removeAllItemFromStore,
  setLocalStorage,
  showToast,
} from "../../utils";
import endpoints from "../../utils/endpoints";
import {
  makeGetCall,
  makePostCall,
} from "../../utils/Requests";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import moment from "moment";
import { STRING_CONSTANTS } from "../../utils/StringConstant";
import { USER_TYPE } from "../../types";
import LogoutModal from "../../global/LogoutModal";
import IconAlertWhite from "../../assets/img/alert-white.svg";
import IconChatWhite from "../../assets/img/icon-chat-white.svg";
import useCheckDeviceScreen from "../../hooks/useCheckScreen";
const NotificationIcon = require("../../assets/img/notification-alert.svg").default;

const GlobalHeader2 = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [logout, setLogout] = useState(false);
  const { isMobile } = useCheckDeviceScreen();
  const [profileImage, setProfileImage] = useState("");
  const [profileImageExtension, setProfileImageExtension] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [userNameRes, setUserNameRes] = useState();
  const [unReadNotificationCount, setUnreadNotificationCount] =
    useState<any>(null);
  // for unread chat count
  const [unreadChatCount, setIsUnreadChatCount] = useState(0);
  let tempUserType = getItemFromStore("userProfile")?.userType;
  const [, setUserType] = useState(tempUserType);
  const queryParams = new URLSearchParams(location.search);
  const toastShowOrNot = queryParams.get("toast");
  const onboardingStep = queryParams.get("step");
  const [, setUserDetails] = useState<any>(null);

  // if (
  //   process.env.REACT_APP_IS_PROD === "true" &&
  //   !window.location.pathname.includes([
  //     ROUTE_CONSTANTS.register,
  //     ROUTE_CONSTANTS.manage_profile,
  //     ROUTE_CONSTANTS.joining,
  //   ])
  // )
  //   window.location.replace(ROUTE_CONSTANTS.register);

  // useEffect(() => {
  //   if (!tempUserType) {
  //     makeGetCall({ url: endpoints.profile })
  //       .then(async (res) => {
  //         setUserType(res.data.userDetail.userType);
  //         if (localStorage.getItem("attribute")) {
  //           localStorage.removeItem("attribute");
  //         }

  //         const locationData: any = await getGeolocation();
  //         if(locationData?.currentLng !== null && locationData?.currentLat !== null ){
  //         await makePatchCall({
  //           url: "auth/location/",
  //           apiPayload: {
  //             location: `${locationData?.currentLng},${locationData?.currentLat}`,
  //           },
  //         })
  //           .then((res) => {})
  //           .catch((err) => {
  //             showToast(err, "error");
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         showToast(err, "error");
  //       });
  //   } else {
  //     if (localStorage.getItem("attribute")) {
  //       localStorage.removeItem("attribute");
  //     }
  //   }
  // }, []);

  const handleLogout = () => {
    let apiPayload = { email: getItemFromStore("userProfile").email };
    makePostCall({ url: endpoints.logout, apiPayload })
      .then((res) => {
        if (res.status.code === 200) {
          removeAllItemFromStore();
          showToast(STRING_CONSTANTS.logout_success, "success");
          navigate(ROUTE_CONSTANTS.dashboard);
        }
      })
      .catch((err) => showToast(err, "error"));
  };
  useEffect(() => {
    window.onpopstate = () => {
      let currentPath = window.location.pathname;
      if (
        currentPath === ROUTE_CONSTANTS.manage_profile ||
        currentPath === ROUTE_CONSTANTS.upload_document ||
        currentPath === ROUTE_CONSTANTS.business_intrest ||
        currentPath === ROUTE_CONSTANTS.manage_expertise
      ) {
        navigate(ROUTE_CONSTANTS.dashboard);
      }
    };
  }, [navigate]);

  useEffect(() => {
    let decoUserData: any;
    makeGetCall({ url: endpoints.profile })
      .then((res) => {
        setUserNameRes(res.data?.userDetail?.name);
        setUnreadNotificationCount(
          res?.data?.userDetail?.unreadNotificationCount
        );
        if (res?.data?.userDetail?.unreadMessagesCount > 0) {
          setIsUnreadChatCount(res?.data?.userDetail?.unreadMessagesCount);
        }
        if (res.status.code === 200) {
          let data = {
            ...res.data?.userDetail,
            ...res.data?.businessDetail?.[0],
          };

          setUserDetails(data);

          const pathname = window.location.pathname;
          if (data) {
            //Just added fot avoiding redirection

            if (pathname === "/user-profile") {
              navigate(ROUTE_CONSTANTS.user_profile);
            } else if (pathname === "/events") {
              // if (isUserAuthenticated()) {
              // if (!data.bio || !data.name) {
              //   navigate(ROUTE_CONSTANTS.manage_profile);
              // }
              // } else {
              navigate(ROUTE_CONSTANTS.events);
              // }
            } else if (pathname.startsWith("/events/")) {
              // if (isUserAuthenticated()) {
              //   if (!data.bio || !data.name) {
              //     navigate(ROUTE_CONSTANTS.manage_profile);
              //   }
              // } else {
              navigate(pathname, { replace: true });
              // }
            } else if (!data.name) {
              if (window.location.pathname !== ROUTE_CONSTANTS.welcome) {
                // navigate(ROUTE_CONSTANTS.manage_profile);
              }
            }
            //for pre registration form
            //  else if (data?.interest?.length === 0)
            // else if (
            //   data?.interest?.length === 0 &&
            //   process.env.REACT_APP_IS_PROD !== "true"
            // ) {
            //   if (!toastShowOrNot) {
            //     showToast(
            //       "complete the onboarding process",
            //       "error",
            //       "complete-process-error"
            //     );
            //   }
            //   if (onboardingStep !== "profile") {
            //     navigate(ROUTE_CONSTANTS.business_intrest);
            //   }
            // }
            else {
              decoUserData = {
                ...data,
                businessContactNumber: data.businessContactNumber?.slice(3),
                dob: moment(data.dob, "YYYY-MM-DD")?.toDate(),
              };

              setProfileImage(decoUserData?.profilePicture);
              setProfileImageExtension(decoUserData?.profilePictureExtension);
              setLocalStorage("userProfile", decoUserData);
            }
          }
        } else {
          showToast(STRING_CONSTANTS.wrong_data_message, "error");
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  }, [props.isUserProfileChange]);

  useEffect(() => {
    setUnreadNotificationCount(
      getItemFromStore("userProfile")?.unreadNotificationCount
    );
    // setIsUnreadChatCount(getItemFromStore("userProfile")?.unreadMessagesCount);
  }, [getItemFromStore("userProfile")]);

  return (
    <div className="header-container ">
      <div className=" new-header-container">
        <div className="new-header">
          <div className="'left-logo d-none d-md-block">
            <Link to={ROUTE_CONSTANTS.landing}>
              <img src="/static/fitness-logo.svg" alt="" />
            </Link>
          </div>
          <div className=" d-md-none">
            <Link to={ROUTE_CONSTANTS.landing}>
              <img src="/static/logo_small.svg" alt="" />
            </Link>
          </div>
          <div className="middle-bar">
            <div className={`menus d-none d-md-block`}>
              <ul>
                <li>
                  <Link
                    to={ROUTE_CONSTANTS.landing}
                    className={
                      location.pathname === ROUTE_CONSTANTS.landing
                        ? "active"
                        : "inactive"
                    }
                  >
                    {STRING_CONSTANTS.event}
                  </Link>
                </li>

                  <li>
                    <Link
                      to={ROUTE_CONSTANTS.connect}
                      className={
                        location.pathname === ROUTE_CONSTANTS.connect
                          ? "active"
                          : "inactive"
                      }
                    >
                      {STRING_CONSTANTS.connect}
                    </Link>
                  </li>
                
                  <li>
                    <Link
                      to={ROUTE_CONSTANTS.promotion}
                      className={
                        location.pathname === ROUTE_CONSTANTS.promotion
                          ? "active"
                          : "inactive"
                      }
                    >
                      {STRING_CONSTANTS.promotion}
                    </Link>
                  </li>
              </ul>
            </div>
          </div>
          <div className="right-bar ">
            {(getItemFromStore("userProfile")?.userType === USER_TYPE.GUEST ||
              !isUserAuthenticated()) &&
              !isMobile && (
                <div
                  className="join-as-a-member"
                  onClick={() => navigate(ROUTE_CONSTANTS.subscription)}
                >
                  Become a Member
                </div>
              )}
            {/* {!isUserAuthenticated() && (
              <div
                className="join-as-a-member"
                onClick={() => navigate(ROUTE_CONSTANTS.register)}
              >
                Become a Member
              </div>
            )} */}
            {/* for gray seperator line */}
            {getItemFromStore("userProfile")?.userType === USER_TYPE.GUEST &&
              !isMobile && (
                <div
                  style={{
                    height: "33px",
                    border: "1px solid #D9D9D9",
                    borderRadius: "20px",
                    marginInline: "10px 20px",
                  }}
                ></div>
              )}
            {/* for chat icon */}
            {isUserAuthenticated() && (
              <div className="d-none d-md-block notification-icon">
                <Link to={ROUTE_CONSTANTS.chat}>
                  {location.pathname === ROUTE_CONSTANTS.chat ? (
                    <img src="/static/chat-theme.svg" alt="chat" />
                  ) : (
                    <img src={IconChatWhite} alt="chat" />
                  )}
                </Link>
                {unreadChatCount > 0 && (
                  <img
                    alt=""
                    src="/static/unreaddot.svg"
                    className="active-dot"
                  />
                )}
              </div>
            )}
            {isUserAuthenticated() && (
              <div
                className="notification-icon"
                onClick={() => navigate(ROUTE_CONSTANTS.notifications)}
              >
                <img
                  src={
                    location.pathname === ROUTE_CONSTANTS.notifications
                      ? NotificationIcon
                      : IconAlertWhite
                  }
                  alt="notification icon"
                  className="alert-icon"
                />
                {unReadNotificationCount > 0 && (
                  <img
                    alt=""
                    src="/static/unreaddot.svg"
                    className="active-dot"
                  />
                )}
              </div>
            )}
            {!isUserAuthenticated() && (
              <div className="login-signup-wrapper">
                <button
                  className="login-btn"
                  onClick={() => navigate(ROUTE_CONSTANTS.login)}
                >
                  Login
                </button>
                <button
                  className="sign-up-btn"
                  onClick={() => navigate(ROUTE_CONSTANTS.register)}
                >
                  Sign up
                </button>
              </div>
            )}

            {isUserAuthenticated() && window.location.pathname !== "/policy" ? (
              <div className="icon-menu ">
                <ul>
                  <li className={`menu-des d-none d-md-block`}>
                    <div
                      className="inner"
                      onClick={() =>
                        isUserAuthenticated()
                          ? navigate(ROUTE_CONSTANTS.personal_details)
                          : notAValidUser(navigate)
                      }
                    >
                      <img
                        src="/static/navigation2.png"
                        className="menu-icon"
                        alt=""
                      />
                      {profileImageExtension?.includes("video") ? (
                        <video
                          className="profile"
                          autoPlay
                          playsInline
                          loop
                          muted
                          src={profileImage}
                        >
                          <source src={""} type="video/mp4" />
                        </video>
                      ) : (
                        <img
                          src={profileImage || "/static/dummyimg.svg"}
                          className="profile"
                          alt=""
                        />
                      )}
                    </div>
                  </li>

                  <li className="menu-des  d-md-none">
                    <div
                      className="inner"
                      onClick={() =>
                        isUserAuthenticated() ? "" : setShowMenu(true)
                      }
                    >
                      <img
                        onClick={() =>
                          isUserAuthenticated() ? setShowMenu(true) : ""
                        }
                        src="/static/navigation2.png"
                        className="menu-icon"
                        alt=""
                      />
                      {profileImageExtension?.includes("video") ? (
                        <video
                          className="profile"
                          autoPlay
                          playsInline
                          loop
                          muted
                          src={profileImage}
                          onClick={() =>
                            isUserAuthenticated()
                              ? navigate(ROUTE_CONSTANTS.profile_menu)
                              : setShowMenu(true)
                          }
                        >
                          <source src={""} type="video/mp4" />
                        </video>
                      ) : (
                        <img
                          src={profileImage || "/static/dummyimg.svg"}
                          className="profile"
                          alt=""
                          onClick={() =>
                            isUserAuthenticated()
                              ? navigate(ROUTE_CONSTANTS.profile_menu)
                              : setShowMenu(true)
                          }
                        />
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <div className={` fixed-sidebar-menu ${showMenu ? "open " : ""} `}>
        <div className={`sidebar_menu p-3 ${showMenu ? "open" : ""} `}>
          <div className="py-1 close_menu text-end">
            <img
              style={{ cursor: "pointer" }}
              onClick={() => setShowMenu(false)}
              src="/static/close-sm.png"
              alt=""
            />
          </div>
          <div className="profile_container p-1">
            {profileImageExtension?.includes("video") ? (
              <video
                className="profile profile-video"
                autoPlay
                playsInline
                loop
                muted
                src={profileImage}
                onClick={() => {
                  isUserAuthenticated()
                    ? navigate(ROUTE_CONSTANTS.profile_menu)
                    : navigate(ROUTE_CONSTANTS.login);
                  setShowMenu(false);
                }}
              >
                <source src={""} type="video/mp4" />
              </video>
            ) : (
              <img
                src={profileImage || "/static/dummyimg.svg"}
                className="profile"
                alt=""
                onClick={() => {
                  isUserAuthenticated()
                    ? navigate(ROUTE_CONSTANTS.profile_menu)
                    : navigate(ROUTE_CONSTANTS.login);
                  setShowMenu(false);
                }}
              />
            )}
            <div className="profile_info">
              <div className="profile_content">
                <span>welcome</span>
                <h4 style={{ wordBreak: "break-all" }} className="">
                  {userNameRes}
                </h4>
              </div>
              <img src="/static/ar-sm-left.png" alt="arrow" />
            </div>
          </div>

          <div className="profile_line my-4"></div>
          <div className="all_menus">
            <Link to="/" style={{ textDecoration: "none" }}>
              <li className="menu_item">
                <p>Home</p>
                <img src="/static/ar-sm-left.png" alt="" />
              </li>
            </Link>
            <Link
              to={ROUTE_CONSTANTS.events}
              style={{ textDecoration: "none" }}
            >
              <li className="menu_item">
                <p>Events</p>
                <img src="/static/ar-sm-left.png" alt="" />
              </li>
            </Link>
            <Link
              to={ROUTE_CONSTANTS.connect}
              style={{ textDecoration: "none" }}
            >
              <li className="menu_item">
                <p>Connect</p>
                <img src="/static/ar-sm-left.png" alt="" />
              </li>
            </Link>
            <Link
              to={ROUTE_CONSTANTS.view_listing}
              style={{ textDecoration: "none" }}
            >
              <li className="menu_item">
                <p>{STRING_CONSTANTS.listing}</p>
                <img src="/static/ar-sm-left.png" alt="" />
              </li>
            </Link>

            <Link
              to={ROUTE_CONSTANTS.promotion}
              style={{ textDecoration: "none" }}
            >
              <li className="menu_item">
                <p> {STRING_CONSTANTS.promotion}</p>
                <img src="/static/ar-sm-left.png" alt="" />
              </li>
            </Link>
            {isUserAuthenticated() ? (
              <Link
                to={ROUTE_CONSTANTS.chat}
                style={{ textDecoration: "none" }}
              >
                <li className="menu_item">
                  <p>Message</p>
                  <img src="/static/ar-sm-left.png" alt="" />
                </li>
              </Link>
            ) : (
              <Link
                to={ROUTE_CONSTANTS.chat}
                style={{ textDecoration: "none" }}
              >
                <li className="menu_item">
                  <p>Message</p>
                  <img src="/static/ar-sm-left.png" alt="" />
                </li>
              </Link>
            )}

            {isUserAuthenticated() ? (
              getItemFromStore("userProfile")?.isProfileCompleted && (
                <li
                  className="menu_item"
                  style={{ cursor: "pointer" }}
                  onClick={() => setLogout(true)}
                >
                  <p>Logout</p>
                  <img src="/static/ar-sm-left.png" alt="" />
                </li>
              )
            ) : (
              <Link
                to={ROUTE_CONSTANTS.login}
                style={{ textDecoration: "none" }}
              >
                <li className="menu_item">
                  <p>Login</p>
                  <img src="/static/ar-sm-left.png" alt="" />
                </li>
              </Link>
            )}
          </div>
        </div>
      </div>
      {logout && (
        <LogoutModal
          onOk={() => handleLogout()}
          onCancel={() => setLogout(false)}
        />
      )}
    </div>
  );
};

export default GlobalHeader2;
