const BackArrow: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2955 19.7159C10.6889 20.1036 11.322 20.0989 11.7097 19.7055C12.0973 19.3121 12.0926 18.6789 11.6992 18.2913L6.3289 13L20 13C20.5523 13 21 12.5522 21 12C21 11.4477 20.5523 11 20 11L6.33539 11L11.6992 5.71501C12.0926 5.32739 12.0973 4.69424 11.7097 4.30083C11.322 3.90743 10.6889 3.90274 10.2955 4.29036L3.3713 11.1127C2.87439 11.6024 2.87439 12.4039 3.3713 12.8936L10.2955 19.7159Z"
        fill="#25272D"
      />
    </svg>
  );
};

export default BackArrow;
