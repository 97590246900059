import React, { Fragment } from "react";
import "./style.container.scss";
import EventListingCard from "../EventListingCard";
type Props = {
  [key: string]: any;
};

const EventsContainer = (props: Props) => {
  const { listing = [], handleEventActionCallback = () => null } = props;
  if (listing?.length < 1) {
    return (
      <div className="no_data_container">
        <img className="no_data_img" src="/static/connect/noData.svg" alt="" />
      </div>
    );
  } else {
    return (
      <div className="event_container_outer">
        {listing?.map((data, index) => (
          <Fragment key={"event_card" + index}>
            <EventListingCard
              data={data}
              handleEventActionCallback={handleEventActionCallback}
            />
          </Fragment>
        ))}
      </div>
    );
  }
};

export default EventsContainer;
