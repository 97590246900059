import React from "react";
import { BUTTON_TYPE } from "../types";
import { emptyFunction } from "../utils";

import Loader from "./Loader";
interface ButtonProps {
  onClick?: () => void;
  className?: string;
  btnTxt: string;
  type?: any;
  restProps?: any;
  isLoading?: boolean;
  disabled?: boolean;
  loaderColor?: string;
  btnImg?: string;
  style?: React.CSSProperties;
  isRecommend?: boolean;
}

const PaymentButton = (props: ButtonProps) => {
  const {
    onClick = emptyFunction,
    className,
    btnTxt = "",
    isLoading = false,
    disabled,
    type = BUTTON_TYPE.TYPE_BUTTON,
    loaderColor,
    btnImg,
    isRecommend = false,
    ...restProps
  } = props;

  return (
    <button
      style={props?.style}
      type={type}
      disabled={disabled}
      onClick={() => {
        if (!isLoading) onClick();
      }}
      className={`common_btn_payment ${className ? className : ""}`}
      {...restProps}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {isRecommend ? (
            <span className="isRecommend">Recommended</span>
          ) : null}
          {btnImg ? <img src={btnImg} alt="" /> : null}
          {btnTxt}
        </>
      )}
    </button>
  );
};

export default PaymentButton;
