import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";
import { downloadFile, showToast } from "../../utils";
import endpoints from "../../utils/endpoints";
import { makeGetCall, makeNextPageCall } from "../../utils/Requests";
import DashboardWrapper from "./DashboardWrapper";
import ApiLoader from "../../global/ApiLoader";
import Button from "../../global/Button";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import TimeIcon from "../../assets/img/icons/TimeIcon";
import LocationIcon from "../../assets/img/icons/LocationIcon";
import GlobalHeader from "./GlobalHeader";
import { LeftArrowStickIcon } from "../../utils/Icons/svgs";
import GoogleMapContainer from "../../global/GoogleMapContainer";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import CommonOutlineButton from "../../global/CommonOutlineButton";
import OutlineButton from "../../global/OutlineButton";
import PaymentButton from "../../global/PaymentButton";
import BasicButton from "../../global/BasicButton";
import ArrowForward from "../../assets/img/icons/ArrowForward";
import ArrowForwardImage from "../../assets/img/ArrowForward.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
const Vector2 = require("../../assets/img/Physical.svg").default;

export default function MyBookings() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCsvPopup, setShowCsvPopup] = useState<boolean>(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const urlActiveTab = queryParams.get("activeTab");
  const [activeTab, setActiveTab] = useState<any>(
    urlActiveTab === "Cancelled" ? urlActiveTab : "Upcoming"
  );
  const [scheduleData, setScheduleData] = useState<any>([]);
  const [mapCenter, setMapCenter] = useState<any>();
  const [mapSidebar, setMapSidebar] = useState<any>();
  const [moreNextUrl, setMoreNextUrl] = useState<any>(null);

  const tabs = ["Upcoming", "Archived", "Cancelled"];

  const downloadCsv = () => {
    setIsLoading(true);
    makeGetCall({ url: endpoints.listings_booking_csv })
      .then((res) => {
        downloadFile(res);
        setShowCsvPopup(false);
        setIsLoading(false);
        if (res.status.code === 200) {
          setShowCsvPopup(false);
        } else {
          showToast(res?.status?.message, "error");
        }
      })
      .catch((err) => showToast(err, "error"));
  };

  const handleBookingType = () => {
    setIsLoading(true);
    console.log('activeTab: ', activeTab);
    makeGetCall({
      url:
        activeTab === "Upcoming"
          ? endpoints.listingSchedule
          : activeTab === "Archived"
          ? `${endpoints.listingSchedule}?type=archive`
          : `${
              endpoints.listingSchedule
            }?type=${activeTab.toLocaleLowerCase()}`,
    })
      .then((res) => {
        setScheduleData(res.results);
        setMoreNextUrl(res?.next);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(err, "error");
      });
  };
  useEffect(() => {
    handleBookingType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const fetchMoreData = () => {
    if (!moreNextUrl) return;
    makeNextPageCall({ url: moreNextUrl }).then((res) => {
      const tempData = [...scheduleData, ...res.results];
      setMoreNextUrl(res?.next);
      setScheduleData(tempData as never);
    });
  };

  const handleViewDetails: any = (id, listingType, eventId) => {
    navigate(`/view-details/${id}?type=${listingType}&event=${eventId}`);
  };

  const groupByMonth = (data) => {
    return data.reduce((acc, item) => {
      const month = moment(item.date).format("MMMM YYYY");
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(item);
      return acc;
    }, {});
  };

  const groupedScheduleData = groupByMonth(scheduleData);

  return (
    <DashboardWrapper showTab={true}>
      <div className="main-section-right profile-managment-right business_interest manage_profile update-interests left-sdie earning-page-wrap">
        <div
          className="profile_box earning_box"
          style={{
            height: "100%",
          }}
        >
          <div
            className="title"
            style={{
              textAlign: "left",
            }}
          >
            My Bookings
            <img
              src="/static/backarrow.svg"
              alt=""
              className="back_arrow d-md-none"
              onClick={() => navigate(-1)}
            />
          </div>

          <div
            className={`document-download ${
              scheduleData?.length ? "" : "diasble-download"
            }`}
            onClick={() => setShowCsvPopup(true)}
          >
            <span>Export</span>
            <img alt="" src="/static/document-right.svg" />
          </div>

          <div className="info_container_booking">
            <div className="info_wrap">
              <img src="/static/info-sm.svg" alt="" className="icon_info" />
              <div className="d-inline" style={{ textAlign: "start" }}>
                <span>
                  Please note that your sessions and package bookings will be
                  shown here, to check team listings please{" "}
                </span>
                <span
                  className="click_action"
                  onClick={() => navigate("/team-membership")}
                >
                  click here
                </span>
              </div>
            </div>
          </div>

          <div className="tabs-container-wrap">
            <div className="tabs-container">
              {tabs.map((tab) => (
                <button
                  key={tab}
                  className={`tab-button ${activeTab === tab ? "active" : ""}`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab.toUpperCase()}
                </button>
              ))}
            </div>
            <div className="tab-content">
              {isLoading ? (
                <ApiLoader />
              ) : (
                <>
                  {activeTab === tabs[0] && (
                    <>
                      {isLoading ? (
                        <ApiLoader />
                      ) : Object.keys(groupedScheduleData).length > 0 ? (
                        <InfiniteScroll
                          dataLength={scheduleData?.length}
                          next={fetchMoreData}
                          hasMore={moreNextUrl ? true : false}
                          loader={<ApiLoader />}
                        >
                          {Object.keys(groupedScheduleData).map((month) => (
                            <div
                              key={month}
                              className="booking_wrapper_profile"
                            >
                              <div className="month-title">{month}</div>
                              {groupedScheduleData[month].map((item) => (
                                <div
                                  key={item.id}
                                  className="upcoming-schedule-row-outer"
                                >
                                  {item && (
                                    <>
                                      <div className="sb-date-cont">
                                        <p className="sb-day mb-0">
                                          {moment(item?.date).format(
                                            "YYYY-MM-DD"
                                          ) ===
                                          moment(Date.now()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "Today"
                                            : moment(item?.date).format("ddd")}
                                        </p>
                                        <p className="sb-date">
                                          {moment(item?.date)
                                            .format("Do")
                                            .slice(0, -2)}
                                          <sup>
                                            {moment(item?.date)
                                              .format("Do")
                                              .slice(-2)}
                                          </sup>
                                        </p>
                                      </div>
                                      <div
                                        style={{ cursor: "pointer" }}
                                        className="upcoming-schedule-wrap"
                                        onClick={() => {
                                          navigate(
                                            `/listing-two/${item.event.listing.id}`
                                          );
                                        }}
                                      >
                                        <div className="card-content">
                                          <div className="left">
                                            <div className="title">
                                              <div className="event_desc">
                                                {item.title}
                                              </div>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  gap: "12px",
                                                }}
                                              >
                                                <img
                                                  alt=""
                                                  className="package_resp"
                                                  src={
                                                    item.listingType === "P"
                                                      ? "/static/bookingpacakge.svg"
                                                      : "/static/bookingsession.svg"
                                                  }
                                                />
                                                <img
                                                  alt=""
                                                  className="virtual_resp"
                                                  src={
                                                    item.listingEventType ===
                                                    "P"
                                                      ? "/static/booking/bookingPhysical.svg"
                                                      : "/static/booking/bookingVirtual.svg"
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div className="content-items">
                                              <div className="listing_detail_content">
                                                {item?.listingEventType ===
                                                "V" ? (
                                                  <p>
                                                    Event Type:{" "}
                                                    <span> Virtual </span>
                                                    <img
                                                      src="/static/Internet.svg"
                                                      alt="Internet Icon"
                                                      style={{ width: "16px" }}
                                                    />
                                                  </p>
                                                ) : (
                                                  <p>
                                                    Event Type:{" "}
                                                    <span
                                                      className="event-type-orange"
                                                      style={{
                                                        color: "#ee762f",
                                                      }}
                                                    >
                                                      {" "}
                                                      Physical{" "}
                                                    </span>
                                                    <img
                                                      src={Vector2}
                                                      alt="Internet Icon"
                                                      style={{ width: "16px" }}
                                                    />
                                                  </p>
                                                )}
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <TimeIcon color="#25272D" />
                                                <span
                                                  className="sub-title"
                                                  style={{
                                                    marginLeft: "4px",
                                                  }}
                                                >
                                                  {moment(
                                                    item?.startTime?.substring(
                                                      0,
                                                      5
                                                    ),
                                                    ["HH:mm"]
                                                  ).format("hh:mmA")}
                                                </span>
                                                <span className="sub-title">
                                                  -
                                                </span>
                                                <span className="sub-title">
                                                  {" "}
                                                  {moment(
                                                    item?.endTime?.substring(
                                                      0,
                                                      5
                                                    ),
                                                    ["HH:mm"]
                                                  ).format("hh:mmA")}
                                                </span>
                                              </div>
                                            </div>
                                            {item.listingEventType === "P" ? (
                                              <div className="content-items">
                                                <span>
                                                  <LocationIcon />
                                                </span>
                                                <div className="t-sub-title-box">
                                                  <span className="sub-title">
                                                    {item.address}
                                                  </span>
                                                </div>
                                                <span
                                                  className="map-link"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    setMapCenter({
                                                      lat: Number(
                                                        item?.latitude
                                                      ),
                                                      lng: Number(
                                                        item?.longitude
                                                      ),
                                                    });
                                                    setMapSidebar(true);
                                                  }}
                                                >
                                                  Open maps
                                                </span>
                                              </div>
                                            ) : (
                                              <div
                                                className="content-items"
                                                onClick={(e) =>
                                                  e.stopPropagation()
                                                }
                                              >
                                                <div
                                                  className="open-maps-box-booking-detail"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <img
                                                    alt=""
                                                    src="/static/meet.svg"
                                                  />
                                                  <span
                                                    className="meet-link"
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      window.open(
                                                        item.virtualLink
                                                      );
                                                    }}
                                                  >
                                                    Meet Link
                                                  </span>
                                                  <CopyToClipboard
                                                    onClick={(e) =>
                                                      e.stopPropagation()
                                                    }
                                                    onCopy={() =>
                                                      showToast(
                                                        "Text copied!",
                                                        "success"
                                                      )
                                                    }
                                                    text={item.virtualLink}
                                                  >
                                                    <img
                                                      alt=""
                                                      src="/static/copyblue.svg"
                                                      className="copy-btn"
                                                    />
                                                  </CopyToClipboard>
                                                </div>
                                              </div>
                                            )}
                                          </div>

                                          <div className="avatar-participants-container d-none d-md-flex">
                                            <div
                                              className="cal-btn d-none d-md-flex flex-column justify-content-center align-items-end  gap-2"
                                              onClick={(e) =>
                                                e.stopPropagation()
                                              }
                                            >
                                              <AddToCalendarButton
                                                name={item?.title}
                                                label={"Add to Calendar"}
                                                startDate={item?.date}
                                                timeZone="Australia/Sydney"
                                                location={
                                                  item?.listingEventType === "P"
                                                    ? `https://www.google.com/maps/place/${item?.latitude},${item?.longitude}`
                                                    : ""
                                                }
                                                startTime={item?.startTime?.substring(
                                                  0,
                                                  5
                                                )}
                                                endTime={item?.endTime?.substring(
                                                  0,
                                                  5
                                                )}
                                                trigger="click"
                                                hideCheckmark={true}
                                                hideBranding
                                                size="5|3|2"
                                                options={[
                                                  "Apple",
                                                  "Google",
                                                  "Outlook.com",
                                                  "iCal",
                                                ]}
                                                styleLight="--btn-background: #faebe3;--btn-background-hover:   #faebe3; --btn-text: #ee7830;--btn-text-hover: #ee7830; --font: Poppins ;"
                                                styleDark="--btn-background: #faebe3; --btn-text: #ee7830; --font: Georgia, 'Times New Roman', Times, serif;"
                                              />
                                              <div>
                                                <BasicButton
                                                  onClick={() =>
                                                    handleViewDetails(
                                                      item?.id,
                                                      item?.event?.listing
                                                        ?.listingType,
                                                      item?.event?.id
                                                    )
                                                  }
                                                  btnTxt="View Details"
                                                  endIcon={<ArrowForward />}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="d-md-none d-flex justify-content-between">
                                          <div
                                            className="cal-btn d-flex justify-content-end"
                                            style={{
                                              width: "100%",
                                            }}
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <AddToCalendarButton
                                              name={item?.title}
                                              label={"Add to Calendar"}
                                              hideBranding
                                              timeZone="Australia/Sydney"
                                              location={
                                                item?.listingEventType === "P"
                                                  ? `https://www.google.com/maps/place/${item?.latitude},${item?.longitude}`
                                                  : ""
                                              }
                                              startDate={item?.date}
                                              startTime={item?.startTime?.substring(
                                                0,
                                                5
                                              )}
                                              endTime={item?.endTime?.substring(
                                                0,
                                                5
                                              )}
                                              trigger="click"
                                              hideCheckmark={true}
                                              size="5|3|2"
                                              options={[
                                                "Apple",
                                                "Google",
                                                "Outlook.com",
                                                "iCal",
                                              ]}
                                              styleLight="--btn-background: #faebe3;--btn-background-hover:   #faebe3; --btn-text: #ee7830;--btn-text-hover: #ee7830; --font: Poppins ;"
                                              styleDark="--btn-background: #faebe3; --btn-text: #ee7830; --font: Georgia, 'Times New Roman', Times, serif;"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              ))}
                            </div>
                          ))}
                        </InfiniteScroll>
                      ) : (
                        <div className="empty-data">
                          <img src="/static/EmptyBooking.svg" alt="" />
                          <p>No bookings yet</p>
                          <p style={{ fontSize: 12 }}>
                            Explore more listing and start booking now.
                          </p>
                        </div>
                      )}
                    </>
                  )}

                  {activeTab === tabs[1] ? (
                    <>
                      {isLoading ? (
                        <ApiLoader />
                      ) : scheduleData.length > 0 ? (
                        <>
                          <InfiniteScroll
                            dataLength={scheduleData?.length}
                            next={fetchMoreData}
                            hasMore={moreNextUrl ? true : false}
                            loader={<ApiLoader />}
                          >
                            {Object.keys(groupedScheduleData).map((month) => (
                              <div
                                key={month}
                                className="booking_wrapper_profile"
                              >
                                <div className="month-title">{month}</div>
                                {groupedScheduleData[month].map((item) => (
                                  <div
                                    key={item.id}
                                    className="upcoming-schedule-row-outer"
                                  >
                                    {item && (
                                      <>
                                        <div className="sb-date-cont">
                                          <p className="sb-day mb-0">
                                            {moment(item?.date).format(
                                              "YYYY-MM-DD"
                                            ) ===
                                            moment(Date.now()).format(
                                              "YYYY-MM-DD"
                                            )
                                              ? "Today"
                                              : moment(item?.date).format(
                                                  "ddd"
                                                )}
                                          </p>
                                          <p className="sb-date">
                                            {moment(item?.date)
                                              .format("Do")
                                              .slice(0, -2)}
                                            <sup>
                                              {moment(item?.date)
                                                .format("Do")
                                                .slice(-2)}
                                            </sup>
                                          </p>
                                        </div>
                                        <div
                                          style={{ cursor: "pointer" }}
                                          className="upcoming-schedule-wrap"
                                          onClick={() => {
                                            navigate(
                                              `/listing-two/${item.event.listing.id}`
                                            );
                                          }}
                                        >
                                          <div className="card-content">
                                            <div className="left">
                                              <div className="title">
                                                <div className="event_desc">
                                                  {item.title}
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    gap: "12px",
                                                  }}
                                                >
                                                  <img
                                                    alt=""
                                                    className="package_resp"
                                                    src={
                                                      item.listingType === "P"
                                                        ? "/static/bookingpacakge.svg"
                                                        : "/static/bookingsession.svg"
                                                    }
                                                  />
                                                  <img
                                                    alt=""
                                                    className="virtual_resp"
                                                    src={
                                                      item.listingEventType ===
                                                      "P"
                                                        ? "/static/booking/bookingPhysical.svg"
                                                        : "/static/booking/bookingVirtual.svg"
                                                    }
                                                  />
                                                </div>
                                              </div>

                                              <div className="content-items">
                                                <div className="listing_detail_content">
                                                  {item?.listingEventType ===
                                                  "V" ? (
                                                    <p>
                                                      Event Type:{" "}
                                                      <span> Virtual </span>
                                                      <img
                                                        src="/static/Internet.svg"
                                                        alt="Internet Icon"
                                                        style={{
                                                          width: "16px",
                                                        }}
                                                      />
                                                    </p>
                                                  ) : (
                                                    <p>
                                                      Event Type:{" "}
                                                      <span
                                                        className="event-type-orange"
                                                        style={{
                                                          color: "#ee762f",
                                                        }}
                                                      >
                                                        {" "}
                                                        Physical{" "}
                                                      </span>
                                                      <img
                                                        src={Vector2}
                                                        alt="Internet Icon"
                                                        style={{
                                                          width: "16px",
                                                        }}
                                                      />
                                                    </p>
                                                  )}
                                                </div>
                                                <div className="d-flex align-items-center">
                                                  <TimeIcon color="#25272D" />
                                                  <span
                                                    className="sub-title"
                                                    style={{
                                                      marginLeft: "4px",
                                                    }}
                                                  >
                                                    {moment(
                                                      item?.startTime?.substring(
                                                        0,
                                                        5
                                                      ),
                                                      ["HH:mm"]
                                                    ).format("hh:mmA")}
                                                  </span>
                                                  <span className="sub-title">
                                                    -
                                                  </span>
                                                  <span className="sub-title">
                                                    {" "}
                                                    {moment(
                                                      item?.endTime?.substring(
                                                        0,
                                                        5
                                                      ),
                                                      ["HH:mm"]
                                                    ).format("hh:mmA")}
                                                  </span>
                                                </div>
                                              </div>
                                              {item.listingEventType === "P" ? (
                                                <div className="content-items">
                                                  <span>
                                                    <LocationIcon />
                                                  </span>
                                                  <div className="t-sub-title-box">
                                                    <span className="sub-title">
                                                      {item.address}
                                                    </span>
                                                  </div>
                                                  <span
                                                    className="map-link"
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      setMapCenter({
                                                        lat: Number(
                                                          item?.latitude
                                                        ),
                                                        lng: Number(
                                                          item?.longitude
                                                        ),
                                                      });
                                                      setMapSidebar(true);
                                                    }}
                                                  >
                                                    Open maps
                                                  </span>
                                                </div>
                                              ) : (
                                                <div
                                                  className="content-items"
                                                  onClick={(e) =>
                                                    e.stopPropagation()
                                                  }
                                                >
                                                  <div
                                                    className="open-maps-box-booking-detail"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <img
                                                      alt=""
                                                      src="/static/meet.svg"
                                                    />
                                                    <span
                                                      className="meet-link"
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        window.open(
                                                          item.virtualLink
                                                        );
                                                      }}
                                                    >
                                                      Meet Link
                                                    </span>
                                                    <CopyToClipboard
                                                      onClick={(e) =>
                                                        e.stopPropagation()
                                                      }
                                                      onCopy={() =>
                                                        showToast(
                                                          "Text copied!",
                                                          "success"
                                                        )
                                                      }
                                                      text={item.virtualLink}
                                                    >
                                                      <img
                                                        alt=""
                                                        src="/static/copyblue.svg"
                                                        className="copy-btn"
                                                      />
                                                    </CopyToClipboard>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                ))}
                              </div>
                            ))}
                          </InfiniteScroll>
                        </>
                      ) : (
                        <div className="empty-data">
                          <img src="/static/EmptyBooking.svg" alt="" />
                          <p>No Archive bookings</p>
                          <p style={{ fontSize: 12 }}>
                            No archive history to show
                          </p>
                        </div>
                      )}
                    </>
                  ) : null}

                  {activeTab === tabs[2] ? (
                    <>
                      {isLoading ? (
                        <ApiLoader />
                      ) : scheduleData.length > 0 ? (
                        <InfiniteScroll
                          dataLength={scheduleData?.length}
                          next={fetchMoreData}
                          hasMore={moreNextUrl ? true : false}
                          loader={<ApiLoader />}
                        >
                          {Object.keys(groupedScheduleData).map((month) => (
                            <div
                              key={month}
                              className="booking_wrapper_profile"
                            >
                              <div className="month-title">{month}</div>
                              {groupedScheduleData[month].map((item) => (
                                <div
                                  key={item.id}
                                  className="upcoming-schedule-row-outer"
                                  style={{
                                    flexDirection: "column",
                                  }}
                                >
                                  {item && (
                                    <>
                                      <div
                                        className="d-flex justify-content-between"
                                        style={{
                                          width: "100%",
                                        }}
                                      >
                                        <div className="sb-date-cont">
                                          <p className="sb-day mb-0">
                                            {moment(item?.date).format(
                                              "YYYY-MM-DD"
                                            ) ===
                                            moment(Date.now()).format(
                                              "YYYY-MM-DD"
                                            )
                                              ? "Today"
                                              : moment(item?.date).format(
                                                  "ddd"
                                                )}
                                          </p>
                                          <p className="sb-date">
                                            {moment(item?.date)
                                              .format("Do")
                                              .slice(0, -2)}
                                            <sup>
                                              {moment(item?.date)
                                                .format("Do")
                                                .slice(-2)}
                                            </sup>
                                          </p>
                                        </div>
                                        <div
                                          style={{ cursor: "pointer" }}
                                          className="upcoming-schedule-wrap"
                                          onClick={() => {
                                            navigate(
                                              `/listing-two/${item.event.listing.id}`
                                            );
                                          }}
                                        >
                                          <div className="card-content">
                                            <div className="left">
                                              <div className="title">
                                                <div className="event_desc">
                                                  {item.title}
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    gap: "12px",
                                                  }}
                                                >
                                                  <img
                                                    alt=""
                                                    className="package_resp"
                                                    src={
                                                      item.listingType === "P"
                                                        ? "/static/bookingpacakge.svg"
                                                        : "/static/bookingsession.svg"
                                                    }
                                                  />
                                                  <img
                                                    alt=""
                                                    className="virtual_resp"
                                                    src={
                                                      item.listingEventType ===
                                                      "P"
                                                        ? "/static/booking/bookingPhysical.svg"
                                                        : "/static/booking/bookingVirtual.svg"
                                                    }
                                                  />
                                                </div>
                                              </div>

                                              <div className="content-items">
                                                <div className="listing_detail_content">
                                                  {item?.listingEventType ===
                                                  "V" ? (
                                                    <p>
                                                      Event Type:{" "}
                                                      <span> Virtual </span>
                                                      <img
                                                        src="/static/Internet.svg"
                                                        alt="Internet Icon"
                                                        style={{
                                                          width: "16px",
                                                        }}
                                                      />
                                                    </p>
                                                  ) : (
                                                    <p>
                                                      Event Type:{" "}
                                                      <span
                                                        className="event-type-orange"
                                                        style={{
                                                          color: "#ee762f",
                                                        }}
                                                      >
                                                        {" "}
                                                        Physical{" "}
                                                      </span>
                                                      <img
                                                        src={Vector2}
                                                        alt="Internet Icon"
                                                        style={{
                                                          width: "16px",
                                                        }}
                                                      />
                                                    </p>
                                                  )}
                                                </div>
                                                <div className="d-flex align-items-center">
                                                  <TimeIcon color="#25272D" />
                                                  <span
                                                    className="sub-title"
                                                    style={{
                                                      marginLeft: "4px",
                                                    }}
                                                  >
                                                    {moment(
                                                      item?.startTime?.substring(
                                                        0,
                                                        5
                                                      ),
                                                      ["HH:mm"]
                                                    ).format("hh:mmA")}
                                                  </span>
                                                  <span className="sub-title">
                                                    -
                                                  </span>
                                                  <span className="sub-title">
                                                    {" "}
                                                    {moment(
                                                      item?.endTime?.substring(
                                                        0,
                                                        5
                                                      ),
                                                      ["HH:mm"]
                                                    ).format("hh:mmA")}
                                                  </span>
                                                </div>
                                              </div>
                                              {item.listingEventType === "P" ? (
                                                <div className="content-items">
                                                  <span>
                                                    <LocationIcon />
                                                  </span>
                                                  <div className="t-sub-title-box">
                                                    <span className="sub-title">
                                                      {item.address}
                                                    </span>
                                                  </div>
                                                  <span
                                                    className="map-link"
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      setMapCenter({
                                                        lat: Number(
                                                          item?.latitude
                                                        ),
                                                        lng: Number(
                                                          item?.longitude
                                                        ),
                                                      });
                                                      setMapSidebar(true);
                                                    }}
                                                  >
                                                    Open maps
                                                  </span>
                                                </div>
                                              ) : (
                                                <div
                                                  className="content-items"
                                                  onClick={(e) =>
                                                    e.stopPropagation()
                                                  }
                                                >
                                                  <div
                                                    className="open-maps-box-booking-detail"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <img
                                                      alt=""
                                                      src="/static/meet.svg"
                                                    />
                                                    <span
                                                      className="meet-link"
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        window.open(
                                                          item.virtualLink
                                                        );
                                                      }}
                                                    >
                                                      Meet Link
                                                    </span>
                                                    <CopyToClipboard
                                                      onClick={(e) =>
                                                        e.stopPropagation()
                                                      }
                                                      onCopy={() =>
                                                        showToast(
                                                          "Text copied!",
                                                          "success"
                                                        )
                                                      }
                                                      text={item.virtualLink}
                                                    >
                                                      <img
                                                        alt=""
                                                        src="/static/copyblue.svg"
                                                        className="copy-btn"
                                                      />
                                                    </CopyToClipboard>
                                                  </div>
                                                </div>
                                              )}
                                              <div>
                                                <div className="content-items">
                                                  <span className="cancel_date">
                                                    Cancelled on{" "}
                                                    {moment(
                                                      scheduleData?.cancelDate
                                                    ).format("ddd D MMM")}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="d-none d-md-block">
                                              <div
                                                className="refund-status-box"
                                                style={{
                                                  border: `1px solid ${
                                                    item?.status === "RF"
                                                      ? "#29CC6A"
                                                      : "#EE7830"
                                                  }`,
                                                }}
                                              >
                                                <div className="title">
                                                  Refund:
                                                </div>
                                                <div
                                                  className="status"
                                                  style={{
                                                    color: `${
                                                      item?.status === "RF"
                                                        ? "#29CC6A"
                                                        : "#EE7830"
                                                    }`,
                                                  }}
                                                >
                                                  {item?.status === "RF"
                                                    ? "Done"
                                                    : "In Progress"}
                                                </div>
                                                <div className="ref_amt">
                                                  $
                                                  {item?.refundedTotalAmount ||
                                                    0}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="d-flex gap-2 d-md-none">
                                        <div
                                          style={{
                                            maxWidth: "50%",
                                            fontSize: "14px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              maxWidth: "60%",
                                              margin: "auto",
                                            }}
                                          >
                                            <span className="cancel_date">
                                              Cancelled on{" "}
                                              {moment(
                                                scheduleData?.cancelDate
                                              ).format("ddd D MMM")}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          className="refund-status-box"
                                          style={{
                                            border: `1px solid ${
                                              item?.status === "RF"
                                                ? "#29CC6A"
                                                : "#EE7830"
                                            }`,
                                          }}
                                        >
                                          <div>
                                            <div className="title">Refund:</div>
                                            <div
                                              className="status"
                                              style={{
                                                color: `${
                                                  item?.status === "RF"
                                                    ? "#29CC6A"
                                                    : "#EE7830"
                                                }`,
                                              }}
                                            >
                                              {item?.status === "RF"
                                                ? "Done"
                                                : "In Progress"}
                                            </div>
                                          </div>
                                          <div className="ref_amt">
                                            ${item?.refundedTotalAmount || 0}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              ))}
                            </div>
                          ))}
                        </InfiniteScroll>
                      ) : (
                        <div className="empty-data">
                          <img src="/static/EmptyBooking.svg" alt="" />
                          <p>No Cancelled bookings</p>
                          <p style={{ fontSize: 12 }}>
                            Glad you do not have any cancelled events
                          </p>
                        </div>
                      )}
                    </>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {showCsvPopup ? (
        <div className={"modal show"}>
          <div className="modal-section log_out_modal csv-modal-wrap">
            <div className="close-btn-csv">
              <img
                src="/static/cross-black.svg"
                onClick={() => {
                  setShowCsvPopup(false);
                }}
                className="close-btn hide-large"
                alt=""
              />
            </div>
            <div className="modal-top-part">
              <div>
                <img alt="" src="/static/file-type-excel.svg" />
              </div>

              <h5>Export Your Bookings</h5>
              <p>
                Effortlessly transfer your listings to an Excel spreadsheet for
                easy organization and analysis.
              </p>
            </div>
            <div className="button_option">
              <Button
                btnTxt="Download as Excel(.csv)"
                className={"backtologin"}
                onClick={downloadCsv}
              />
              <Button
                btnTxt="Not now"
                className={"backtologin notnow"}
                onClick={() => setShowCsvPopup(false)}
              />
            </div>
          </div>
        </div>
      ) : null}
      {mapSidebar ? (
        <div className="map-fixed-sidebar" style={{ zIndex: "1000" }}>
          <div className="map-sidebar-box ">
            <div className="map-heading d-none d-md-flex mt-4">
              <img
                src="/static/cross-black.svg"
                onClick={() => setMapSidebar(false)}
                className="close-btn"
                alt=""
              />
              <p>Maps</p>
            </div>
            <div className="sb-map-container sb-map-container-new">
              <div className="d-md-none">
                <GlobalHeader />
              </div>
              <div
                className="map-back-icon d-md-none"
                onClick={() => setMapSidebar(false)}
              >
                <LeftArrowStickIcon />
              </div>
              <GoogleMapContainer height="100%" center={mapCenter} />
            </div>
          </div>
        </div>
      ) : null}
    </DashboardWrapper>
  );
}
