import React from "react";

const EditIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15.5047 3.4478C16.9237 2.02893 19.233 2.05806 20.6157 3.51227C21.95 4.91558 21.9221 7.12679 20.5529 8.49604L19.7501 9.29882L14.7019 4.25061L15.5047 3.4478ZM13.8533 5.09912L4.25494 14.6971C3.86154 15.0904 3.58849 15.5878 3.46783 16.1309L2.41469 20.8708C2.37019 21.0711 2.4311 21.2802 2.57618 21.4253C2.72126 21.5703 2.93039 21.6312 3.13065 21.5867L7.84232 20.5389C8.40323 20.4141 8.91696 20.1321 9.32328 19.7257L18.9016 10.1474L13.8533 5.09912Z"
        fill="#8F939A"
      />
    </svg>
  );
};

export default EditIcon;
