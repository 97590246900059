import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { Navigate } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../utils/RouteConstants";

const OnboardingRoutes = ({ auth, children }) => {
  console.log('auth: ', auth);
  const isAuth = Boolean(localStorage.getItem("fm_token"));
  const isProfileCompleted = Boolean(auth?.moreUserDetails?.isProfileCompleted);

  if (isAuth && !isProfileCompleted) {
    console.log('object')
    return children;
  } else {
    if (isAuth && isProfileCompleted) {
      return <Navigate to={ROUTE_CONSTANTS.landing} />;
    } else {
      return <Navigate to={ROUTE_CONSTANTS.register} />;
    }
  }
};

OnboardingRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(OnboardingRoutes);
