import { forwardRef, useEffect, useState } from "react";
import { Controller, useController } from "react-hook-form";
import { emptyFunction, showToast } from "../utils";
interface InputFieldProps {
  [key: string]: any;
  name: string;
  type: React.HTMLInputTypeAttribute;
  inputLabel?: string;
  as?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
  hideErrorMessage?: boolean;
  errors?: object | any;
  value?: string | boolean | any;
  maxlength?: number | any;
  id?: string;
  control?: any;
  extramsg?: string;
  isChecked?: boolean;
  customOnChange?: any;
  reference?: any;
  onlyPastDates?: boolean;
  onlyFuturedates?: boolean;
  onKeyDown?: any;
  fileOnChange?: any;
  getValues?: any;
  setValue?: any;
  pattern?: any;
  handleAge?: any;
  handleNotes?: any;
  isListingTextArea?: any;
  isSpecialInstruction?: any;
  onBlur: any;
  displayErrorOutlined?: any;
}

const InputField = forwardRef((props: InputFieldProps, ref: any) => {
  const {
    name,
    type,
    extramsg,
    inputLabel,
    placeholder,
    style,
    control,
    className,
    errors,
    id,
    setValue,
    customOnChange,
    fileOnChange,
    reference,
    maxlength,
    getValues,
    disabled,
    pattern,
    onKeyDown = emptyFunction,
    handleAge = emptyFunction,
    handleNotes = emptyFunction,
    isListingTextArea = false,
    isSpecialInstruction = false,
    onKeyPress,
    addIconPadding = false,
    addIconPaddingTop = false,
    onBlur = () => null,
    displayErrorOutlined = false,
    manualValue,
    manualClear = () => null,
  } = props;
  
  // const [isFocus] = useState(false);
  const [isFocus,setIsFocus] = useState(false);
  const [isPasteEvent, setIsPasteEvent] = useState(false);
  const [showRemoveFilledOnPaste, setShowRemoveFilledOnPaste] = useState(false);
  const {
    field: { onChange, value = manualValue },
  } = useController({
    name,
    control,
  });
  const handleOnchange = (e: any) => {
    if (customOnChange) {
      customOnChange(e.target);
    } else if (fileOnChange) {
      fileOnChange(e);
    }
    onChange(e);
  };
  const handleOnFocus = () => {
    setIsFocus(true);
  };
  const handleOnBlur = () => {
    setIsFocus(false);
    // onBlur();
  };
  const renderMainInput = () => {
    switch (type) {
      case "radio":
        return (
          <Controller
            control={control}
            name={name}
            render={() => {
              return (
                <input
                  type={type}
                  onChange={onChange}
                  id={value}
                  checked={value === id}
                  placeholder={placeholder}
                  value={id}
                  disabled={disabled}
                  onKeyDown={onKeyDown || {}}
                  onKeyPress={onKeyPress}
                />
              );
            }}
          />
        );
      case "textarea":
        return (
          <Controller
            control={control}
            name={name}
            render={({ field: { value, onChange } }) => {
              return (
                <textarea
                  onChange={(e) => {
                    if (!maxlength) {
                      onChange(e);
                      handleNotes(e);
                    } else if (
                      e.target.value?.split(" ")?.length <= maxlength
                    ) {
                      onChange(e);
                      handleNotes(e);
                    }
                  }}
                  placeholder={isFocus ? placeholder : ""}
                  value={value || ""}
                  // maxLength={maxlength}
                  disabled={disabled}
                  onKeyPress={onKeyPress}
                  onFocus={handleOnFocus}
                  onBlur={handleOnBlur}
                />
              );
            }}
          />
        );
      case "checkbox":
        return (
          <Controller
            control={control}
            name={name}
            render={() => {
              return (
                <input
                  name={name}
                  type="checkbox"
                  onChange={(e) => {
                    handleOnchange(e);
                  }}
                  id={id || ""}
                  value={value}
                  checked={value}
                  onKeyDown={onKeyDown || {}}
                  onKeyPress={onKeyPress}
                />
              );
            }}
          />
        );
      case "number":
        return (
          <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value } }) => {
              return (
                <input
                  onChange={(e) => {
                    const numericValue =
                      e?.target?.value === "" ? "" : Number(e?.target?.value);
                    onChange(numericValue);
                    if (!maxlength) {
                      handleOnchange(e);
                      handleAge();
                    } else if (e.target.value?.length <= maxlength) {
                      handleOnchange(e);
                      handleAge();
                    }
                    if (
                      e?.target?.value?.length > maxlength &&
                      isPasteEvent &&
                      name === "aNumber"
                    ) {
                      showToast(
                        "Account number must be between 6 to 10 characters long",
                        "error"
                      );
                      setIsPasteEvent(false);
                    }
                    if (
                      e?.target?.value?.length > maxlength &&
                      isPasteEvent &&
                      name === "bNumber"
                    ) {
                      showToast("BSB number should be 6 digits", "error");
                      setIsPasteEvent(false);
                    }
                  }}
                  onPaste={() => {
                    setIsPasteEvent(true);
                    setShowRemoveFilledOnPaste(true);
                  }}
                  type={type}
                  id={id || ""}
                  ref={reference}
                  placeholder={placeholder}
                  value={value ? value : value === 0 ? value : null}
                  min={1}
                  onKeyDown={onKeyDown || {}}
                  pattern={pattern}
                  max={maxlength}
                  disabled={disabled}
                  onKeyPress={onKeyPress}
                  onWheel={(e) => e.currentTarget.blur()} //prevent value change on scroll
                  onBlur={onBlur}
                />
              );
            }}
            onPaste={() => {
              setIsPasteEvent(true);
              setShowRemoveFilledOnPaste(true);
            }}
            type={type}
            id={id || ""}
            ref={reference}
            placeholder={isFocus ? placeholder : ""}
            value={value ? value : value === 0 ? value : null}
            min={1}
            onKeyDown={onKeyDown || {}}
            pattern={pattern}
            max={maxlength}
            disabled={disabled}
            onKeyPress={onKeyPress}
            onWheel={(e) => e.currentTarget.blur()} //prevent value change on scroll
            // onBlur={onBlur}
            onFocus={handleOnFocus}
            onBlur={() => {
              handleOnBlur();
              onBlur();
            }}
          />
        );
      case "file":
        return (
          <input
            onChange={(e) => {
              handleOnchange(e);
            }}
            type={type}
            ref={reference}
            id={id || ""}
            value={value || ""}
            onKeyPress={onKeyPress}
          />
        );
      default:
        return (
          <Controller
            control={control}
            name={name}
            render={() => {
              return (
                <input
                  onChange={(e) => {
                    handleOnchange(e);
                  }}
                  type={type}
                  ref={reference}
                  maxLength={maxlength}
                  id={id || ""}
                  placeholder={isFocus ? placeholder : ""}
                  value={value || ""}
                  onKeyDown={onKeyDown || {}}
                  style={{
                    border: displayErrorOutlined
                      ? "1px solid #FF3B30"
                      : "1px solid #dcdcdc",
                  }}
                  autoComplete={"off"}
                  disabled={disabled}
                  onKeyPress={onKeyPress}
                  onFocus={handleOnFocus}
                  onBlur={handleOnBlur}
                />
              );
            }}
          />
        );
    }
  };
  useEffect(() => {
    if (id === "autocomplete") {
      onChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return (
    <div
      style={style}
      className={`form_group ${props?.value || ( value ||isFocus ) ? "focused" : ""} ${
        className ? className : ""
      } `}
    >
      <div
        className={
          errors && errors[name] ? "input_box input_error" : "input_box"
        }
      >
        {inputLabel && <span className="input_label">{inputLabel}</span>}
        {renderMainInput()}
        {type === "textarea" ? (
          <>
            {isListingTextArea ? (
              <span className="count">
                {`${
                  getValues(name)
                    ?.split(/\s+/)
                    ?.filter((word) => word.trim() !== "").length || 0
                }/${maxlength}
                `}
              </span>
            ) : isSpecialInstruction ? (
              <span className="count">
                {`${
                  getValues(name)
                    ?.split(/\s+/)
                    ?.filter((word) => word.trim() !== "").length || 0
                }/${maxlength}
                `}
              </span>
            ) : (
              <span className="count">
                {getValues(name)?.trim()?.split(" ")?.length || 0}/ {maxlength}
              </span>
            )}
          </>
        ) : null}

        {extramsg && <p className="extramsg">{extramsg}</p>}
        {(value?.length > 0 || showRemoveFilledOnPaste) && (
          <img
            src="/static/cross-circle-gray-dark.svg"
            className={`${addIconPadding ? "icon_adjust" : ""}${
              disabled ? "disabled" : ""
            } ${addIconPaddingTop ? " icon_adjust_top " : ""} cross-icon`}
            // className={disabled ? "disabled cross-icon " : "cross-icon"}
            alt=""
            onClick={() => {
              if (type === "number") {
                setValue(name, null);
                if (reference) {
                  reference.current.value = null;
                }
                setShowRemoveFilledOnPaste(false);
              } else {
                setValue(name, "");
              }
              manualClear()
            }}
          />
        )}
      </div>
      {errors && errors[name] && (
        <span className="text-danger">{errors[name]?.message}</span>
      )}
    </div>
  );
});

export default InputField;
