import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react'

type Props = {
  label?: string;
  checked?: boolean;
  handleChecked?: any;
};

const CheckboxInput = ({
  label = "",
  checked = false,
  handleChecked,
}: Props) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          sx={{
            color: "#EE7830",
            "&.Mui-checked": {
              color: "#EE7830",
            },
          }}
          checked={checked}
          onChange={handleChecked}
        />
      }
      label={label}
      sx={{
        "& .MuiFormControlLabel-label": {
          fontFamily: "Poppins, sans-serif",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",
          color: "#25272D",
        },
        "@media screen and (max-width:768px)": {
          "& .MuiFormControlLabel-label": {
            fontSize: "12px",
          },
        },
      }}
    />
  );
};

export default CheckboxInput