import React, { Fragment } from "react";
import dayjs from "dayjs";
import { FREQUENCY_TYPE, RECURRING_TYPE, SCHEDULE_TYPE } from "../types";
import moment from "moment";
import { STRING_CONSTANTS } from "../utils/StringConstant";

export const handleShowScheduleDateOld = (scheduleData) => {
  if (
    scheduleData?.type === SCHEDULE_TYPE.O &&
    scheduleData?.oneTimeDate &&
    scheduleData?.startTime &&
    scheduleData?.endTime
  ) {
    return (
      <>
        <p>{dayjs(scheduleData?.oneTimeDate).format("MMMM Do, YYYY")}</p>
        <small>
          {`${moment(scheduleData?.startTime, "YYYY-MM-DD hh:mm A").format(
            "hh:mm A"
          )} - ${moment(scheduleData?.endTime, "YYYY-MM-DD hh:mm A").format(
            "hh:mm A"
          )}`}
        </small>
      </>
    );
  } else if (
    scheduleData?.type === SCHEDULE_TYPE.R &&
    scheduleData?.recurringType === RECURRING_TYPE.C
  ) {
    return (
      <>
        <p>
          {scheduleData?.customDates?.length > 1
            ? `${scheduleData?.customDates?.length} days selected`
            : scheduleData?.customDates?.length === 1
            ? `${scheduleData?.customDates?.length} day selected`
            : ""}
        </p>
        <small>
          {`${moment(scheduleData?.startTime, "YYYY-MM-DD hh:mm A").format(
            "hh:mm A"
          )} - ${moment(scheduleData?.endTime, "YYYY-MM-DD hh:mm A").format(
            "hh:mm A"
          )}`}
        </small>
      </>
    );
  } else if (
    scheduleData?.type === SCHEDULE_TYPE.R &&
    scheduleData?.recurringType === SCHEDULE_TYPE.R &&
    scheduleData?.recurringRepeatType === FREQUENCY_TYPE.W
  ) {
    return (
      <>
        <p>
          Every {scheduleData?.repeatNumber} {scheduleData?.recurringRepeatType}{" "}
        </p>
        <small>
          {scheduleData?.weekDays?.length > 0
            ? scheduleData?.weekDays?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}> {item.slice(0, 3) + " "}</Fragment>
                );
              })
            : null}
          <small>
            {`${moment(scheduleData?.startTime, "YYYY-MM-DD hh:mm A").format(
              "hh:mm A"
            )} - ${moment(scheduleData?.endTime, "YYYY-MM-DD hh:mm A").format(
              "hh:mm A"
            )}`}
          </small>
        </small>
      </>
    );
  } else if (
    scheduleData?.type === SCHEDULE_TYPE.R &&
    scheduleData?.recurringType === SCHEDULE_TYPE.R &&
    scheduleData?.recurringRepeatType === FREQUENCY_TYPE.D
  ) {
    return (
      <>
        <p>
          Every {scheduleData?.repeatNumber} {scheduleData?.recurringRepeatType}{" "}
        </p>
        <small>
          {`${moment(scheduleData?.startTime, "YYYY-MM-DD hh:mm A").format(
            "hh:mm A"
          )} - ${moment(scheduleData?.endTime, "YYYY-MM-DD hh:mm A").format(
            "hh:mm A"
          )}`}
        </small>
      </>
    );
  } else if (
    scheduleData?.type === SCHEDULE_TYPE.R &&
    scheduleData?.recurringType === RECURRING_TYPE.R &&
    scheduleData?.recurringRepeatType === FREQUENCY_TYPE.M
  ) {
    return (
      <>
        <p>
          Every {scheduleData?.repeatNumber} {scheduleData?.recurringRepeatType}{" "}
          {scheduleData?.monthlyFrequencyData?.split(" ")?.splice(1)?.join(" ")}
        </p>
        <small>
          {`${moment(scheduleData?.startTime, "YYYY-MM-DD hh:mm A").format(
            "hh:mm A"
          )} - ${moment(scheduleData?.endTime, "YYYY-MM-DD hh:mm A").format(
            "hh:mm A"
          )}`}
        </small>
      </>
    );
  } else {
    return (
      <p style={{ color: "#8F939A" }}>
        {STRING_CONSTANTS.schedule_date_placeholder}
      </p>
    );
  }
};
