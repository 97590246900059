import React from "react";
interface LoaderProps {
  style?: object;
}

const Loader: React.FC<LoaderProps> = ({ style }) => {
  return (
    <div className="lds-ring" style={style}>
      <img style={{ width: "18px" }} src="/static/spin.svg" alt="loader" />
    </div>
  );
};
export default Loader;
