import { useState } from "react";
import IconArrowUp from "../../assets/img/createListingIcon/arrow-up.svg";
import IconArrowDown from "../../assets/img/createListingIcon/arrow-down.svg";

const Accordion = ({ title,titleClassName, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className={titleClassName}>{title}</div>
        <div>
          <img
            src={!isOpen ? IconArrowDown : IconArrowUp}
            onClick={()=> setIsOpen((prev)=> !prev)}
            style={{ height: "24px", width: "24px" }}
            alt="arrow"
          />
        </div>
      </div>
      {
        isOpen && <div>{children}</div>
      }
    </div>
  );
};

export default Accordion;