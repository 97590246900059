import { STRING_CONSTANTS } from "../utils/StringConstant";
import CommonOutlineButton from "./CommonOutlineButton";
interface UserFlagged {
  onOk?: any;
}
const UserFlaggedModal = (props: UserFlagged) => {
  const { onOk } = props;
  return (
    <div className={"modal show"}>
      <div className="modal-section log_out_modal">
        <div className="modal-top-part" style={{ position: "relative" }}>
          <img src="/static/UserFlagged.svg" alt="" />
          <h5>User Flagged</h5>
          <p>
            Thanks for keeping our community safe! Rest assured, actions will be
            taken. You won't encounter this user again.
          </p>
        </div>
        <div className="" style={{ display: "flex", justifyContent: "center" }}>
          <CommonOutlineButton
            image=""
            width="100%"
            height="fit-content"
            text="Go back"
            className={"common_btn_grey"}
            onClick={() => onOk()}
          />
        </div>
      </div>
    </div>
  );
};

export default UserFlaggedModal;
