import { shortenName } from "../ConnectConstants";

const NameAge = ({ connectionName, connectionAge }) => {
  return (
    <div>
      <span
        style={{
          fontSize: "20px",
          fontWeight: "600",
          lineHeight: "28px",
          color: "#25272D",
          // fontFamily:"Poppins"
        }}
      >{`${shortenName(connectionName)}`}</span>
     {connectionAge ? `, ${connectionAge} years` : ""}
      <span
        style={{
          fontSize: "14px",
          fontWeight: "600",
          lineHeight: "28px",
          color: "#6C6D6F",
          // fontFamily:"Poppins"
        }}
      ></span>
    </div>
  );
};

export default NameAge;
