import React, {Dispatch, SetStateAction} from "react";
import GlobalHeader2 from "../../Dashboard/GlobalHeader2";

import ConnectSidebar from "./ConnectSidebar";
import useCheckDeviceScreen from "../../../hooks/useCheckScreen";
import ConnectDropdownMenu from "./ConnectMobileMenu";
import { createContext, useEffect, useState } from "react";
import { showToast } from "../../../utils";
import { apiConstants } from "../../../utils/Constants";
import { makeGetCall } from "../../../utils/Requests";
import GlobalFooter from "../../Dashboard/GlobalFooter";
import Requests from "./Requests";

export const mobileDropdownContext = createContext(null);
interface ConnectContextType {
  isProfileUpdated:boolean
  setIsProfileUpdated: Dispatch<SetStateAction<boolean>>;
}

const defaultContextValue: ConnectContextType = {
  isProfileUpdated:false,
  setIsProfileUpdated: () => {},
};

export const connectContext = createContext<ConnectContextType>(defaultContextValue);

const ConnectWrapper = (props: any) => {
  const { isMobile } = useCheckDeviceScreen();
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isProfileUpdated, setIsProfileUpdated] = useState(true);
  const [connectUserProfile, setConnectUserProfile] = useState({
    name: "",
    userType: "",
    isTrainer: false,
    profilePicture: "",
    profilePictureExtension: "",
    thumbnail: null,
    coverPhotoVideo: "",
    coverExtension: null,
    totalConnections: 0,
    eventsOrganized: 0,
    pendingConnectionRequests: 0,
  });

  const getConnectProfile = () => {
    makeGetCall({
      url: apiConstants.get_connect_profile,
    })
      .then((res) => {
        if (res.status.code === 200) {
          setConnectUserProfile(res.data);
          localStorage.setItem("userId", res.data.id);
          setIsProfileUpdated(false);
          setIsLoading(false);
        }
      })
      .catch((err: any) => {
        showToast(err, "error");
      });
  }
  useEffect(() => {
    if(isProfileUpdated){
      getConnectProfile();
    }
  }, [isProfileUpdated]);


  return (
    <div className="connect-page">
      <GlobalHeader2 />
      {isLoading ? (
        <div className="page_loader">
          <div
            className="lds-ring"
            style={{
              margin: "0 auto",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                style={{ width: "50px" }}
                src="/static/spinner.svg"
                alt="loader"
              />
            </div>

            <div className="text__container">
              <span>Please wait... Loading</span>
            </div>
          </div>
        </div>
      ) : isMobile ? (
        <mobileDropdownContext.Provider value={{ isOpen: isOpen }}>
          <div style={{ marginInline: "20px" }}>
            <ConnectDropdownMenu
              connectUserProfile={connectUserProfile}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
            <div className="connect-mobile-content">{props.children}</div>
          </div>
        </mobileDropdownContext.Provider>
      ) : (
        <connectContext.Provider value={{isProfileUpdated , setIsProfileUpdated}}>
        <div className="connect-wrapper">
          <div className="sidebar-wrapper">
            <ConnectSidebar
              connectUserProfile={connectUserProfile}
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
            />
            {!isCollapsed && <Requests />}
          </div>
          <div className="connect-content-wrapper">{props.children}</div>
        </div>
        </connectContext.Provider>
      )}
      <GlobalFooter />
    </div>
  );
};

export default ConnectWrapper;
