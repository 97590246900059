import { useState, useCallback } from "react";
import { memberTypeData } from "../../utils/Constants";
import { makePatchCall } from "../../utils/Requests";
import apiConfig from "../../utils/endpoints";
import { showToast } from "../../utils";
import ApiLoader from "../../global/ApiLoader";
import GlobalHeader2 from "../Dashboard/GlobalHeader2";
import GlobalFooter from "../Dashboard/GlobalFooter";
import { useNavigate } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";

const MemberType = () => {
  const [memberData, setMemberData] = useState(memberTypeData);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const MEMBER_USER_TYPES = {
    BUSINESS: "Business",
    INDIVIDUAL: "Individual",
    TRAINER: "Trainer",
  };

  const getPayload = (selectedLabel: string) => {
    let apiPayload: any = {};
    if (MEMBER_USER_TYPES.BUSINESS === selectedLabel) {
      apiPayload.isBusiness = true;
    } else if (MEMBER_USER_TYPES.INDIVIDUAL === selectedLabel) {
      apiPayload.isIndividual = true;
    } else if (MEMBER_USER_TYPES.TRAINER === selectedLabel) {
      apiPayload.isTrainer = true;
    }

    return apiPayload;
  };
  const handleMemberTypeUpdate = async (selectedLabel: string) => {
    setIsLoading(true);
    let apiPayload = await getPayload(selectedLabel);
    makePatchCall({
      url: apiConfig.profile,
      apiPayload,
    })
      .then((res) => {
        if (res.status.code === 200) {
          // showToast(res.status.message, "success");
          navigate(ROUTE_CONSTANTS.manage_profile);
        } else {
        }
        setIsLoading(false);
        
      })
      .catch((err) => {
        showToast(err, "error");
        setIsLoading(false);
      });
  };

  const handleUserTypeSelection = (selectedLabel: string) => {
    setMemberData((prevData) =>
      prevData.map((item) => ({
        ...item,
        isSelected: item.label === selectedLabel,
      }))
    );
    handleMemberTypeUpdate(selectedLabel);
    // setSelectedUserType(selectedLabel);
  };


  return (
    <div>
      <GlobalHeader2 />
      <div className="page-members">
        {isLoading ? (
          <div
            style={{ display: "flex", height: "100vh", alignItems: "center" }}
          >
            <ApiLoader />
          </div>
        ) : (
          <>
            <div className="select-heading">Select the user type</div>
            <div className="select-sub-heading">
              Please choose the type of user you are to enjoy a smooth and
              personalized experience with the app.
            </div>
            <div className="users-container">
              {memberData.map((item) => (
                <div className="user-type-wraps" key={item.label}>
                  <div
                    className={`img-user-wrap ${
                      item.isSelected ? "selected-color" : "not-selected"
                    }`}
                    onClick={() => handleUserTypeSelection(item.label)}
                  >
                    <img
                      className="icon-user-style"
                      src={item.img}
                      alt={item.alt}
                    />
                  </div>
                  <div className="user-label-text">{item.label}</div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MemberType;
