import React from "react";

const PinnedLocation: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.4211 12.5327C15.8628 10.0898 15.8628 6.12921 13.4211 3.68637C10.9793 1.24354 7.02041 1.24354 4.57864 3.68637C2.13687 6.12921 2.13687 10.0898 4.57864 12.5327L5.93725 13.8715L7.76192 15.6446L7.88057 15.7498C8.57261 16.3107 9.58601 16.2757 10.238 15.6448L12.414 13.5278L13.4211 12.5327ZM8.99985 10.7864C7.51992 10.7864 6.32019 9.58672 6.32019 8.10678C6.32019 6.62685 7.51992 5.42713 8.99985 5.42713C10.4798 5.42713 11.6795 6.62685 11.6795 8.10678C11.6795 9.58672 10.4798 10.7864 8.99985 10.7864Z"
        fill="#8F939A"
      />
    </svg>
  );
};

export default PinnedLocation;
