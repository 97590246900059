import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/system";

interface PrimaryButtonProps extends ButtonProps {
  maxWidth?: any;
}

const StyledButton = styled(Button)(({ maxWidth }: PrimaryButtonProps) => ({
  backgroundColor: "#EE7830",
  color: "#FFFFFF",
  fontFamily: "Poppins, sans-serif",
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "24px",
  textAlign: "center",
  textTransform: "capitalize",
  padding: "16px 44px",
  borderRadius: "12px",
  "&:hover": {
    backgroundColor: "#EE7830cc",
  },
  "@media screen and (max-width:768px)": {
    padding: "16px",
  },
}));

const PrimaryButton = ({
  children = "Submit",
  maxWidth = false,
  ...restProps
}: PrimaryButtonProps) => {
  return (
    <StyledButton
      sx={{
        maxWidth: maxWidth || "auto",
      }}
      variant="contained"
      {...restProps}
    >
      {children}
    </StyledButton>
  );
};

export default PrimaryButton;
