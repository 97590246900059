import React from "react";

interface ButtonProps {
  text: string;
  icon?: React.ReactNode; // This can be your icon component
  onClick?: () => void;
  onBlur?: () => void;
  className: string;
  checked: boolean;
  height: string;
  width: string;
}

const CommonCheckboxTwo: React.FC<ButtonProps> = ({
  text,
  icon,
  onClick,
  onBlur,
  className,
  checked,
  height = "20px",
  width = "20px",
  ...props
}) => {
  return (
    <>
      {checked ? (
        <img
          alt=""
          className="check-img"
          style={{ height: height, width: width, cursor: "pointer" }}
          src={icon}
          onClick={onClick}
        />
      ) : (
        <div
          className={`cursor-pointer ${
            checked ? "checked-checkbox" : "unchecked-checkbox"
          }`}
          onClick={onClick}
        ></div>
      )}
    </>
  );
};

export default CommonCheckboxTwo;
