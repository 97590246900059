import React from "react";

const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={"next-arrow-btn-container"}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <div className="next-arrow-btn">
          <img src="/static/chevron-black-left.svg" alt="" />
        </div>
      </div>
    );
  }

export default React.memo(SampleNextArrow);  