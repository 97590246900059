import { useEffect, useState } from "react";
import IconLocation from "../../../assets/img/connectIcons/location.png";
import { gettingDistance } from "../../../constants/helperConnectUser";

const Location = ({ distance }) => {
 const [away, setAway] = useState(3);
  useEffect(()=>{
    // let dist = Number(distance);
    // setAway(Math.floor(dist));
    setAway(distance);
  },[distance])
  return (
    <div style={{display:"flex", marginTop:"8px" , marginInline:"16px"}}>
      {" "}
      <img src={IconLocation}></img>
      <span style={{
        fontSize:"12px",
        fontWeight:"400",
        lineHeight:"18px",
        color:"#52545B",
        marginLeft:"3px"
      }}>{ away === null ? "Location not available" : gettingDistance(away || 0)}</span>
    </div>
  );
};

export default Location;
