import React from "react";
import "./style.scss";

type Props = {
  message: any;
  style?: any;
  [key: string]: any;
};

const InfoMessage = ({ message, ...otherProps }: Props) => {
  return (
    <div className="info_message_wrap" {...otherProps}>
      <img className="info_icon_message" src="/static/greyinfo.svg" alt="" />
      <div className="info_message_text">{message}</div>
    </div>
  );
};

export default InfoMessage;
