import React from "react";
import "./style.scss";

type Props = {
  [key: string]: any;
};

const LoadingSplash = (props: Props) => {
  return (
    <div className="loader_wrapper_splash">
      <div className="inner_wrap">
        <img src="/static/connect/spinner.svg" className="spinner_img" alt="" />
      </div>
    </div>
  );
};

export default LoadingSplash;
