import { useState } from "react";
import Subscription from "../components/Dashboard/Subscription";
import useRequestNotificationPermission from "../useRequestNotificationPermission";
import UserMembership from "../components/SignupUserMembership/UserMembership";

const Welcome = () => {
  const [showPlan, setShowPlan] = useState(false);
  useRequestNotificationPermission();

  // return <Subscription showPlan={showPlan} setShowPlan={setShowPlan} />;
  return <UserMembership showPlan={showPlan} setShowPlan={setShowPlan} />;
};

export default Welcome;
