import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { Table } from "react-bootstrap";
import dayjs from "dayjs";
import {
  useNavigate,
  Link,
  useSearchParams,
  useParams,
  useLocation,
} from "react-router-dom";

import Button from "../../global/Button";
import InputField from "../../global/InputField";
import {
  FREQUENCY_TYPE,
  LISTING_TYPE,
  RECURRING_TYPE,
  SCHEDULE_TYPE,
  USER_TYPE,
  GendersTypeSpecific,
} from "../../types";
import { handleFileUpload, handleLocation, showToast } from "../../utils";
import {
  apiConstants,
  difficultyLevelPreferenceNew,
  meetingLinkRegex,
} from "../../utils/Constants";
import { STRING_CONSTANTS } from "../../utils/StringConstant";
import ScheduleListingModal from "../Dashboard/CreateListing.tsx/ScheduleListingModal";
import { makeGetCall, makePatchCall, makePostCall } from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import GooglePlacesSearchBar from "../../global/GooglePlaceSelector";

import { ListingFormDefaultValue } from "../../utils/FormConfig";
import DraftPopup from "../Dashboard/CreateListing.tsx/DraftPopup";
import { OutlineYellowButton } from "../../global/OutlineButton";
import AddIconYellow from "../../assets/img/icons/AddIconYellow";
import ScheduleListingSidebar from "../Dashboard/CreateListing.tsx/ScheduleListingSidebar";
import SaveEventPopup from "../Dashboard/CreateListing.tsx/SaveEventPopup";
import { useOutsideClick } from "../../hooks";
import "../../assets/scss/createListing.scss";
import FilesUploadComponent from "../Dashboard/FilesUploadComponent";
import PaymentOption from "../Dashboard/CreateListing.tsx/PaymentOption";
import SelectPayout from "../Dashboard/CreateListing.tsx/SelectPayout";
import MeetingLink from "../ListingComponents/MeetingLink/MeetingLink";
import LocationSection from "../ListingComponents/LocationSection/LocationSection";
import SelectInterestComponent from "../ListingComponents/SelectInterestComponent";
import SelectInterestButton from "../ListingComponents/SelectInterestButton";
import {
  handleShowScheduleDate,
  resetScheduleData,
  timeDayjsFormatApiReverse,
} from "../../constants/helperCreateListing";
import EventCardSection from "../ListingComponents/EventsCardSection/EventCardSection";
import InfoCard from "../ListingComponents/InfoCard/InfoCard";
import InfoMessage from "../InfoMessage";
import { useAppSelector } from "../../store/store";
import apiConfig from "../../utils/endpoints";
import LoadingLayer from "../ConnectUserProfile/LoaderLayer";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import ScheduleDatesLayout from "../../components/AddListingComponent/ScheduleDatesSection/ScheduleDatesLayout";
import {
  DATE_FORMAT,
  formatDatePayload,
  formatDateResponse,
} from "../../constants/helperScheduleDates";
import ApiLoaderListing from "../../global/ApiLoaderListing";
import SpecialInstruction from "../AddListingComponent/SpecialInstruction";
interface ListingFormProps {
  edit?: boolean;
}
const EditListingForm = ({ edit }: ListingFormProps) => {
  const auth = useAppSelector((state) => state.auth);
  const { listingId } = useParams();
  const Navigate = useNavigate();
  const location = useLocation();

  //form
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
    reset,
    trigger,
  } = useForm({
    defaultValues: ListingFormDefaultValue,
    mode: "onChange",
  });

  //states
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [scheduleListing, setScheduleLisiting] = useState(false);
  const [scheduleData, setScheduleData] = useState<any>({});
  const [searchTerm, setSearchTerm] = useState("");
  const [isRecommend, setIsRecommend] = useState<boolean>(true);
  const [paymentMode, setPaymentMode] = useState<any>(null);
  const [isLocationAccessible, setIsLocationAccessible] = useState<any>(false);
  const [showAddEventSidebar, setShowAddEventSidebar] = useState<any>(false);
  const [, setScheduleDate] = useState<any>("");
  const [editEvent, setEditEvent] = useState<any>(false);
  const [editEventId, setEditEventId] = useState<any>(null);
  const [userType] = useState(auth?.moreUserDetails?.userType);
  const [commisionAmounts, setCommisionAmounts] = useState<any>(null);
  const [images, setImages] = useState([]);
  const [multipleScheduleListing, setMultipleScheduleLisiting] =
    useState(false);
  const [showEventPopup, setShowEventPopup] = useState(false);
  const [locationData, setLocationData] = useState(null);
  const [packageLocation, setPackageLocation] = useState(null);
  const [listingData, setListingData] = useState<any>(null);
  const [currUserInfo, setCurrUserInfo] = useState(auth?.moreUserDetails);

  const [isInterestTabOpen, setIsInterestTabOpen] = useState(false);
  const [showDraftPopUp, setShowDraftPopUp] = useState(false);
  const [showEventListingsSidebar, setShowEventListingsSidebar] =
    useState<boolean>(false);
  const [isPaymentOpen, setIsPaymentOpen] = useState<any>(false);
  const [isScheduleDatesSection, setIsScheduleDatesSection] =
    useState<any>(false);

  const [isAddEventInfo, setIsAddEventInfo] = useState<boolean>(false);
  const [paymentHowInfo, setPaymentHowInfo] = useState<any>(false);
  const [isFeeInfoShown, setIsFeeInfoShown] = useState(false);
  const [isListingInfo, setIsListingInfo] = useState<any>(false);
  const [paymentInfo, setPaymentInfo] = useState<any>(false);
  const [isBookingInfo, setIsisBookingInfo] = useState<any>(false);

  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<any>({
    isDraft: false,
    isCreate: false,
  });

  const [sessionScheduleData, setSessionScheduleData] = useState<any>(null);
  const [savingType, setIsSavingType] = useState<any>(null);

  const ref = useRef(null);
  const refInfo = useRef(null);

  const disableLoading = (type) => {
    setTimeout(() => {
      switch (type) {
        case "data":
          setIsDataLoading(false);
          break;
        case "inline":
          setIsDataLoading(false);
          break;
        default:
          break;
      }
    }, 0);
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const screenSection = searchParams.get("section");

  const handleScreenSection = (type) => {
    setSearchParams({ section: type });
  };

  useEffect(() => {
    switch (screenSection) {
      case "schedule":
        setIsScheduleDatesSection(true);
        break;
      case "interest":
        setIsInterestTabOpen(true);
        break;
      case "payment":
        if (
          userType !== USER_TYPE.GUEST ||
          watch(apiConstants.listingType) !== LISTING_TYPE.C
        ) {
          setIsPaymentOpen(true);
        } else {
          setIsInterestTabOpen(false);
          setIsPaymentOpen(false);
          setIsScheduleDatesSection(false);
        }
        break;
      default:
        setIsInterestTabOpen(false);
        setIsPaymentOpen(false);
        setIsScheduleDatesSection(false);

        break;
    }
  }, [searchParams]);

  // fetching-draft-data
  const fetchDraftData = async () => {
    if (listingId) {
      setIsDataLoading(true);
      await makeGetCall({ url: apiConfig.listing + `/${listingId}` })
        .then(async (res) => {
          const temp: any = {};
          const item = res.data;
          setListingData(res?.data);
          const pointString = item?.scheduleDetails?.address?.location;
          const coordinates = pointString?.match(/-?\d+\.\d+/g);
          const [lng, lat] = coordinates?.map(parseFloat) || [
            "-25.363",
            "131.044",
          ];
          temp[apiConstants.is_listing_private] = res?.data?.isPrivate;
          temp[apiConstants.listing_event_type] =
            res?.data?.listingEventType || "V";
          if (res?.data?.listingType) {
            temp["listingType"] = res?.data?.listingType;
          }
          temp[apiConstants.listingDetails] = {
            type: res?.data?.listingType,
            id: res?.data?.id,
            singleListingType: res.data?.scheduleDetails?.singleListingType,
            hasDraft: res?.data?.isDraft,
          };
          temp[apiConstants.isDraft] = res.data?.isDraft;
          setImages(res.data?.documents || []);
          temp[apiConstants.listing_name] = res.data?.title;
          temp[apiConstants.listing_description] = res?.data?.description;
          temp[apiConstants.selectedInterest] = res.data?.interests;
          temp[apiConstants.selectedInstruction] =
            res.data?.specialInstruction?.map((val) => {
              return {
                instruction: val || "",
              };
            });
          temp[apiConstants.listing_location] =
            res.data?.scheduleDetails?.address?.location;

          temp[apiConstants.payment_mode] = res.data?.paymentMode;
          temp["eventListingType"] = res.data?.listingEventType;
          setPaymentMode(res.data?.paymentMode);
          temp[apiConstants.stripe_account_id] = res.data?.stripeConnectBank;
          temp[apiConstants.existing_or_new] = res.data?.existingOrNew;
          temp[apiConstants.bank_name] = res.data?.directBankAccountName;
          temp[apiConstants.bank_name] = res.data?.directBankAccountName;
          temp[apiConstants.bsb_number] = res.data?.directBankBsbNumber;
          temp[apiConstants.account_number] = res.data?.directBankAccountNumber;

          //new field
          temp[apiConstants.multiple_virtual_links_allowed] =
            !res.data?.multipleVirtualLinksAllowed;

          temp[apiConstants.max_participants] =
            item?.type !== LISTING_TYPE.C
              ? res?.data?.maxParticipants
              : res.data?.clubScheduleDetails?.slot;

          temp[apiConstants.listing_cost] =
            item?.type !== LISTING_TYPE.C && userType !== "Guest"
              ? res.data?.price
              : res.data?.clubScheduleDetails?.monthlyCost;

          if (res?.data?.listingType === LISTING_TYPE.M) {
            temp[apiConstants.multipleEvents] = res?.data?.scheduleDetails?.map(
              ({ date, endTime, startTime, virtualLinks, ...others }) => {
                return {
                  date,
                  end_time: endTime,
                  start_time: startTime,
                  virtual_links_package: virtualLinks,
                  ...others,
                };
              }
            );
          }

          temp[apiConstants.lat] = lat;
          temp[apiConstants.lng] = lng;
          temp[apiConstants.city] = res.data?.scheduleDetails?.address?.city;
          temp[apiConstants.state] = res.data?.scheduleDetails?.address?.state;
          temp[apiConstants.country] =
            res.data?.scheduleDetails?.address?.country;
          temp[apiConstants.postCode] =
            res.data?.scheduleDetails?.address?.postCode;
          temp[apiConstants.listing_location] =
            res.data?.scheduleDetails?.address?.location;
          if (res.data?.gender) {
            temp[apiConstants.gender_preference] =
              res.data?.gender?.length > 0 ? res.data?.gender : ["M", "F", "N"];
            temp[apiConstants.selectedGender] =
              res.data?.gender?.length > 0 ? res.data?.gender : ["M", "F", "N"];
          }
          temp[apiConstants.difficulty_level] = res.data?.difficultyLevel || [];
          temp[apiConstants.selecteddifficulty] = res.data?.difficultyLevel || [];
          temp[apiConstants.upload_image] = res.data?.media;
          temp[apiConstants.address] =
            res.data?.scheduleDetails?.address?.address;
          temp[apiConstants.image] = res.data?.media;
          temp[apiConstants.min_age] = res.data?.minAge;
          temp[apiConstants.max_age] = res.data?.maxAge;
          temp[apiConstants.is_specific_age] =
            res.data?.minAge || res.data?.maxAge ? true : false;
          temp[apiConstants.isCancellationAllowed] =
            res.data?.isCancellationAllowed;
          temp[apiConstants.payment_mode] = res.data?.paymentMode;
          temp[apiConstants.cancellation_allowed_till_hours] =
            res.data?.cancellationAllowedTillHours;

          if (res?.data?.listingType === "P") {
            temp[apiConstants.virtual_links_single] =
              res.data?.scheduleDetails?.virtualLink || "";
          } else {
            temp[apiConstants.virtual_links_single] =
              res.data?.scheduleDetails?.virtualLink || "";
          }

          if (item?.type !== LISTING_TYPE.C) {
            temp[apiConstants.has_schedule_date] =
              handleShowScheduleDate(scheduleData) !== null || undefined
                ? true
                : false;

            const {
              eventDates = [],
              repeatType = null,
              endType = "",
              endAfter = 1,
              endBy = null,
              recurringType = "Day",

              repeatInEvery = 1,
              weekDays = [],
              monthWeekNo = [],
              monthWeekDays = [],

              events = [],
              singleListingType,
            } = res?.data?.scheduleDetails;
            if (eventDates?.length > 0) {
              temp[apiConstants.schedule_type] = SCHEDULE_TYPE.O;

              const formattedEvents = events?.map((event) => ({
                date: dayjs(event.date).format(DATE_FORMAT.YYYYMMDD_FORMAT),
                start_time: event.startTime,
                end_time: event.endTime,
                ...(res?.data?.listingEventType === "V" &&
                  res?.data?.multipleVirtualLinksAllowed === true &&
                  event?.virtualLink && { virtual_link: event.virtualLink }),
              }));

              const convertedDates = formatDateResponse(eventDates);

              setSessionScheduleData({
                eventDate: convertedDates || [],
                eventType: singleListingType || SCHEDULE_TYPE.O,
                repeatInEvery: repeatInEvery || 1,
                repeatType: recurringType || "Day",
                weekDays: weekDays || [],
                monthOnEvery: monthWeekNo || [],
                monthOnDays: monthWeekDays || [],
                endEventType: endType || "",
                endAfterVal: endAfter || 1,
                endByVal: endBy || "",
                timeSlotsData: [],
                finalScheduleData: formattedEvents || null,
                payloadSchedule: formattedEvents || null,
                respSchedule: [],
              });
            }
            temp[apiConstants.isDraft] = false;
          }

          if (res?.data?.listingType !== LISTING_TYPE.M) {
            setLocationData(res.data?.scheduleDetails?.address?.address);
          }

          if (
            res?.data?.listingType !== "C" &&
            res?.data?.multipleVirtualLinksAllowed === true
          ) {
            const virtual_links = res.data?.scheduleDetails?.virtualLinks?.map(
              (event) => {
                return {
                  date: event.date,
                  start: event.date + " " + res.data?.scheduleDetails.startTime,
                  end: event.date + " " + res.data?.scheduleDetails.endTime,
                  virtual_link: event.virtualLink,
                };
              }
            );

            temp[apiConstants.virtual_links_multiple] = virtual_links || [];
          }

          await reset(temp);
          temp[apiConstants.user_type] = userType;

          if (res?.data?.listingType === "S") {
            if (
              watch(apiConstants.schedule_type) === SCHEDULE_TYPE.O &&
              watch(apiConstants.event_date) &&
              watch(apiConstants.event_start_time) &&
              watch(apiConstants.event_end_time)
            ) {
              setScheduleData({
                type: watch(apiConstants.schedule_type) || SCHEDULE_TYPE.O,
                oneTimeDate: watch(apiConstants.event_date),
                startTime: timeDayjsFormatApiReverse(
                  watch(apiConstants.event_date),
                  watch(apiConstants.event_start_time)
                ),
                endTime: timeDayjsFormatApiReverse(
                  watch(apiConstants.event_date),

                  watch(apiConstants.event_end_time)
                ),
                virtualLinksMultiple:
                  watch(apiConstants?.virtual_links_multiple) || [],
              });
            } else if (watch(apiConstants.schedule_type) === SCHEDULE_TYPE.R) {
              if (
                watch(apiConstants.recurring_type) === RECURRING_TYPE.C &&
                watch(apiConstants.event_start_time) &&
                watch(apiConstants.event_end_time)
              ) {
                setScheduleData({
                  type: watch(apiConstants.schedule_type),
                  recurringType: watch(apiConstants.recurring_type),
                  customDates: watch(apiConstants.event_custom_date),
                  startTime: timeDayjsFormatApiReverse(
                    watch(apiConstants.event_date),
                    watch(apiConstants.event_start_time)
                  ),
                  endTime: timeDayjsFormatApiReverse(
                    watch(apiConstants.event_date),

                    watch(apiConstants.event_end_time)
                  ),
                  virtualLinksMultiple:
                    watch(apiConstants?.virtual_links_multiple) || [],
                });
              } else {
                if (watch(apiConstants.recur_frequency) === FREQUENCY_TYPE.D) {
                  setScheduleData({
                    type: watch(apiConstants.schedule_type),
                    recurringType: watch(apiConstants.recurring_type),
                    startTime: timeDayjsFormatApiReverse(
                      watch(apiConstants.event_date),
                      watch(apiConstants.event_start_time)
                    ),
                    endTime: timeDayjsFormatApiReverse(
                      watch(apiConstants.event_date),
                      watch(apiConstants.event_end_time)
                    ),
                    startDate: watch(apiConstants.event_start_date),
                    endDate: watch(apiConstants.event_end_date),
                    repeatNumber: watch(apiConstants.repeat_every) || 1,
                    recurringRepeatType: watch(apiConstants.recur_frequency),
                    virtualLinksMultiple:
                      watch(apiConstants?.virtual_links_multiple) || [],
                  });
                } else if (
                  watch(apiConstants.recur_frequency) === FREQUENCY_TYPE.M
                ) {
                  setScheduleData({
                    type: watch(apiConstants.schedule_type),
                    recurringType: watch(apiConstants.recurring_type),
                    startTime: timeDayjsFormatApiReverse(
                      watch(apiConstants.event_date),
                      watch(apiConstants.event_start_time)
                    ),
                    endTime: timeDayjsFormatApiReverse(
                      watch(apiConstants.event_date),

                      watch(apiConstants.event_end_time)
                    ),
                    startDate: watch(apiConstants.event_start_date),
                    endDate: watch(apiConstants.event_end_date),
                    repeatNumber: watch(apiConstants.repeat_every) || 1,
                    recurringRepeatType: watch(apiConstants.recur_frequency),
                    monthlyRepeatType: /on\s+Day/i.test(
                      watch(apiConstants.monthly_frequency)
                    )
                      ? "day"
                      : "week",
                    monthlyFrequencyData: watch(apiConstants.monthly_frequency),
                    virtualLinksMultiple:
                      watch(apiConstants?.virtual_links_multiple) || [],
                  });
                } else if (
                  watch(apiConstants.recur_frequency) === FREQUENCY_TYPE.W
                ) {
                  setScheduleData({
                    type: watch(apiConstants.schedule_type),
                    recurringType: watch(apiConstants.recurring_type),
                    startTime: timeDayjsFormatApiReverse(
                      watch(apiConstants.event_date),
                      watch(apiConstants.event_start_time)
                    ),
                    endTime: timeDayjsFormatApiReverse(
                      watch(apiConstants.event_date),

                      watch(apiConstants.event_end_time)
                    ),
                    startDate: watch(apiConstants.event_start_date),
                    endDate: watch(apiConstants.event_end_date),
                    repeatNumber: watch(apiConstants.repeat_every) || 1,
                    recurringRepeatType: watch(apiConstants.recur_frequency),
                    weekDays: watch(apiConstants.selected_days),
                    virtualLinksMultiple:
                      watch(apiConstants?.virtual_links_multiple) || [],
                  });
                }
              }
            }
          }

          disableLoading("data");
        })
        .catch((err) => {
          console.error(err);
          disableLoading("data");
        });
    }
  };

  // fetch data before ui loads
  useLayoutEffect(() => {
    if (edit) {
      fetchDraftData();
    } else {
      if (!location?.state?.listingType) {
        Navigate(ROUTE_CONSTANTS.select_listing, { replace: true });
      }
      setValue(apiConstants.listingType, location?.state?.listingType);
    }
  }, []);

  const handleSetValue = (field: string, value: any) => {
    setValue(field, value, { shouldDirty: true });
  };

  const getDisableData = () => {
    let isDisable = true;
    // Handling cancellationAllowedTillHours
    const listingAll: any = getValues();
    const specialCount = watch(apiConstants.selectedInstruction)?.filter(
      (val) => val?.instruction?.trim()
    );
    if (listingAll?.title?.trim()) {
      isDisable = false;
    } else if (listingAll?.description?.trim()) {
      isDisable = false;
    } else if (
      sessionScheduleData?.payloadSchedule?.length > 0 &&
      watch(apiConstants.listingType) === LISTING_TYPE.S
    ) {
      isDisable = false;
    } else if (watch(apiConstants.media)?.length > 0) {
      isDisable = false;
    } else if (specialCount?.length > 0) {
      isDisable = false;
    } else if (
      watch(apiConstants.is_specific_age) &&
      watch(apiConstants.min_age) &&
      watch(apiConstants.max_age)
    ) {
      isDisable = false;
    } else if (
      watch(apiConstants.payment_mode) &&
      currUserInfo?.userType !== "Guest"
    ) {
      isDisable = false;
    } else if (watch(apiConstants.max_participants)) {
      isDisable = false;
    } else if (
      watch(apiConstants.listing_cost) &&
      currUserInfo?.userType !== "Guest" &&
      watch(apiConstants.listingType) !== LISTING_TYPE.C
    ) {
      isDisable = false;
    } else if (watch(apiConstants.selectedInterest)?.length > 0) {
      isDisable = false;
    }

    return isDisable;
  };

  const handleNext = async ({ isDraft }: any) => {
    let regex = /^(https:\/\/meet\.google\.com\/[a-zA-Z0-9-]+(\?[a-zA-Z0-9&=_-]*)?|https:\/\/([a-z0-9]+\.|)zoom\.us\/j\/[a-zA-Z0-9?&=]+|https:\/\/teams\.microsoft\.com\/l\/meetup-join\/[a-zA-Z0-9-?&=]+)$/;
    if (!isDraft) {
      if (watch(apiConstants.media).length < 1) {
        showToast("At least one media file is required.", "error");
        return;
      } else if (!watch(apiConstants.listing_name)) {
        showToast("Please provide a listing name.", "error");
        return;
      } else if (watch(apiConstants.listing_name)?.length < 3) {
        showToast(
          "The listing name must be at least 3 characters long.",
          "error"
        );
        return;
      } else if (!watch(apiConstants.listing_description)) {
        showToast("Please provide a description.", "error");
        return;
      } else if (watch(apiConstants.listing_description)?.length < 10) {
        showToast(
          "The description must be at least 10 characters long.",
          "error"
        );
        return;
      } else if (
        !watch(apiConstants.selectedInterest) ||
        watch(apiConstants.selectedInterest)?.length < 1
      ) {
        showToast("Please add at least one interest.", "error");
        return;
      }
      //  else if (
      //   watch(apiConstants.listing_event_type) === "V" &&
      //   (watch(apiConstants.multiple_virtual_links_allowed) !== true ||
      //     watch(apiConstants.multiple_virtual_links_allowed) !== false)
      // ) {
      //   showToast("Please select same virtual link or not", "error");
      //   return;
      // }
      else if (
        watch(apiConstants.listing_event_type) === "V" &&
        watch(apiConstants.multiple_virtual_links_allowed) === true &&
        !watch(apiConstants.virtual_links_single)
      ) {
        showToast("Please provide a virtual meeting link.", "error");
        return;
      } 
      else if (
        watch(apiConstants.listing_event_type) === "V" &&
        watch(apiConstants.multiple_virtual_links_allowed) === true &&
        watch(apiConstants.virtual_links_single) &&
        !regex.test(
          watch(apiConstants.virtual_links_single)
        )
      ) {
        showToast("The virtual meeting link is not valid.", "error");
        return;
      } else if (
        watch(apiConstants.listing_event_type) === "P" &&
        watch(apiConstants.listingType) !== LISTING_TYPE.M &&
        !watch(apiConstants.address)
      ) {
        showToast("Please provide a location.", "error");
        return;
      } else if (
        watch(apiConstants.listing_event_type) === "P" &&
        watch(apiConstants.listingType) !== LISTING_TYPE.M &&
        watch(apiConstants.address) !== locationData
      ) {
        showToast("Please select location from the suggestion.", "error");
        return;
      } else if (
        !watch(apiConstants.max_participants) ||
        watch(apiConstants.max_participants) < 1
      ) {
        showToast("At least one participant is required.", "error");
        return;
      } else if (
        watch(apiConstants.listing_event_type) === "V" &&
        watch(apiConstants.max_participants) > 75
      ) {
        showToast("Maximum 75 participant is required", "error");
        return;
      } else if (
        watch(apiConstants.max_participants) > 4 &&
        currUserInfo?.userType === "Guest"
      ) {
        showToast("A maximum of 4 participants is allowed.", "error");
        return;
      } else if (
        (!watch(apiConstants.listing_cost) ||
          watch(apiConstants.listing_cost) < 6) &&
        currUserInfo?.userType !== "Guest" &&
        watch(apiConstants.listingType) !== LISTING_TYPE.C
      ) {
        showToast("Minimum cost allowed is 6", "error");
        return;
      } else if (
        watch(apiConstants.multipleEvents)?.length < 2 &&
        watch(apiConstants.listingType) === LISTING_TYPE.M
      ) {
        showToast("Please add at least two events.", "error");
        return;
      } else if (
        !sessionScheduleData?.payloadSchedule?.length &&
        watch(apiConstants.listingType) === LISTING_TYPE.S
      ) {
        showToast("Please provide the schedule dates.", "error");
        return;
      } else if (
        watch(apiConstants.listingType) === LISTING_TYPE.S &&
        watch(apiConstants.listing_event_type) === "V" &&
        watch(apiConstants.multiple_virtual_links_allowed) === false &&
        !sessionScheduleData?.payloadSchedule[0]?.virtual_link
      ) {
        showToast(
          "Please include only web URLs for Google Meet, Zoom, and Microsoft Teams here.",
          "error"
        );
        return;
      } else if (
        !watch(apiConstants.payment_mode) &&
        currUserInfo?.userType !== "Guest" &&
        watch(apiConstants.listingType) !== LISTING_TYPE.C
      ) {
        showToast("Kindly choose a payment method.", "error");
        return;
      } else if (
        watch(apiConstants.isCancellationAllowed) &&
        !watch(apiConstants.cancellation_allowed_till_hours) &&
        watch(apiConstants.listingType) !== LISTING_TYPE.C
      ) {
        showToast("Please specify the allowed cancellation range.", "error");
        return;
      } else if (
        watch(apiConstants.is_specific_age) &&
        (!watch(apiConstants.min_age) || !watch(apiConstants.max_age))
      ) {
        showToast("Please provide the minimum and maximum age.", "error");
        return;
      } else if (
        !watch(apiConstants.selectedGender) ||
        watch(apiConstants.selectedGender)?.length < 1
      ) {
        showToast("Please specify your gender.", "error");
        return;
      } else if (
        !watch(apiConstants.selecteddifficulty) ||
        watch(apiConstants.selecteddifficulty)?.length < 1
      ) {
        showToast("Please select the difficulty level.", "error");
        return;
      }
    }

    if (watch(apiConstants.listingType) === LISTING_TYPE.S) {
      await resetScheduleData(scheduleData, handleSetValue);
    }

    let tempIntrestList: any = [];
    if (watch(apiConstants.selectedInterest)?.length > 0) {
      tempIntrestList = watch(apiConstants.selectedInterest).map(
        (item: any) => {
          return item.id;
        }
      );
      handleSetValue(apiConstants.interests, tempIntrestList);
    }

    handleSubmit(async (data) => {
      if (
        currUserInfo?.userType !== "Guest" &&
        !watch(apiConstants.payment_mode) &&
        !Object.keys(errors).length &&
        !isDraft &&
        watch(apiConstants.listingType) !== LISTING_TYPE.C
      ) {
        // setShowStripeError(true);
        return;
      } else {
        setLoadingState({ isDraft, isCreate: !isDraft });

        if (watch(apiConstants.isDraft)) {
          setIsSavingType("draft");
        } else {
          setIsSavingType("publish");
        }

        //temp state
        let reqData: any = {};
        let addressObject: any = {};
        let scheduleDetails: any = {};
        // common payload
        reqData.isActive = !watch(apiConstants.isDraft);
        reqData.listingType = getValues(apiConstants.listingType);
        if (watch("isListingPrivate")) {
          reqData.isPrivate =
            getValues(apiConstants.is_listing_private) || false;
        }
        reqData.isDraft = watch(apiConstants.isDraft);
        reqData.title = watch(apiConstants.listing_name);
        reqData.description = watch(apiConstants.description);
        reqData.interests = watch(apiConstants.interests);

        if (watch(apiConstants.selectedInstruction)?.length > 0) {
          const tempInstruction = [];
          watch(apiConstants.selectedInstruction)?.forEach((val: any) => {
            if (val?.instruction?.trim()) {
              tempInstruction.push(val?.instruction?.trim());
            }
          });
          reqData.special_instruction = tempInstruction;
        }

        if (watch(apiConstants.selectedGender)?.length) {
          reqData.gender = getValues(apiConstants.selectedGender);
        }

        if (watch(apiConstants.selecteddifficulty)?.length) {
          reqData.difficultyLevel = watch(apiConstants.selecteddifficulty)?.map(
            ([key]) => key
          );
        }

        if (watch(apiConstants.is_specific_age)) {
          reqData["is_age_limit"] = true;
          reqData["minAge"] = parseInt(watch(apiConstants.min_age));
          reqData["maxAge"] = parseInt(watch(apiConstants.max_age));
        } else {
          reqData["is_age_limit"] = false;
        }

        if (watch("eventListingType")) {
          reqData.listing_event_type = watch("eventListingType");
        }

        if (watch(apiConstants.is_specific_age)) {
          reqData.is_age_limit = true;
          reqData.minAge = parseInt(watch(apiConstants.min_age));
          reqData.maxAge = parseInt(watch(apiConstants.max_age));
        } else {
          reqData.is_age_limit = false;
        }

        // media files
        const media = await getValues(apiConstants.media);
        const uploadResponse = await handleUploadFiles(media);
        reqData.document_details = uploadResponse;

        // payment excluding-team
        if (
          getValues(apiConstants.payment_mode) &&
          getValues(apiConstants.listingType) !== LISTING_TYPE.C
        ) {
          if (
            getValues(apiConstants.payment_mode) === "Through Stripe" &&
            getValues(apiConstants.stripe_account_id)
          ) {
            reqData.existing_or_new =
              getValues(apiConstants.existing_or_new) || null;
            reqData.stripe_connect_bank = getValues(
              apiConstants.stripe_account_id
            );
            reqData.payment_mode = getValues(apiConstants.payment_mode);
          } else if (
            getValues(apiConstants.payment_mode) === "Direct Bank Transfer" &&
            getValues(apiConstants.bsb_number)
          ) {
            reqData.payment_mode = getValues(apiConstants.payment_mode);
            reqData.direct_bank_account_name = getValues(
              apiConstants.bank_name
            );
            reqData.direct_bank_bsb_number = getValues(apiConstants.bsb_number);
            reqData.direct_bank_account_number = getValues(
              apiConstants.account_number
            );
            reqData.existing_or_new = getValues(apiConstants.existing_or_new);
          }
        }

        if (
          getValues(apiConstants.cancellation_allowed_till_hours) &&
          getValues(apiConstants.isCancellationAllowed)
        ) {
          reqData.is_cancellation_allowed = true;
          reqData.cancellation_allowed_till_hours = getValues(
            apiConstants.cancellation_allowed_till_hours
          );
        } else {
          reqData.is_cancellation_allowed = false;
        }

        // address obj
        if (
          watch("eventListingType") === "P" &&
          getValues(apiConstants.listingType) !== LISTING_TYPE.M
        ) {
          if (watch(apiConstants.lat) && watch(apiConstants.lng)) {
            addressObject.location = `POINT (${getValues(
              apiConstants.lng
            )} ${getValues(apiConstants.lat)})`;
          }
          addressObject.city = watch(apiConstants.city) || "";
          addressObject.state = watch(apiConstants.state) || "";
          addressObject.country = watch(apiConstants.country) || "";
          addressObject.postCode = watch(apiConstants.postCode) || "";
          addressObject.address = watch(apiConstants.address) || "";
          addressObject.city = watch(apiConstants.city) || "";

          scheduleDetails.address = addressObject;
        }

        if (
          userType !== USER_TYPE.GUEST &&
          getValues(apiConstants.listingType) !== LISTING_TYPE.C &&
          getValues(apiConstants.listing_cost)
        ) {
          reqData.price = getValues(apiConstants.listing_cost);
        }

        if (watch(apiConstants.max_participants)) {
          reqData.max_participants = getValues(apiConstants.max_participants);
        }

        // end of common data

        // schedule data for session/special
        if (
          getValues(apiConstants.listingType) === LISTING_TYPE.S ||
          getValues(apiConstants.listingType) === LISTING_TYPE.SPECIAL
        ) {
          //schedule session data
          if (
            sessionScheduleData?.eventType === "O" &&
            sessionScheduleData?.eventDate?.length > 0
          ) {
            scheduleDetails.single_listing_type = "O";
            scheduleDetails.event_dates = formatDatePayload(
              sessionScheduleData?.eventDate
            );

            let payloadFilter = [];

            if (
              watch(apiConstants.listing_event_type) === "V" &&
              watch(apiConstants.multiple_virtual_links_allowed) === false
            ) {
              payloadFilter = sessionScheduleData?.payloadSchedule?.map(
                ({
                  date = "",
                  start_time = "",
                  end_time = "",
                  virtual_link,
                }) => {
                  return {
                    date,
                    start_time,
                    end_time,
                    virtual_link,
                  };
                }
              );
            } else if (
              watch(apiConstants.listing_event_type) === "V" &&
              watch(apiConstants.multiple_virtual_links_allowed) === true
            ) {
              payloadFilter = sessionScheduleData?.payloadSchedule?.map(
                ({ date = "", start_time = "", end_time = "" }) => {
                  return {
                    date,
                    start_time,
                    end_time,
                    virtual_link: getValues(apiConstants.virtual_links_single),
                  };
                }
              );
            } else {
              payloadFilter = sessionScheduleData?.payloadSchedule?.map(
                ({ date = "", start_time = "", end_time = "" }) => {
                  return {
                    date,
                    start_time,
                    end_time,
                  };
                }
              );
            }

            scheduleDetails.events = payloadFilter;
          } else if (
            sessionScheduleData?.eventType === "R" &&
            sessionScheduleData?.eventDate?.length > 0
          ) {
            scheduleDetails.single_listing_type = "R";
            scheduleDetails.event_dates = formatDatePayload(
              sessionScheduleData?.eventDate
            );
            scheduleDetails.repeat_in_every =
              sessionScheduleData?.repeatInEvery;

            scheduleDetails.end_type = sessionScheduleData?.endEventType;
            scheduleDetails.end_after = sessionScheduleData?.endAfterVal;
            scheduleDetails.end_by = sessionScheduleData?.endByVal;

            let payloadFilter = [];

            if (
              watch(apiConstants.listing_event_type) === "V" &&
              watch(apiConstants.multiple_virtual_links_allowed) === false
            ) {
              payloadFilter = sessionScheduleData?.payloadSchedule?.map(
                ({
                  date = "",
                  start_time = "",
                  end_time = "",
                  virtual_link,
                }) => {
                  return {
                    date,
                    start_time,
                    end_time,
                    virtual_link,
                  };
                }
              );
            } else if (
              watch(apiConstants.listing_event_type) === "V" &&
              watch(apiConstants.multiple_virtual_links_allowed) === true
            ) {
              payloadFilter = sessionScheduleData?.payloadSchedule?.map(
                ({ date = "", start_time = "", end_time = "" }) => {
                  return {
                    date,
                    start_time,
                    end_time,
                    virtual_link: getValues(apiConstants.virtual_links_single),
                  };
                }
              );
            } else {
              payloadFilter = sessionScheduleData?.payloadSchedule?.map(
                ({ date = "", start_time = "", end_time = "" }) => {
                  return {
                    date,
                    start_time,
                    end_time,
                  };
                }
              );
            }

            scheduleDetails.events = payloadFilter;

            if (sessionScheduleData?.repeatType === "Day") {
              scheduleDetails.recurring_type = sessionScheduleData?.repeatType;
            } else if (sessionScheduleData?.repeatType === "Month") {
              scheduleDetails.recurring_type = sessionScheduleData?.repeatType;

              if (sessionScheduleData?.eventDate?.length < 2) {
                scheduleDetails.month_week_no =
                  sessionScheduleData?.monthOnEvery;
                scheduleDetails.month_week_days =
                  sessionScheduleData?.monthOnDays;
              }
            } else if (sessionScheduleData?.repeatType === "Week") {
              scheduleDetails.recurring_type = sessionScheduleData?.repeatType;

              scheduleDetails.week_days = sessionScheduleData?.weekDays;
              scheduleDetails.end_by = sessionScheduleData?.endByVal;
            }
          }
          reqData["schedule_details"] = scheduleDetails;
          // return
          // end of schedule dates session
        } else if (getValues(apiConstants.listingType) === LISTING_TYPE.M) {
          // package-listing
          let newEvents = getValues(apiConstants.multipleEvents)?.map(
            ({ virtual_links_package, address, ...others }) => {
              if (watch("eventListingType") === "V") {
                return { ...others };
              } else {
                return { ...others, address };
              }
            }
          );
          reqData["schedule_details"] = newEvents;
        } else if (getValues(apiConstants.listingType) === LISTING_TYPE.C) {
          //team-listing
          reqData["schedule_details"] = scheduleDetails;
        }

        // virtual links

        if (watch("eventListingType") === "V") {
          if (watch(apiConstants.multiple_virtual_links_allowed) === true) {
            reqData["multiple_virtual_links_allowed"] = false;
            reqData.schedule_details.virtual_link = getValues(
              apiConstants.virtual_links_single
            );
          } else if (
            watch(apiConstants.multiple_virtual_links_allowed) === false
          ) {
            if (getValues(apiConstants.listingType) === LISTING_TYPE.S) {
              const newData = scheduleData?.virtualLinksMultiple || [];
              reqData["multiple_virtual_links_allowed"] = true;
              reqData["virtual_links"] = newData?.map((val) => {
                const { event_start_time, event_end_time, ...rest } = val;
                return { ...rest };
              });
            } else if (getValues(apiConstants.listingType) === LISTING_TYPE.M) {
              const newData = getValues(apiConstants.multipleEvents)?.map(
                ({ virtual_links_package, date, ...others }) => {
                  return { virtual_link: virtual_links_package, date };
                }
              );
              reqData["multiple_virtual_links_allowed"] = true;
              reqData["virtual_links"] = newData;
            }
          } else {
            reqData["multiple_virtual_links_allowed"] = false;
            reqData.schedule_details.virtual_link = getValues(
              apiConstants.virtual_links_single
            );
          }
        }

        const listingId = getValues(apiConstants.listingDetails)?.id;

        if (listingId) {
          setLoadingState({ isDraft, isCreate: !isDraft });
          setIsDisable(true);
          makePatchCall({
            url: `${endpoints.listing}${listingId}?version=v2`,
            apiPayload: reqData,
            content_type: "application/json",
          })
            .then((res) => {
              setShowDraftPopUp(false);
              if (res?.status?.code === 200) {
                showToast(res?.status?.message, "success");
                Navigate("/drafts");
              } else {
                showToast(res?.status?.message, "error", "errmess2");
              }
              setLoadingState({ isDraft: false, isCreate: false });
              setIsDisable(false);
              setIsSavingType(null);
              if (!isDraft) {
                Navigate("/view-listing");
              } else {
                Navigate("/drafts");
              }
            })
            .catch((err) => {
              setLoadingState({ isDraft: false, isCreate: false });
              setShowDraftPopUp(false);
              setIsDisable(false);
              setIsSavingType(null);
              showToast(err?.status?.message, "error", "errmes21");
            });
        } else {
          handleSetValue(apiConstants.isDraft, false);
          setLoadingState({ isDraft, isCreate: !isDraft });
          setIsDisable(true);

          makePostCall({
            url: endpoints.listing + "?version=v2",
            apiPayload: reqData,
            content_type: "application/json",
          })
            .then((res) => {
              setShowDraftPopUp(false);
              if (res?.status?.code === 200) {
                showToast(res.status.message, "success");
              } else {
                showToast(res?.status?.message, "error", "errmess23");
              }
              setLoadingState({ isDraft: false, isCreate: false });
              setIsDisable(false);
              setIsSavingType(null);

              if (!isDraft) {
                Navigate("/view-listing");
              } else {
                Navigate("/drafts");
              }
            })
            .catch((err) => {
              setShowDraftPopUp(false);
              setLoadingState({ isDraft: false, isCreate: false });
              setIsDisable(false);
              setIsSavingType(null);

              showToast(err?.status?.message, "error", "errmess234");
            });
        }
      }
    })();
  };

  function calculatePercentageOfNumber(percentage: any, number: any) {
    const value = (percentage / 100) * number;

    const stringValue = value.toString();
    return Number(parseFloat(stringValue).toFixed(2));
  }

  const handleUploadFiles = async (files: any = []) => {
    let finalMedia: any = [];

    for (const val of files) {
      if (!val?.uploadDocumentUrl) {
        const res = await makeGetCall({
          url: `${endpoints.signed_url}/${val.file.name}`,
        });

        if (res.status.code === 200) {
          const {
            AWSAccessKeyId = "",
            key = "",
            policy = "",
            signature = "",
          } = res?.data?.fields;
          const uploadedResponse = await handleFileUpload(
            val.file,
            res?.data?.url,
            key,
            AWSAccessKeyId,
            policy,
            signature
          );
          finalMedia.push(uploadedResponse);
        }
      }
    }

    return finalMedia;
  };

  const handleMediaFIles = (media: any) => {
    setValue(apiConstants.media, media);
  };

  useOutsideClick(ref, () => {
    if (searchTerm) {
      setSearchTerm("");
    }
  });

  useOutsideClick(refInfo, () => {
    setIsListingInfo(false);
    setPaymentInfo(false);
    setIsisBookingInfo(false);
    setPaymentHowInfo(false);
  });

  const tempObj = {
    id: new Date().getTime(),
    title: watch(apiConstants?.event_title),
    address: {
      location:
        watch(apiConstants?.listing_location) ||
        `POINT (${watch(apiConstants.lng)} ${watch(apiConstants.lat)})`,
      address: watch(apiConstants?.address),
      city: watch(apiConstants?.city),
      state: watch(apiConstants?.state),
      country: watch(apiConstants?.country),
      post_code: watch(apiConstants?.postCode),
    },
    date: dayjs(getValues(apiConstants.event_start_date)).format("YYYY-MM-DD"),
    start_time:
      dayjs(watch(apiConstants.event_start_time)).format("HH:mm:ss.SSSSSS") ||
      null,
    end_time:
      dayjs(watch(apiConstants.event_end_time)).format("HH:mm:ss.SSSSSS") ||
      null,
    virtual_links_package: watch(apiConstants?.virtual_links_package),
  };

  const onDeleteEvent = (index) =>
    setValue(
      apiConstants.multipleEvents,
      getValues(apiConstants.multipleEvents).filter((_, idx) => idx !== index)
    );

  const handleInstructions = (inst: any) => {
    let tempList = watch(apiConstants.selectedInstruction) || [];
    tempList.push(inst);
    handleSetValue(apiConstants.selectedInstruction, [...tempList]);
    setValue(apiConstants.eventInstruction, "");
  };
  const handleRemoveInstructions = (indexToRemove: any) => {
    let tempList = watch(apiConstants.selectedInstruction) || [];
    tempList = tempList.filter((item, index) => index !== indexToRemove);
    handleSetValue(apiConstants.selectedInstruction, [...tempList]);
    setValue(apiConstants.eventInstruction, "");
  };

  const handleConnectStripe = () => {
    makePostCall({ url: endpoints.connect_stripe })
      .then((res) => {
        if (res?.status.code === 200) {
          window.open(res?.data, "_blank", "rel=noopener noreferrer");
          showToast(res.status.message, "success");
        }
      })
      .catch((err) => {
        showToast(err?.status?.message, "error");
      });
  };

  const handleEventEditClick = (i) => {
    setEditEvent(true);
    setEditEventId(i.id);
    setValue(apiConstants.event_title, i?.title);
    setValue(apiConstants.city, i?.address?.city);
    setValue(apiConstants.state, i?.address?.state);
    setValue(apiConstants.country, i?.address?.country);
    setValue(apiConstants.postCode, i?.address?.postCode);
    setValue(apiConstants.address, i?.address?.address);
    setValue(apiConstants.listing_location, i?.address?.location);
    setValue(apiConstants.event_start_date, i.date);
    setValue(apiConstants.event_date, i.date);
    setValue(apiConstants.virtual_links_package, i.virtual_links_package);
    setPackageLocation(i?.address?.address);
    setValue(
      apiConstants.event_start_time,
      dayjs(i?.date + " " + i?.start_time, "YYYY-MM-DD HH:mm:ss.SSSSSS").format(
        "YYYY-MM-DD HH:mm:ss.SSSSSS"
      )
    );
    setValue(
      apiConstants.event_end_time,
      dayjs(i?.date + " " + i?.end_time, "YYYY-MM-DD HH:mm:ss.SSSSSS").format(
        "YYYY-MM-DD HH:mm:ss.SSSSSS"
      )
    );

    setShowEventListingsSidebar(false);
    setShowAddEventSidebar(true);
  };

  const selectedPreferences = Array.isArray(watch("gender_preference"))
    ? watch("gender_preference")
    : ["M", "F", "N"];

  const selectedPreferencesDifficulty = Array.isArray(watch("difficulty_level"))
    ? watch("difficulty_level")
    : watch(apiConstants.difficulty_level);

  const handleGender = (gender: any) => {
    const tempList = watch(apiConstants.selectedGender) || [];
    let index = tempList.findIndex((gen: any) => gen === gender);
    if (index > -1) {
      tempList.splice(index, 1);
    } else {
      tempList.push(gender);
    }
    handleSetValue(apiConstants.selectedGender, [...tempList]);
  };
  const handleDifficulty = (level: any) => {
    const tempList = watch(apiConstants.selecteddifficulty) || [];
    let index = tempList.findIndex((lev: any) => lev === level);
    if (index > -1) {
      tempList.splice(index, 1);
    } else {
      tempList.push(level);
    }
    handleSetValue(apiConstants.selecteddifficulty, [...tempList]);
  };

  useEffect(() => {
    handleSetValue(
      apiConstants.selecteddifficulty,
      selectedPreferencesDifficulty
    );
    handleSetValue(apiConstants.selectedGender, selectedPreferences);
  }, []);

  useEffect(() => {
    makeGetCall({ url: apiConfig.commission_amounts })
      .then((res) => {
        if (res.status.code === 200) {
          setCommisionAmounts(res.results);
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  }, []);

  const validateMeetingLink = (link) => {
    const regex =
      /^(https:\/\/meet\.google\.com(\/[a-zA-Z0-9-?&=]+)?|https:\/\/([a-z0-9]+\.|)zoom\.us\/j\/[a-zA-Z0-9?&=]+|https:\/\/teams\.microsoft\.com\/l\/meetup-join\/[a-zA-Z0-9-?&=]+)$/;
    return regex.test(link);
  };

  const handleUpdateOrSaveEvent = () => {
    let meetingError = false;
    if (
      watch(apiConstants.listing_event_type) === "V" &&
      watch(apiConstants.multiple_virtual_links_allowed) === false &&
      watch(apiConstants.virtual_links_package)
    ) {
      const data = validateMeetingLink(
        watch(apiConstants.virtual_links_package)
      );
      if (!data) {
        meetingError = true;
      }
    }
    //
    if (!watch(apiConstants.event_title)) {
      showToast("Please enter the event title.", "error");
    } else if (
      watch(apiConstants.listing_event_type) === "P" &&
      !watch(apiConstants.address)
    ) {
      showToast("Please provide the event location.", "error");
    } else if (
      watch(apiConstants.listing_event_type) === "P" &&
      watch(apiConstants.address) !== packageLocation
    ) {
      showToast("Please select location from the suggestion.", "error");
    } else if (
      watch(apiConstants.listing_event_type) === "V" &&
      watch(apiConstants.multiple_virtual_links_allowed) === false &&
      !watch(apiConstants.virtual_links_package)
    ) {
      showToast("Please enter the virtual event meeting link.", "error");
    } else if (meetingError) {
      showToast("Please enter a valid meeting link.", "error");
    } else if (
      !watch(apiConstants.event_start_time) ||
      !watch(apiConstants.event_end_time)
    ) {
      showToast("Please provide the schedule dates.", "error");
    } else {
      setShowAddEventSidebar(false);
      setShowEventPopup(true);
      // setAddEventOpen(true);
    }
  };

  const handleScheduleListing = async () => {
    await handleScreenSection("schedule");
    // await setScheduleLisiting(true);
  };

  useEffect(() => {
    if (watch(apiConstants.max_participants)) {
      const length = watch(apiConstants.max_participants)?.length;
      if (
        watch(apiConstants.max_participants) > 4 &&
        currUserInfo?.userType === "Guest" &&
        length > 0
      ) {
        showToast(
          "A maximum of 4 participants is allowed.",
          "error",
          "guest_4"
        );
        handleSetValue(apiConstants.max_participants, 4);
      } else if (
        watch(apiConstants.max_participants) > 75 &&
        watch(apiConstants.listing_event_type) === "V" &&
        length > 0
      ) {
        showToast("Max 75 participant is allowed", "error", "member_75");
        handleSetValue(apiConstants.max_participants, 75);
      }
    }
  }, [
    watch(apiConstants.max_participants),
    watch(apiConstants.listing_event_type),
  ]);

  const handleMinListingCost = () => {
    if (
      watch(apiConstants.listing_cost) &&
      watch(apiConstants.listing_cost) < 6 &&
      currUserInfo?.userType !== "Guest" &&
      watch(apiConstants.listingType) !== LISTING_TYPE.C
    ) {
      showToast("Minimum cost allowed is 6", "error");
      handleSetValue(apiConstants.listing_cost, 6);
    } else if (
      watch(apiConstants.listing_cost) &&
      watch(apiConstants.listing_cost) > 100000 &&
      currUserInfo?.userType !== "Guest" &&
      watch(apiConstants.listingType) !== LISTING_TYPE.C
    ) {
      showToast("Maximum cost allowed is 100,000", "error");
      handleSetValue(apiConstants.listing_cost, 100000);
    }
  };

  return (
    <>
      {isDataLoading && <LoadingLayer />}
      {savingType && (
        <ApiLoaderListing isLoadingDraft={savingType === "draft"} />
      )}
      {isPaymentOpen ? (
        <PaymentOption
          setIsPaymentOpen={setIsPaymentOpen}
          handleClose={() => Navigate(-1)}
          isRecommend={isRecommend}
          setIsRecommend={setIsRecommend}
          paymentMode={paymentMode}
          setPaymentMode={setPaymentMode}
          setValue={setValue}
          control={control}
          errors={errors}
          handleConnectStripe={handleConnectStripe}
          watch={watch}
        />
      ) : isInterestTabOpen ? (
        <SelectInterestComponent
          interestPicked={watch(apiConstants?.selectedInterest)}
          handleAdd={async (value) => {
            await handleSetValue(apiConstants.selectedInterest, value);
            Navigate(-1);
          }}
        />
      ) : isScheduleDatesSection ? (
        <ScheduleDatesLayout
          virtual={
            watch(apiConstants.listing_event_type) === "V" &&
            watch(apiConstants.multiple_virtual_links_allowed) === false
          }
          sessionScheduleData={sessionScheduleData}
          setSessionScheduleData={setSessionScheduleData}
        />
      ) : (
        <div className="user-profile create_listing create_listing_main">
          <div className="user-white-part-width p-0">
            <div className="user-white-part-large listing_types">
              <div
                className="lsiting-detials"
                style={{
                  paddingBottom: "24px",
                }}
              >
                <img
                  src="/static/leftarrow.svg"
                  alt=""
                  className="back_arrow"
                  onClick={() => {
                    //   if (!isObjEmpty(dirtyFields) && notSaved) {
                    if (Object.keys(dirtyFields).length > 2) {
                      setShowDraftPopUp(true);
                    } else {
                      setShowDraftPopUp(false);
                      Navigate(-1);
                    }
                  }}
                />
                <div className="d-flex justify-content-between d-md-none mb-1">
                  <div
                    className="mb-1  position-absolute pe-3 "
                    style={{ cursor: "pointer", right: "0px" }}
                    onClick={() => {
                      if (!getDisableData()) {
                        handleSetValue(apiConstants.isDraft, true);

                        setTimeout(() => {
                          handleNext({ isDraft: true });
                        }, 500);
                      }
                    }}
                  >
                    <img src="/static/saveResDraft.png" alt="" />
                  </div>
                </div>
                <img
                  src="/static/leftarrow.svg"
                  alt=""
                  className="back_arrow d-none d-md-block"
                  onClick={() => {
                    if (Object.keys(dirtyFields).length > 2) {
                      setShowDraftPopUp(true);
                    } else {
                      setShowDraftPopUp(false);
                      Navigate(-1);
                    }
                  }}
                />
                <div className="listing_heading_main">
                  {watch(apiConstants.listingType) === LISTING_TYPE.M
                    ? "Create a package"
                    : watch(apiConstants.listingType) === LISTING_TYPE.C
                    ? "Create a team"
                    : "Create a Single/ Multiple sessions"}
                </div>

                <FilesUploadComponent
                  handleMediaFIles={handleMediaFIles}
                  images={images}
                  setImages={setImages}
                />
                {watch(apiConstants.media)?.length < 1 && errors?.media ? (
                  <div className="mb-4">
                    <span className="text-danger">{`${errors.media.message}`}</span>
                  </div>
                ) : null}

                <div className="main-heaidng">
                  <h6>
                    {watch(apiConstants.listingType) === LISTING_TYPE.S
                      ? STRING_CONSTANTS.listing_details
                      : watch(apiConstants.listingType) === LISTING_TYPE.M
                      ? STRING_CONSTANTS.listing_details
                      : STRING_CONSTANTS.team_details}
                  </h6>
                  <img
                    src="/static/info.svg"
                    alt=""
                    onClick={() => setIsListingInfo(!isListingInfo)}
                  />
                </div>

                <div style={{ marginBottom: "18px" }}>
                  <InputField
                    type={"text"}
                    name={apiConstants.listing_name}
                    inputLabel={"Listing Name"}
                    placeholder={"Name your listing"}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                  />
                </div>
                <div style={{ marginBottom: "18px" }}>
                  <InputField
                    isListingTextArea={true}
                    type={"textarea"}
                    name={apiConstants.listing_description}
                    inputLabel={
                      watch(apiConstants.listingType) === LISTING_TYPE.C
                        ? "Team Description & Benefits"
                        : "Listing Description"
                    }
                    className={"listing_desc"}
                    placeholder={"Tell others more about this listing"}
                    control={control}
                    maxlength={"300"}
                    getValues={getValues}
                    errors={errors}
                    setValue={setValue}
                  />
                  {isListingInfo && (
                    <InfoMessage message={STRING_CONSTANTS.listing_info} />
                  )}
                </div>

                <SelectInterestButton
                  isOutside={false}
                  handleClick={() => {
                    handleScreenSection("interest");
                  }}
                  list={watch(apiConstants.selectedInterest) || []}
                  handleRemoveInterest={(id) => {
                    let tempData = watch(apiConstants.selectedInterest)?.filter(
                      (item) => item?.id !== id
                    );
                    handleSetValue(apiConstants.selectedInterest, tempData);
                  }}
                />

                <div className="listing_type_container">
                  <div className="listing_type_heading">Listing Type</div>
                  <div>
                    <div className="listing_type_radio">
                      <img
                        src={
                          watch("eventListingType") === "P"
                            ? "/static/radiocheck.svg"
                            : "/static/radiouncheck.svg"
                        }
                        alt=""
                        onClick={() => handleSetValue("eventListingType", "P")}
                      />
                      <label
                        className={
                          watch("eventListingType") === "P"
                            ? "listing_ype_label active"
                            : "listing_ype_label"
                        }
                        onClick={() => handleSetValue("eventListingType", "P")}
                        style={{ cursor: "pointer" }}
                      >
                        Physical
                      </label>
                      <img
                        src={
                          "/static/createListingIcon/physicalListingType.svg"
                        }
                        alt=""
                      />
                    </div>
                    <div className="listing_type_radio">
                      <img
                        src={
                          watch("eventListingType") === "V"
                            ? "/static/radiocheck.svg"
                            : "/static/radiouncheck.svg"
                        }
                        alt=""
                        onClick={() => handleSetValue("eventListingType", "V")}
                      />
                      <label
                        className={
                          watch("eventListingType") === "V"
                            ? "listing_ype_label active"
                            : "listing_ype_label"
                        }
                        onClick={() => handleSetValue("eventListingType", "V")}
                        style={{ cursor: "pointer" }}
                      >
                        Virtual
                      </label>
                      <img
                        src={"/static/createListingIcon/virtualListingType.svg"}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                {watch(apiConstants.listing_event_type) === "V" && (
                  <MeetingLink
                    watch={watch}
                    handleSetValue={handleSetValue}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    isTeam={watch(apiConstants.listingType) === "C"}
                  />
                )}

                <SpecialInstruction watch={watch} setValue={setValue} />

                {watch(apiConstants.listingType) === LISTING_TYPE.M ? (
                  <>
                    <div className="main-had" style={{ marginTop: "24px" }}>
                      <h3 className="headis">Add Event</h3>
                      <img
                        src="/static/info.svg"
                        alt=""
                        className="info_icon"
                        onClick={() => setIsAddEventInfo(!isAddEventInfo)}
                      />
                    </div>

                    {getValues(apiConstants.multipleEvents)?.length > 0 && (
                      <>
                        <EventCardSection
                          data={getValues(apiConstants.multipleEvents)?.slice(
                            0,
                            2
                          )}
                          onDeleteEvent={onDeleteEvent}
                          handleEventEditClick={handleEventEditClick}
                          setShowEventListingsSidebar={setShowAddEventSidebar}
                          totalData={
                            getValues(apiConstants.multipleEvents)?.length
                          }
                          watch={watch}
                        />
                      </>
                    )}

                    <div>
                      <OutlineYellowButton
                        style={{
                          border: "1px solid #8f939a",
                        }}
                        text="Add Event"
                        icon={<AddIconYellow />}
                        onClick={() => {
                          setShowAddEventSidebar(false);
                          setShowAddEventSidebar(true);
                        }}
                      />
                    </div>
                  </>
                ) : null}

                {isAddEventInfo && (
                  <InfoMessage
                    message={STRING_CONSTANTS.listing_info}
                    style={{ marginTop: "12px" }}
                  />
                )}

                {watch("eventListingType") === "P" &&
                  watch(apiConstants.listingType) !== LISTING_TYPE.M && (
                    <div style={{ marginTop: "24px" }}>
                      <LocationSection
                        watch={watch}
                        setIsLocationAccessible={setIsLocationAccessible}
                        setShowLocationPopup={() => null}
                        isLocationAccessible={isLocationAccessible}
                        handleLocation={handleLocation}
                        handleSetValue={handleSetValue}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        setLocationData={setLocationData}
                      />
                    </div>
                  )}

                <div className="main-had" style={{ marginTop: "28px" }}>
                  <h3 className="headis">
                    {userType !== USER_TYPE.GUEST &&
                    watch(apiConstants.listingType) !== LISTING_TYPE.C
                      ? "Payment and scheduling"
                      : "Participants"}
                  </h3>
                  <img
                    src="/static/info.svg"
                    alt=""
                    className="info_icon"
                    onClick={() => setPaymentInfo(!paymentInfo)}
                  />
                </div>

                <div className="ps_container_wrap">
                  <div
                    className={
                      userType === USER_TYPE.GUEST ||
                      watch(apiConstants.listingType) === LISTING_TYPE.C
                        ? "ps_content_inner full_width"
                        : "ps_content_inner"
                    }
                  >
                    <InputField
                      type={"number"}
                      name={apiConstants.max_participants}
                      inputLabel={"Max Participants"}
                      placeholder={"Add your max participants"}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      max={75}
                    />
                    {paymentInfo && (
                      <InfoMessage
                        message={STRING_CONSTANTS.max_participant_info}
                      />
                    )}
                  </div>
                  {userType !== USER_TYPE.GUEST &&
                    watch(apiConstants.listingType) !== LISTING_TYPE.C && (
                      <div className="ps_content_inner">
                        <InputField
                          type={"number"}
                          name={apiConstants.listing_cost}
                          inputLabel={
                            watch(apiConstants.listingType) === LISTING_TYPE.C
                              ? "Team Listing Cost"
                              : "Event Cost"
                          }
                          placeholder={"Add your joining cost"}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          onBlur={() => handleMinListingCost()}
                        />
                        {paymentInfo && (
                          <InfoMessage
                            message={STRING_CONSTANTS.listing_cost_info}
                          />
                        )}
                      </div>
                    )}
                </div>

                {userType !== USER_TYPE.GUEST ? (
                  <>
                    {watch(apiConstants.listing_cost) ? (
                      <>
                        <div
                          className="earning-box-wrap"
                          style={{ marginTop: "22px" }}
                        >
                          <div className="earning-title">
                            Your Earnings per Participant
                          </div>
                          <div className="amount_earning">
                            {"AUD "}
                            {(Number(watch(apiConstants.listing_cost)) || 0) -
                              calculatePercentageOfNumber(
                                Number(currUserInfo?.merchantFee || 0),
                                Number(watch(apiConstants.listing_cost)) || 0
                              )}
                          </div>
                          <div className="info_main">
                            <div>
                              <img alt="" src="/static/smallinfo.svg" />
                            </div>
                            <div>
                              The earnings shown are before the deduction of
                              booking fee. Your actual earnings per participant
                              will vary based on the number of attendees. For
                              more details on booking fee, please visit the{" "}
                              <strong>My Earnings page</strong>. For additional
                              information, please review the{" "}
                              <Link
                                to="/policies?activeTab=terms"
                                target="_blank"
                                className="link_terms"
                              >
                                {"Terms & Conditions."}
                              </Link>
                            </div>
                          </div>
                          <div className="divider" />
                          <div className="fee-box">
                            <div>
                              <div className="fee-title">Merchant Fee: </div>
                              <div className="desc">
                                {"AUD "}
                                {calculatePercentageOfNumber(
                                  currUserInfo?.merchantFee || 0,
                                  watch(apiConstants.listing_cost)
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="info">
                            <span>Note:</span> Your earnings are subjective to
                            deduction of booking fee as well as merchant fee, in
                            order to gain more knowledge{" "}
                            <Link
                              to="#"
                              //  target="_blank"
                              className="link_terms"
                              onClick={() => setIsFeeInfoShown(!isFeeInfoShown)}
                            >
                              {isFeeInfoShown ? "Hide" : "Click here."}
                            </Link>
                          </div>
                          {isFeeInfoShown ? (
                            <Table responsive borderless bsPrefix="pay-table">
                              <thead className="pay-t-header">
                                <tr>
                                  <th className="first">
                                    Number of participants
                                  </th>
                                  <th className="second">
                                    Platform fixed Fee per booking per person
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {commisionAmounts?.map((item, index) => {
                                  return (
                                    <tr>
                                      <td>
                                        {item.minimumSlots}{" "}
                                        {item.minimumSlots === item.maximumSlots
                                          ? ""
                                          : item.maximumSlots === null
                                          ? "plus"
                                          : `to ${item.maximumSlots}`}
                                      </td>
                                      <td>${item.commissionAmount}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          ) : (
                            ""
                          )}
                          {isFeeInfoShown ? (
                            <div className="fee-box">
                              <div>
                                <div className="fee-title">Merchant Fee: </div>
                                <div className="desc">
                                  $
                                  {calculatePercentageOfNumber(
                                    currUserInfo?.merchantFee || 0,
                                    watch(apiConstants.listing_cost)
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {isFeeInfoShown ? (
                            <>
                              <div className="divider" />
                              <div className="info">
                                <span>Note:</span>
                                {watch(apiConstants.listingType) ===
                                LISTING_TYPE.C
                                  ? " You will be charged on monthly basis per booking"
                                  : watch(apiConstants.listingType) ===
                                    LISTING_TYPE.M
                                  ? "You will be charged per package booking"
                                  : "You will be charged as per the number of events"}
                              </div>
                              <div className="terms-info">
                                To gain a better understanding of the booking
                                fee charges, please review the{" "}
                                <Link
                                  className="terms-link"
                                  to="/policies"
                                  target="_blank"
                                >
                                  terms and conditions.
                                </Link>
                              </div>{" "}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ) : null}
                  </>
                ) : null}

                {watch(apiConstants.listingType) !== LISTING_TYPE.C &&
                watch(apiConstants.listingType) !== LISTING_TYPE.M ? (
                  <div
                    className="form_group with-button"
                    style={{
                      marginTop: "28px",
                      cursor: "pointer",
                    }}
                  >
                    <div className="input_box">
                      <span className="input_label">Schedule Dates</span>
                      <div
                        className="schedule_date_input"
                        onClick={handleScheduleListing}
                      >
                        {handleShowScheduleDate(sessionScheduleData)}
                      </div>
                    </div>
                  </div>
                ) : null}

                {userType !== USER_TYPE.GUEST &&
                watch(apiConstants.listingType) !== LISTING_TYPE.C ? (
                  <div style={{ marginBottom: "28px", marginTop: "28px" }}>
                    <div className="main-had">
                      <h3 className="headis">
                        How do you want to receive the payment
                      </h3>
                      <img
                        src="/static/info.svg"
                        alt=""
                        className="info_icon"
                        onClick={() => setPaymentHowInfo((prev) => !prev)}
                      />
                    </div>
                    <SelectPayout
                      type={
                        watch(apiConstants.payment_mode) &&
                        watch(apiConstants.stripe_account_id)
                          ? "stripe"
                          : watch(apiConstants.payment_mode) &&
                            watch(apiConstants.bsb_number)
                          ? "direct"
                          : ""
                      }
                      getValues={getValues}
                      handleClick={() => handleScreenSection("payment")}
                    />

                    {paymentHowInfo && (
                      <InfoMessage
                        message={STRING_CONSTANTS.listing_cost_method}
                        style={{ marginTop: "12px" }}
                      />
                    )}
                  </div>
                ) : null}

                {userType !== USER_TYPE.GUEST &&
                  watch(apiConstants.listingType) !== LISTING_TYPE.C && (
                    <div className="main-heaidng">
                      <h6>Allow booking to cancel</h6>
                      <img
                        src="/static/info.svg"
                        alt=""
                        onClick={() => setIsisBookingInfo(!isBookingInfo)}
                      />
                    </div>
                  )}
                {userType !== USER_TYPE.GUEST &&
                  watch(apiConstants.listingType) !== LISTING_TYPE.C && (
                    <div className="all-radio-button">
                      <div className="form_radio_button ">
                        <img
                          className="cursor-pointer"
                          src={
                            !watch(apiConstants.isCancellationAllowed)
                              ? "/static/radiocheck.svg"
                              : "/static/radiouncheck.svg"
                          }
                          alt=""
                          onClick={() => {
                            handleSetValue(
                              apiConstants.isCancellationAllowed,
                              false
                            );
                            handleSetValue(
                              apiConstants.cancellation_allowed_till_hours,
                              ""
                            );
                          }}
                        />
                        <label
                          className={
                            watch(apiConstants.isCancellationAllowed) === false
                              ? "form-check-label cursor-pointer active"
                              : "form-check-label cursor-pointer"
                          }
                          htmlFor="bookingdontallow"
                          onClick={() => {
                            handleSetValue(
                              apiConstants.isCancellationAllowed,
                              false
                            );
                            handleSetValue(
                              apiConstants.cancellation_allowed_till_hours,
                              ""
                            );
                          }}
                        >
                          Don’t allow
                        </label>
                      </div>
                      <div className="form_radio_button">
                        <img
                          className="cursor-pointer"
                          src={
                            watch(apiConstants.isCancellationAllowed)
                              ? "/static/radiocheck.svg"
                              : "/static/radiouncheck.svg"
                          }
                          alt=""
                          onClick={() =>
                            handleSetValue(
                              apiConstants.isCancellationAllowed,
                              true
                            )
                          }
                        />
                        <label
                          className={
                            watch(apiConstants.isCancellationAllowed) === true
                              ? "form-check-label cursor-pointer active"
                              : "form-check-label cursor-pointer"
                          }
                          htmlFor="bookingallow"
                          onClick={() =>
                            handleSetValue(
                              apiConstants.isCancellationAllowed,
                              true
                            )
                          }
                        >
                          Allow
                        </label>
                      </div>
                      {watch(apiConstants.cancellation_allowed_till_hours) ? (
                        <div className="booking-chip">
                          Allowed till{" "}
                          {watch(apiConstants.cancellation_allowed_till_hours)}{" "}
                          hours
                          <span
                            onClick={() => {
                              handleSetValue(
                                apiConstants.cancellation_allowed_till_hours,
                                ""
                              );
                            }}
                          >
                            <img alt="" src="/static/dismisscircle.svg" />
                          </span>
                        </div>
                      ) : null}
                      {watch(apiConstants.listingType) !== LISTING_TYPE.C &&
                      watch(apiConstants.isCancellationAllowed) === true ? (
                        <div className="form_group">
                          <div className="select-box-booking">
                            <span className="input_label">
                              Allow cancellation till
                            </span>
                            <select
                              value={watch(
                                apiConstants.cancellation_allowed_till_hours
                              )}
                              onChange={(e) => {
                                handleSetValue(
                                  apiConstants.cancellation_allowed_till_hours,
                                  e.target.value
                                );
                              }}
                              className="select-wrap"
                            >
                              <option value={""} disabled>
                                select an option
                              </option>
                              <option value={24}>Allow till 24 hours</option>
                              <option value={48}>Allow till 48 hours</option>
                              <option value={72}>Allow till 72 hours</option>
                              <option value={96}>Allow till 96 hours</option>
                              <option value={120}>Allow till 120 hours</option>
                            </select>
                            <img
                              src={
                                watch(
                                  apiConstants.cancellation_allowed_till_hours
                                )
                                  ? "/static/dismisscircle.svg"
                                  : "/static/cross-circle-gray.svg"
                              }
                              className="clear-icon"
                              alt=""
                              onClick={() => {
                                handleSetValue(
                                  apiConstants.cancellation_allowed_till_hours,
                                  ""
                                );
                              }}
                            />
                          </div>
                        </div>
                      ) : null}
                      <div className="terms-cancel-allow">
                        Please read{" "}
                        <Link
                          to="/policies?activeTab=terms"
                          target="_blank"
                          style={{ color: "#ee762f" }}
                        >
                          {" "}
                          T&C
                        </Link>{" "}
                        to understand the{" "}
                        <Link
                          to="/policies?activeTab=privacy"
                          target="_blank"
                          style={{ color: "#ee762f" }}
                        >
                          Cancellation Terms{" "}
                        </Link>
                      </div>
                    </div>
                  )}

                {isBookingInfo && (
                  <InfoMessage
                    message={STRING_CONSTANTS.booking_info}
                    style={{ marginTop: "12px" }}
                  />
                )}

                <div className="main-had" style={{ marginTop: "24px" }}>
                  <h3 className="headis">{STRING_CONSTANTS.age_prefrence}</h3>
                </div>
                <div className="all-radio-button">
                  <div className="form_radio_button ">
                    <img
                      className="cursor-pointer"
                      src={
                        !watch(apiConstants.is_specific_age)
                          ? "/static/radiocheck.svg"
                          : "/static/radiouncheck.svg"
                      }
                      alt=""
                      onClick={() =>
                        handleSetValue(apiConstants.is_specific_age, false)
                      }
                    />
                    <label
                      className={
                        watch(apiConstants.is_specific_age) === false
                          ? "form-check-label cursor-pointer active"
                          : "form-check-label cursor-pointer"
                      }
                      htmlFor="allages"
                      onClick={() =>
                        handleSetValue(apiConstants.is_specific_age, false)
                      }
                    >
                      {STRING_CONSTANTS.all_age_listing}
                    </label>
                  </div>
                  <div className="form_radio_button mb_24">
                    <img
                      className="cursor-pointer"
                      src={
                        watch(apiConstants.is_specific_age)
                          ? "/static/radiocheck.svg"
                          : "/static/radiouncheck.svg"
                      }
                      alt=""
                      onClick={() =>
                        handleSetValue(apiConstants.is_specific_age, true)
                      }
                    />
                    <label
                      className={
                        watch(apiConstants.is_specific_age) === true
                          ? "form-check-label cursor-pointer active"
                          : "form-check-label cursor-pointer"
                      }
                      htmlFor="specificage"
                      onClick={() =>
                        handleSetValue(apiConstants.is_specific_age, true)
                      }
                    >
                      {STRING_CONSTANTS.specific_age_listing}
                    </label>
                  </div>
                </div>
                {watch(apiConstants.is_specific_age) && (
                  <div className="two-row no_cross">
                    <InputField
                      type={"number"}
                      name={apiConstants.min_age}
                      inputLabel={"Minimum Age"}
                      placeholder={"Minimum Age"}
                      control={control}
                      errors={errors}
                    />
                    <InputField
                      type={"number"}
                      name={apiConstants.max_age}
                      inputLabel={"Maximum Age"}
                      placeholder={"Maximum Age"}
                      control={control}
                      errors={errors}
                      disabled={watch(apiConstants.min_age) ? false : true}
                    />
                  </div>
                )}
                <div
                  className="main-had "
                  style={{
                    marginTop: "0px",
                  }}
                >
                  <h3 className="headis">
                    {STRING_CONSTANTS.gender_preference}
                  </h3>
                </div>
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <div className="listing_type_radio">
                    <img
                      src={
                        watch(apiConstants.selectedGender)?.length === 3
                          ? "/static/radiocheck.svg"
                          : "/static/radiouncheck.svg"
                      }
                      alt=""
                      onClick={() =>
                        handleSetValue(apiConstants.selectedGender, [
                          "M",
                          "F",
                          "N",
                        ])
                      }
                    />
                    <label
                      className={
                        watch(apiConstants.selectedGender)?.length === 3
                          ? "listing_ype_label cursor-pointer active"
                          : "listing_ype_label cursor-pointer"
                      }
                      onClick={() =>
                        handleSetValue(apiConstants.selectedGender, [
                          "M",
                          "F",
                          "N",
                        ])
                      }
                    >
                      This listing is for all genders
                    </label>
                  </div>
                  <div className="listing_type_radio">
                    <img
                      src={
                        watch(apiConstants.selectedGender)?.length < 3
                          ? "/static/radiocheck.svg"
                          : "/static/radiouncheck.svg"
                      }
                      alt=""
                      onClick={() =>
                        handleSetValue(apiConstants.selectedGender, [])
                      }
                    />
                    <label
                      className={
                        watch(apiConstants.selectedGender)?.length < 3
                          ? "listing_ype_label cursor-pointer active"
                          : "listing_ype_label cursor-pointer"
                      }
                      onClick={() =>
                        handleSetValue(apiConstants.selectedGender, [])
                      }
                    >
                      This listing is for a specific gender
                    </label>
                  </div>
                </div>
                <div className="all-checkbox-button_with-check m-0">
                  {watch(apiConstants.selectedGender)?.length < 3 &&
                    GendersTypeSpecific?.map((g) => (
                      <div
                        key={g.value}
                        className="form_checkbox_button_with_check"
                      >
                        <Controller
                          name={`gender_preference.${g.value}`}
                          control={control}
                          render={({ field }) => (
                            <div className="form_group">
                              <input
                                {...field}
                                type="checkbox"
                                id={g.title}
                                value={g.value}
                                checked={watch(
                                  apiConstants.selectedGender
                                ).includes(g.value)}
                                onClick={(e: any) =>
                                  handleGender(e.target.value)
                                }
                              />
                              <label
                                htmlFor={g.title}
                                style={{ justifyContent: "normal" }}
                                className={
                                  watch(apiConstants.selectedGender).includes(
                                    g.value
                                  )
                                    ? "checked"
                                    : "checkbox-gender-label"
                                }
                              >
                                <img
                                  src={g.activeImg}
                                  style={{
                                    background: "#ffffff",
                                    padding: "8px 8.5px",
                                    borderRadius: "50%",
                                  }}
                                  alt=""
                                />
                                <span>{g.title}</span>
                                {watch(apiConstants.selectedGender).includes(
                                  g.value
                                ) && (
                                  <img
                                    src="/static/checkmark-circle.svg"
                                    className="checkmark-circle"
                                    alt=""
                                  />
                                )}
                              </label>
                            </div>
                          )}
                        />
                      </div>
                    ))}
                  <span
                    className="text-danger"
                    style={{ position: "absolute", bottom: "0" }}
                  >
                    <>
                      {errors?.selectedGender
                        ? errors.selectedGender?.message
                        : ""}
                    </>
                  </span>
                </div>

                <div
                  className="main-had "
                  style={{
                    marginTop: "18px",
                  }}
                >
                  <h3 className="headis">Difficulty level</h3>
                </div>
                <div className="all-checkbox-button_with-check">
                  {difficultyLevelPreferenceNew?.map((g) => (
                    <div
                      key={g.value}
                      className="form_checkbox_button_with_check"
                    >
                      <Controller
                        name={`difficulty_level.${g.value}`}
                        control={control}
                        render={({ field }) => (
                          <div className="form_group">
                            <input
                              {...field}
                              type="checkbox"
                              id={g.title}
                              value={g.value}
                              checked={selectedPreferencesDifficulty.includes(
                                g.value
                              )}
                              onChange={(e) => {
                                const value = e.target.value;
                                const updatedDifficulty = e.target.checked
                                  ? [...selectedPreferencesDifficulty, value]
                                  : selectedPreferencesDifficulty.filter(
                                      (pref) => pref !== value
                                    );

                                setValue("difficulty_level", updatedDifficulty);
                              }}
                              onClick={(e: any) =>
                                handleDifficulty(e.target.value)
                              }
                            />
                            <label
                              htmlFor={g.title}
                              style={{ justifyContent: "normal" }}
                              className={
                                selectedPreferencesDifficulty.includes(g.value)
                                  ? "checked"
                                  : "checkbox-gender-label"
                              }
                            >
                              <img
                                src={
                                  selectedPreferencesDifficulty.includes(
                                    g.value
                                  )
                                    ? g.activeImg
                                    : g.img
                                }
                                style={{
                                  background: "#ffffff",
                                  padding: "8px 8.5px",
                                  borderRadius: "50%",
                                }}
                                alt=""
                              />
                              <span>{g.title}</span>
                              {selectedPreferencesDifficulty.includes(
                                g.value
                              ) && (
                                <img
                                  src="/static/checkmark-circle.svg"
                                  className="checkmark-circle"
                                  alt=""
                                />
                              )}
                            </label>
                          </div>
                        )}
                      />
                    </div>
                  ))}
                  <span
                    className="text-danger"
                    style={{ position: "absolute", bottom: "0" }}
                  >
                    <>
                      {errors?.selecteddifficulty
                        ? errors.selecteddifficulty?.message
                        : ""}
                    </>
                  </span>
                </div>

                <div className="d-flex gap-2 flex-column flex-sm-row">
                  {listingData?.isDraft && (
                    <Button
                      btnTxt={
                        // watch(apiConstants.isDraft)
                        // ? "Create listing"
                        // :
                        "Save as Draft"
                      }
                      style={{
                        background: "#ed772e3e",
                        color: "#ed772e",
                        border: "1px solid #ed772e",
                      }}
                      onClick={async () => {
                        await handleSetValue(apiConstants.isDraft, true);
                        await setTimeout(() => {
                          handleNext({ isDraft: true });
                        }, 500);
                      }}
                      isLoading={loadingState?.isDraft}
                    />
                  )}
                  <Button
                    btnTxt={
                      // watch(apiConstants.isDraft)
                      // ? "Create listing"
                      // :
                      "Publish listing"
                    }
                    onClick={async () => {
                      await handleNext({ isDraft: false });
                      handleSetValue(apiConstants.isDraft, false);
                    }}
                    isLoading={loadingState.isCreate}
                  />
                </div>
              </div>
              {!edit && (
                <div className="d-flex justify-content-end">
                  <Button
                    btnImg="/static/savebtn.svg"
                    btnTxt="Save to draft"
                    className="draft_btn d-none d-md-block"
                    style={{
                      background: getDisableData() ? "#d9d9d9" : "",
                    }}
                    isLoading={loadingState.isDraft}
                    onClick={() => {
                      handleSetValue(apiConstants.isDraft, true);
                      if (watch(apiConstants.listingType) === LISTING_TYPE.M) {
                        if (
                          getValues(apiConstants.multipleEvents).length < 2 &&
                          !watch(apiConstants.isDraft)
                        ) {
                          showToast(
                            "Package listings must include a minimum of two scheduled events.",
                            "error",
                            "pacakgelist"
                          );
                        }
                      }
                      setTimeout(() => {
                        handleNext({ isDraft: true });
                      }, 500);
                    }}
                    disabled={getDisableData()}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {scheduleListing && (
        <ScheduleListingModal
          setScheduleLisiting={setScheduleLisiting}
          control={control}
          handleSetValue={handleSetValue}
          watch={watch}
          getValues={getValues}
          trigger={trigger}
          errors={errors}
          setScheduleDate={setScheduleDate}
          dirtyFields={dirtyFields}
          setScheduleData={setScheduleData}
          scheduleData={scheduleData}
        />
      )}
      {showDraftPopUp && (
        <DraftPopup
          title={
            watch(apiConstants.isDraft) ? "Save as a draft" : "Save Listing?"
          }
          btnTxt="Save"
          // discard={handleDiscardListing}
          discard={() => Navigate("/drafts")}
          onClose={() => setShowDraftPopUp(false)}
          showDiscard={watch(apiConstants.listingDetails)?.hasDraft}
          draft={() => {
            handleSetValue(apiConstants.isDraft, true);
            setTimeout(() => {
              handleNext({ isDraft: true });
            }, 500);
          }}
        />
      )}
      {/* Right sidebar for multiple listing type  */}

      {showAddEventSidebar && !showEventListingsSidebar ? (
        <div className="fixed-sidebar-left">
          <div className="sidebar-box-event">
            <div className="event-title">
              <img
                src="/static/cross-black.svg"
                onClick={() => {
                  setShowAddEventSidebar(false);
                  setEditEvent(false);
                  setValue(apiConstants.event_title, "");
                  setValue(apiConstants.city, "");
                  setValue(apiConstants.state, "");
                  setValue(apiConstants.country, "");
                  setValue(apiConstants.postCode, "");
                  setValue(apiConstants.address, "");
                  setValue(apiConstants.listing_location, "");
                  setValue(apiConstants.event_start_date, "");
                  setValue(apiConstants.event_start_time, "");
                  setValue(apiConstants.event_end_time, "");
                  setValue(apiConstants.event_end_date, "");
                  setValue(apiConstants.schedule_date, "");
                  setValue(apiConstants.event_date, "");
                  setValue(apiConstants.virtual_links_package, "");
                  setPackageLocation(null);
                }}
                className="close-btn d-none d-md-block"
                alt=""
              />

              <span
                className="d-md-none"
                onClick={() => {
                  setShowAddEventSidebar(false);
                }}
                style={{ cursor: "pointer" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M8.73464 16.7917C9.03557 17.0763 9.51026 17.0631 9.79489 16.7621C10.0795 16.4612 10.0663 15.9865 9.76536 15.7019L4.52632 10.7468L17.25 10.7468C17.6642 10.7468 18 10.411 18 9.99678C18 9.58256 17.6642 9.24678 17.25 9.24678L4.52405 9.24678L9.76536 4.28952C10.0663 4.00489 10.0795 3.53021 9.79489 3.22927C9.51026 2.92834 9.03557 2.91512 8.73464 3.19974L2.31741 9.26918C2.15112 9.42647 2.05112 9.62658 2.01741 9.83537C2.00601 9.88736 2 9.94136 2 9.99678C2 10.0544 2.00649 10.1104 2.01878 10.1643C2.05377 10.3701 2.15331 10.567 2.31741 10.7222L8.73464 16.7917Z"
                    fill="#25272D"
                  />
                </svg>
              </span>
              <div className="filter-heading pe-3">
                {editEvent ? "Update Event" : "Add Event"}
              </div>
            </div>
            <div className="inner-contet">
              <>
                <InputField
                  type={"text"}
                  name={apiConstants.event_title}
                  inputLabel={"Event Name"}
                  placeholder={"Type event name"}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                />

                {watch(apiConstants.listing_event_type) === "V" &&
                  watch(apiConstants.multiple_virtual_links_allowed) ===
                    false && (
                    <>
                      <div>
                        <InputField
                          type={"text"}
                          name={apiConstants.virtual_links_package}
                          inputLabel={"Add Meeting URL"}
                          placeholder={"Enter URL"}
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          style={{ marginBottom: "12px" }}
                        />
                      </div>
                      <div className="mb-4">
                        <InfoCard
                          title="
              Please include only web URLs for Google Meet, Zoom, and Microsoft Teams here."
                        />
                      </div>
                    </>
                  )}
                {watch(apiConstants.listing_event_type) === "P" && (
                  <GooglePlacesSearchBar
                    setIsLocationAccessible={(val) =>
                      setIsLocationAccessible(val)
                    }
                    setShowLocationPopup={(val) => () => null}
                    isLocationAccessible={isLocationAccessible}
                    hideMap={true}
                    onChangeLocation={(
                      lat: string,
                      lng: string,
                      address: any,
                      address_components: any
                    ) => {
                      let location = handleLocation(
                        lat,
                        lng,
                        address,
                        address_components
                      );
                      setPackageLocation(address);
                      handleSetValue(apiConstants.address, address);
                      handleSetValue(apiConstants.lat, lat);
                      handleSetValue(apiConstants.lng, lng);
                      handleSetValue(apiConstants.postCode, location.zipcode);
                      handleSetValue(apiConstants.state, location.state);
                      handleSetValue(apiConstants.city, location.locality);
                      handleSetValue(apiConstants.country, location.country);
                    }}
                    setValue={setValue}
                    handleLocation={handleLocation}
                    control={control}
                    name={apiConstants.address}
                    label={"Search location"}
                    placeholder="Include a location"
                  />
                )}

                <div className="form_group with-button">
                  <div className="input_box">
                    <span className="input_label">Schedule Dates</span>
                    <div
                      className="schedule_date_input"
                      onClick={() => {
                        setShowAddEventSidebar(false);
                        setMultipleScheduleLisiting(true);
                      }}
                    >
                      {watch(apiConstants.event_date) ? (
                        <>
                          <p>
                            {dayjs(
                              watch(apiConstants.event_date),
                              "YYYY-MM-DD"
                            ).format("MMMM Do, YYYY")}
                          </p>
                          <small>
                            {`${dayjs(
                              watch(apiConstants.event_start_time)
                            ).format("hh:mm A")} - ${dayjs(
                              watch(apiConstants.event_end_time)
                            ).format("hh:mm A")}`}
                          </small>
                        </>
                      ) : (
                        <p style={{ color: "#8F939A" }}>
                          {STRING_CONSTANTS.schedule_date_placeholder}
                        </p>
                      )}
                    </div>
                    {errors[apiConstants.has_schedule_date] ? (
                      <span className="text-danger">
                        {`${errors[apiConstants.has_schedule_date]?.message}`}
                      </span>
                    ) : null}
                  </div>
                </div>
              </>
            </div>

            <div>
              <Button
                btnTxt={editEvent ? "Update Event" : "Save Event"}
                className="save_btn d-none d-md-block"
                onClick={handleUpdateOrSaveEvent}
              />
              <Button
                btnTxt={editEvent ? "Update Event" : "Save Event"}
                className=" d-md-none"
                onClick={handleUpdateOrSaveEvent}
              />
            </div>
          </div>
        </div>
      ) : null}
      {multipleScheduleListing ? (
        <ScheduleListingSidebar
          setMultipleScheduleLisiting={() => {
            setMultipleScheduleLisiting(false);
            setShowAddEventSidebar(true);
          }}
          control={control}
          handleSetValue={handleSetValue}
          watch={watch}
          getValues={getValues}
          trigger={trigger}
          errors={errors}
          setScheduleDate={setScheduleDate}
          dirtyFields={dirtyFields}
        />
      ) : null}
      {showEventPopup ? (
        <SaveEventPopup
          title="Save Event"
          onClose={() => {
            setShowEventPopup(false);
            setEditEvent(false);
          }}
          reCheck={() => {
            setShowEventPopup(false);
            setShowAddEventSidebar(true);
          }}
          onContinue={() => {
            setShowEventPopup(false);
            if (editEvent) {
              setEditEvent(false);
              const newMultipleEvents = watch(apiConstants.multipleEvents).map(
                (event) => (event.id === editEventId ? tempObj : event)
              );
              setValue(apiConstants.multipleEvents, newMultipleEvents);
              setEditEventId(null);
            } else {
              if (getValues(apiConstants.multipleEvents).length > 0) {
                setValue(apiConstants.multipleEvents, [
                  ...getValues(apiConstants.multipleEvents),
                  tempObj,
                ]);
              } else {
                setValue(apiConstants.multipleEvents, [tempObj]);
              }
            }
            setValue(apiConstants.event_title, "");
            setValue(apiConstants.city, "");
            setValue(apiConstants.state, "");
            setValue(apiConstants.country, "");
            setValue(apiConstants.postCode, "");
            setValue(apiConstants.address, "");
            setValue(apiConstants.listing_location, "");
            setValue(apiConstants.event_start_date, "");
            setValue(apiConstants.event_start_time, "");
            setValue(apiConstants.event_end_time, "");
            setValue(apiConstants.event_end_time, "");
            setValue(apiConstants.event_end_date, "");
            setValue(apiConstants.schedule_date, "");
            setValue(apiConstants.event_date, "");
            setPackageLocation(null);
          }}
        />
      ) : null}

      {showEventListingsSidebar ? (
        <div className="fixed-sidebar-left">
          <div className="sidebar-box-event">
            <div className="event-title mt-0">
              <img
                src="/static/cross-black.svg"
                onClick={() => setShowEventListingsSidebar(false)}
                className="close-btn"
                alt=""
              />
              <div className="filter-heading">Events</div>
            </div>
            <div className="inner-contet">
              <Fragment>
                {getValues(apiConstants.multipleEvents)?.length > 0 && (
                  <EventCardSection
                    data={getValues(apiConstants.multipleEvents)}
                    onDeleteEvent={onDeleteEvent}
                    handleEventEditClick={handleEventEditClick}
                    setShowEventListingsSidebar={setShowEventListingsSidebar}
                    viewAll={false}
                    watch={watch}
                  />
                )}
                {getValues(apiConstants.multipleEvents)?.length > 0 ? (
                  <div>
                    <OutlineYellowButton
                      style={{
                        border: "1px solid #8f939a",
                      }}
                      text="Add Event"
                      icon={<AddIconYellow />}
                      onClick={() => {
                        setShowEventListingsSidebar(false);
                        setShowAddEventSidebar(true);
                      }}
                    />
                  </div>
                ) : null}
              </Fragment>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default EditListingForm;
