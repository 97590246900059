const Call: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.52814 1.20866L4.11164 1.03316C4.43129 0.936773 4.77508 0.959976 5.07888 1.09844C5.38267 1.23691 5.62571 1.48117 5.76264 1.78566L6.21364 2.78866C6.33138 3.05056 6.36416 3.34275 6.3074 3.62423C6.25064 3.90571 6.10717 4.16236 5.89714 4.35816L5.14964 5.05466C5.12781 5.07501 5.11397 5.1025 5.11064 5.13216C5.08864 5.33066 5.22314 5.71716 5.53364 6.25466C5.75864 6.64516 5.96314 6.91966 6.13664 7.07316C6.25814 7.18066 6.32464 7.20366 6.35314 7.19566L7.35814 6.88816C7.6326 6.80421 7.92644 6.80825 8.19849 6.89971C8.47053 6.99117 8.70714 7.16546 8.87514 7.39816L9.51514 8.28616C9.70998 8.55615 9.80023 8.88766 9.76915 9.21915C9.73807 9.55064 9.58777 9.8596 9.34614 10.0887L8.90264 10.5087C8.66753 10.7313 8.37875 10.8893 8.06445 10.9672C7.75015 11.0451 7.42103 11.0402 7.10914 10.9532C5.73214 10.5687 4.49764 9.40666 3.39164 7.49116C2.28464 5.57316 1.89564 3.92116 2.25464 2.53466C2.33523 2.22283 2.49479 1.93701 2.71793 1.70476C2.94107 1.47251 3.21978 1.30165 3.52814 1.20866ZM3.74514 1.92716C3.56009 1.98281 3.39249 2.0852 3.2585 2.22444C3.1245 2.36369 3.02864 2.5351 2.98014 2.72216C2.67914 3.88816 3.02364 5.35266 4.04164 7.11616C5.05864 8.87716 6.15314 9.90766 7.31164 10.2312C7.49878 10.2834 7.69625 10.2862 7.88482 10.2394C8.07338 10.1926 8.24661 10.0978 8.38764 9.96416L8.83064 9.54416C8.94055 9.44006 9.00893 9.2996 9.0231 9.14888C9.03726 8.99816 8.99623 8.84742 8.90764 8.72466L8.26764 7.83716C8.19127 7.73127 8.08366 7.65195 7.95991 7.61034C7.83615 7.56873 7.70248 7.56692 7.57764 7.60516L6.57014 7.91366C5.98514 8.08766 5.45464 7.61716 4.88464 6.62966C4.49964 5.96466 4.32064 5.44966 4.36564 5.04916C4.38864 4.84116 4.48564 4.64916 4.63814 4.50616L5.38564 3.80966C5.48107 3.72061 5.54622 3.60391 5.57196 3.47595C5.59769 3.34799 5.58272 3.21518 5.52914 3.09616L5.07864 2.09316C5.01639 1.95476 4.90591 1.84373 4.76781 1.7808C4.62971 1.71787 4.47344 1.70733 4.32814 1.75116L3.74514 1.92716Z"
        fill="#8F939A"
      />
    </svg>
  );
};

export default Call;
