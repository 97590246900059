import React, { Fragment } from "react";
import "./style.scss";
import { Box } from "@mui/material";
import DateWiseSection from "./DateWiseSection";
import { FORM_KEYS } from "../../../../constants/helperScheduleDates";

type Props = {
  watch: any;
  setValue: any;
  getValues: any;
  errors: any;
  clearErrors: any;
};

const TimeSlotsContainer = ({
  setValue,
  getValues,
  watch,
  errors,
  clearErrors,
}: Props) => {
  return (
    <Box className="time_slots_wrapper_outer">
      {watch(FORM_KEYS.TEMP_DATE_TIME_SLOTS) &&
        watch(FORM_KEYS.TEMP_DATE_TIME_SLOTS)?.map((item, index) => (
          <Fragment key={index + "total_slots"}>
            <DateWiseSection
              dateWiseData={item}
              indexing={index}
              watch={watch}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              clearErrors={clearErrors}
              disableCopy={watch(FORM_KEYS.TEMP_DATE_TIME_SLOTS)?.length < 2}
            />
          </Fragment>
        ))}
    </Box>
  );
};

export default TimeSlotsContainer;
