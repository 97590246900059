import React from "react";

const Dismiss: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_10552_5322)">
        <path
          d="M-0.108655 0.0689855L-0.0254659 -0.0252218C0.335018 -0.385706 0.902249 -0.413436 1.29454 -0.108411L1.38875 -0.0252218L7.68164 6.26688L13.9745 -0.0252218C14.3651 -0.415746 14.9982 -0.415746 15.3887 -0.0252218C15.7793 0.365303 15.7793 0.998467 15.3887 1.38899L9.09664 7.68188L15.3887 13.9748C15.7492 14.3353 15.777 14.9025 15.4719 15.2948L15.3887 15.389C15.0283 15.7495 14.461 15.7772 14.0687 15.4722L13.9745 15.389L7.68164 9.09688L1.38875 15.389C0.998223 15.7795 0.365058 15.7795 -0.0254659 15.389C-0.41599 14.9985 -0.41599 14.3653 -0.0254659 13.9748L6.26664 7.68188L-0.0254659 1.38899C-0.38595 1.02851 -0.41368 0.461277 -0.108655 0.0689855L-0.0254659 -0.0252218L-0.108655 0.0689855Z"
          fill="#25272D"
        />
      </g>
      <defs>
        <clipPath id="clip0_10552_5322">
          <rect
            width="13.6364"
            height="13.6364"
            fill="white"
            transform="translate(0.681641 0.681885)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Dismiss;
