interface ButtonPropsI {
  className: string;
  title: string;
  handleBtnClick: () => void;
  iconFront?: any;
  iconBack?: any;
}

const Button: React.FC<ButtonPropsI> = ({
  className,
  title,
  handleBtnClick,
  iconFront,
  iconBack
}) => {
  return (
    <button className={className} onClick={(e)=> {
      e.stopPropagation();
      handleBtnClick()
    }}
      >
      {iconFront && <img src={iconFront} alt="icon" />}
      {title}
      {iconBack && <img src={iconBack} alt="icon" />}

    </button>
  );
};

export default Button;
