import ViewListing from "../components/ViewListing/ViewListing";

const ViewListingPage = () => {
  return (
    <>
      <ViewListing />
    </>
  );
};

export default ViewListingPage;
