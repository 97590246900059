import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppleLoginButton,
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import {
  IResolveParams,
  LoginSocialApple,
  LoginSocialFacebook,
  LoginSocialGoogle,
} from "reactjs-social-login";
import { PROVIDER_TYPE } from "../../types";
import { getItemFromStore, setLocalStorage, showToast } from "../../utils";
import { apiConstants } from "../../utils/Constants";
import endpoints from "../../utils/endpoints";
import { makePostCall } from "../../utils/Requests";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import { useAppDispatch } from "../../store/store";
import { authSuccess } from "../../store/auth/action";

const SocialLogin = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState<any>();

  const onLoginStart = useCallback(() => { }, []);
  const handleSocialLogin = () => {
    props.setSocialLoading(true);
    let formData: any = new FormData();
    let url;
    formData.append(
      apiConstants.client_id,
      process.env.REACT_APP_IS_DEV === "true"
        ? process.env.REACT_APP_CLIENT_ID
        : process.env.REACT_APP_IS_LOCAL === "true"
          ? process.env.REACT_APP_LOCAL_CLIENT_ID
          : process.env.REACT_APP_STAGING_CLIENT_ID
    );
    formData.append(
      apiConstants.client_secret,
      process.env.REACT_APP_IS_DEV === "true"
        ? process.env.REACT_APP_CLIENT_SECRET
        : process.env.REACT_APP_IS_LOCAL === "true"
          ? process.env.REACT_APP_LOCAL_CLIENT_SECRET
          : process.env.REACT_APP_STAGING_CLIENT_SECRET
    );

    if (provider === PROVIDER_TYPE.APPLE) {
      formData.append(apiConstants.is_ios, false);
      formData.append(apiConstants.code, profile.authorization.code);
      formData.append(apiConstants.id_token, profile.authorization.id_token);

      url = endpoints.apple_signin;
    } else {
      formData.append(apiConstants.grant_type, "convert_token");
      url = endpoints.convert_token;
      if (provider === PROVIDER_TYPE.GOOGLE) {
        formData.append(apiConstants.backend, "google-oauth2");
        formData.append(apiConstants.token, profile.access_token);
      }
      if (provider === PROVIDER_TYPE.FACEBOOK) {
        formData.append(apiConstants.token, profile.accessToken);
        formData.append(apiConstants.backend, "facebook");
      }
    }

    if (localStorage.getItem('attribute')) {
      formData.append("attribution", localStorage.getItem('attribute'));
    }
    makePostCall({
      url,
      apiPayload: formData,
      content_type: "multipart/form-data",
    })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res.status.message, "success");
          dispatch(
            authSuccess
            ({
              token: res.token.accessToken,
              moreUserDetails: res.data,
              loggedIn: true,
            })
          );
          setLocalStorage("userData", res.data);
          setLocalStorage("access_token", res.token.accessToken);
          setLocalStorage(
            "isProfileComplete",
            res?.data.isProfileCompleted || false
          );
          setLocalStorage("refresh_token", res.token.refreshToken);
          if (getItemFromStore("isProfileComplete"))
            navigate(ROUTE_CONSTANTS.dashboard, { replace: true });
          else {
            if (process.env.REACT_APP_IS_PROD === "true") {
              navigate(ROUTE_CONSTANTS.manage_profile);
            } else {
              navigate(ROUTE_CONSTANTS.welcome);
            }
          }
        } else {
          showToast(res.status.message || res.error.message, "error");
        }
        props.setSocialLoading(false);
      })
      .catch((err) => {
        showToast(err, "error");
        props.setSocialLoading(false);
      });
  };
  useEffect(() => {
    if (provider && profile) {
      handleSocialLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, profile]);

  return (
    <ul className="other_otions">
      <li>
        <LoginSocialApple
          client_id={process.env.REACT_APP_APPLE_ID || ""}
          scope={"name email"}
          redirect_uri={
            process.env.REACT_APP_IS_DEV === "true"
              ? process.env.REACT_APP_REDIRECT_URI
              : process.env.REACT_APP_STAGING_REDIRECT_URI
          }
          onLoginStart={onLoginStart}
          onResolve={({ provider, data }: IResolveParams) => {
            setProvider(provider);
            setProfile(data);
          }}
          onReject={(err) => {
            showToast(err, "error");
          }}
        >
          <div className="social_btn_container">
            <AppleLoginButton
              text={window.innerWidth >= 576 ? "Log in with Apple" : ""}
              className="social_btn apple"
              style={{ border: "0px", margin: "0px", padding: "0px" }}
            />
            {/* <span className="d-none d-sm-block">Log in with Apple</span> */}
          </div>
        </LoginSocialApple>
      </li>
      {/* <li>
        <LoginSocialFacebook
          isOnlyGetToken
          appId={process.env.REACT_APP_FB_APP_ID || ""}
          onLoginStart={onLoginStart}
          onResolve={({ provider, data }: IResolveParams) => {
            setProvider(provider);
            setProfile(data);
          }}
          onReject={(err) => {
            showToast(err, "error");
          }}
        >
          <div className="social_btn_container">
            <FacebookLoginButton
              text={window.innerWidth >= 576 ? "Log in with Facebook" : ""}
              className="social_btn fb"
            />
          </div>
        </LoginSocialFacebook>
      </li> */}
      <li>
        <LoginSocialGoogle
          client_id={process.env.REACT_APP_GG_APP_ID || ""}
          onLoginStart={onLoginStart}
          scope="openid profile email"
          onResolve={({ provider, data }: IResolveParams) => {
            setProvider(provider);
            setProfile(data);
          }}
          onReject={(err) => {
            showToast(err, "error");
          }}
        >
          {" "}
          <div className="social_btn_container">
            <GoogleLoginButton
              text={window.innerWidth >= 576 ? "Log in with Google" : ""}
              className="social_btn google"
            />
            {/* <span className="d-none d-sm-block social_txt">Log in with Google</span> */}
          </div>
        </LoginSocialGoogle>
      </li>
    </ul>
  );
};

export default SocialLogin;
