import { Fragment, useEffect, useState } from "react";
// import { Accordion } from "react-bootstrap";
// import Button from "../../../global/Button";
// import { MemberIcon } from "../../../utils/Icons/svgs";
// import moment from "moment";
// import Subscription2 from "./Subscription2";
import endpoints from "../../../utils/endpoints";
import { Card, Button } from "@mui/material";
import { makeGetCall } from "../../../utils/Requests";
import { showToast } from "../../../utils";
import IconPlan from "../../../assets/img/membership/plan.svg";
import TimeIcon from "../../../assets/img/icons/TimeIcon";
import IconRenewal from "../../../assets/img/membership/renewal.svg";
import IconTotalAmount from "../../../assets/img/membership/total-amount.svg";
import IconMembershipAmount from "../../../assets/img/membership/membership-amount.svg";
import IconDangerGray from "../../../assets/img/membership/danger-gray.svg";
import moment from "moment";

const SubsPlanCard = ({ userProfile, onCancelPayCut, subscriptionDetails }) => {
  const [seeMore, setSeeMore] = useState(false);
  const [stripUrl, setStripeUrl] = useState("");
  const [subscriptionDetailData, setSubscriptionDetailData] =
    useState(subscriptionDetails);
  const [showRedirectingModal, setShowRedirectingModal] = useState(false);

  const handleRedirect = () => {
    setShowRedirectingModal(true);
    setTimeout(() => setShowRedirectingModal(false), 1000);
    window.location.href = stripUrl;
  };

  const getManageSubscriptio = () => {
    makeGetCall({ url: endpoints.manage_subscription })
      .then((res) => {
        if (res.status.code === 200) {
          if (res.data.url) {
            setStripeUrl(res.data.url);
          }
          // setSubscriptionDetail(res.data);
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };
  const DateFormatter = (timestamp: any) => {
    // Convert Unix timestamp (in seconds) to a moment object
    // moment.unix(timestamp).format("1726476357")
    if (timestamp) {
      return moment.unix(timestamp).format("DD MMMM YYYY");
    }
    return "Date not found";
  };
  let slicedTransaction = subscriptionDetails?.transaction;

  if (subscriptionDetails?.transaction?.length > 5 && seeMore === false) {
    slicedTransaction = subscriptionDetails?.transaction?.slice(0, 5);
  }

  useEffect(() => {
    getManageSubscriptio();
  }, []);
  useEffect(() => {
    setSubscriptionDetailData(subscriptionDetails);
  }, [subscriptionDetails]);
  return (
    // <div className="s-block-details-card-wrap">
    //   <div className="s-card-top-content">
    //     <div className="subs-img-container">
    //       <MemberIcon />
    //     </div>
    //     <div>
    //       <div className="s-boxtitle">Member</div>
    //       <div className="s-subtitle">You have subscribed as a member</div>
    //     </div>
    //   </div>
    //   <div className="s-price-detail-box-wrap">
    //     <div className="s-price-detail-box">
    //       <div className="s-left">
    //         <span>
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="20"
    //             height="20"
    //             viewBox="0 0 20 20"
    //             fill="none"
    //           >
    //             <path
    //               d="M3.5 4C2.67157 4 2 4.67157 2 5.5V12.5C2 13.3284 2.67157 14 3.5 14H14.5C15.3284 14 16 13.3284 16 12.5V5.5C16 4.67157 15.3284 4 14.5 4H3.5ZM6 5V6C6 7.10457 5.10457 8 4 8L3 8V7H4C4.55228 7 5 6.55228 5 6V5H6ZM9 10.75C8.0335 10.75 7.25 9.9665 7.25 9C7.25 8.0335 8.0335 7.25 9 7.25C9.9665 7.25 10.75 8.0335 10.75 9C10.75 9.9665 9.9665 10.75 9 10.75ZM3 11V10H4C5.10457 10 6 10.8954 6 12V13H5V12C5 11.4477 4.55228 11 4 11H3ZM14 11C13.4477 11 13 11.4477 13 12V13H12V12C12 10.8954 12.8954 10 14 10H15V11H14ZM14 7H15V8L14 8C12.8954 8 12 7.10457 12 6V5H13V6C13 6.55228 13.4477 7 14 7ZM17.0001 12.5C17.0001 13.8807 15.8808 15 14.5001 15H4.08545C4.29137 15.5826 4.84699 16 5.5001 16H14.5001C16.4331 16 18.0001 14.433 18.0001 12.5V7.49997C18.0001 6.84686 17.5827 6.29125 17.0001 6.08533V12.5Z"
    //               fill="#6C6D6F"
    //             />
    //           </svg>
    //         </span>
    //         <span>Subscription Amount</span>
    //       </div>
    //       <div className="s-right">
    //         ${subscriptionDetails?.subscription?.plan?.amount / 100}
    //       </div>
    //     </div>
    //     <div className="s-price-detail-box">
    //       <div className="s-left">
    //         <span>
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="20"
    //             height="20"
    //             viewBox="0 0 20 20"
    //             fill="none"
    //           >
    //             <path
    //               d="M6.71681 3.48438H6.06017C4.09024 3.48438 3.43359 4.65977 3.43359 6.11095V6.76759V15.9606C3.43359 16.5056 4.05084 16.8142 4.48422 16.4859L5.60708 15.6454C5.86974 15.4484 6.23746 15.4747 6.47385 15.7111L7.56388 16.8077C7.81997 17.0638 8.24022 17.0638 8.49632 16.8077L9.59948 15.7045C9.8293 15.4747 10.197 15.4484 10.4531 15.6454L11.576 16.4859C12.0094 16.8077 12.6266 16.499 12.6266 15.9606V4.79766C12.6266 4.07535 13.2176 3.48438 13.9399 3.48438H6.71681ZM9.50755 11.1999H6.55265C6.28343 11.1999 6.06017 10.9767 6.06017 10.7075C6.06017 10.4382 6.28343 10.215 6.55265 10.215H9.50755C9.77677 10.215 10 10.4382 10 10.7075C10 10.9767 9.77677 11.1999 9.50755 11.1999ZM10 8.57336H6.06017C5.79094 8.57336 5.56768 8.3501 5.56768 8.08088C5.56768 7.81166 5.79094 7.5884 6.06017 7.5884H10C10.2693 7.5884 10.4925 7.81166 10.4925 8.08088C10.4925 8.3501 10.2693 8.57336 10 8.57336Z"
    //               fill="#6C6D6F"
    //             />
    //             <path
    //               d="M13.9468 3.48438V4.46934C14.3802 4.46934 14.7939 4.64663 15.0959 4.94212C15.4111 5.26388 15.5818 5.67756 15.5818 6.11095V7.70003C15.5818 8.18594 15.3652 8.4092 14.8727 8.4092H13.6119V4.80423C13.6119 4.62037 13.7629 4.46934 13.9468 4.46934V3.48438ZM13.9468 3.48438C13.2179 3.48438 12.627 4.07535 12.627 4.80423V9.39417H14.8727C15.9102 9.39417 16.5668 8.73752 16.5668 7.70003V6.11095C16.5668 5.38864 16.2713 4.732 15.7985 4.25265C15.3192 3.77986 14.6691 3.49094 13.9468 3.48438C13.9534 3.48438 13.9468 3.48438 13.9468 3.48438Z"
    //               fill="#6C6D6F"
    //             />
    //           </svg>
    //         </span>
    //         <span>Total amount paid</span>
    //       </div>
    //       <div className="s-right">
    //         $
    //         {subscriptionDetails?.totalAmount?.totalPayment
    //           ? subscriptionDetails?.totalAmount?.totalPayment
    //           : "0"}
    //       </div>
    //     </div>
    //   </div>
    //   <div className="s-transaction-btn-box">
    //     <Accordion>
    //       <Accordion.Item eventKey="0">
    //         <Accordion.Header>Payment Transactions</Accordion.Header>
    //         <Accordion.Body>
    //           {slicedTransaction?.map((item, index) => {
    //             return (
    //               <Fragment key={index}>
    //                 <div className="s-transactions-info-wrap">
    //                   <div className="s-left">
    //                     <img alt="" src="/static/trans-arrow.svg" />
    //                     <div>
    //                       <div>
    //                         Paid for{" "}
    //                         {item?.paymentDuration === "m" ? "month" : "year"}
    //                       </div>
    //                       <div className="s-date">
    //                         {moment(item?.createdAt).date()}{" "}
    //                         {moment(item?.date).format("MMM")},{" "}
    //                         {moment(item?.createdAt).year()}
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <div className="s-right">
    //                     ${parseInt(item?.netAmount)}
    //                   </div>
    //                 </div>
    //                 <div className="s-divider"></div>
    //               </Fragment>
    //             );
    //           })}
    //           {subscriptionDetails?.transaction?.length > 5 && (
    //             <div
    //               style={{
    //                 fontFamily: "Poppins",
    //                 fontSize: "14px",
    //                 textAlign: "right",
    //                 color: "#EE7840",
    //                 fontWeight: "500",
    //                 display: "inline-block",
    //                 width: "100%",
    //               }}
    //             >
    //               <span
    //                 className=" cursor-pointer"
    //                 onClick={() => setSeeMore(!seeMore)}
    //               >
    //                 {!seeMore ? "See more" : "Hide"}
    //               </span>
    //             </div>
    //           )}
    //         </Accordion.Body>
    //       </Accordion.Item>
    //     </Accordion>
    //   </div>
    //   <div className="s-auto-paycut-btn-box">
    //     {subscriptionDetails?.subscription?.cancelAtPeriodEnd ? (
    //       <Button
    //         btnTxt="Cancelled"
    //         className="s-auto-paycut-btn disable"
    //         disabled
    //       />
    //     ) : (
    //       <Button
    //         btnTxt="Cancel Membership"
    //         className="s-auto-paycut-btn"
    //         onClick={onCancelPayCut}
    //       />
    //     )}
    //     {!subscriptionDetails?.subscription?.cancelAtPeriodEnd ? (
    //       <div className="info-box-cancel-wrap">
    //         <div className="info-box-cancel">
    //           <svg
    //             width="23"
    //             height="22"
    //             viewBox="0 0 23 22"
    //             fill="none"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path
    //               d="M10.0908 15.5859H11.9253V10.0859H10.0908V15.5859ZM11.0081 8.2526C11.2679 8.2526 11.4859 8.1646 11.662 7.9886C11.8381 7.8126 11.9259 7.59505 11.9253 7.33594C11.9253 7.07622 11.8372 6.85866 11.6611 6.68327C11.485 6.50788 11.2673 6.41988 11.0081 6.41927C10.7482 6.41927 10.5305 6.50727 10.355 6.68327C10.1795 6.85927 10.0915 7.07683 10.0908 7.33594C10.0908 7.59566 10.1789 7.81352 10.355 7.98952C10.5311 8.16552 10.7488 8.25322 11.0081 8.2526ZM11.0081 20.1693C9.73924 20.1693 8.54687 19.9285 7.43093 19.4469C6.31499 18.9654 5.34427 18.3124 4.51878 17.488C3.69329 16.663 3.03993 15.6929 2.5587 14.5776C2.07747 13.4623 1.83655 12.2707 1.83594 11.0026C1.83594 9.73455 2.07686 8.54288 2.5587 7.4276C3.04054 6.31233 3.6939 5.34219 4.51878 4.51719C5.34427 3.69219 6.31499 3.03922 7.43093 2.55827C8.54687 2.07733 9.73924 1.83655 11.0081 1.83594C12.2769 1.83594 13.4692 2.07672 14.5852 2.55827C15.7011 3.03983 16.6718 3.6928 17.4973 4.51719C18.3228 5.34219 18.9765 6.31233 19.4583 7.4276C19.9402 8.54288 20.1808 9.73455 20.1802 11.0026C20.1802 12.2707 19.9392 13.4623 19.4574 14.5776C18.9756 15.6929 18.3222 16.663 17.4973 17.488C16.6718 18.313 15.7011 18.9663 14.5852 19.4479C13.4692 19.9294 12.2769 20.1699 11.0081 20.1693Z"
    //               fill="#6C6D6F"
    //             />
    //           </svg>
    //           <span>
    //             To stop the recurring payments press the “Cancel membership”
    //             button above.
    //           </span>
    //         </div>
    //       </div>
    //     ) : null}
    //   </div>
    // </div>
    <div className="manage-subscription-container">
      <Card className="manage-subscription-card">
        <div className="manage-subscription-header">
          <div className="manage-subscription-icon">
            <img src="/static/member-icon.png" alt="Member Icon" />
          </div>
          <div className="manage-subscription-title">
            <div className="title">Member</div>
            <div className="description">You have subscribed as a member</div>
          </div>
        </div>

        <div className="manage-subscription-details">
          <div className="detail-item">
            <div className="detail-icon">
              <div className="img-box">
              <img src={IconPlan} height="11px" width="15px" alt="Plan Icon" />
              </div>
             
              <span className="detail-label">Plan</span>
            </div>

            <span className="detail-value">
              {subscriptionDetailData?.subscription?.plan?.interval === "month"
                ? "Monthly"
                : "Yearly"}
            </span>
          </div>

          <div className="detail-item">
            <div className="detail-icon">
            <div className="img-box">
              <img
              height="12px"
              width="16px"
                src={IconMembershipAmount}
                alt="Membership Amount Icon"
              />
              </div>
              <span className="detail-label">Membership amount</span>
            </div>

            <div className="detail-value">
              {(subscriptionDetailData?.subscription?.status === "active" )
                ? `${subscriptionDetailData?.subscription?.plan?.currency.toUpperCase()} ${
                    subscriptionDetailData?.subscription?.plan?.amount / 100
                  }`
                : "Free trial"}
            </div>
          </div>

          {(subscriptionDetailData?.subscription?.status === "active") && (
            <div className="detail-item">
              <div className="detail-icon">
              <div className="img-box">
                <img
                  height="13.5px"
                  width="10.5px"
                    src={IconTotalAmount}
                  alt="Total Amount Icon"
                />
                </div>
                <span className="detail-label">Total amount paid</span>
              </div>

              <div className="detail-value">{`${subscriptionDetailData?.subscription?.plan?.currency.toUpperCase()} ${
                subscriptionDetailData?.totalAmount?.totalPayment ? subscriptionDetailData?.totalAmount?.totalPayment : 0
              }`}</div>
            </div>
          )}
          <div className="detail-item">
            <div className="detail-icon">
              
                <div className="img-box">
                  <TimeIcon />
                </div>
              <span className="detail-label">Subscribed on</span>
            </div>

            <div className="detail-value">
              {DateFormatter(
                subscriptionDetailData?.subscription?.currentPeriodStart
              )}
            </div>
          </div>

          <div className="detail-item">
            <div className="detail-icon">
            <div className="img-box">
              <img  height="14px"
                  width="14px"
                    src={IconRenewal} alt="Renewal Icon" />
</div>
              <span className="detail-label">Renewal</span>
            </div>

            <div className="detail-value">
              {" "}
              {DateFormatter(
                subscriptionDetailData?.subscription?.currentPeriodEnd
              )}
            </div>
          </div>
        </div>
      </Card>
      <div className="manage-subscription-bottom">
        <div className="bottom-content">
          <div className="bottom-icon">
            <img src={IconDangerGray} alt="info Icon" />
          </div>
          <div className="bottom-description">
            Clicking on the below tabs will redirect you to Stripe.
          </div>{" "}
        </div>

        <div className="bottom-buttons">
          <Button
            variant="outlined"
            className="bottom-btn"
            onClick={handleRedirect}
          >
            Manage subscription
          </Button>
          <Button
            variant="outlined"
            className="bottom-btn"
            onClick={handleRedirect}
          >
            Billing information
          </Button>
          <Button
            variant="outlined"
            className="bottom-btn"
            onClick={handleRedirect}
          >
            Invoice history
          </Button>
        </div>
      </div>

      {showRedirectingModal && (
        <div className="modal show">
          <div
            style={{
              background: "#ffffff",
              borderRadius: "12px",
              padding: "30px",
            }}
          >
            Please wait redirecting...
          </div>
        </div>
      )}
    </div>
    // <Subscription2 />
  );
};

export default SubsPlanCard;
