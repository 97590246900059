import Button from "../../../global/Button";
import {
  emptyFunction, formatNumber, scrollToTop,
} from "../../../utils";
import moment from "moment";
import React, { useRef, useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import ReactSimplyCarousel from "react-simply-carousel";
import { GENDER_PREFERENCE, LISTING_TYPE, USER_TYPE } from "../../../types";
import img from "../../../assets/img/fitness.png";
import img2 from "../../../assets/img/star.png";

export const EventsMapCard = ({ showEvent, onClickOutside, item }) => {
  const ref = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  // function EventImageCarousel({ item }: any) {
  //   const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  //   return (
  //     <div>
  //       {item?.photos.length > 0 ? (
  //         <ReactSimplyCarousel
  //           activeSlideIndex={activeSlideIndex}
  //           onRequestChange={setActiveSlideIndex}
  //           itemsToShow={1}
  //           itemsToScroll={1}
  //           dotsNav={{
  //             show: true,
  //             containerProps: {
  //               className: "dots-nav-container",
  //               style: {
  //                 position: "absolute",
  //                 bottom: "30%",
  //                 gap: "6px",
  //                 width: "100%",
  //                 display: "flex",
  //                 justifyContent: "center",
  //               },
  //             },
  //             itemBtnProps: {
  //               style: {
  //                 height: 6,
  //                 width: 6,
  //                 borderRadius: "50%",
  //                 border: 0,
  //                 background: "rgba(255, 255, 255, 0.4)",
  //               },
  //             },
  //             activeItemBtnProps: {
  //               style: {
  //                 height: 6,
  //                 width: 6,
  //                 borderRadius: "50%",
  //                 border: 0,
  //                 background: "white",
  //               },
  //             },
  //           }}
  //           forwardBtnProps={{
  //             //here you can also pass className, or any other button element attributes
  //             className: "image-carousel-button",

  //             style: {
  //               background: "rgba(255, 255, 255, 0.4)",
  //               borderRadius: "50%",
  //               border: "none",
  //               cursor: "pointer",
  //               height: 24,
  //               width: 24,
  //               position: "absolute",
  //               zIndex: "0",
  //               top: "50%",
  //               right: "16px",
  //               translate: "0 -50%",
  //               display: "flex",
  //               justifyContent: "center",
  //               alignItems: "center",
  //             },
  //             children: (
  //               <img
  //                 style={{ width: "16px", height: "16px" }}
  //                 alt=""
  //                 src="/static/chevron-black-right.svg"
  //               />
  //             ),
  //           }}
  //           backwardBtnProps={{
  //             //here you can also pass className, or any other button element attributes
  //             className: "image-carousel-button",
  //             style: {
  //               background: "rgba(255, 255, 255, 0.4)",
  //               borderRadius: "50%",
  //               border: "none",
  //               cursor: "pointer",
  //               height: 24,
  //               width: 24,
  //               position: "absolute",
  //               zIndex: "99",
  //               top: "50%",
  //               left: "16px",
  //               translate: "0 -50%",
  //               display: "flex",
  //               justifyContent: "center",
  //               alignItems: "center",
  //             },
  //             children: (
  //               <img
  //                 style={{ width: "16px", height: "16px" }}
  //                 alt=""
  //                 src="/static/chevron-black-left.svg"
  //               />
  //             ),
  //           }}
  //           speed={400}
  //           easing="linear"
  //         >
  //           {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}

  //           {item?.photos.map((ph) =>
  //             /\.(mp4|mov|avi)$/.test(ph?.documentName?.toLowerCase()) ? (
  //               <>
  //                 <video
  //                   src={ph?.uploadDocument}
  //                   className="event_img"
  //                   //preload="none"
  //                   //autoPlay
  //                   controls
  //                   controlsList="nodownload"
  //                   // playsInline
  //                   // loop
  //                   muted
  //                   style={{ cursor: "pointer" }}
  //                   onClick={(e) => {
  //                     e.preventDefault();
  //                     scrollToTop();
  //                     navigate(`listing-two/${item.id}`);
  //                   }}
  //                 >
  //                   <source src={ph?.uploadDocument} />
  //                 </video>
  //               </>
  //             ) : (
  //               <img
  //                 src={ph?.uploadDocument || "/static/expertise2.png"}
  //                 className="event_img"
  //                 alt=""
  //                 loading="lazy"
  //                 onClick={(e) => {
  //                   e.preventDefault();
  //                   scrollToTop();
  //                   navigate(`listing-two/${item.id}`);
  //                 }}
  //               />
  //             )
  //           )}
  //         </ReactSimplyCarousel>
  //       ) : /\.(mp4|mov|avi)$/.test(item?.media?.toLowerCase()) ? (
  //         <>
  //           <video
  //             src={item?.media}
  //             className="event_img"
  //             //preload="none"
  //             // autoPlay
  //             controls
  //             controlsList="nodownload"
  //             // playsInline
  //             // loop
  //             muted
  //             style={{ cursor: "pointer" }}
  //             onClick={(e) => {
  //               e.preventDefault();
  //               scrollToTop();
  //               navigate(`listing-two/${item.id}`);
  //             }}
  //           >
  //             <source src={item?.media} />
  //           </video>
  //         </>
  //       ) : (
  //         <img
  //           src={item?.media || "/static/expertise2.png"}
  //           className="event_img"
  //           alt=""
  //           loading="lazy"
  //           onClick={(e) => {
  //             e.preventDefault();
  //             scrollToTop();
  //             navigate(`listing-two/${item.id}`);
  //           }}
  //         />
  //       )}
  //     </div>
  //   );
  // }
  function EventImageCarousel({ item }: any) {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    console.log(item, "===============//////////////");
    return (
      <div className="carousal-item-custom">
        {item?.photos.length > 1 ? (
          <ReactSimplyCarousel
            activeSlideIndex={activeSlideIndex}
            onRequestChange={setActiveSlideIndex}
            itemsToShow={1}
            itemsToScroll={1}
            dotsNav={{
              show: true,
              containerProps: {
                className: "dots-nav-container",
                style: {
                  position: "absolute",
                  bottom: "10%",
                  gap: "6px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                },
              },
              itemBtnProps: {
                style: {
                  height: 6,
                  width: 6,
                  borderRadius: "50%",
                  border: 0,
                  background: "rgba(255, 255, 255, 0.4)",
                },
              },
              activeItemBtnProps: {
                style: {
                  height: 6,
                  width: 6,
                  borderRadius: "50%",
                  border: 0,
                  background: "white",
                },
              },
            }}
            forwardBtnProps={{
              //here you can also pass className, or any other button element attributes
              className: "image-carousel-button",

              style: {
                background: "rgba(255, 255, 255, 0.4)",
                borderRadius: "50%",
                border: "none",
                cursor: "pointer",
                height: 24,
                width: 24,
                position: "absolute",
                zIndex: "0",
                top: "50%",
                right: "16px",
                translate: "0 -50%",
                display: "none",
                justifyContent: "center",
                alignItems: "center",
              },
              children: (
                <img
                  style={{ width: "16px", height: "16px" }}
                  alt=""
                  src="/static/chevron-black-right.svg"
                />
              ),
            }}
            backwardBtnProps={{
              //here you can also pass className, or any other button element attributes
              className: "image-carousel-button",
              style: {
                background: "rgba(255, 255, 255, 0.4)",
                borderRadius: "50%",
                border: "none",
                cursor: "pointer",
                height: 24,
                width: 24,
                position: "absolute",
                zIndex: "99",
                top: "50%",
                left: "16px",
                translate: "0 -50%",
                display: "none",
                justifyContent: "center",
                alignItems: "center",
              },
              children: (
                <img
                  style={{ width: "16px", height: "16px" }}
                  alt=""
                  src="/static/chevron-black-left.svg"
                />
              ),
            }}
            speed={400}
            easing="linear"
          >
            {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}

            {item?.photos.map((ph) =>
              /\.(mp4|mov|avi)$/.test(ph?.documentName?.toLowerCase()) ? (
                <>
                  <video
                    src={ph?.uploadDocument}
                    className="event_img"
                    //preload="none"
                    //autoPlay
                    controls
                    disablePictureInPicture
                    controlsList="nodownload noplaybackrate nofullscreen"
                    // playsInline
                    // loop
                    muted
                    style={{ cursor: "pointer", minWidth: '295px', height: '295px' }}
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToTop();
                      window.open("/listing-two/" + item.id);
                    }}
                  >
                    <source src={ph?.uploadDocument} />
                  </video>
                </>
              ) : (
                <img
                  src={ph?.uploadDocument || "/static/expertise2.png"}
                  className="event_img"
                  //preload="none"
                  // autoPlay
                  controls
                  controlsList="nodownload"
                  // playsInline
                  // loop
                  muted
                  style={{ cursor: "pointer", minWidth: '295px', height: '295px' }}
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToTop();
                    window.open("/listing-two/" + item.id);
                  }}
                />
              )
            )}
          </ReactSimplyCarousel>
        ) : item?.photos.length == 1 ? (
          /\.(mp4|mov|avi)$/.test(
            item?.photos[0].documentName?.toLowerCase()
          ) ? (
            <>
              <div className="individual-image-video-block">
                <div className="individual-image-video-block-2" style={{ width: '295px' }}>
                  <video
                    disablePictureInPicture
                    src={item?.photos[0].uploadDocument}
                    className="event_img"
                    //preload="none"
                    // autoPlay
                    controls
                    controlsList="nodownload noplaybackrate nofullscreen"
                    // playsInline
                    // loop
                    muted
                    style={{ cursor: "pointer", width: '295px' }}
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToTop();
                      window.open("/listing-two/" + item.id);
                    }}
                  >
                    <source src={item?.photos[0].uploadDocument} />
                  </video>
                </div>
              </div>
            </>
          ) : (
            <div className="individual-image-video-block">
              <div className="individual-image-video-block-2 " style={{ width: '295px' }}>
                <img
                  src={
                    item?.photos[0].uploadDocument || "/static/expertise2.png"
                  }
                  className="event_img"
                  alt=""
                  loading="lazy"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToTop();
                    window.open("/listing-two/" + item.id);
                  }}
                />
              </div>
            </div>
          )
        ) : /\.(mp4|mov|avi)$/.test(item?.media?.toLowerCase()) ? (
          <>
            <div className="individual-image-video-block">
              <div className="individual-image-video-block-2" style={{ width: '295px' }}>
                <video
                  disablePictureInPicture
                  src={item?.media}
                  className="event_img"
                  //preload="none"
                  // autoPlay
                  controls
                  controlsList="nodownload noplaybackrate nofullscreen"
                  // playsInline
                  // loop
                  muted
                  style={{ cursor: "pointer", minWidth: '295px', height: '295px' }}
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToTop();
                    window.open("/listing-two/" + item.id);
                  }}
                >
                  <source src={item?.media} />
                </video>
              </div>
            </div>
          </>
        ) : (
          <div className="individual-image-video-block">
            <div className="individual-image-video-block-2" style={{ width: '295px' }}>
              <img
                src={item?.media || "/static/expertise2.png"}
                className="event_img"
                alt=""
                loading="lazy"
                style={{ cursor: "pointer", minWidth: '295px', height: '295px' }}
                onClick={(e) => {
                  e.preventDefault();
                  scrollToTop();
                  window.open("/listing-two/" + item.id);
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
  return (
    <div
      ref={ref}

    >
      <div>
        <div className="event-link-title">
          <div className="listing_box" key={1}>
            <div className="crousel-container">
              {/* <div className="span_box">
                  <span
                    className="heart"
                    onClick={() => {
                      if (item.isFavourite) {
                        handleDislike(item.id);
                      } else {
                        handleLike(item.id);
                      }
                    }}
                  >
                    {item.isFavourite ? (
                      <img src="/static/heartred.svg" alt="" />
                    ) : (
                      <img
                        src="/static/card-heart.svg"
                        alt=""
                      />
                    )}
                  </span>
                </div>
                <div className="span_box">
                  <span
                    className="share"
                    onClick={() => {
                      setDetailsData(item);
                      setShowShare(true);
                    }}
                  >
                    <img
                      src="/static/Card-Share-Icon.svg"
                      alt=""
                    />
                  </span>
                </div> */}
              <div className="span_box">
                {item.listingEventType !== "V" ? (
                  <>
                    <img
                      className="event_type"
                      src="/static/event-type2.svg"
                      alt=""
                    />
                  </>
                ) : (
                  <>
                    <span className="event_type">
                      <img
                        src="/static/event-type.svg"
                        alt=""
                      />
                    </span>
                  </>
                )}
              </div>
              {item.listingEventType !== "V" ? (
                <div className="span_box">
                  {item?.distance ? (
                    <span className="distance" style={{ top: '50px' }}>
                      {formatNumber(
                        item.distance?.toFixed(2)
                      )}{" "}
                      km away...
                    </span>
                  ) : null}
                </div>
              ) : (
                ""
              )}

              <EventImageCarousel item={item} />
              {item?.listingType === LISTING_TYPE.SPECIAL && (
                <div className="special-box">
                  <img
                    src={img2}
                    alt=""
                    style={{
                      height: "30px",
                      width: "30px",
                      marginRight: "auto",
                    }}
                  />
                  <div className="exc-text">
                    Exclusive for Members
                  </div>
                </div>
              )}

              <div className="span_box">
                {item?.listingType === LISTING_TYPE.C ? (
                  <span className="price">Join</span>
                ) : item?.price || item?.monthlyCost ? (
                  <span className="price">
                    {Number(item?.price) > 1 ? (
                      "Free"
                    ) : (
                      <>
                        {" "}
                        AUD {item?.price || item?.monthlyCost}
                      </>
                    )}
                  </span>
                ) : (
                  <span className="price">Free</span>
                )}
              </div>
            </div>

            <div
              style={{ paddingLeft: '7px' }}
              className="limited-event-info"
              onClick={(e) => {
                e.preventDefault();
                scrollToTop();
                window.open("/listing-two/" + item.id);
              }}
            >
              <div className="title item">
                <Link
                  className="event-link-title"
                  to={`listing-two/${item.id}`}
                >
                  {item?.title}
                </Link>
              </div>
              <div
                className="item"
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  minHeight: "16px",
                }}
              >
                {item?.address ? (
                  <>
                    <img
                      src="/static/ion_location-outline.svg"
                      alt=""
                    />
                    <span style={{ paddingLeft: "4px" }}>
                      {
                        item?.address
                      }
                    </span>
                  </>
                ) : null}
              </div>
              {item?.listingType === LISTING_TYPE.C ? null : (
                <div
                  className="item"
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  <img src="/static/calendar-2.svg" alt="" />
                  <span style={{ paddingLeft: "4px" }}>
                    {moment(item?.upcomingEventDate?.date).format(
                      "DD MMM, YYYY"
                    )}
                  </span>
                  <img
                    src="/static/EmptyDot.svg"
                    style={{ marginBottom: "3px", marginLeft: "4px" }}
                    alt=""
                  />
                  <span style={{ paddingLeft: "4px" }}>
                    {moment(item?.upcomingEventStartTime?.substring(0, 5), [
                      "HH:mm",
                    ]).format("hh:mmA")}{" "}
                    -{" "}
                    {moment(item?.upcomingEventEndTime?.substring(0, 5), [
                      "HH:mm",
                    ]).format("hh:mmA")}
                  </span>
                </div>
              )}
            </div>

            {item?.listingType === LISTING_TYPE.SPECIAL ? (
              <div className="name name-special">
                <img
                  src={img}
                  alt=""
                  style={{
                    height: "100%",
                    width: "65%",
                    marginRight: "auto",
                  }}
                />
                {/* <span>Fitness Mates</span> */}
              </div>
            ) : (
              <div
                className="name"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/our-mates/${item?.createdBy?.id}`);
                }}
              >
                <Link
                  className="event-link-title"
                  to={`listing-two/${item.id}`}
                >
                  {["mp4", "mov", "avi"].includes(
                    item?.createdBy?.profilePicture
                      ?.split(".")
                      .splice(-1)[0]
                      ?.toLowerCase()
                  ) ? (
                    <video
                      disablePictureInPicture
                      src={item?.createdBy?.profilePicture}
                      //preload="none"
                      className="event_img"
                      style={{ cursor: "pointer", minWidth: '295px', height: '295px' }}
                      autoPlay
                      //controls
                      // playsInline
                      // loop
                      muted
                    >
                      <source src={item?.createdBy?.profilePicture} />
                    </video>
                  ) : (
                    <img
                      src={
                        item?.createdBy?.profilePicture ||
                        "/static/dummyimg.svg"
                      }
                      alt=""
                      loading="lazy"
                    />
                  )}
                </Link>
                <span>
                  {" "}
                  {item?.createdBy?.tradingName
                    ? item?.createdBy?.tradingName
                    : item?.createdBy?.name}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div className="event-link-title">
        <div className="listing_box">
          <div className="crousel-container">
            <EventImageCarousel item={showEvent} />
          </div>
          <div className="span_box"
            onClick={(e) => {
              e.preventDefault();
              navigate(`listing-two/${showEvent.id}`);
            }}
          >
            {showEvent.distance ? <span className="distance">{formatNumber(showEvent.distance.toFixed(2))} km away...</span> : ""}


            <span className="price">$ {showEvent.price}</span>
          </div>

          <div
            className="limited-event-info"
            onClick={(e) => {
              e.preventDefault();
              navigate(`listing-two/${showEvent.id}`);
            }}
          >
            <div className="title item">{showEvent.title}</div>
            <div className="item">{showEvent.address}</div>
            <div className="item">
              Upcoming event date: <span>{moment(showEvent.upcomingEventDate).format('D MMM, YYYY')}</span>
            </div>
            <div className="item">
              Participants:<span> {(!showEvent.slotsAvailable) ? "0" : showEvent.slotsAvailable} booked out of {showEvent.maxParticipants}</span>
            </div>
            <div className="item">
              Event Type: <span className="event-type">{showEvent.listingEventType === "P" ? "Physical" : "Virtual"}</span>
            </div>
          </div>

          <div className="name" onClick={() => {
            navigate(`/our-mates/${showEvent?.createdBy?.id}`);
          }}>
            <img src={(showEvent?.createdBy?.profilePicture) ? showEvent?.createdBy?.profilePicture : "/static/dummyimg.svg"} alt="" loading="lazy" />

            <span> {(showEvent?.createdBy?.tradingName) ? showEvent?.createdBy?.tradingName : showEvent?.createdBy?.name}</span>
          </div>
        </div>
      </div> */}
    </div>
  );
};
