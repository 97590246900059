import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import IconToggleOn from "../../../assets/img/connectIcons/collapse-on.png";
import IconToggleOff from "../../../assets/img/connectIcons/collapse-off.png";
import IconSeeAll from "../../../assets/img/connectIcons/see-all.svg";

const ConnectScrollableCard = ({
  title,
  description,
  path = "/connect/favourite",
  ClassName,
  render,
  isLoading,
}) => {
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);
  const [isAtLeftEdge, setIsAtLeftEdge] = useState(true);
  const [isAtRightEdge, setIsAtRightEdge] = useState(true);
  const scrollByAmount = (amount: any) => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current?.scrollBy({
        left: amount,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = scrollContainerRef.current;

      if (!scrollContainer) return;

      const scrollLeft = scrollContainer.scrollLeft;
      const scrollWidth = scrollContainer.scrollWidth;
      const clientWidth = scrollContainer.clientWidth;

      setIsAtLeftEdge(scrollLeft === 0);
      // console.log("width",scrollLeft, scrollWidth, clientWidth)
      setIsAtRightEdge(scrollLeft + clientWidth + 50 >= scrollWidth);
    };

    const scrollContainer = scrollContainerRef.current;
    scrollContainer.addEventListener("scroll", handleScroll);

    // Initial check
    handleScroll();

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  
  return (
    <div className="fav-connections">
      <div className="suggestion-wrapper ">
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "70% 30%",
          }}
        >
          <span className="suggestion-heading">{title}</span>
          <div className="see-all-btn-scrollable">
            <img src={IconSeeAll} height="27px" width="67px" onClick={() => navigate(path)}/>
          </div>
        </div>
        <div className="suggestion-desc">{description}</div>
        <div
          className="fav-overflow"
          style={
            isAtLeftEdge
              ? { display: "grid", gridTemplateColumns: "0% 98% 2%" }
              : isAtRightEdge
              ? { display: "grid", gridTemplateColumns: "2% 98% 0%" }
              : { display: "grid", gridTemplateColumns: "2% 96% 2%" } 
          }
        >
          <div className="scroll-icon-wrap" style={{ paddingRight: "15px" }}>
            {!isAtLeftEdge && (
              <img src={IconToggleOff} className="scroll-icon-style" onClick={() => scrollByAmount(-200)} />
            )}
          </div>

          <div className={ClassName} ref={scrollContainerRef}>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div className="lds-ring">
                  <div style={{ textAlign: "center" }}>
                    <img
                      style={{ width: "50px" }}
                      src="/static/spinner.svg"
                      alt="loader"
                    />
                  </div>

                  <div className="text__container">
                    <span>Please wait... Loading</span>
                  </div>
                </div>
              </div>
            ) : (
              render
            )}
          </div>
          {!isAtRightEdge && <div className="scroll-icon-wrap" style={{ paddingLeft: "15px" }}>
            <img src={IconToggleOn} className="scroll-icon-style" onClick={() => scrollByAmount(250)} />
          </div> }
          
        </div>
      </div>
    </div>
  );
};

export default ConnectScrollableCard;
