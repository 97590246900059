interface FullLoaderProps {
  style?: object;
  loaderClass?: string;
}
const FullLoader = (props: FullLoaderProps) => {
  const { loaderClass } = props;
  return (
    <div className={loaderClass ? `${loaderClass} lds-ring ` : "lds-ring"}>
      <img style={{ width: "25px" }} src="/static/spinner.svg" alt="loader" />
    </div>
  );
};

export default FullLoader;
