import React from "react";
import Button from "../../global/Button";
import { useNavigate } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";

const StripeFailed = () => {
  const navigate = useNavigate();
  return (
    <div className="coming_soon ">
      <img src="/static/orange-logo.svg" alt="" />
      <div className="stripe-connect-wrapper">
        <h1 className="wl-title mb-3">Failed!</h1>
        <div className="text-center">
          Sorry! There was some problem configuring stripe connect on fitness
          mates. Kindly contact support.
        </div>
        <div className="mt-4">
          <Button
            btnTxt="Back to fitness"
            className={"backtologin"}
            onClick={() => {
              // navigate(ROUTE_CONSTANTS.events)
              if(window?.closeWindow){
                window?.closeWindow();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default StripeFailed;
