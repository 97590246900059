import React from "react";
const MoreIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="More">
        <path
          id="Shape"
          d="M8.66732 13.6669C8.66732 14.5874 7.92113 15.3336 7.00065 15.3336C6.08018 15.3336 5.33398 14.5874 5.33398 13.6669C5.33398 12.7464 6.08018 12.0002 7.00065 12.0002C7.92113 12.0002 8.66732 12.7464 8.66732 13.6669ZM15.334 13.6669C15.334 14.5874 14.5878 15.3336 13.6673 15.3336C12.7468 15.3336 12.0007 14.5874 12.0007 13.6669C12.0007 12.7464 12.7468 12.0002 13.6673 12.0002C14.5878 12.0002 15.334 12.7464 15.334 13.6669ZM20.334 15.3336C21.2545 15.3336 22.0007 14.5874 22.0007 13.6669C22.0007 12.7464 21.2545 12.0002 20.334 12.0002C19.4135 12.0002 18.6673 12.7464 18.6673 13.6669C18.6673 14.5874 19.4135 15.3336 20.334 15.3336Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default MoreIcon;
